'use client';

import React, { useState } from 'react';
import './GenUICard.css';

export interface CardAction {
  label: string;
  action: () => void;
  type?: 'primary' | 'secondary' | 'danger';
  disabled?: boolean;
}

export interface GenUICardProps {
  title: string;
  description?: string;
  type: 'memory' | 'resource' | 'event' | 'update' | 'quest';
  data?: Record<string, any>;
  actions?: CardAction[];
  className?: string;
  onClose?: () => void;
}

export const GenUICard: React.FC<GenUICardProps> = ({
  title,
  description,
  type,
  data = {},
  actions = [],
  className = '',
  onClose
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  const getIcon = () => {
    switch (type) {
      case 'memory':
        return '💭';
      case 'resource':
        return '📦';
      case 'event':
        return '📅';
      case 'update':
        return '📝';
      case 'quest':
        return '✨';
      default:
        return '📄';
    }
  };
  
  const renderContent = () => {
    switch (type) {
      case 'memory':
        return renderMemoryContent();
      case 'resource':
        return renderResourceContent();
      case 'event':
        return renderEventContent();
      case 'update':
        return renderUpdateContent();
      case 'quest':
        return renderQuestContent();
      default:
        return <p className="card-description">{description || 'No description available'}</p>;
    }
  };
  
  const renderMemoryContent = () => {
    const { content, keywords = [], createdAt } = data;
    const formattedDate = createdAt ? new Date(createdAt).toLocaleDateString() : '';
    
    return (
      <>
        {content && <p className="card-content">{content}</p>}
        
        {keywords?.length > 0 && (
          <div className="card-tags">
            {keywords.map((keyword: string, index: number) => (
              <span key={index} className="card-tag">{keyword}</span>
            ))}
          </div>
        )}
        
        {formattedDate && (
          <div className="card-date">
            <small>{formattedDate}</small>
          </div>
        )}
      </>
    );
  };
  
  const renderResourceContent = () => {
    const { name, description, location, contact, availability } = data;
    
    return (
      <>
        {description && <p className="card-description">{description}</p>}
        
        <div className="card-details">
          {location && (
            <div className="card-detail-item">
              <span className="card-detail-icon">📍</span>
              <span className="card-detail-text">{location}</span>
            </div>
          )}
          
          {contact && (
            <div className="card-detail-item">
              <span className="card-detail-icon">👤</span>
              <span className="card-detail-text">{contact}</span>
            </div>
          )}
          
          {availability && (
            <div className="card-detail-item">
              <span className="card-detail-icon">🕒</span>
              <span className="card-detail-text">{availability}</span>
            </div>
          )}
        </div>
      </>
    );
  };
  
  const renderEventContent = () => {
    const { date, time, location, organizer, description } = data;
    
    return (
      <>
        {description && <p className="card-description">{description}</p>}
        
        <div className="card-details">
          {date && (
            <div className="card-detail-item">
              <span className="card-detail-icon">📅</span>
              <span className="card-detail-text">{date}{time ? ` at ${time}` : ''}</span>
            </div>
          )}
          
          {location && (
            <div className="card-detail-item">
              <span className="card-detail-icon">📍</span>
              <span className="card-detail-text">{location}</span>
            </div>
          )}
          
          {organizer && (
            <div className="card-detail-item">
              <span className="card-detail-icon">👤</span>
              <span className="card-detail-text">{organizer}</span>
            </div>
          )}
        </div>
      </>
    );
  };
  
  const renderUpdateContent = () => {
    const { current, proposed, field } = data;
    
    return (
      <>
        {description && <p className="card-description">{description}</p>}
        
        <div className="card-update">
          {field && <div className="card-update-field">Field: {field}</div>}
          
          <div className="card-update-comparison">
            <div className="card-update-item">
              <div className="card-update-label">Current</div>
              <div className="card-update-value">{current}</div>
            </div>
            
            <div className="card-update-arrow">→</div>
            
            <div className="card-update-item">
              <div className="card-update-label">Proposed</div>
              <div className="card-update-value">{proposed}</div>
            </div>
          </div>
        </div>
      </>
    );
  };
  
  const renderQuestContent = () => {
    const { description, reward, progress, total, completed } = data;
    const progressPercent = total ? Math.round((progress / total) * 100) : 0;
    
    return (
      <>
        {description && <p className="card-description">{description}</p>}
        
        {typeof progress !== 'undefined' && typeof total !== 'undefined' && (
          <div className="card-progress">
            <div className="card-progress-bar">
              <div 
                className="card-progress-fill" 
                style={{ width: `${progressPercent}%` }}
              ></div>
            </div>
            <div className="card-progress-text">
              {progress} / {total} ({progressPercent}%)
            </div>
          </div>
        )}
        
        {reward && (
          <div className="card-reward">
            <span className="card-reward-icon">🎁</span>
            <span className="card-reward-text">{reward}</span>
          </div>
        )}
        
        {completed && (
          <div className="card-completed">
            <span className="card-completed-icon">✅</span>
            <span className="card-completed-text">Completed!</span>
          </div>
        )}
      </>
    );
  };
  
  return (
    <div className={`genui-card ${type}-card ${isExpanded ? 'expanded' : ''} ${className}`}>
      <div className="card-header" onClick={() => setIsExpanded(!isExpanded)}>
        <div className="card-icon">{getIcon()}</div>
        <h3 className="card-title">{title}</h3>
        <button 
          className="card-expand-button" 
          aria-label={isExpanded ? 'Collapse card' : 'Expand card'}
        >
          {isExpanded ? '−' : '+'}
        </button>
      </div>
      
      <div className="card-body">
        {renderContent()}
      </div>
      
      {actions.length > 0 && (
        <div className="card-actions">
          {actions.map((action, index) => (
            <button
              key={index}
              className={`card-action-button ${action.type || 'secondary'}`}
              onClick={action.action}
              disabled={action.disabled}
            >
              {action.label}
            </button>
          ))}
        </div>
      )}
      
      {onClose && (
        <button
          className="card-close"
          onClick={onClose}
          aria-label="Close card"
        >
          ×
        </button>
      )}
    </div>
  );
}; 