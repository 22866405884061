'use client';

import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useGlobalState } from './GlobalStateContext';
import { Loobricate } from './GlobalStateContext';

// Helper function to resolve loobricate ID to loobricate object
const resolveLoobricate = (loobricate: string | Loobricate | null): Loobricate | null => {
  if (!loobricate) return null;
  if (typeof loobricate === 'string') {
    // This is just a placeholder, in a real implementation this would fetch the loobricate
    return {
      id: loobricate,
      name: loobricate,
      description: '',
      address: '',
      adminUsername: '',
      tags: [],
      type: 'community',
      members: [],
      admins: [],
      dataType: 'loobricate'
    };
  }
  return loobricate;
};

interface ResourceData {
  title: string;
  description: string;
  offeringType: 'venue' | 'gear' | 'talent';
  tags: string[];
  loobricateId?: string;
}

interface ResourceUploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialData: ResourceData;
  onSubmit: (data: ResourceData) => Promise<void>;
}

export const ResourceUploadModal: React.FC<ResourceUploadModalProps> = ({
  isOpen,
  onClose,
  initialData,
  onSubmit
}) => {
  const { connectedLoobricates, activeLoobricate, userId, isAnonymous } = useGlobalState();
  const [formData, setFormData] = useState<ResourceData>(initialData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  
  // Process connected loobricates to ensure they are all objects
  const processedLoobricates = connectedLoobricates.map(loobricate => resolveLoobricate(loobricate)).filter(Boolean) as Loobricate[];

  // Check if user is anonymous in useEffect instead of during render
  useEffect(() => {
    if (isOpen && (isAnonymous || !userId || userId.startsWith('anon-'))) {
      onClose();
    }
  }, [isOpen, isAnonymous, userId, onClose]);

  useEffect(() => {
    // Set active loobricate as default if available
    const resolvedActiveLoobricate = resolveLoobricate(activeLoobricate);
    setFormData(prev => ({
      ...initialData,
      loobricateId: resolvedActiveLoobricate?.id || prev.loobricateId
    }));
  }, [initialData, activeLoobricate]);

  // If user is anonymous, render nothing but don't call onClose directly during render
  if (isAnonymous || !userId || userId.startsWith('anon-')) {
    return null;
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const tags = e.target.value.split(',').map(tag => tag.trim());
    setFormData(prev => ({ ...prev, tags }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!formData.loobricateId) {
      setError('Please select a Loob');
      return;
    }

    setIsSubmitting(true);
    setError(null);

    try {
      await onSubmit(formData);
      onClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to submit resource');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-50 p-4"
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            className="bg-[#1a1b26] rounded-lg w-full max-w-2xl overflow-hidden shadow-lg border border-gray-800"
          >
            <div className="p-6">
              <h2 className="text-2xl font-semibold text-white mb-4">
                Add Resource to LOOBRARY
              </h2>
              
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label className="block text-gray-300 mb-2">Loob</label>
                  <select
                    name="loobricateId"
                    value={formData.loobricateId || ''}
                    onChange={handleInputChange}
                    className="w-full bg-[#2a2b36] text-white rounded-lg p-3 border border-gray-600"
                  >
                    <option value="">Select a Loob</option>
                    {processedLoobricates
                      .filter(loobricate => 
                        loobricate.id && 
                        typeof loobricate.name === 'string' && 
                        loobricate.name.trim() !== ''
                      )
                      .map(loobricate => (
                        <option 
                          key={loobricate.id} 
                          value={loobricate.id}
                        >
                          {loobricate.name}
                        </option>
                      ))}
                  </select>
                </div>

                <div>
                  <label className="block text-gray-300 mb-2">Resource Type</label>
                  <select
                    name="offeringType"
                    value={formData.offeringType}
                    onChange={handleInputChange}
                    className="w-full bg-[#2a2b36] text-white rounded-lg p-3 border border-gray-600"
                  >
                    <option value="venue">Venue</option>
                    <option value="gear">Gear</option>
                    <option value="talent">Talent</option>
                  </select>
                </div>

                <div>
                  <label className="block text-gray-300 mb-2">Title</label>
                  <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    className="w-full bg-[#2a2b36] text-white rounded-lg p-3 border border-gray-600"
                    placeholder="Enter resource title"
                  />
                </div>

                <div>
                  <label className="block text-gray-300 mb-2">Description</label>
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    className="w-full bg-[#2a2b36] text-white rounded-lg p-3 border border-gray-600 min-h-[100px]"
                    placeholder="Enter resource description"
                  />
                </div>

                <div>
                  <label className="block text-gray-300 mb-2">Tags (comma-separated)</label>
                  <input
                    type="text"
                    value={formData.tags.join(', ')}
                    onChange={handleTagChange}
                    className="w-full bg-[#2a2b36] text-white rounded-lg p-3 border border-gray-600"
                    placeholder="Enter tags, separated by commas"
                  />
                </div>

                {error && (
                  <div className="text-red-400 bg-red-400/10 p-3 rounded-lg">
                    {error}
                  </div>
                )}

                <div className="flex justify-end gap-3 mt-6">
                  <button
                    type="button"
                    onClick={onClose}
                    className="px-4 py-2 text-gray-300 hover:text-white transition-colors"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn-gradient"
                  >
                    {isSubmitting ? 'Submitting...' : 'Submit Resource'}
                  </button>
                </div>
              </form>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

