/**
 * LLMResponseFormatter utility for enhancing and structuring AI responses
 * 
 * This utility preprocesses text from Large Language Models before rendering in the UI.
 * It applies consistent formatting to improve readability and visual hierarchy,
 * especially for structured content like numbered lists, principles, and callouts.
 * 
 * @module LLMResponseFormatter
 */

/**
 * Process raw LLM text to enhance formatting
 * @param text - The raw text from the LLM to be formatted
 * @returns Formatted text with enhanced markdown and structural improvements
 */
export const enhanceMarkdown = (text: string): string => {
  // Skip processing if text is empty
  if (!text || text.trim() === '') return text;

  let enhancedText = text;

  // Process DeepSeek's thinking tags
  enhancedText = formatThinkingContent(enhancedText);

  // Special pass for Burning Man camp listings - must come first
  enhancedText = processBurningManCamps(enhancedText);

  // Format camp information with proper spacing and emoji separators
  enhancedText = formatCampInformation(enhancedText);

  // Process numbered lists that look like principles (e.g., "1. **Radical Inclusion**:")
  enhancedText = formatPrinciples(enhancedText);

  // Format general numbered lists (different from principles)
  enhancedText = formatNumberedLists(enhancedText);

  // Format callouts and special sections
  enhancedText = formatCallouts(enhancedText);

  // Convert ascii stars/asterisks to proper markdown formatting
  enhancedText = convertAsciiEmphasis(enhancedText);

  // Add proper spacing for better readability
  enhancedText = improveSpacing(enhancedText);

  return enhancedText;
};

/**
 * Format DeepSeek's thinking content in <think> tags
 * @param text - Text that may contain <think> tags
 * @returns Text with formatted thinking content in collapsible elements
 */
const formatThinkingContent = (text: string): string => {
  // Check if <think> tags exist in the content
  if (!text.includes('<think>')) {
    return text;
  }
  
  // Handle streaming content (incomplete tags)
  const hasOpeningTag = text.includes('<think>');
  const hasClosingTag = text.includes('</think>');
  const isStreamingThinking = hasOpeningTag && !hasClosingTag;
  
  if (isStreamingThinking) {
    // Split content at the opening <think> tag
    const parts = text.split('<think>');
    
    // Everything after the <think> tag is thinking content
    if (parts.length > 1) {
      const thinkingContent = parts[1].trim();
      
      if (thinkingContent) {
        // For streaming content, keep the <think> tag to indicate streaming state
        return `
<div class="thinking-content" data-thinking="true">
  <details>
    <summary>View reasoning process</summary>
    <div class="thinking-details">
<think>${thinkingContent.replace(/\n/g, '<br/>')}
    </div>
  </details>
</div>
        `.trim();
      }
    }
    return text;
  }
  
  // Handle complete think tags
  return text.replace(
    /<think>([\s\S]*?)<\/think>/g, 
    (_, content) => {
      // Format the content for better display
      const formattedContent = content.trim()
        .replace(/\n/g, '<br/>')
        .replace(/```([^`]+)```/g, '<pre><code>$1</code></pre>');
      
      return `
<div class="thinking-content" data-thinking="true">
  <details>
    <summary>View reasoning process</summary>
    <div class="thinking-details">
${formattedContent}
    </div>
  </details>
</div>
      `.trim();
    }
  );
};

/**
 * Format numbered principles like the 10 principles of Burning Man
 * @param text - Text that may contain numbered principles
 * @returns Text with formatted principles using CSS classes
 */
const formatPrinciples = (text: string): string => {
  // Look for patterns like "1. **Radical Inclusion**:" or "1. *Radical Inclusion*:" or "1. Radical Inclusion:"
  const principleRegex = /(\d+)\.\s+(\*\*|\*)?([A-Za-z\s]+)(\*\*|\*)?(\:|\-)/g;
  
  return text.replace(principleRegex, (match, number, prefix, principle, suffix, separator) => {
    return `<div class="numbered-principle">
      <div class="principle-number">${number}.</div>
      <div class="principle-content">
        <span class="principle-name">${principle}</span>
      </div>
    </div>`;
  });
};

/**
 * Format regular numbered lists with better spacing and styling
 * @param text - Text that may contain standard numbered lists
 * @returns Text with properly formatted ordered lists
 */
const formatNumberedLists = (text: string): string => {
  // Skip if we already have HTML ordered lists
  if (text.includes('<ol>')) return text;
  
  // We don't want to match the principles already formatted, so we look for standard numbered lists
  // that don't have special formatting or colons after the item text
  const listRegex = /^\s*(\d+)\.\s+(?!\*\*)(?!\*)([^:\n]+)$/gm;
  
  // Check if we have a numbered list (at least 2 adjacent items)
  const listMatches = text.match(listRegex);
  if (listMatches && listMatches.length >= 2) {
    // Find consecutive list items
    let inList = false;
    let listContent = '';
    
    // Process line by line
    const lines = text.split('\n');
    const processedLines = [];
    
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      const listMatch = line.match(/^\s*(\d+)\.\s+(?!\*\*)(?!\*)([^:\n]+)$/);
      
      if (listMatch) {
        if (!inList) {
          // Start a new list
          inList = true;
          listContent = `<ol start="${listMatch[1]}">\n`;
        }
        
        // Add list item
        listContent += `  <li>${listMatch[2]}</li>\n`;
      } else if (inList) {
        // End current list
        inList = false;
        listContent += '</ol>';
        processedLines.push(listContent);
        processedLines.push(line);
      } else {
        processedLines.push(line);
      }
    }
    
    // If we were still in a list at the end
    if (inList) {
      listContent += '</ol>';
      processedLines.push(listContent);
    }
    
    return processedLines.join('\n');
  }
  
  return text;
};

/**
 * Format special callouts and sections
 * @param text - Text that may contain callout blocks
 * @returns Text with formatted callout blocks using CSS classes
 */
const formatCallouts = (text: string): string => {
  // Format blocks that look like tips, notes, warnings
  const calloutRegex = /(?:^|\n)(?:\> |\>\s*)((?:NOTE|TIP|WARNING|IMPORTANT|INFO):)\s*([^\n]+)/gi;
  
  return text.replace(calloutRegex, (match, type, content) => {
    const calloutType = type.replace(':', '').toLowerCase();
    return `\n> <div class="callout ${calloutType}">
> <strong>${type}</strong> ${content}
> </div>\n`;
  });
};

/**
 * Convert ascii-style emphasis to proper markdown
 * @param text - Text with potentially improper emphasis markup
 * @returns Text with properly formatted markdown emphasis
 */
const convertAsciiEmphasis = (text: string): string => {
  let processedText = text;
  
  // Process double asterisks for bold/strong text with improved regex
  // This covers both **Text** and ** Text ** patterns
  const doubleAsteriskPattern = /\*\*([^*\n]+?)\*\*/g;
  if (!text.includes('<strong>') && doubleAsteriskPattern.test(text)) {
    processedText = processedText.replace(doubleAsteriskPattern, '<strong>$1</strong>');
  }
  
  // Process special case with multiple asterisks in one line - like "**Ten Principles of Burning Man**"
  const titlesWithAsterisks = /\*\*([^*\n:]+?)(\*\*:|\*\*\.|\*\*\s|$)/g;
  processedText = processedText.replace(titlesWithAsterisks, (match, title, suffix) => {
    const cleanSuffix = suffix.replace(/\*\*/g, '');
    return `<strong>${title}</strong>${cleanSuffix}`;
  });
  
  // Handle single asterisks for italic/emphasis
  const singleAsteriskPattern = /(?<!\*)\*([^*\n]+?)\*(?!\*)/g;
  if (!processedText.includes('<em>') && singleAsteriskPattern.test(processedText)) {
    processedText = processedText.replace(singleAsteriskPattern, '<em>$1</em>');
  }
  
  // Handle underscore emphasis
  const underscoreEmphasis = /_([^_\n]+)_/g;
  if (!processedText.includes('<em class="underscore">') && underscoreEmphasis.test(processedText)) {
    processedText = processedText.replace(underscoreEmphasis, '<em class="underscore">$1</em>');
  }
  
  return processedText;
};

/**
 * Format camp information with proper spacing and structure
 */
const formatCampInformation = (text: string): string => {
  // First pass: process obvious camp info blocks
  // Look for patterns with emoji markers like camp name, location, offerings
  let processedText = processBasicCampInfo(text);
  
  // Process new format pattern that looks like: 
  // --- ✨ **CAMP NAME** ✨ --- 📍 LOCATION 🔥 OFFERINGS: list 🏄‍♀️ DESCRIPTION: text
  processedText = processedText.replace(
    /---\s*✨\s*(?:\*\*)?([A-Z0-9\s&'"\-]+)(?:\*\*)?\s*✨\s*---\s*📍\s*([^🔥]+)\s*🔥\s*OFFERINGS:\s*([^🏄‍♀️\n]+)\s*🏄‍♀️\s*DESCRIPTION:\s*([^---\n]+)/g,
    (match, campName, location, offerings, description) => {
      return `<div class="camp-card">
<div class="camp-title">✨ <strong>${campName.trim()}</strong> ✨</div>
<div class="camp-location">📍 ${location.trim()}</div>
<div class="camp-offerings">🔥 <strong>OFFERINGS:</strong> ${offerings.trim()}</div>
<div class="camp-description">🏄‍♀️ <strong>DESCRIPTION:</strong> ${description.trim()}</div>
</div>`;
    }
  );
  
  // Second pass: process camp information with event listings
  return processEventsInCampInfo(processedText);
};

/**
 * Process basic camp information (name, location, offerings)
 */
const processBasicCampInfo = (text: string): string => {
  // Various patterns to match different camp information formats
  
  // Pattern 1: 🔥 Camp Name 📍 Location - Offerings: list of offerings
  const campPattern1 = /(🔥\s+(?:\*\*)?[\w\s\'\"\-\.&,]+(?:\*\*)?\s+📍[\w\s\d\:\&\-\,\.]+)(?:\s*\-\s*(?:\*\*)?([^:]+):(?:\*\*)?\s+(.+))?/g;
  
  // Pattern 2: 🔥 Camp Name 📍 Location ** Offerings: ** list
  const campPattern2 = /(🔥\s+(?:\*\*)?[\w\s\'\"\-\.&,]+(?:\*\*)?\s+📍[\w\s\d\:\&\-\,\.]+)(?:\s*(?:\*\*)?([^:]+):(?:\*\*)?\s+(.+))?/g;
  
  // Pattern 3: ✨ **CAMP NAME** ✨ with no explicit formatting (for more casual mentions)
  const campPattern3 = /✨\s*(?:\*\*)?([A-Z\s\d&'"\-]+)(?:\*\*)?\s*✨/g;
  
  // Apply pattern 1
  let processedText = text.replace(campPattern1, replaceWithFormattedCampInfo);
  
  // Apply pattern 2 (only if not matched by pattern 1)
  processedText = processedText.replace(campPattern2, (match, header, section, items) => {
    // Only process if this match wasn't already processed by pattern 1
    if (match === text.match(campPattern1)?.[0]) {
      return match;
    }
    return replaceWithFormattedCampInfo(match, header, section, items);
  });
  
  // Apply pattern 3 for just camp names
  processedText = processedText.replace(campPattern3, (match, campName) => {
    // Skip if it's part of a larger pattern we already processed
    if (processedText !== text && processedText.includes(match)) {
      return match;
    }
    return `<span class="camp-name-highlight">✨ <strong>${campName.trim()}</strong> ✨</span>`;
  });
  
  return processedText;
};

/**
 * Process events listings in camp information
 */
const processEventsInCampInfo = (text: string): string => {
  // Look for event listings like "🗓️ Events:" or "Events:" within camp info
  return text.replace(
    /(<div class="camp-info">[\s\S]*?)(?:🗓️\s*(?:\*\*)?Events:(?:\*\*)?\s*)([\s\S]*?)(<\/div>)/g,
    (match, prefix, eventsList, suffix) => {
      // Process the events list
      let formattedEvents = eventsList.trim();
      
      // If the events list has line items or bullet points
      if (formattedEvents.includes('-') || formattedEvents.includes('•')) {
        formattedEvents = formattedEvents
          .split(/[-•]/)
          .filter(item => item.trim())
          .map(item => `  • ${item.trim()}`)
          .join('\n');
          
        return `${prefix}\n<strong>🗓️ Events:</strong>\n${formattedEvents}\n${suffix}`;
      }
      
      return `${prefix}\n<strong>🗓️ Events:</strong>\n${formattedEvents}\n${suffix}`;
    }
  );
};

/**
 * Helper function to replace matched camp info with formatted HTML
 */
const replaceWithFormattedCampInfo = (match: string, header: string, section: string, items: string): string => {
  if (!section) {
    return `<div class="camp-info">
${header}
</div>`;
  }

  // Break information into separate lines with proper spacing
  const formattedHeader = header.trim();
  let formattedItems = '';

  // Format emoji for the section header
  const sectionEmoji = getSectionEmoji(section);
  const formattedSection = `${sectionEmoji} ${section}`;

  // If items exist and contain dashes or bullet points as separators
  if (items && (items.includes('-') || items.includes('•'))) {
    formattedItems = items
      .split(/[-•]/)
      .filter(item => item.trim())
      .map(item => `  • ${item.trim()}`)
      .join('\n');
  } else if (items) {
    formattedItems = items.trim();
  }

  return `<div class="camp-info">
${formattedHeader}
<strong>${formattedSection}:</strong>
${formattedItems}
</div>`;
};

/**
 * Get appropriate emoji for section based on section name
 */
const getSectionEmoji = (section: string): string => {
  const sectionLower = section.toLowerCase().trim();
  
  if (sectionLower.includes('offering')) return '✨';
  if (sectionLower.includes('event')) return '🗓️';
  if (sectionLower.includes('time')) return '⏰';
  if (sectionLower.includes('location')) return '📍';
  if (sectionLower.includes('specialty')) return '🌟';
  if (sectionLower.includes('activity')) return '🎪';
  
  // Default emoji if no match
  return '';
};

/**
 * Improve spacing for better readability
 */
const improveSpacing = (text: string): string => {
  return text
    // Preserve spacing in HTML elements like camp cards
    .replace(/(<div class="camp-card">)([\s\S]*?)(<\/div>)/g, (match) => {
      // Don't modify the spacing inside camp cards
      return match;
    })
    
    // Preserve spacing in HTML elements like camp info
    .replace(/(<div class="camp-info">)([\s\S]*?)(<\/div>)/g, (match) => {
      // Don't modify the spacing inside camp info
      return match;
    })
    
    // Add spacing after headers if not already present
    .replace(/(<h[1-6]>.*?<\/h[1-6]>)(?!\n)/g, '$1\n\n')
    
    // Add spacing after paragraphs if not already present
    .replace(/(<p>.*?<\/p>)(?!\n)/g, '$1\n\n')
    
    // Add spacing after lists if not already present
    .replace(/(<\/[ou]l>)(?!\n)/g, '$1\n\n')
    
    // Add spacing after blockquotes if not already present
    .replace(/(<\/blockquote>)(?!\n)/g, '$1\n\n')
    
    // Add spacing after horizontal rules if not already present
    .replace(/(<hr\s*\/?>)(?!\n)/g, '$1\n\n')
    
    // Fix spacing for camp listings with multiple camps
    .replace(/(---\s*✨\s*\*\*[^*]+\*\*\s*✨\s*---)/g, '\n$1\n')
    
    // Ensure emoji section markers have proper spacing
    .replace(/(📍[^🔥]+)(🔥)/g, '$1\n$2')
    .replace(/(🔥[^🏄‍♀️]+)(🏄‍♀️)/g, '$1\n$2')
    
    // Fix multi-asterisk emphasis that wasn't properly converted (** ** patterns)
    .replace(/\*\*([^*\n]+?)\*\*/g, '<strong>$1</strong>')
    
    // Preserve spacing after HTML emphasis tags
    .replace(/(<\/strong>|<\/em>)(?!\s|<|\n|\.|\,|\?|\!|\:|;)/g, '$1 ')
    
    // Prevent excessive line breaks (more than 2)
    .replace(/\n{3,}/g, '\n\n');
};

/**
 * Special processor for Burning Man camp listings that precisely match the screenshot format
 */
const processBurningManCamps = (text: string): string => {
  // Check if text contains any camp listing patterns
  if (!text.includes("--- ✨") || !text.includes("📍") || 
      !text.includes("🔥 OFFERINGS:") || !text.includes("🏄‍♀️ DESCRIPTION:")) {
    return text;
  }

  // Process the exact format from the screenshot:
  // "Here are some camps related to swimming at Burning Man: --- ✨ **AQUA OASIS CAMP** ✨ --- 📍 6:00 & C 🔥 OFFERINGS: Oasis pool parties..."
  return text.replace(
    /(Here are .+?camps .+?at Burning Man:)\s*(---\s*✨\s*(?:\*\*)?([A-Z0-9\s&'"\-]+)(?:\*\*)?\s*✨\s*---\s*📍\s*([^🔥]+)\s*🔥\s*OFFERINGS:\s*([^🏄‍♀️\n]+)\s*🏄‍♀️\s*DESCRIPTION:\s*([^---\n]+))/g,
    (match, intro, fullCamp, campName, location, offerings, description) => {
      return `${intro}\n\n<div class="camp-card">
<div class="camp-title">✨ <strong>${campName.trim()}</strong> ✨</div>
<div class="camp-location">📍 ${location.trim()}</div>
<div class="camp-offerings">🔥 <strong>OFFERINGS:</strong> ${offerings.trim()}</div>
<div class="camp-description">🏄‍♀️ <strong>DESCRIPTION:</strong> ${description.trim()}</div>
</div>`;
    }
  ).replace(
    /(---\s*✨\s*(?:\*\*)?([A-Z0-9\s&'"\-]+)(?:\*\*)?\s*✨\s*---\s*📍\s*([^🔥]+)\s*🔥\s*OFFERINGS:\s*([^🏄‍♀️\n]+)\s*🏄‍♀️\s*DESCRIPTION:\s*([^---\n]+))/g,
    (match, fullCamp, campName, location, offerings, description) => {
      // Skip if it's already been processed
      if (match.includes('<div class="camp-card">')) {
        return match;
      }
      
      return `<div class="camp-card">
<div class="camp-title">✨ <strong>${campName.trim()}</strong> ✨</div>
<div class="camp-location">📍 ${location.trim()}</div>
<div class="camp-offerings">🔥 <strong>OFFERINGS:</strong> ${offerings.trim()}</div>
<div class="camp-description">🏄‍♀️ <strong>DESCRIPTION:</strong> ${description.trim()}</div>
</div>`;
    }
  );
};

/**
 * Format retry buttons in the response
 * @param text - Text that may contain retry button tags
 * @returns Text with formatted retry buttons using CSS classes
 */
const formatRetryButtons = (text: string): string => {
  // Look for retry button tags
  return text.replace(
    /<retry-button([^>]*)>(.*?)<\/retry-button>/g,
    (match, attrs, content) => {
      // Parse the attributes
      const actionMatch = attrs.match(/action=["']([^"']*)["']/);
      const action = actionMatch ? actionMatch[1] : '';
      
      // Generate a clean button
      return `<button class="retry-button" data-action="${action || 'retry'}">${content || 'Try again'}</button>`;
    }
  );
};

/**
 * Main export for the LLM response formatting utility
 */
export default {
  enhanceMarkdown,
  formatRetryButtons,
  // Export additional utilities if they need to be used separately
  utils: {
    formatPrinciples,
    formatNumberedLists,
    formatCallouts,
    convertAsciiEmphasis,
    improveSpacing,
    formatThinkingContent,
    formatCampInformation,
    formatRetryButtons
  }
}; 