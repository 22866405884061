'use client';

import React, { useState, useEffect, useCallback } from 'react';
import './LoobricateProfile.css';
import { VibeEntity } from './VibeEntity';
import type { LoobricateData } from '../types/loobricate';
import type { Loobricate } from './GlobalStateContext';
import { LoobricateAdminPanel } from './LoobricateAdminPanel';
import { FaLock, FaUserCircle, FaTimes, FaImage, FaCamera, FaMapMarkerAlt, FaUser, FaPlus } from 'react-icons/fa';
import { useGlobalState } from './GlobalStateContext';
import { uploadImage } from '../lib/supabase/client';
import { VenueProfile as OfferingProfile } from './VenueProfile';
import { Offering } from '../types/offering';
import { ListingsCards } from './ListingsCards';
import { Listing } from './ListingCard';

// Sub-components for better organization
const ProfileHeader = ({ 
  currentLoobricate, 
  isAdmin, 
  onPhotoUpload, 
  isUploading,
  onLoginClick 
}: { 
  currentLoobricate: LoobricateData;
  isAdmin: boolean;
  onPhotoUpload: (e: React.ChangeEvent<HTMLInputElement>, type: 'profile' | 'cover') => void;
  isUploading: boolean;
  onLoginClick: () => void;
}) => {
  const profileInputRef = React.useRef<HTMLInputElement>(null);
  const coverInputRef = React.useRef<HTMLInputElement>(null);

  const handleProfilePhotoClick = () => profileInputRef.current?.click();
  const handleCoverPhotoClick = () => coverInputRef.current?.click();

  return (
    <div className="profile-header">
      {!isAdmin && (
        <button 
          className="admin-login-button"
          onClick={onLoginClick}
          data-testid="admin-login-button"
        >
          <FaLock /> Admin Login
        </button>
      )}
      <div className="cover-photo-section">
        {currentLoobricate.coverPhoto ? (
          <img 
            src={currentLoobricate.coverPhoto} 
            alt={`${currentLoobricate.name} cover`}
            className="cover-photo"
          />
        ) : (
          <div className="cover-photo-placeholder">
            <FaImage />
          </div>
        )}
        {isAdmin && (
          <>
            <div className="cover-photo-overlay" onClick={handleCoverPhotoClick}>
              <button className="edit-photo-button">
                <FaCamera />
                {isUploading ? 'Uploading...' : 'Edit'}
              </button>
            </div>
            <input
              ref={coverInputRef}
              type="file"
              accept="image/*"
              onChange={(e) => onPhotoUpload(e, 'cover')}
              style={{ display: 'none' }}
            />
          </>
        )}
        
        <div className="profile-photo-wrapper">
          <div className="profile-photo">
            {currentLoobricate.profilePhoto ? (
              <img 
                src={currentLoobricate.profilePhoto} 
                alt={`${currentLoobricate.name} profile`}
                className="profile-photo-img"
                loading="lazy"
              />
            ) : (
              <div className="profile-photo-placeholder">
                <FaUserCircle />
              </div>
            )}
            {isAdmin && (
              <>
                <div className="profile-photo-overlay" onClick={handleProfilePhotoClick}>
                  <button className="edit-photo-button">
                    <FaCamera />
                    {isUploading ? 'Uploading...' : 'Edit'}
                  </button>
                </div>
                <input
                  ref={profileInputRef}
                  type="file"
                  accept="image/*"
                  onChange={(e) => onPhotoUpload(e, 'profile')}
                  style={{ display: 'none' }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const VibeSection = ({ 
  currentLoobricate,
  showVibeComparison,
  onToggleComparison 
}: { 
  currentLoobricate: LoobricateData;
  showVibeComparison: boolean;
  onToggleComparison: () => void;
}) => (
  <div className="vibe-section">
    <div className="visualization-section">
      <div className={`vibe-display ${showVibeComparison ? 'comparison-mode' : ''}`}>
        <div className="vibe-entity-wrapper">
          <VibeEntity entityId={currentLoobricate.id} />
          <div className="vibe-label">Community Vibe</div>
        </div>
        {showVibeComparison && (
          <div className="vibe-entity-wrapper">
            <VibeEntity entityId={`user-${currentLoobricate.id}`} />
            <div className="vibe-label">Your Vibe</div>
          </div>
        )}
      </div>
      <button 
        className="compare-button"
        onClick={onToggleComparison}
      >
        {showVibeComparison ? 'Hide Comparison' : 'Compare Your Vibe'}
      </button>
    </div>
  </div>
);

const InfoSection = ({ currentLoobricate }: { currentLoobricate: LoobricateData }) => (
  <div className="info-section">
    <div className="info-grid">
      <div className="info-item">
        <span className="info-label">Members</span>
        <span className="info-value">{currentLoobricate.members.length}</span>
      </div>

      {currentLoobricate.tags && currentLoobricate.tags.length > 0 && (
        <div className="info-item">
          <span className="info-label">Tags</span>
          <div className="tags-display">
            {currentLoobricate.tags.map((tag, index) => (
              <span key={`${tag}-${index}`} className="tag">{tag}</span>
            ))}
          </div>
        </div>
      )}

      {currentLoobricate.addressLine1 && (
        <div className="info-item">
          <span className="info-label">Location</span>
          <p className="location">
            {currentLoobricate.addressLine1}
            {currentLoobricate.city && <>, {currentLoobricate.city}</>}
          </p>
        </div>
      )}
    </div>
  </div>
);

interface VibeData {
  id: string;
  state: any; // Replace with proper vibe state type
}

interface LoobricateProfileProps {
  loobricate: Loobricate | LoobricateData;
  onClose: () => void;
  isAdmin?: boolean;
  onUpdate?: (updatedLoobricate: LoobricateData) => void;
  isMainView?: boolean; // Add this prop to determine if it's a main view
}

const LoginModal = ({ 
  onClose, 
  onSubmit, 
  error, 
  isLoggingIn 
}: { 
  onClose: () => void;
  onSubmit: (username: string, password: string) => Promise<void>;
  error: string;
  isLoggingIn: boolean;
}) => (
  <div className="modal-overlay" onClick={onClose}>
    <div className="login-modal" onClick={e => e.stopPropagation()}>
      <button className="close-button" onClick={onClose}>×</button>
      <h2>Admin Login</h2>
      <form onSubmit={async (e) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        await onSubmit(
          formData.get('username') as string,
          formData.get('password') as string
        );
      }}>
        <div className="form-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            name="username"
            required
            autoComplete="username"
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            required
            autoComplete="current-password"
          />
        </div>
        {error && <div className="error-message">{error}</div>}
        <button 
          type="submit" 
          className="submit-button" 
          disabled={isLoggingIn}
        >
          {isLoggingIn ? 'Logging in...' : 'Login'}
        </button>
      </form>
    </div>
  </div>
);

export const LoobricateProfile: React.FC<LoobricateProfileProps> = ({
  loobricate,
  onClose,
  isAdmin = false,
  onUpdate,
  isMainView = false // Default to false for backward compatibility
}) => {
  const [activeModal, setActiveModal] = useState<string | null>(null);
  const [currentLoobricate, setCurrentLoobricate] = useState<LoobricateData>(loobricate as LoobricateData);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isAdmin1, setIsAdmin1] = useState(isAdmin);
  const [loginError, setLoginError] = useState('');
  const [showVibeComparison, setShowVibeComparison] = useState(false);
  const [showAdminPanel, setShowAdminPanel] = useState(false);
  const [error, setError] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  
  // Get userId from global context at the component level
  const { userId } = useGlobalState();

  // Handle modal display
  const handleOpenModal = (modalName: string) => {
    setActiveModal(modalName);
  };

  const handleCloseModal = () => {
    setActiveModal(null);
    setLoginError('');
  };

  const handleLogin = async (username: string, password: string) => {
    setLoginError('');
    setIsLoggingIn(true);

    try {
      const response = await fetch('/api/auth/loobricate-auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          password
        }),
      });

      const data = await response.json();

      if (response.ok) {
        // Convert the loobricate data to the correct format
        const loobricateData: LoobricateData = {
          id: data._id,
          name: data.name,
          description: data.description || '',
          addressLine1: data.addressLine1 || '',
          city: data.city || '',
          adminUsername: data.adminUsername || '',
          tags: data.tags || [],
          admins: data.admins || [],
          members: data.members || [],
          createdAt: data.createdAt,
          updatedAt: new Date().toISOString(),
          type: data.type || 'community',
          status: 'active',
          profilePhoto: data.profilePhoto,
          profilePhotoPath: data.profilePhotoPath,
          coverPhoto: data.coverPhoto,
          coverPhotoPath: data.coverPhotoPath
        };

        setCurrentLoobricate(loobricateData);
        setActiveModal('admin');
      } else {
        setLoginError(data.error || 'Invalid credentials');
      }
    } catch (error) {
      console.error('Login error:', error);
      setLoginError('Failed to login. Please try again.');
    } finally {
      setIsLoggingIn(false);
    }
  };

  const handleUpdate = async (updatedLoobricate: LoobricateData) => {
    if (isUpdating) return { success: false, message: 'Update already in progress' };
    setIsUpdating(true);
    
    try {
      const loobricateId = currentLoobricate.id;
      const formData = new FormData();
      
      // Add basic fields to formData
      if (updatedLoobricate.name) {
        formData.append('name', updatedLoobricate.name);
      }
      
      if (updatedLoobricate.description) {
        formData.append('description', updatedLoobricate.description);
      }
      
      // Handle profile photo and cover photo uploads
      // In LoobricateAdminPanel, these files would be passed as File objects
      // but in the LoobricateData type, they're stored as strings
      
      // userId is now available from the component level
      
      // Check if cover photo is a File object (from a file input)
      if (updatedLoobricate.coverPhoto && 
          typeof updatedLoobricate.coverPhoto === 'object' && 
          'name' in updatedLoobricate.coverPhoto && 
          'type' in updatedLoobricate.coverPhoto) {
        
        try {
          // Type assertion to help TypeScript understand this is a File
          const coverPhotoFile = updatedLoobricate.coverPhoto as unknown as File;
          
          const uploadResult = await uploadImage(
            coverPhotoFile,
            'entry-photos',
            'covers',
            userId
          );
          
          // The uploadImage function returns an object with url and path
          if (uploadResult && uploadResult.url) {
            formData.append('coverPhoto', uploadResult.url);
            if (uploadResult.path) {
              formData.append('coverPhotoPath', uploadResult.path);
            }
          }
        } catch (error) {
          console.error('Error uploading cover photo:', error);
          // Continue without the cover photo
        }
      } else if (typeof updatedLoobricate.coverPhoto === 'string') {
        // If it's already a string URL, just use it directly
        formData.append('coverPhoto', updatedLoobricate.coverPhoto);
      }
      
      // Check if profile photo is a File object (from a file input)
      if (updatedLoobricate.profilePhoto && 
          typeof updatedLoobricate.profilePhoto === 'object' && 
          'name' in updatedLoobricate.profilePhoto && 
          'type' in updatedLoobricate.profilePhoto) {
        
        try {
          // Type assertion to help TypeScript understand this is a File
          const profilePhotoFile = updatedLoobricate.profilePhoto as unknown as File;
          
          const uploadResult = await uploadImage(
            profilePhotoFile,
            'entry-photos',
            'profiles',
            userId
          );
          
          // The uploadImage function returns an object with url and path
          if (uploadResult && uploadResult.url) {
            formData.append('profilePhoto', uploadResult.url);
            if (uploadResult.path) {
              formData.append('profilePhotoPath', uploadResult.path);
            }
          }
        } catch (error) {
          console.error('Error uploading profile photo:', error);
          // Continue without the profile photo
        }
      } else if (typeof updatedLoobricate.profilePhoto === 'string') {
        // If it's already a string URL, just use it directly
        formData.append('profilePhoto', updatedLoobricate.profilePhoto);
      }
      
      // Update the loobricate with the new data
      const updateResponse = await fetch(`/api/loobricate/${loobricateId}`, {
        method: 'PUT',
        body: formData,
      });
      
      if (!updateResponse.ok) {
        const errorData = await updateResponse.json();
        throw new Error(errorData.message || 'Failed to update loobricate');
      }
      
      // Get the updated data
      const updatedResponseData = await updateResponse.json();
      
      // Normalize the updated data to match LoobricateData structure
      const normalizedData: LoobricateData = {
        id: updatedResponseData.id || currentLoobricate.id,
        name: updatedResponseData.name || currentLoobricate.name,
        description: updatedResponseData.description || currentLoobricate.description,
        addressLine1: updatedResponseData.addressLine1 || currentLoobricate.addressLine1 || '',
        city: updatedResponseData.city || currentLoobricate.city || '',
        adminUsername: updatedResponseData.adminUsername || currentLoobricate.adminUsername || '',
        tags: updatedResponseData.tags || currentLoobricate.tags || [],
        admins: updatedResponseData.admins || currentLoobricate.admins || [],
        members: updatedResponseData.members || currentLoobricate.members || [],
        createdAt: updatedResponseData.createdAt || currentLoobricate.createdAt,
        updatedAt: updatedResponseData.updatedAt || new Date().toISOString(),
        type: updatedResponseData.type || currentLoobricate.type || 'community',
        status: updatedResponseData.status || currentLoobricate.status || 'active',
        profilePhoto: updatedResponseData.profilePhoto || currentLoobricate.profilePhoto,
        profilePhotoPath: updatedResponseData.profilePhotoPath || currentLoobricate.profilePhotoPath,
        coverPhoto: updatedResponseData.coverPhoto || currentLoobricate.coverPhoto,
        coverPhotoPath: updatedResponseData.coverPhotoPath || currentLoobricate.coverPhotoPath
      };
      
      // Update the state with normalized data
      setCurrentLoobricate(normalizedData);
      
      if (onUpdate) {
        onUpdate(normalizedData);
      }
      
      setActiveModal(null);
      
      return {
        success: true,
        message: 'Loobricate updated successfully',
      };
    } catch (error) {
      console.error('Error updating loobricate:', error);
      setLoginError(error instanceof Error ? error.message : 'Failed to update changes. Please try again.');
      return {
        success: false,
        message: error instanceof Error ? error.message : 'Unknown error occurred',
      };
    } finally {
      setIsUpdating(false);
    }
  };

  const handleAdminPanelClose = () => {
    if (isUpdating) return;
    setShowAdminPanel(false);
  };

  const handlePhotoUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    type: 'profile' | 'cover'
  ) => {
    const file = e.target.files?.[0];
    if (!file) return;

    try {
      if (!file.type.startsWith('image/')) {
        setError('Please upload an image file');
        return;
      }

      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        setError('Image size should be less than 5MB');
        return;
      }

      setIsUploading(true);
      setError('');

      const { url, path } = await uploadImage(
        file,
        'entry-photos',
        type === 'profile' ? 'profiles' : 'covers',
        userId
      );

      const updateField = type === 'profile' ? {
        profilePhoto: url,
        profilePhotoPath: path
      } : {
        coverPhoto: url,
        coverPhotoPath: path
      };

      const response = await fetch(`/api/loobricates/${loobricate.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updateField),
      });

      if (!response.ok) {
        throw new Error(`Failed to update ${type} photo`);
      }

      const updatedLoobricate = await response.json();
      setCurrentLoobricate(updatedLoobricate);
      if (onUpdate) {
        onUpdate(updatedLoobricate);
      }
    } catch (error) {
      console.error('Error uploading photo:', error);
      setError(error instanceof Error ? error.message : `Failed to upload ${type} photo`);
    } finally {
      setIsUploading(false);
    }
  };

  const handleProfilePhotoClick = () => {
    if (isAdmin && !isUploading) {
      profileInputRef.current?.click();
    }
  };

  const handleCoverPhotoClick = () => {
    if (isAdmin && !isUploading) {
      coverInputRef.current?.click();
    }
  };

  // Handle listing click to show offering details
  const handleListingClick = (listing: Listing) => {
    // Create an Offering object from the Listing
    const offering: Offering = {
      _id: listing.id,
      title: listing.title,
      description: listing.description,
      offeringType: listing.type ? listing.type.toLowerCase() as 'venue' | 'gear' | 'talent' : 'gear',
      createdAt: listing.metadata.createdAt,
      // Handle the location property safely
      location: undefined, // Default to undefined
      images: listing.photos.gallery || [],
      contact: {
        whatsapp: undefined,
        telegram: undefined
      }
    };
    
    // If the listing has location data, add it to the offering
    if ('location' in listing && listing.location) {
      const loc = listing.location;
      if (typeof loc === 'object' && 'address' in loc) {
        offering.location = {
          address: loc.address || '',
          coordinates: [
            typeof loc.lon === 'number' ? loc.lon : 0,
            typeof loc.lat === 'number' ? loc.lat : 0
          ]
        };
      }
    }
    
    setSelectedOffering(offering);
  };

  return (
    <div className="loobricate-profile" data-testid="loobricate-profile">
      {isLoobricateLoading ? (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      ) : activeModal === 'admin' ? (
        <LoobricateAdminPanel
          loobricateId={currentLoobricate.id}
          onClose={() => setActiveModal(null)}
          onUpdate={handleUpdate}
        />
      ) : activeModal === 'login' ? (
        <LoginModal
          onClose={() => setActiveModal(null)}
          onSubmit={handleLogin}
          error={loginError}
          isLoggingIn={isLoggingIn}
        />
      ) : (
        <div className={`loobricate-profile-content ${isMainView ? 'main-view' : ''}`}>
          {!isMainView && (
            <button 
              className="close-button" 
              onClick={onClose}
              aria-label="Close profile"
            >
              <FaTimes />
            </button>
          )}

          {!isAdmin && (
            <button 
              className="admin-login-button"
              onClick={() => setActiveModal('login')}
              data-testid="admin-login-button"
            >
              <FaLock /> Admin Login
            </button>
          )}

          <div className="loobricate-profile-container">
            <div className="profile-header-section">
              <ProfileHeader 
                currentLoobricate={currentLoobricate}
                isAdmin={isAdmin}
                onPhotoUpload={handlePhotoUpload}
                isUploading={isUploading}
                onLoginClick={() => setActiveModal('login')}
              />
            </div>

            <main className="main-content">
              <div className="loobricate-header">
                <h1 className="text-2xl md:text-3xl font-bold mb-2">
                  {currentLoobricate.name}
                </h1>
                <p className="text-gray-400">
                  {currentLoobricate.description}
                </p>
              </div>

              {/* Rest of the content sections */}
              <div className="space-y-8 mt-8">
                <InfoSection currentLoobricate={currentLoobricate} />
                <VibeSection 
                  currentLoobricate={currentLoobricate}
                  showVibeComparison={showVibeComparison}
                  onToggleComparison={() => setShowVibeComparison(!showVibeComparison)}
                />
                
                {/* Use the new ListingsCards component */}
                <ListingsCards 
                  fetchUrl={`/api/listings?loobricateId=${currentLoobricate.id}`}
                  title={`${currentLoobricate.name} Listings`}
                  onListingClick={handleListingClick}
                  emptyStateMessage={`This community doesn't have any active listings at the moment.`}
                  defaultViewMode="grid"
                  defaultItemsPerPage={6}
                  showAddNew={true}
                  onAddNew={() => alert('Add listing feature coming soon!')}
                />
              </div>
            </main>
          </div>
        </div>
      )}

      {selectedOffering && (
        <OfferingProfile 
          offering={selectedOffering} 
          onClose={() => setSelectedOffering(null)} 
        />
      )}
    </div>
  );
}; 