"use client";

import React from 'react';
import { CampMarker } from './Map';

interface CampInfoPanelProps {
  visible: boolean;
  onClose: () => void;
  selectedCamp: CampMarker | null;
  activeTab: 'details' | 'browse';
  setActiveTab: (tab: 'details' | 'browse') => void;
  nearbyCamps: CampMarker[];
  onCampSelect: (camp: CampMarker) => void;
  browseCategoryView: 'streets' | 'themed' | 'all';
  setBrowseCategoryView: (view: 'streets' | 'themed' | 'all') => void;
  categoryFilter: string;
  setCategoryFilter: (filter: string) => void;
  campCategories: { [key: string]: CampMarker[] };
  getLocationText: (camp: CampMarker) => string;
}

const CampInfoPanel: React.FC<CampInfoPanelProps> = ({
  visible,
  onClose,
  selectedCamp,
  activeTab,
  setActiveTab,
  nearbyCamps,
  onCampSelect,
  browseCategoryView,
  setBrowseCategoryView,
  categoryFilter,
  setCategoryFilter,
  campCategories,
  getLocationText
}) => {
  return (
    <div className={`camp-info-panel ${visible ? 'visible' : ''}`}>
      <div className="panel-tabs">
        <button 
          className={`tab-button ${activeTab === 'browse' ? 'active' : ''}`}
          onClick={() => setActiveTab('browse')}
        >
          Browse Camps
        </button>
        <button 
          className={`tab-button ${activeTab === 'details' ? 'active' : ''}`}
          onClick={() => setActiveTab('details')}
          disabled={!selectedCamp}
        >
          Camp Details
        </button>
        <button className="close-panel" onClick={onClose}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </div>
      
      <div className="panel-content">
        {/* Camp Details Tab */}
        {activeTab === 'details' && selectedCamp && (
          <div className="camp-details-tab">
            <div className="camp-header" style={{ borderColor: selectedCamp.color || '#FF93A4' }}>
              <h2>{selectedCamp.name}</h2>
              <p className="camp-location">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                  <circle cx="12" cy="10" r="3"></circle>
                </svg>
                {getLocationText(selectedCamp)}
              </p>
            </div>
            
            {selectedCamp.description && (
              <div className="detail-section">
                <h3>About</h3>
                <p>{selectedCamp.description}</p>
              </div>
            )}
            
            {selectedCamp.hometown && (
              <div className="detail-section">
                <h3>Hometown</h3>
                <p>{selectedCamp.hometown}</p>
              </div>
            )}
            
            {selectedCamp.landmark && (
              <div className="detail-section">
                <h3>Landmark</h3>
                <p>{selectedCamp.landmark}</p>
              </div>
            )}
            
            {selectedCamp.playa_location?.dimensions && (
              <div className="detail-section">
                <h3>Camp Size</h3>
                <p>{selectedCamp.playa_location.dimensions}</p>
              </div>
            )}
            
            <div className="camp-actions">
              {selectedCamp.url && (
                <a 
                  href={selectedCamp.url} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="camp-action-btn website-btn"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                    <polyline points="15 3 21 3 21 9"></polyline>
                    <line x1="10" y1="14" x2="21" y2="3"></line>
                  </svg>
                  Visit Website
                </a>
              )}
              
              {selectedCamp.contact_email && (
                <a 
                  href={`mailto:${selectedCamp.contact_email}`}
                  className="camp-action-btn email-btn"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                    <polyline points="22,6 12,13 2,6"></polyline>
                  </svg>
                  Contact Camp
                </a>
              )}
            </div>
            
            {nearbyCamps.length > 0 && (
              <div className="nearby-camps">
                <h3>Nearby Camps</h3>
                <div className="nearby-camps-list">
                  {nearbyCamps.map(camp => (
                    <div 
                      key={camp.id} 
                      className="nearby-camp-item"
                      onClick={() => onCampSelect(camp)}
                    >
                      <div 
                        className="camp-color-dot" 
                        style={{ backgroundColor: camp.color || '#FF93A4' }}
                      ></div>
                      <div className="nearby-camp-info">
                        <p className="nearby-camp-name">{camp.name}</p>
                        <p className="nearby-camp-location">{getLocationText(camp)}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        
        {/* Browse Camps Tab */}
        {activeTab === 'browse' && (
          <div className="browse-camps-tab">
            <div className="browse-options">
              <div className="browse-category-selector">
                <button 
                  className={`browse-option ${browseCategoryView === 'all' ? 'active' : ''}`}
                  onClick={() => setBrowseCategoryView('all')}
                >
                  All Camps
                </button>
                <button 
                  className={`browse-option ${browseCategoryView === 'streets' ? 'active' : ''}`}
                  onClick={() => setBrowseCategoryView('streets')}
                >
                  By Street
                </button>
                <button 
                  className={`browse-option ${browseCategoryView === 'themed' ? 'active' : ''}`}
                  onClick={() => setBrowseCategoryView('themed')}
                >
                  A-Z
                </button>
              </div>
              
              <div className="browse-search">
                <input
                  type="text"
                  placeholder="Filter camps..."
                  value={categoryFilter}
                  onChange={(e) => setCategoryFilter(e.target.value)}
                  className="browse-search-input"
                />
                {categoryFilter && (
                  <button 
                    className="clear-browse-search" 
                    onClick={() => setCategoryFilter('')}
                  >
                    ×
                  </button>
                )}
              </div>
            </div>
            
            <div className="browse-categories">
              {Object.entries(campCategories).map(([category, campsList]) => {
                // Filter camps by search term if there is one
                const filteredCampsList = categoryFilter 
                  ? campsList.filter(camp => 
                      camp.name.toLowerCase().includes(categoryFilter.toLowerCase()) ||
                      (camp.description && camp.description.toLowerCase().includes(categoryFilter.toLowerCase()))
                    )
                  : campsList;
                
                // Only show categories that have camps after filtering
                if (filteredCampsList.length === 0) return null;
                
                return (
                  <div key={category} className="camp-category">
                    <h3 className="category-heading">
                      <span>{category}</span>
                      <span className="category-count">{filteredCampsList.length}</span>
                    </h3>
                    <div className="category-camps">
                      {filteredCampsList.slice(0, 50).map(camp => (
                        <div 
                          key={camp.id} 
                          className="browse-camp-item"
                          onClick={() => onCampSelect(camp)}
                        >
                          <div 
                            className="camp-color-dot" 
                            style={{ backgroundColor: camp.color || '#FF93A4' }}
                          ></div>
                          <div className="browse-camp-info">
                            <p className="browse-camp-name">{camp.name}</p>
                            <p className="browse-camp-location">{getLocationText(camp)}</p>
                          </div>
                        </div>
                      ))}
                      
                      {filteredCampsList.length > 50 && (
                        <div className="more-camps-note">
                          + {filteredCampsList.length - 50} more camps
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      
      <style jsx>{`
        .camp-info-panel {
          position: fixed;
          background: white;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
          border: 1px solid rgba(255, 255, 255, 0.18);
          z-index: 1000;
          overflow: hidden;
          transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
        }
        
        /* Desktop sidebar style */
        @media (min-width: 769px) {
          .camp-info-panel {
            top: 70px;
            right: -420px;
            width: 420px;
            height: calc(100vh - 80px);
            border-radius: 12px 0 0 12px;
            opacity: 0;
            pointer-events: none;
            transform: translateX(20px);
          }
          
          .camp-info-panel.visible {
            right: 0;
            opacity: 1;
            pointer-events: all;
            transform: translateX(0);
          }
        }
        
        /* Mobile bottom sheet style */
        @media (max-width: 768px) {
          .camp-info-panel {
            bottom: -90vh;
            left: 0;
            width: 100%;
            height: 85vh;
            border-radius: 20px 20px 0 0;
            transform: translateY(30px);
            pointer-events: none;
            box-shadow: 0 -5px 30px rgba(0, 0, 0, 0.15);
          }
          
          .camp-info-panel.visible {
            transform: translateY(0);
            bottom: 0;
            pointer-events: all;
          }
          
          .camp-info-panel::before {
            content: '';
            position: absolute;
            top: 8px;
            left: 50%;
            transform: translateX(-50%);
            width: 40px;
            height: 5px;
            background-color: rgba(0, 0, 0, 0.2);
            border-radius: 3px;
          }
        }
        
        .panel-tabs {
          display: flex;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          position: relative;
          background: #fff;
          padding: 0 5px;
        }
        
        .tab-button {
          flex: 1;
          padding: 18px 10px;
          border: none;
          background: none;
          font-size: 15px;
          font-weight: 600;
          color: #666;
          cursor: pointer;
          transition: all 0.3s ease;
          position: relative;
          outline: none;
        }
        
        .tab-button:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
        
        .tab-button.active {
          color: #FF93A4;
        }
        
        .tab-button.active::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 10%;
          width: 80%;
          height: 3px;
          background-color: #FF93A4;
          border-radius: 3px 3px 0 0;
          transition: all 0.3s ease;
        }
        
        .tab-button:hover:not(:disabled) {
          color: #FF93A4;
          background-color: rgba(255, 147, 164, 0.05);
        }
        
        .close-panel {
          position: absolute;
          right: 12px;
          top: 12px;
          background: none;
          border: none;
          color: #666;
          cursor: pointer;
          padding: 8px;
          border-radius: 50%;
          transition: all 0.2s;
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        
        .close-panel:hover {
          color: #333;
          background-color: rgba(0, 0, 0, 0.05);
        }
        
        .panel-content {
          height: calc(100% - 56px);
          overflow-y: auto;
          padding-bottom: 20px;
          -webkit-overflow-scrolling: touch;
          scrollbar-width: thin;
          scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
        }
        
        .panel-content::-webkit-scrollbar {
          width: 6px;
        }
        
        .panel-content::-webkit-scrollbar-track {
          background: transparent;
        }
        
        .panel-content::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: 3px;
        }
        
        /* Camp Details Tab */
        .camp-details-tab {
          padding: 20px;
        }
        
        .camp-header {
          margin-bottom: 24px;
          padding-bottom: 16px;
          border-bottom: 2px solid;
          animation: fadeIn 0.3s ease-in-out;
        }
        
        .camp-header h2 {
          margin: 0 0 10px 0;
          font-size: 24px;
          line-height: 1.3;
          font-weight: 700;
          color: #333;
        }
        
        .camp-location {
          display: flex;
          align-items: center;
          gap: 8px;
          margin: 0;
          font-size: 14px;
          color: #666;
          font-weight: 500;
        }
        
        .detail-section {
          margin-bottom: 24px;
          animation: fadeIn 0.3s ease-in-out;
          animation-fill-mode: both;
        }
        
        .detail-section:nth-child(2) { animation-delay: 0.05s; }
        .detail-section:nth-child(3) { animation-delay: 0.1s; }
        .detail-section:nth-child(4) { animation-delay: 0.15s; }
        .detail-section:nth-child(5) { animation-delay: 0.2s; }
        
        .detail-section h3 {
          font-size: 16px;
          margin: 0 0 10px 0;
          color: #333;
          font-weight: 600;
        }
        
        .detail-section p {
          margin: 0;
          line-height: 1.6;
          font-size: 15px;
          color: #444;
        }
        
        .camp-actions {
          display: flex;
          gap: 12px;
          margin-top: 28px;
          margin-bottom: 32px;
          animation: fadeIn 0.3s ease-in-out;
          animation-delay: 0.25s;
          animation-fill-mode: both;
        }
        
        .camp-action-btn {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 12px 18px;
          border-radius: 8px;
          text-decoration: none;
          font-size: 14px;
          font-weight: 600;
          transition: all 0.2s ease;
        }
        
        .website-btn {
          background-color: #FF93A4;
          color: white;
          box-shadow: 0 2px 8px rgba(255, 147, 164, 0.4);
        }
        
        .email-btn {
          background-color: #f5f5f5;
          color: #333;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
        }
        
        .camp-action-btn:hover {
          transform: translateY(-3px);
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
        }
        
        .website-btn:hover {
          background-color: #ff7f96;
        }
        
        .email-btn:hover {
          background-color: #eeeeee;
        }
        
        /* Nearby Camps */
        .nearby-camps {
          margin-top: 32px;
          animation: fadeIn 0.3s ease-in-out;
          animation-delay: 0.3s;
          animation-fill-mode: both;
        }
        
        .nearby-camps h3 {
          font-size: 18px;
          margin: 0 0 16px 0;
          color: #333;
          font-weight: 600;
        }
        
        .nearby-camps-list {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
        
        .nearby-camp-item {
          display: flex;
          align-items: center;
          gap: 12px;
          padding: 12px;
          border-radius: 10px;
          cursor: pointer;
          transition: all 0.2s ease;
          border: 1px solid transparent;
        }
        
        .nearby-camp-item:hover {
          background-color: rgba(255, 147, 164, 0.05);
          border-color: rgba(255, 147, 164, 0.1);
          transform: translateX(3px);
        }
        
        .camp-color-dot {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          flex-shrink: 0;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        }
        
        .nearby-camp-info {
          flex: 1;
        }
        
        .nearby-camp-name {
          margin: 0 0 3px 0;
          font-size: 15px;
          font-weight: 600;
          color: #333;
        }
        
        .nearby-camp-location {
          margin: 0;
          font-size: 13px;
          color: #666;
        }
        
        /* Browse Camps Tab */
        .browse-camps-tab {
          display: flex;
          flex-direction: column;
          animation: fadeIn 0.3s ease-in-out;
        }
        
        .browse-options {
          padding: 16px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          position: sticky;
          top: 0;
          background: white;
          z-index: 1;
        }
        
        .browse-category-selector {
          display: flex;
          gap: 10px;
          margin-bottom: 16px;
          overflow-x: auto;
          padding-bottom: 5px;
          -webkit-overflow-scrolling: touch;
          scrollbar-width: none;
        }
        
        .browse-category-selector::-webkit-scrollbar {
          display: none;
        }
        
        .browse-option {
          padding: 10px 16px;
          border-radius: 30px;
          border: 1px solid rgba(0, 0, 0, 0.08);
          background: none;
          font-size: 14px;
          white-space: nowrap;
          cursor: pointer;
          transition: all 0.2s ease;
          font-weight: 500;
        }
        
        .browse-option.active {
          background-color: #FF93A4;
          color: white;
          border-color: #FF93A4;
          box-shadow: 0 3px 10px rgba(255, 147, 164, 0.3);
        }
        
        .browse-option:hover:not(.active) {
          border-color: #FF93A4;
          background-color: rgba(255, 147, 164, 0.05);
        }
        
        .browse-search {
          position: relative;
        }
        
        .browse-search-input {
          width: 100%;
          padding: 12px 18px;
          border-radius: 8px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          font-size: 14px;
          transition: all 0.2s ease;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.03);
        }
        
        .browse-search-input:focus {
          outline: none;
          border-color: #FF93A4;
          box-shadow: 0 2px 12px rgba(255, 147, 164, 0.15);
        }
        
        .clear-browse-search {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          background: none;
          border: none;
          color: #666;
          font-size: 18px;
          cursor: pointer;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          transition: all 0.2s ease;
        }
        
        .clear-browse-search:hover {
          background-color: rgba(0, 0, 0, 0.05);
          color: #333;
        }
        
        .browse-categories {
          padding: 16px;
          overflow-y: auto;
        }
        
        .camp-category {
          margin-bottom: 28px;
          animation: fadeIn 0.3s ease-in-out;
          animation-fill-mode: both;
        }
        
        .camp-category:nth-child(1) { animation-delay: 0.05s; }
        .camp-category:nth-child(2) { animation-delay: 0.1s; }
        .camp-category:nth-child(3) { animation-delay: 0.15s; }
        .camp-category:nth-child(4) { animation-delay: 0.2s; }
        .camp-category:nth-child(5) { animation-delay: 0.25s; }
        
        .category-heading {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0 0 12px 0;
          font-size: 17px;
          padding-bottom: 8px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          color: #333;
          font-weight: 600;
        }
        
        .category-count {
          font-size: 14px;
          color: #666;
          font-weight: normal;
          background: rgba(0, 0, 0, 0.05);
          padding: 3px 8px;
          border-radius: 12px;
        }
        
        .category-camps {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
        
        .browse-camp-item {
          display: flex;
          align-items: center;
          gap: 12px;
          padding: 12px;
          border-radius: 10px;
          cursor: pointer;
          transition: all 0.2s ease;
          border: 1px solid transparent;
        }
        
        .browse-camp-item:hover {
          background-color: rgba(255, 147, 164, 0.05);
          border-color: rgba(255, 147, 164, 0.1);
          transform: translateX(3px);
        }
        
        .browse-camp-info {
          flex: 1;
          min-width: 0; /* For text truncation */
        }
        
        .browse-camp-name {
          margin: 0 0 3px 0;
          font-size: 15px;
          font-weight: 600;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #333;
        }
        
        .browse-camp-location {
          margin: 0;
          font-size: 13px;
          color: #666;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        
        .more-camps-note {
          text-align: center;
          padding: 14px;
          color: #666;
          font-size: 13px;
          font-style: italic;
          background-color: rgba(0, 0, 0, 0.02);
          border-radius: 8px;
          margin-top: 10px;
        }
        
        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(10px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </div>
  );
};

export default CampInfoPanel; 