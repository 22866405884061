'use client';

import React, { useState, useEffect } from 'react';
import { uploadImage } from '../lib/supabase/client';
import { useGlobalState } from './GlobalStateContext';
import './EditEntryModal.css';
import { FaImage, FaTimes } from 'react-icons/fa';

interface Entry {
  id: string;
  title: string;
  description: string;
  offeringType: string;
  photos: string[];
  createdAt: string;
  status: 'available' | 'unavailable' | 'maintenance';
  loobricateId: string;
  createdBy: string;
  creatorPseudonym: string;
  location?: string | { lat: number; lon: number };
}

interface EditEntryModalProps {
  entry: Entry;
  onClose: () => void;
  onSubmit: (updatedEntry: Entry) => Promise<void>;
  isUpdating: boolean;
  role?: string;
  'aria-modal'?: string;
  'aria-labelledby'?: string;
}

export const EditEntryModal: React.FC<EditEntryModalProps> = ({ 
  entry, 
  onClose, 
  onSubmit,
  isUpdating,
  role,
  'aria-modal': ariaModal,
  'aria-labelledby': ariaLabelledBy
}) => {
  const { userId } = useGlobalState();
  const [formData, setFormData] = useState<Entry>({
    ...entry,
    // Ensure photos is always an array of strings
    photos: Array.isArray(entry.photos) 
      ? entry.photos 
      : (entry.photos as any)?.gallery || []
  });
  const [isUploading, setIsUploading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [uploadError, setUploadError] = useState<string | null>(null);

  useEffect(() => {
    // Slight delay to ensure mount animation works
    const timer = setTimeout(() => setIsVisible(true), 50);
    return () => clearTimeout(timer);
  }, [entry]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handlePhotoUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;

    try {
      setIsUploading(true);
      setUploadError(null);
      
      // Validate file types
      const newPhotos = Array.from(files).filter(file => file.type.startsWith('image/'));
      
      if (newPhotos.length === 0) {
        throw new Error('Please select valid image files');
      }
      
      // Check maximum photos limit
      if (formData.photos.length + newPhotos.length > 4) {
        throw new Error('Maximum 4 photos allowed');
      }

      // Upload each photo
      const uploadPromises = newPhotos.map(async (file) => {
        try {
          const result = await uploadImage(
            file,
            'entry-photos',
            formData.offeringType.toLowerCase(),
            userId
          );
          
          if (!result || !result.url) {
            throw new Error('Failed to upload image');
          }
          
          return result.url;
        } catch (error) {
          console.error('Error uploading individual photo:', error);
          throw new Error(`Failed to upload ${file.name}`);
        }
      });

      const uploadedPhotos = await Promise.all(uploadPromises);
      
      setFormData(prev => ({
        ...prev,
        photos: [...prev.photos, ...uploadedPhotos]
      }));
    } catch (error) {
      console.error('Error uploading photos:', error);
      setUploadError(error instanceof Error ? error.message : 'Failed to upload photos');
    } finally {
      setIsUploading(false);
      // Clear the file input
      e.target.value = '';
    }
  };

  const handleSubmit = () => {
    // Validate required fields
    if (!formData.title.trim()) {
      alert('Title is required');
      return;
    }
    
    // Prepare the entry data for submission
    const updatedEntry: Entry = {
      ...formData,
      // Ensure photos is always an array of strings
      photos: Array.isArray(formData.photos) ? formData.photos : [],
      // Trim whitespace from text fields
      title: formData.title.trim(),
      description: formData.description.trim()
    };
    
    onSubmit(updatedEntry);
  };

  // Call onClose directly without animation delay
  const handleClose = () => {
    onClose();
  };

  const removePhoto = (index: number) => {
    setFormData(prev => ({
      ...prev,
      photos: prev.photos.filter((_, i) => i !== index)
    }));
  };

  // Helper function to get display text for status
  const getStatusDisplayText = (status: string): string => {
    switch (status) {
      case 'available': return 'Available';
      case 'unavailable': return 'Unavailable';
      case 'maintenance': return 'Under Maintenance';
      default: return status;
    }
  };

  return (
    <div 
      className="modal-overlay"
      style={{
        position: 'fixed',
        zIndex: 10000,
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: isVisible ? 1 : 0,
        transition: 'opacity 0.2s ease-out'
      }}
      onClick={(e) => {
        if (e.target === e.currentTarget) handleClose();
      }}
    >
      <div 
        className="edit-modal"
        style={{ position: 'relative', zIndex: 10001 }}
        onClick={e => e.stopPropagation()}
      >
        <div className="modal-header">
          <h2>Edit {formData.offeringType}</h2>
          <button onClick={handleClose} className="close-button" aria-label="Close">×</button>
        </div>
        <div className="modal-content">
          <div className="form-group">
            <label htmlFor="title">Title</label>
            <input
              id="title"
              type="text"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              className="form-input"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              className="form-input"
              rows={4}
            />
          </div>
          <div className="form-group">
            <label htmlFor="status">Status</label>
            <select
              id="status"
              name="status"
              value={formData.status}
              onChange={handleInputChange}
              className="form-input"
              data-testid="status-select"
            >
              <option value="available">Available</option>
              <option value="unavailable">Unavailable</option>
              <option value="maintenance">Under Maintenance</option>
            </select>
          </div>
          <div className="form-group">
            <label>Photos</label>
            <div className="photos-grid">
              {formData.photos.map((photo, index) => (
                <div key={index} className="photo-item">
                  <img src={photo} alt={`Entry photo ${index + 1}`} />
                  <button 
                    onClick={() => removePhoto(index)}
                    className="remove-photo"
                    aria-label={`Remove photo ${index + 1}`}
                  >
                    <FaTimes />
                  </button>
                </div>
              ))}
              {formData.photos.length < 4 && (
                <div className="add-photo" data-testid="add-photo-container">
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handlePhotoUpload}
                    disabled={isUploading}
                    aria-label="Upload photos"
                    data-testid="photo-upload-input"
                  />
                  <div className="upload-label">
                    {isUploading ? 'Uploading...' : 'Add Photo'}
                    <FaImage />
                  </div>
                </div>
              )}
            </div>
            {uploadError && (
              <div className="error-message">{uploadError}</div>
            )}
            <p className="helper-text">Maximum 4 photos allowed. Click on a photo to remove it.</p>
          </div>
          <div className="modal-actions">
            <button 
              onClick={handleSubmit}
              className="submit-button btn-gradient"
              disabled={isUpdating || isUploading}
            >
              {isUpdating ? 'Updating...' : 'Save Changes'}
            </button>
            <button 
              onClick={handleClose}
              className="cancel-button"
              disabled={isUpdating}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}; 