'use client';

import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './Bubble.css';
import { SurveyModal } from './SurveyModal';
import { LoobricateProfile } from './LoobricateProfile';
import Link from "next/link";
import {forwardRef, JSXElementConstructor, useMemo, RefObject} from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import type { LoobricateData } from '../types/loobricate';
import type { Survey } from '../types/survey';
import { VenueProfile as OfferingProfile } from './VenueProfile';
import { CampCard } from './CampCard';
import LLMResponseFormatter from '../lib/LLMResponseFormatter';
import Collapsible from 'react-collapsible';
import { CollapsibleThinking } from './CollapsibleThinking';
import './CollapsibleThinking.css';
import useConfiguration from '../app/hooks/useConfiguration';

interface InteractiveElement {
  type: 'survey' | 'loobricate' | 'link' | 'action' | 'choice';
  text: string;
  action: {
    id?: string;
    title?: string;
    modalType?: string;
    url?: string;
    nextState?: string;
    feedback?: string;
  };
  style?: {
    variant: 'primary' | 'secondary' | 'subtle';
    icon?: string;
    animation?: 'fade' | 'slide' | 'expand';
  };
}

interface StructuredContent {
  text: string;
  interactiveElements?: InteractiveElement[];
  state?: string;
  followUp?: {
    text: string;
    condition: string;
  };
  visualState?: {
    mood: 'calm' | 'excited' | 'focused';
    intensity: number;
    animation?: string;
  };
}

interface BubbleProps {
  content: {
    role: string;
    content: string | React.ReactNode;
    url?: string;
    processing?: boolean;
  };
  icon?: string;
}

const InteractiveChoice: React.FC<{
  element: InteractiveElement;
  onClick: () => void;
  isSelected: boolean;
}> = ({ element, onClick, isSelected }) => {
  const variants = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
    hover: { scale: 1.02, transition: { duration: 0.2 } },
    tap: { scale: 0.98 }
  };

  const getVariantStyle = () => {
    switch (element.style?.variant) {
      case 'primary':
        return 'bg-[#ff9494]/20 hover:bg-[#ff9494]/30 text-[#ff9494]';
      case 'secondary':
        return 'bg-[#2a2b36] hover:bg-[#32333e] text-gray-300';
      case 'subtle':
        return 'bg-transparent hover:bg-[#2a2b36] text-gray-400';
      default:
        return 'bg-[#2a2b36] hover:bg-[#32333e] text-gray-300';
    }
  };

  return (
    <motion.button
      className={`choice-button ${getVariantStyle()} rounded-lg px-4 py-2 mb-2 w-full text-left transition-colors ${isSelected ? 'selected' : ''}`}
      variants={variants}
      initial="initial"
      animate="animate"
      exit="exit"
      whileHover="hover"
      whileTap="tap"
      onClick={onClick}
    >
      {element.text}
    </motion.button>
  );
};

export const Bubble: JSXElementConstructor<BubbleProps> = forwardRef(function Bubble({ content, icon }, ref) {
  const { role } = content;
  const isUser = role === "user";
  const isSystem = role === "system";
  const [activeModal, setActiveModal] = useState<InteractiveElement | null>(null);
  const [modalData, setModalData] = useState<{
    survey?: Survey;
    loobricate?: LoobricateData;
  }>({});
  const [currentState, setCurrentState] = useState<string>('initial');
  const [selectedChoice, setSelectedChoice] = useState<string | null>(null);
  const [feedback, setFeedback] = useState<string | null>(null);
  const [selectedOffering, setSelectedOffering] = useState<string | null>(null);
  const [offeringData, setOfferingData] = useState<any>(null);
  const [campCards, setCampCards] = useState<any[]>([]);
  const [thinkingContent, setThinkingContent] = useState('');
  const [isStreamingThinking, setIsStreamingThinking] = useState(false);
  
  // Get showThinking setting from useConfiguration hook
  const { showThinking } = useConfiguration();
  console.log('Bubble: Using showThinking value:', showThinking);

  useEffect(() => {
    const fetchModalData = async () => {
      if (!activeModal?.action.id) return;

      try {
        if (activeModal.type === 'survey') {
          const response = await fetch(`/api/surveys/${activeModal.action.id}`);
          if (response.ok) {
            const survey = await response.json();
            setModalData(prev => ({ ...prev, survey }));
          }
        } else if (activeModal.type === 'loobricate') {
          const response = await fetch(`/api/loobricates/${activeModal.action.id}`);
          if (response.ok) {
            const loobricate = await response.json();
            setModalData(prev => ({ ...prev, loobricate }));
          }
        }
      } catch (error) {
        console.error('Error fetching modal data:', error);
      }
    };

    if (activeModal) {
      fetchModalData();
    } else {
      setModalData({});
    }
  }, [activeModal]);

  // Handle thinking content extraction
  useEffect(() => {
    if (!showThinking || typeof content.content !== 'string') return;

    const messageContent = cleanMessageContent(content.content);
    const formattedContent = LLMResponseFormatter.enhanceMarkdown(messageContent);
    const thinkingBlocks = extractThinkingContent(formattedContent);
    
    if (thinkingBlocks.length > 0) {
      setThinkingContent(thinkingBlocks.join('\n\n'));
      setIsStreamingThinking(formattedContent.includes('<think>') && !formattedContent.includes('</think>'));
    }
  }, [content.content, showThinking]);

  const handleChoiceClick = (element: InteractiveElement) => {
    setSelectedChoice(element.text);
    
    if (element.action.feedback) {
      setFeedback(element.action.feedback);
    }

    if (element.action.nextState) {
      setCurrentState(element.action.nextState);
    }

    if (element.action.modalType) {
      setActiveModal(element);
    }
  };

  const handleResourceClick = async (resourceId: string) => {
    try {
      // Fetch the full resource data
      const response = await fetch(`/api/offerings/${resourceId}`);
      const data = await response.json();
      setOfferingData(data);
      setSelectedOffering(resourceId);
    } catch (error) {
      console.error('Error fetching offering details:', error);
    }
  };

  // Parse camp card data from message content
  const parseCampCards = (messageContent: string) => {
    try {
      // Check if the message uses our new simple formatting
      if (messageContent.includes("--- ✨") && messageContent.includes("📍") && messageContent.includes("🔥 OFFERINGS:")) {
        // Don't attempt to parse camp cards for the new format
        console.log('Using simplified camp formatting, skipping camp card parsing');
        return [];
      }
      
      // Enhanced regex to better match various camp card formats with both single and double quotes
      const campCardRegex = /<camp-card\s+data=['"]({.*?})['"]>([\s\S]*?)<\/camp-card>/g;
      const matches: any[] = [];
      let match;
      
      // Debug log to help troubleshoot
      console.log('Parsing message for camp cards:', messageContent.length, 'chars');
      
      while ((match = campCardRegex.exec(messageContent)) !== null) {
        try {
          console.log('Found camp card match:', match[1].substring(0, 100) + '...');
          // Try to parse the JSON data from the camp card
          const campData = JSON.parse(match[1]);
          if (campData) {
            matches.push(campData);
          }
        } catch (e) {
          console.error('Error parsing camp card data:', e);
        }
      }
      
      console.log(`Found ${matches.length} camp cards in message`);
      return matches;
    } catch (e) {
      console.error('Error in parseCampCards:', e);
      return [];
    }
  };

  // Clean message content by removing camp-card tags but preserving thinking content
  const cleanMessageContent = (messageContent: string): string => {
    if (!messageContent || typeof messageContent !== 'string') {
      console.warn('cleanMessageContent received non-string input:', messageContent);
      return '';
    }
    
    console.log('Cleaning message content, length before:', messageContent.length);
    
    // Aggressively remove all camp card data
    let cleaned = messageContent.replace(/<camp-card\s+data=['"].*?['"]>([\s\S]*?)<\/camp-card>/g, '');
    
    // Don't remove thinking content anymore - we'll handle it in the render pipeline
    console.log('Finished cleaning, length after:', cleaned.length);
    return cleaned;
  };

  // Extract thinking content for collapsible display
  const extractThinkingContent = (html: string): string[] => {
    console.log('Extracting thinking content with showThinking:', showThinking);
    
    if (!showThinking) {
      console.log('showThinking is disabled, skipping extraction');
      return [];
    }
    
    console.log('Extracting thinking content:', html.substring(0, 100) + '...');
    
    // Array to collect content parts
    const contentParts: string[] = [];
    
    // Check if we're dealing with streaming content (incomplete tags)
    const hasOpeningTag = html.includes('<think>');
    const hasClosingTag = html.includes('</think>');
    const isStreamingThinking = hasOpeningTag && !hasClosingTag;
    
    console.log('Streaming thinking detection:', { 
      hasOpeningTag, 
      hasClosingTag, 
      isStreamingThinking 
    });
    
    // First priority: handle raw <think> tags from DeepSeek
    if (isStreamingThinking) {
      console.log('Detected streaming thinking - content has opening tag but no closing tag');
      
      // Split content at the opening <think> tag
      const parts = html.split('<think>');
      
      // Everything after the <think> tag is thinking content
      if (parts.length > 1) {
        const thinkingContent = parts[1].trim();
        console.log('Streaming thinking content, length:', thinkingContent.length);
        
        if (thinkingContent) {
          contentParts.push(thinkingContent);
        }
      }
      
      return contentParts;
    }
    
    // Handle completed <think> tags
    const rawThinkPattern = /<think>([\s\S]*?)<\/think>/g;
    let match;
    
    while ((match = rawThinkPattern.exec(html)) !== null) {
      if (match[1]) {
        const thinkingContent = match[1].trim();
        if (thinkingContent) {
          contentParts.push(thinkingContent);
        }
      }
    }
    
    // If no raw think tags found, try to find thinking content in formatted HTML
    if (contentParts.length === 0) {
      const thinkingDetailsMatch = html.match(/<div class="thinking-details">([\s\S]*?)<\/div>/g);
      if (thinkingDetailsMatch) {
        thinkingDetailsMatch.forEach(details => {
          const content = details.replace(/<div class="thinking-details">|<\/div>/g, '').trim();
          if (content) {
            contentParts.push(content);
          }
        });
      }
    }
    
    console.log('Extracted thinking content parts:', contentParts.length);
    return contentParts;
  };

  const renderContent = (contentData: any) => {
    // If content is a React node (not a string), return it directly
    if (typeof contentData !== 'string') {
      return contentData;
    }

    // Clean up content and extract thinking blocks
    const messageContent = cleanMessageContent(contentData);
    
    // Parse for camp cards
    const campCardsArray = parseCampCards(messageContent);
    
    // Update the camp cards state if cards were found
    if (campCardsArray.length > 0) {
      setCampCards(campCardsArray);
    }
    
    // Look for retry buttons
    let cleanedContent = messageContent;
    if (cleanedContent.includes('<retry-button')) {
      cleanedContent = LLMResponseFormatter.formatRetryButtons(cleanedContent);
    }
    
    // Process formatted content with markdown and custom components
    const formattedMessageContent = LLMResponseFormatter.enhanceMarkdown(cleanedContent);

    return (
      <div className="message-wrapper">
        {/* Main message content with markdown formatting - show immediately */}
        <div 
          className="message-content" 
          dangerouslySetInnerHTML={{ __html: formattedMessageContent }}
        />
        
        {/* Display feedback if it exists */}
        {feedback && (
          <motion.div 
            className="feedback-message mt-2 text-sm text-green-400"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            {feedback}
          </motion.div>
        )}
        
        {/* Display camp cards if they exist */}
        {campCards.length > 0 && (
          <div className="camp-cards-container mt-3 grid gap-3 grid-cols-1">
            {campCards.map((camp, index) => (
              <CampCard 
                key={`camp-${index}`} 
                campData={camp}
                className="w-full"
              />
            ))}
          </div>
        )}
        
        {/* Display selected offering if it exists */}
        {selectedOffering && offeringData && (
          <div className="mt-3">
            <OfferingProfile 
              offering={offeringData} 
              onClose={() => {
                setSelectedOffering(null);
                setOfferingData(null);
              }} 
            />
          </div>
        )}
      </div>
    );
  };

  // Enhanced bubble animation variants
  const bubbleVariants = {
    initial: { 
      opacity: 0,
      y: 20,
      scale: 0.95
    },
    animate: { 
      opacity: 1,
      y: 0,
      scale: 1,
      transition: { 
        duration: 0.3,
        ease: "easeOut"
      }
    },
    exit: { 
      opacity: 0,
      scale: 0.95,
      transition: { duration: 0.2 }
    }
  };

  // Add debug log for content
  if (isSystem && typeof content.content === 'string') {
    console.log("Rendering system message in Bubble:", content.content.slice(0, 50) + '...');
  }

  return (
    <motion.div
      className={`flex w-full my-1 ${isUser ? 'justify-end' : 'justify-start'}`}
      initial="initial"
      animate="animate"
      exit="exit"
      variants={bubbleVariants}
      ref={ref as RefObject<HTMLDivElement>}
    >
      <div 
        className={`bubble-container flex max-w-[94%] md:max-w-[80%] ${
          isUser ? 'flex-row-reverse' : 'flex-row'
        } items-end gap-1.5`}
      >
        {(!isUser || isSystem) && icon && (
          <div className="flex-shrink-0 h-8 w-8 overflow-hidden rounded-full bg-[#1e1e1e] flex items-center justify-center border border-[rgba(255,255,255,0.1)]">
            {icon.startsWith('/') ? (
              <img src={icon} alt={isSystem ? "System" : "Assistant"} className="h-6 w-6 object-contain" />
            ) : (
              <span className="text-xl">{icon}</span>
            )}
          </div>
        )}
        
        <div 
          className={`message-bubble ${
            isUser ? 'user-message' : isSystem ? 'system-message' : 'assistant-message'
          }`}
        >
          <div className="message-text">
            {renderContent(content.content)}
          </div>
        </div>
      </div>
      
      <AnimatePresence>
        {activeModal?.type === 'survey' && modalData.survey && (
          <SurveyModal
            isOpen={true}
            onClose={() => setActiveModal(null)}
            surveys={[{
              ...modalData.survey,
              description: modalData.survey.description || 'No description provided',
              type: modalData.survey.studyType ? 
                (modalData.survey.studyType.toLowerCase().includes('private') ? 'private' : 'public') : 
                'public',
              duration: modalData.survey.expiresAt ? 
                `Expires ${new Date(modalData.survey.expiresAt).toLocaleDateString()}` : 
                '10-15 minutes',
              reward: modalData.survey.compensation || 'Participation credit',
              url: modalData.survey.surveyUrl || '#'
            }]}
            onSelect={() => setActiveModal(null)}
          />
        )}
        {activeModal?.type === 'loobricate' && modalData.loobricate && (
          <LoobricateProfile
            onClose={() => setActiveModal(null)}
            loobricate={modalData.loobricate}
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
});