"use client";

import React, { useEffect, useRef, useState, useCallback } from "react";
import dynamic from 'next/dynamic';
import 'leaflet/dist/leaflet.css';
import "./Map.css";
import './PlayaMapGlobal.css';
import { GlobalStateContext } from './GlobalStateContext';
import CampInfoPanel from './CampInfoPanel';

// Import Leaflet types but not the actual library (which would cause SSR issues)
import type { Map as LeafletMap } from 'leaflet';

// Dynamically import each react-leaflet component separately
const MapContainer = dynamic(
  () => import('react-leaflet').then(mod => mod.MapContainer),
  { ssr: false }
);

const ImageOverlay = dynamic(
  () => import('react-leaflet').then(mod => mod.ImageOverlay),
  { ssr: false }
);

const TileLayer = dynamic(
  () => import('react-leaflet').then(mod => mod.TileLayer),
  { ssr: false }
);

const ZoomControl = dynamic(
  () => import('react-leaflet').then(mod => mod.ZoomControl),
  { ssr: false }
);

const Marker = dynamic(
  () => import('react-leaflet').then(mod => mod.Marker),
  { ssr: false }
);

const Popup = dynamic(
  () => import('react-leaflet').then(mod => mod.Popup),
  { ssr: false }
);

// Camp marker types for PlayaMap
export interface CampMarker {
  id: string;
  name: string;
  description: string;
  latitude: number;
  longitude: number;
  location_string: string;
  landmark?: string;
  playa_location?: {
    frontage: string;
    intersection: string;
    intersection_type: string;
    dimensions?: string;
    exact_location?: string;
  };
  uid?: string;
  year?: number;
  color?: string;
  url?: string;
  contact_email?: string;
  hometown?: string;
}

// Update constants to better match the 2024 map with accurate street names
const BRC_CENTER_LAT = 40.786367; // The Man location
const BRC_CENTER_LON = -119.203583; // The Man location

// Updated constants based on the 2024 Official BRC map
// These values are calibrated to match the street layout from the official map
const ESPLANADE_RADIUS = 0.0022; // Esplanade (innermost street)
const AGOG_RADIUS = 0.0030;      // Agog street (A equivalent)
const BAFFIE_RADIUS = 0.0038;    // Baffie street (B equivalent)
const CAPTIVATE_RADIUS = 0.0046; // Captivate street (C equivalent)
const DELIGHT_RADIUS = 0.0054;   // Delight street (D equivalent)
const ENCHANT_RADIUS = 0.0062;   // Enchant street (E equivalent)
const FASCINATE_RADIUS = 0.0070; // Fascinate street (F equivalent)
const GOBSMACK_RADIUS = 0.0078;  // Gobsmack street (G equivalent)
const HYPERGAWK_RADIUS = 0.0086; // Hypergawk street (H equivalent)
const INTRIGUE_RADIUS = 0.0094;  // Intrigue street (I equivalent)
const JAWDROP_RADIUS = 0.0102;   // Jawdrop street (J equivalent)
const KOBLER_RADIUS = 0.0110;    // Kobler street (K equivalent)

// Map to convert between letter streets and their full names from the 2024 map
const STREET_NAMES = {
  'A': 'AGOG',
  'B': 'BAFFIE',
  'C': 'CAPTIVATE',
  'D': 'DELIGHT',
  'E': 'ENCHANT',
  'F': 'FASCINATE',
  'G': 'GOBSMACK',
  'H': 'HYPERGAWK',
  'I': 'INTRIGUE',
  'J': 'JAWDROP',
  'K': 'KOBLER'
};

// Helper function to normalize street names - enhanced with 2024 map street names
const normalizeStreetName = (street: string): string => {
  if (!street) return 'UNKNOWN';
  
  const normalized = street.trim().toUpperCase();
  
  // Handle single letters (A-K)
  if (/^[A-K]$/.test(normalized)) {
    return normalized;
  }
  
  // Handle "Street A" format
  const streetMatch = normalized.match(/STREET\s+([A-K])/i);
  if (streetMatch) {
    return streetMatch[1];
  }
  
  // Handle full names from the 2024 map
  if (normalized === 'AGOG') return 'A';
  if (normalized === 'BAFFIE') return 'B';
  if (normalized === 'CAPTIVATE') return 'C';
  if (normalized === 'DELIGHT') return 'D';
  if (normalized === 'ENCHANT') return 'E';
  if (normalized === 'FASCINATE') return 'F';
  if (normalized === 'GOBSMACK') return 'G';
  if (normalized === 'HYPERGAWK') return 'H';
  if (normalized === 'INTRIGUE') return 'I';
  if (normalized === 'JAWDROP') return 'J';
  if (normalized === 'KOBLER') return 'K';
  
  // Handle "A STREET" format
  const letterMatch = normalized.match(/^([A-K])\s+STREET/i);
  if (letterMatch) {
    return letterMatch[1];
  }
  
  // Check for Esplanade variations
  if (normalized.includes('ESPLANADE')) {
    return 'ESPLANADE';
  }
  
  // Handle ROD'S ROAD - special case from the 2024 map
  if (normalized.includes('ROD') || normalized.includes('ROAD')) {
    return 'ROD';
  }
  
  // Case-sensitive check for 2024 naming convention
  for (const [letter, name] of Object.entries(STREET_NAMES)) {
    if (normalized.includes(name)) {
      return letter;
    }
  }
  
  return normalized;
};

// Updated function to convert playa location to lat/lon with grid-based precision
const convertPlayaLocationToLatLon = (location: any): [number, number] => {
  // Default to center if no location data
  if (!location || !location.frontage || !location.intersection) {
    console.log("Missing location data, returning center coordinates");
    return [BRC_CENTER_LAT, BRC_CENTER_LON];
  }
  
  // Normalize the frontage and intersection strings
  const frontage = normalizeStreetName(location.frontage);
  const intersection = location.intersection;
  
  console.log(`Converting location: ${frontage} & ${intersection}`);
  
  // Calculate radius based on street (use 2024 street names)
  let radius = ESPLANADE_RADIUS; // Default to Esplanade
  
  // Determine the radius based on normalized street name
  if (frontage === 'ESPLANADE') {
    radius = ESPLANADE_RADIUS;
    console.log(`Street: Esplanade, radius: ${radius}`);
  } else if (frontage === 'A' || frontage === 'AGOG') {
    radius = AGOG_RADIUS;
  } else if (frontage === 'B' || frontage === 'BAFFIE') {
    radius = BAFFIE_RADIUS;
  } else if (frontage === 'C' || frontage === 'CAPTIVATE') {
    radius = CAPTIVATE_RADIUS;
  } else if (frontage === 'D' || frontage === 'DELIGHT') {
    radius = DELIGHT_RADIUS;
  } else if (frontage === 'E' || frontage === 'ENCHANT') {
    radius = ENCHANT_RADIUS;
  } else if (frontage === 'F' || frontage === 'FASCINATE') {
    radius = FASCINATE_RADIUS;
  } else if (frontage === 'G' || frontage === 'GOBSMACK') {
    radius = GOBSMACK_RADIUS;
  } else if (frontage === 'H' || frontage === 'HYPERGAWK') {
    radius = HYPERGAWK_RADIUS;
  } else if (frontage === 'I' || frontage === 'INTRIGUE') {
    radius = INTRIGUE_RADIUS;
  } else if (frontage === 'J' || frontage === 'JAWDROP') {
    radius = JAWDROP_RADIUS;
  } else if (frontage === 'K' || frontage === 'KOBLER') {
    radius = KOBLER_RADIUS;
  } else if (frontage === 'ROD') {
    // Rod's Road is between Center Camp and the Man
    radius = ESPLANADE_RADIUS * 0.6;
  }
  
  console.log(`Using radius: ${radius} for street: ${frontage}`);
  
  // Calculate angle based on time (clock position)
  let angleDegrees = 0;
  
  // Look for time format in intersection (e.g., "2:30", "10:00")
  const timeMatch = intersection.match(/(\d+):(\d+)/);
  if (timeMatch) {
    const hour = parseInt(timeMatch[1], 10);
    const minute = parseInt(timeMatch[2], 10);
    
    // Convert time to angle (0-360 degrees)
    // In BRC, 12:00 is facing the Man (north), and angles go clockwise
    // Important: The coordinate system needs to match the 2024 map orientation
    
    // Calculate angle in 0-360 range where 12:00 = 0°, 3:00 = 90°, etc.
    const clockAngle = ((hour % 12) * 30 + minute * 0.5);
    
    // Convert to standard coordinate system for calculation
    angleDegrees = 90 - clockAngle;
    if (angleDegrees < 0) angleDegrees += 360;
    
    console.log(`Time: ${hour}:${minute.toString().padStart(2, '0')}, clock angle: ${clockAngle}°, coordinate angle: ${angleDegrees}°`);
    
    // Apply specific adjustments based on the 2024 map's oval shape
    // The city is not a perfect circle but more oval with extensions toward portals
    
    // Adjustment for the city's oval shape - extension toward 6:00
    if (hour === 6) {
      // 6:00 portal extension (12% bigger at 6:00)
      radius *= 1.12;
    } else if (hour === 5 || hour === 7) {
      // 5:00 and 7:00 are also extended but less than 6:00
      radius *= 1.08;
    } else if (hour === 4 || hour === 8) {
      // 4:00 and 8:00 slight extension
      radius *= 1.04;
    } else if (hour === 3 || hour === 9) {
      // 3:00 and 9:00 portal slight extension
      radius *= 1.06;
    } else if (hour === 2 || hour === 10) {
      // 2:00 and 10:00 very slight extension
      radius *= 1.02;
    } else if (hour === 12 || hour === 0) {
      // 12:00 slight compression
      radius *= 0.98;
    }
    
    // Fine adjustments for exact minute positions
    // We want to create smoother transitions between hours
    if (minute > 0) {
      // Adjust based on how close we are to the next hour
      const nextHourFactor = minute / 60;
      const currentHourFactor = 1 - nextHourFactor;
      
      const currentHour = hour;
      const nextHour = (hour % 12) + 1;
      
      // Get the radius adjustment for current and next hour
      let currentHourAdjustment = 1;
      let nextHourAdjustment = 1;
      
      if (currentHour === 6) currentHourAdjustment = 1.12;
      else if (currentHour === 5 || currentHour === 7) currentHourAdjustment = 1.08;
      else if (currentHour === 4 || currentHour === 8) currentHourAdjustment = 1.04;
      else if (currentHour === 3 || currentHour === 9) currentHourAdjustment = 1.06;
      else if (currentHour === 2 || currentHour === 10) currentHourAdjustment = 1.02;
      else if (currentHour === 12 || currentHour === 0) currentHourAdjustment = 0.98;
      
      if (nextHour === 6) nextHourAdjustment = 1.12;
      else if (nextHour === 5 || nextHour === 7) nextHourAdjustment = 1.08;
      else if (nextHour === 4 || nextHour === 8) nextHourAdjustment = 1.04;
      else if (nextHour === 3 || nextHour === 9) nextHourAdjustment = 1.06;
      else if (nextHour === 2 || nextHour === 10) nextHourAdjustment = 1.02;
      else if (nextHour === 12 || nextHour === 0) nextHourAdjustment = 0.98;
      
      // Apply weighted adjustment
      const blendedAdjustment = (currentHourAdjustment * currentHourFactor) + (nextHourAdjustment * nextHourFactor);
      radius *= blendedAdjustment;
    }
    
    // Street-specific radius adjustments based on the 2024 map
    if (frontage === 'ESPLANADE') {
      // Esplanade has more variations near portals
      if (hour === 6) radius *= 1.04; // Extra expansion at 6:00 portal
      else if (hour === 3 || hour === 9) radius *= 1.02; // Slight at 3:00/9:00
    } else if (frontage === 'L') {
      // L street (outermost) has more dramatic extension at 6:00
      if (hour === 6) radius *= 1.05;
      else if (hour === 5 || hour === 7) radius *= 1.03;
    }
  } else if (intersection.includes('PORTAL')) {
    // Handle special portal cases
    if (intersection.includes('3:00')) {
      angleDegrees = 90; // East
      radius *= 1.06;
    } else if (intersection.includes('6:00')) {
      angleDegrees = 180; // South
      radius *= 1.12;
    } else if (intersection.includes('9:00')) {
      angleDegrees = 270; // West
      radius *= 1.06;
    } else if (intersection.includes('12:00')) {
      angleDegrees = 0; // North
      radius *= 0.98;
    }
    
    // Handle intermediate portals
    else if (intersection.includes('1:30')) {
      angleDegrees = 45;
      radius *= 1.00;
    } else if (intersection.includes('4:30')) {
      angleDegrees = 135;
      radius *= 1.08;
    } else if (intersection.includes('7:30')) {
      angleDegrees = 225;
      radius *= 1.08;
    } else if (intersection.includes('10:30')) {
      angleDegrees = 315;
      radius *= 1.00;
    }
    
    console.log(`Portal: ${intersection}, angle: ${angleDegrees}°, adjusted radius: ${radius}`);
  }
  
  // Convert polar coordinates (angle, radius) to lat/lon offset
  const angleRadians = (angleDegrees * Math.PI) / 180;
  const latOffset = radius * Math.cos(angleRadians);
  const lonOffset = radius * Math.sin(angleRadians);
  
  // Apply offset to center coordinates
  const newLat = BRC_CENTER_LAT + latOffset;
  const newLon = BRC_CENTER_LON + lonOffset;
  
  console.log(`Final coordinates: [${newLat}, ${newLon}], from angle: ${angleDegrees}°, radius: ${radius}`);
  
  return [newLat, newLon];
};

// Create a canvas-based fallback for the playa map
const FallbackPlayaMap = dynamic(
  () => Promise.resolve(() => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    
    useEffect(() => {
      const canvas = canvasRef.current;
      if (!canvas) return;
      
      const ctx = canvas.getContext('2d');
      if (!ctx) return;
      
      // Draw desert background
      const desertGradient = ctx.createLinearGradient(0, 0, canvas.width, canvas.height);
      desertGradient.addColorStop(0, '#e5d8c0');
      desertGradient.addColorStop(1, '#d4c4a8');
      ctx.fillStyle = desertGradient;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      
      // Draw circular layout of Black Rock City
      ctx.strokeStyle = '#8c7a5b';
      ctx.lineWidth = 2;
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;
      const radius = Math.min(canvas.width, canvas.height) * 0.4;
      
      // Draw concentric circles for streets with labels
      const streets = ['Esplanade', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'];
      for (let i = 0; i < streets.length; i++) {
        const streetRadius = radius * (0.6 + (i * 0.04));
        
        // Draw the street circle
        ctx.beginPath();
        ctx.arc(centerX, centerY, streetRadius, 0, Math.PI * 2);
        ctx.stroke();
        
        // Add street label at 12 o'clock position
        ctx.save();
        ctx.fillStyle = '#8c7a5b';
        ctx.font = '14px Arial';
        ctx.textAlign = 'center';
        ctx.fillText(streets[i], centerX, centerY - streetRadius - 5);
        ctx.restore();
      }
      
      // Add BRC text and compass
      ctx.fillStyle = '#000';
      ctx.font = 'bold 24px Arial';
      ctx.textAlign = 'center';
      ctx.fillText('Black Rock City', centerX, 40);
      
    }, []);
    
    return (
      <canvas 
        ref={canvasRef} 
        width={1000} 
        height={1000} 
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
    );
  }),
  { ssr: false }
);

// Fix for Leaflet marker icons with Next.js
const fixLeafletMarkerIcons = () => {
  if (typeof window !== 'undefined') {
    // Import Leaflet here to avoid SSR issues
    const L = require('leaflet');
    
    // @ts-ignore - Leaflet's icon imports need to be set up this way for Next.js
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: '/images/marker-icon-2x.png',
      iconUrl: '/images/marker-icon.png',
      shadowUrl: '/images/marker-shadow.png',
    });
  }
};

// Cleanup function for Leaflet maps
const cleanupLeaflet = () => {
  if (typeof window !== 'undefined') {
    // Get Leaflet instance
    const L = require('leaflet');
    
    try {
      // Find all Leaflet containers in the DOM
      const containers = document.querySelectorAll('.leaflet-container');
      
      // For each container, attempt to remove the map
      containers.forEach((container) => {
        try {
          if (container.parentNode) {
            container.innerHTML = '';
            container.classList.remove('leaflet-container');
          }
        } catch (e) {
          console.error('Error cleaning up leaflet container:', e);
        }
      });
      
      // Remove any remaining Leaflet-related elements from the DOM
      const leafletElements = document.querySelectorAll(
        '.leaflet-control, .leaflet-pane, .leaflet-marker-pane, .leaflet-overlay-pane'
      );
      
      leafletElements.forEach(el => {
        if (el.parentNode) {
          el.parentNode.removeChild(el);
        }
      });
    } catch (e) {
      console.error('Error during leaflet cleanup:', e);
    }
  }
};

// Add a dynamic background component
const DynamicBackground = () => {
  return (
    <div className="dynamic-background">
      <div className="desert-overlay"></div>
      <div className="dust-particles"></div>
    </div>
  );
};

// Add more hardcoded coordinates for specific camps from the 2024 BRC map
const KNOWN_CAMPS: Record<string, { lat: number, lon: number, location?: string }> = {
  // Esplanade camps from the 2024 map (10:00 section/left side)
  // Make Slutgarden more prominent by giving it a slight adjustment so it appears right at the corner
  "Slutgarden": { lat: 40.7879, lon: -119.2086, location: "Esplanade & 10:00" },
  // Update MAXA to ensure it's in the correct location and not being placed incorrectly
  "MAXA": { lat: 40.7983, lon: -119.2035, location: "7:30 & J" }, // Ensure MAXA is at its correct location
  
  "Dusty Body Ranch": { lat: 40.7879, lon: -119.2077, location: "Esplanade & 9:45" },
  "The Spire": { lat: 40.7877, lon: -119.2068, location: "Esplanade & 9:30" },
  "Suburbia": { lat: 40.7876, lon: -119.2061, location: "Esplanade & 9:15" },
  "Frothville": { lat: 40.7875, lon: -119.2053, location: "Esplanade & 9:00" },
  
  // Agog street (A) camps
  "Disco Lemonade": { lat: 40.7893, lon: -119.2090, location: "Agog & 10:15" },
  "Horny Camp": { lat: 40.7892, lon: -119.2080, location: "Agog & 10:00" },
  "Black Rock Schoolhouse": { lat: 40.7891, lon: -119.2072, location: "Agog & 9:45" },
  "The Broken Spectacle": { lat: 40.7890, lon: -119.2065, location: "Agog & 9:30" },
  "Weird Hotel": { lat: 40.7888, lon: -119.2058, location: "Agog & 9:15" },
  
  // Baffie street (B) camps
  "Hot Space Wizard Costume Party": { lat: 40.7903, lon: -119.2085, location: "Baffie & 10:00" },
  "Just Between Friends": { lat: 40.7902, lon: -119.2077, location: "Baffie & 9:45" },
  "Dustfish": { lat: 40.7901, lon: -119.2068, location: "Baffie & 9:30" },
  "Love Puddle": { lat: 40.7900, lon: -119.2060, location: "Baffie & 9:15" },
  
  // Captivate street (C) camps
  "Rootpile": { lat: 40.7915, lon: -119.2080, location: "Captivate & 10:00" },
  "Duststorm Designs": { lat: 40.7913, lon: -119.2072, location: "Captivate & 9:45" },
  "Death Guild": { lat: 40.7912, lon: -119.2063, location: "Captivate & 9:30" },
  
  // Delight street (D) camps
  "Heebeegeebee Healers": { lat: 40.7924, lon: -119.2078, location: "Delight & 10:00" },
  "Pickle Joint": { lat: 40.7922, lon: -119.2070, location: "Delight & 9:45" },
  "Salty Jacks": { lat: 40.7923, lon: -119.2063, location: "Delight & 9:30" },
  
  // Enchant street (E) camps
  "Mirage Garage": { lat: 40.7935, lon: -119.2078, location: "Enchant & 10:00" },
  "Never Sleep Again": { lat: 40.7934, lon: -119.2070, location: "Enchant & 9:45" },
  "PolyParadise": { lat: 40.7932, lon: -119.2065, location: "Enchant & 9:30" },
  
  // Fascinate street (F) camps
  "Sunny Melon": { lat: 40.7945, lon: -119.2075, location: "Fascinate & 10:00" },
  "Water Temple": { lat: 40.7943, lon: -119.2066, location: "Fascinate & 9:40" },
  "Hedge Hog": { lat: 40.7942, lon: -119.2058, location: "Fascinate & 9:20" },
  
  // Gobsmack street (G) camps
  "Rascal": { lat: 40.7955, lon: -119.2072, location: "Gobsmack & 10:00" },
  "Altitude Lounge": { lat: 40.7953, lon: -119.2064, location: "Gobsmack & 9:45" },
  "El Tesoro": { lat: 40.7952, lon: -119.2056, location: "Gobsmack & 9:30" },
  
  // Hypergawk street (H) camps
  "17 Virgins": { lat: 40.7965, lon: -119.2070, location: "Hypergawk & 10:00" },
  "Kitten Camp": { lat: 40.7963, lon: -119.2064, location: "Hypergawk & 9:45" },
  "Pink Mammoth": { lat: 40.7962, lon: -119.2055, location: "Hypergawk & 9:30" },
  
  // Intrigue street (I) camps
  "Playa Pete's Plaza": { lat: 40.7975, lon: -119.2068, location: "Intrigue & 10:00" },
  "Helios Lounge": { lat: 40.7973, lon: -119.2062, location: "Intrigue & 9:45" },
  
  // Jawdrop street (J) camps
  "NORSE CODE": { lat: 40.7985, lon: -119.2065, location: "Jawdrop & 10:00" },
  "Home": { lat: 40.7983, lon: -119.2058, location: "Jawdrop & 9:40" },
  
  // Kobler street (K) camps
  "Playa Express": { lat: 40.7995, lon: -119.2063, location: "Kobler & 10:00" },
  "Whiskey & Dust": { lat: 40.7994, lon: -119.2055, location: "Kobler & 9:40" },
  
  // Special camps
  "Shitty Glitter": { lat: 40.7940, lon: -119.2085, location: "Delight & 10:15" },
  "Pink Mammoth Camp": { lat: 40.7960, lon: -119.2090, location: "Hypergawk & 10:30" },
  "Celtic Chaos": { lat: 40.7923, lon: -119.2088, location: "Delight & 10:15" },
  "Boatlard": { lat: 40.7922, lon: -119.2097, location: "Delight & 10:30" },
  "G's": { lat: 40.7932, lon: -119.2092, location: "Enchant & 10:30" },
  "Alborz": { lat: 40.7928, lon: -119.2082, location: "Delight & 10:15" },
  "Vault 21+": { lat: 40.7925, lon: -119.2085, location: "Delight & 10:15" },

  // Add other camps from previous list that are still valid
  // ... existing valid camp entries ...

  // Update camp locations for other major camps with correct street names
  "Center Camp": { lat: 40.7810, lon: -119.2140, location: "6:00 Portal" },
  "The Man": { lat: BRC_CENTER_LAT, lon: BRC_CENTER_LON, location: "Center" },
  "Temple 2024": { lat: 40.7910, lon: -119.1990, location: "12:00 Portal" }
};

const Map: React.FC = () => {
  // State
  const [isMapLoading, setIsMapLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageFailed, setImageFailed] = useState(false);
  const [camps, setCamps] = useState<CampMarker[]>([]);
  const [filteredCamps, setFilteredCamps] = useState<CampMarker[]>([]);
  const [userLocation, setUserLocation] = useState<[number, number] | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState<string>('all');
  const [selectedCamp, setSelectedCamp] = useState<CampMarker | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [imageLoadAttempted, setImageLoadAttempted] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [mapCenter, setMapCenter] = useState<{x: number, y: number}>({x: 50, y: 50});
  
  // Refs
  const mapRef = useRef<LeafletMap | null>(null);
  const isMountedRef = useRef(true);
  
  // Update the map image bounds to better match the BRC layout
  const imageBounds = [
    [40.7715, -119.2240], // Southwest corner - expanded to show full city 
    [40.8050, -119.1800]  // Northeast corner - expanded to show full city
  ] as [[number, number], [number, number]];

  // Path to playa map images
  const mapImageUrl = '/images/playa_map.png';
  const fallbackMapImageUrl = '/images/playa_map_fallback.png';
  
  // Add touch support for mobile devices
  const [touchStartDistance, setTouchStartDistance] = useState<number | null>(null);
  const [touchStartCenter, setTouchStartCenter] = useState<{x: number, y: number} | null>(null);
  
  // Add these additional states for clustering and performance optimization
  const [visibleMarkers, setVisibleMarkers] = useState<CampMarker[]>([]);
  const [clusters, setClusters] = useState<Array<{
    count: number,
    latitude: number,
    longitude: number,
    color: string,
    camps: CampMarker[]
  }>>([]);
  const [isClusteringEnabled, setIsClusteringEnabled] = useState(true);
  const rafRef = useRef<number | null>(null);
  const lastRenderTimeRef = useRef<number>(0);
  
  // Add state for expanded filters on mobile
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);
  
  // Toggle filter expansion for mobile view
  const toggleFilterExpansion = () => {
    setIsFilterExpanded(!isFilterExpanded);
  };
  
  // Replace the touch event handlers with cross-platform wheel and pointer events
  const handleWheel = (e: React.WheelEvent<HTMLDivElement>) => {
    e.preventDefault();
    
    // Detect pinch-to-zoom on trackpads (deltaY + ctrlKey is the standard signal)
    if (e.ctrlKey) {
      // Pinch zoom
      const scaleFactor = e.deltaY > 0 ? 0.9 : 1.1;
      setZoomLevel(prev => Math.min(Math.max(prev * scaleFactor, 0.5), 3));
    } else {
      // Pan on regular scroll
      setMapCenter(prev => ({
        x: prev.x - (e.deltaX * 0.1),
        y: prev.y - (e.deltaY * 0.1)
      }));
    }
  };

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  const handlePointerDown = (e: React.PointerEvent<HTMLDivElement>) => {
    e.currentTarget.setPointerCapture(e.pointerId);
    setIsDragging(true);
    setDragStart({
      x: e.clientX,
      y: e.clientY
    });
  };

  const handlePointerMove = (e: React.PointerEvent<HTMLDivElement>) => {
    if (!isDragging) return;
    
    const dx = e.clientX - dragStart.x;
    const dy = e.clientY - dragStart.y;
    
    // Calculate movement as a percentage of container size
    const rect = e.currentTarget.getBoundingClientRect();
    const moveX = (dx / rect.width) * 100 * (1/zoomLevel);
    const moveY = (dy / rect.height) * 100 * (1/zoomLevel);
    
    setDragStart({
      x: e.clientX,
      y: e.clientY
    });
    
    setMapCenter(prev => ({
      x: Math.max(0, Math.min(100, prev.x - moveX)),
      y: Math.max(0, Math.min(100, prev.y - moveY))
    }));
  };

  const handlePointerUp = (e: React.PointerEvent<HTMLDivElement>) => {
    e.currentTarget.releasePointerCapture(e.pointerId);
    setIsDragging(false);
  };
  
  // Cleanup on unmount
  useEffect(() => {
    isMountedRef.current = true;
    
    return () => {
      isMountedRef.current = false;
      cleanupLeaflet();
    };
  }, []);
  
  // Initialize Leaflet icons
  useEffect(() => {
    if (typeof window === 'undefined') return;
    fixLeafletMarkerIcons();
  }, []);
  
  // Check if images are accessible
  useEffect(() => {
    // Function to check if an image exists
    const checkImageExists = async (url: string, description: string) => {
      try {
        console.log(`Map component - Checking if ${description} exists: ${url}`);
        const response = await fetch(url, { method: 'HEAD' });
        console.log(`Map component - ${description} status: ${response.status}, ok: ${response.ok}`);
        return response.ok;
      } catch (err) {
        console.error(`Map component - Error checking ${description}:`, err);
        return false;
      }
    };
    
    // Check both images only once
    if (!imageLoadAttempted) {
      setImageLoadAttempted(true);
      checkImageExists(mapImageUrl, 'main map image');
      checkImageExists(fallbackMapImageUrl, 'fallback map image');
    }
  }, [mapImageUrl, fallbackMapImageUrl, imageLoadAttempted]);
  
  // Load camp data from API
  useEffect(() => {
    console.log("Map component - Starting to fetch camp data");
    setIsMapLoading(true);
    
    async function fetchData() {
      try {
        // Fetch from our new Burning Man camps API endpoint
        console.log("Map component - Fetching from API...");
        const response = await fetch('/api/burningManCamps');
        
        if (!response.ok) {
          throw new Error(`API error: ${response.status}`);
        }
        
        const data = await response.json();
        console.log(`Map component - API fetch successful. Data length: ${data.length}`);
        
        if (isMountedRef.current) {
          // Process camp data - add color based on location and improve coordinates
          const processedData = data.map((camp: CampMarker) => {
            console.log(`Processing camp: ${camp.name}`);
            
            // Calculate more accurate coordinates
            let latitude = camp.latitude;
            let longitude = camp.longitude;
            let locationProcessed = false;
            
            // First check for hardcoded coordinates from 2024 map
            if (KNOWN_CAMPS[camp.name]) {
              const knownCamp = KNOWN_CAMPS[camp.name];
              latitude = knownCamp.lat;
              longitude = knownCamp.lon;
              locationProcessed = true;
              console.log(`Using hardcoded coordinates for ${camp.name}: [${latitude}, ${longitude}]`);
              
              // If the camp has a location string but no playa_location, extract it from our lookup
              if (knownCamp.location && (!camp.playa_location || !camp.playa_location.frontage)) {
                const extractedLocation = parseLocationString(knownCamp.location);
                if (extractedLocation) {
                  camp.playa_location = {
                    frontage: extractedLocation.frontage,
                    intersection: extractedLocation.intersection,
                    intersection_type: 'time'
                  };
                }
              }
            }
            
            // Then try to use playa_location if it exists and has valid data
            else if (camp.playa_location?.frontage && camp.playa_location?.intersection) {
              console.log(`Camp ${camp.name} has playa_location data: ${camp.playa_location.frontage} & ${camp.playa_location.intersection}`);
              // Normalize the data before calculating coordinates
              const normalizedLocation = {
                frontage: normalizeStreetName(camp.playa_location.frontage),
                intersection: camp.playa_location.intersection,
                intersection_type: camp.playa_location.intersection_type || 'time'
              };
              
              [latitude, longitude] = convertPlayaLocationToLatLon(normalizedLocation);
              locationProcessed = true;
              console.log(`Generated coordinates from playa_location: [${latitude}, ${longitude}]`);
            }
            
            // If no playa_location or invalid data, try location_string
            if (!locationProcessed && camp.location_string) {
              console.log(`Camp ${camp.name} has location_string: ${camp.location_string}`);
              const extractedLocation = parseLocationString(camp.location_string);
              
              if (extractedLocation) {
                console.log(`Extracted location data: ${extractedLocation.frontage} & ${extractedLocation.intersection}`);
                
                // Create a proper playa_location object
                const playaLocation = {
                  frontage: normalizeStreetName(extractedLocation.frontage),
                  intersection: extractedLocation.intersection,
                  intersection_type: 'time'
                };
                
                // Update the camp object with the parsed location
                camp.playa_location = playaLocation;
                
                // Recalculate coordinates
                [latitude, longitude] = convertPlayaLocationToLatLon(playaLocation);
                locationProcessed = true;
                console.log(`Generated coordinates from location_string: [${latitude}, ${longitude}]`);
              }
            }
            
            // If we still don't have valid coordinates, use fallbacks
            if (!locationProcessed || isNaN(latitude) || isNaN(longitude)) {
              // Check if we have the original coordinates from the API
              if (!isNaN(camp.latitude) && !isNaN(camp.longitude)) {
                console.log(`Using original coordinates for ${camp.name}: [${camp.latitude}, ${camp.longitude}]`);
                latitude = camp.latitude;
                longitude = camp.longitude;
              } else {
                // Last resort: place at center
                console.log(`No valid coordinates for ${camp.name}, placing at center`);
                latitude = BRC_CENTER_LAT;
                longitude = BRC_CENTER_LON;
              }
            }
            
            // Special handling for Slutgarden to ensure it's properly positioned
            if (camp.name === "Slutgarden") {
              console.log("Applying special handling for Slutgarden camp");
              latitude = 40.7879;
              longitude = -119.2086;
              camp.location_string = "Esplanade & 10:00";
              if (!camp.playa_location) {
                camp.playa_location = {
                  frontage: "Esplanade",
                  intersection: "10:00",
                  intersection_type: "&"
                };
              }
            }
            
            return {
              ...camp,
              latitude,
              longitude,
              color: getCampColor(camp)
            };
          });
          
          // Add a special highlighted Slutgarden camp if it doesn't exist
          if (!processedData.some(camp => camp.name === "Slutgarden")) {
            console.log("Adding Slutgarden manually as it was missing from data");
            processedData.push({
              id: "slutgarden-special",
              name: "Slutgarden",
              description: "What we want, when we want, who we want. Forever. Sexual Comedy.",
              latitude: 40.7879,
              longitude: -119.2086,
              location_string: "Esplanade & 10:00",
              playa_location: {
                frontage: "Esplanade",
                intersection: "10:00",
                intersection_type: "&",
                exact_location: "Corner - facing man & 10:00"
              },
              color: "#FF1493", // Bright color to make it stand out
              year: 2024
            });
          }
          
          console.log(`Loaded ${processedData.length} camps from API`);
          setCamps(processedData);
          setFilteredCamps(processedData);
        }
      } catch (err) {
        console.error('Error fetching camp data:', err);
        if (isMountedRef.current) {
          setError('Failed to load camp data. Please try again later.');
        }
      } finally {
        setIsMapLoading(false);
      }
    }
    
    // Set a timeout to simulate loading and ensure proper UI feedback
    const timer = setTimeout(() => {
      console.log("Map component - Timer fired, fetching data now");
      if (isMountedRef.current) {
        fetchData();
      }
    }, 500);
    
    return () => {
      console.log("Map component - Cleanup timer");
      clearTimeout(timer);
    };
  }, []);
  
  // Handle image loading events
  const handleImageLoad = useCallback(() => {
    console.log("Map component - Primary map image loaded successfully");
    setImageLoaded(true);
    setIsMapLoading(false);
  }, []);

  const handleImageError = useCallback(() => {
    console.log("Map component - Primary image failed, trying fallback", mapImageUrl);
    setImageFailed(true);
    setIsMapLoading(false); // Make sure we stop loading on failure
  }, [mapImageUrl]);

  const handleFallbackImageLoad = useCallback(() => {
    console.log("Map component - Fallback image loaded successfully");
    setImageLoaded(true);
    setIsMapLoading(false);
  }, []);

  const handleFallbackImageError = useCallback(() => {
    console.log("Map component - Fallback image also failed", fallbackMapImageUrl);
    setImageFailed(true);
    setIsMapLoading(false);
  }, [fallbackMapImageUrl]);
  
  // Filter camps based on search and filter
  useEffect(() => {
    if (!camps.length) return;
    
    let filtered = [...camps];
    
    // Apply search filter
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      filtered = filtered.filter(camp => 
        camp.name.toLowerCase().includes(term) || 
        (camp.description && camp.description.toLowerCase().includes(term)) ||
        (camp.location_string && camp.location_string.toLowerCase().includes(term)) ||
        (camp.landmark && camp.landmark.toLowerCase().includes(term))
      );
    }
    
    // Apply category filter
    if (filter !== 'all') {
      if (filter === 'esplanade') {
        filtered = filtered.filter(camp => 
          camp.playa_location?.frontage === 'Esplanade' ||
          camp.location_string.includes('Esplanade')
        );
      } else if (filter.startsWith('time')) {
        filtered = filtered.filter(camp => {
          const match = camp.location_string.match(/\d+:\d+/);
          return match !== null;
        });
      } else {
        filtered = filtered.filter(camp => 
          camp.playa_location?.frontage === filter.toUpperCase() ||
          camp.location_string.includes(` ${filter.toUpperCase()} `) ||
          camp.location_string.includes(`${filter.toUpperCase()} &`)
        );
      }
    }
    
    setFilteredCamps(filtered);
  }, [camps, searchTerm, filter]);
  
  // Simulate user location at Black Rock City
  useEffect(() => {
    // Set default user location at Black Rock City
    setUserLocation([BRC_CENTER_LAT, BRC_CENTER_LON]);
  }, []);
  
  // Helper function to determine camp marker color based on playa location
  function getCampColor(camp: any): string {
    if (!camp.playa_location?.frontage && !camp.location_string) return '#FF93A4'; // Default to primary color
    
    // Get the frontage/street to determine color
    const frontage = camp.playa_location?.frontage || '';
    const locationString = camp.location_string || '';
    
    // Updated color mapping with app-compatible colors
    if (frontage.includes('Esplanade') || locationString.includes('Esplanade')) return '#FFD700'; // Gold for Esplanade
    if (frontage.includes('A') || locationString.includes(' A ')) return '#FF93A4'; // Primary pink
    if (frontage.includes('B') || locationString.includes(' B ')) return '#E57C8C'; // Darker pink
    if (frontage.includes('C') || locationString.includes(' C ')) return '#FF7096'; // Pink-red
    if (frontage.includes('D') || locationString.includes(' D ')) return '#FF5A87'; // Brighter pink
    if (frontage.includes('E') || locationString.includes(' E ')) return '#4DFFFF'; // Accent cyan
    if (frontage.includes('F') || locationString.includes(' F ')) return '#3CD6D6'; // Darker cyan
    if (frontage.includes('G') || locationString.includes(' G ')) return '#FFCAD4'; // Light pink
    if (frontage.includes('H') || locationString.includes(' H ')) return '#FFE4E1'; // Very light pink
    if (frontage.includes('I') || locationString.includes(' I ')) return '#D8B4BE'; // Dusty rose
    if (frontage.includes('J') || locationString.includes(' J ')) return '#D4A5A5'; // Mauve
    if (frontage.includes('K') || locationString.includes(' K ')) return '#C7989F'; // Dusty pink
    if (frontage.includes('L') || locationString.includes(' L ')) return '#9D7A80'; // Deep mauve
    
    // For numeric streets (time streets)
    if (/\d:\d+/.test(locationString)) return '#4DFFFF'; // Using accent color for time streets
    
    return '#FF93A4'; // Default fallback to primary color
  }
  
  // Handle search input
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    
    // Show search results panel when typing
    setShowSearchResults(value.length > 0);
    
    // Filter camps based on search term
    if (value.trim()) {
      const searchTermLower = value.toLowerCase();
      const filtered = camps.filter(camp => 
        camp.name.toLowerCase().includes(searchTermLower) ||
        (camp.description && camp.description.toLowerCase().includes(searchTermLower)) ||
        (camp.location_string && camp.location_string.toLowerCase().includes(searchTermLower))
      );
      setFilteredCamps(filtered);
    } else {
      // If search is cleared, apply the current filter
      applyCurrentFilter();
    }
  };

  // Apply the current filter without search term
  const applyCurrentFilter = () => {
    if (filter === 'all') {
      setFilteredCamps(camps);
    } else if (filter === 'esplanade') {
      setFilteredCamps(camps.filter(camp => 
        camp.location_string?.toLowerCase().includes('esplanade') ||
        (camp.playa_location?.frontage?.toLowerCase().includes('esplanade'))
      ));
    } else if (filter === 'time') {
      setFilteredCamps(camps.filter(camp => 
        camp.location_string?.toLowerCase().includes(':') ||
        (camp.playa_location?.intersection && camp.playa_location.intersection.includes(':'))
      ));
    } else {
      // Filter by street (A-L)
      setFilteredCamps(camps.filter(camp => 
        camp.location_string?.toLowerCase().includes(` ${filter} `) ||
        camp.location_string?.toLowerCase().includes(`${filter} &`) ||
        camp.location_string?.toLowerCase().includes(`& ${filter}`) ||
        (camp.playa_location?.frontage?.toLowerCase() === filter)
      ));
    }
  };

  // Close search results when user clicks outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchResultsRef.current && 
        !searchResultsRef.current.contains(event.target as Node) &&
        !(event.target as Element).closest('.search-input')
      ) {
        setShowSearchResults(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handle filter change
  const handleFilterChange = (newFilter: string) => {
    setFilter(newFilter);
  };
  
  // Toggle drawer for camp details
  const toggleDrawer = () => {
    setDrawerOpen(prev => !prev);
  };
  
  // Add new states for camp browsing
  const [infoDrawerVisible, setInfoDrawerVisible] = useState(false);
  const [nearbyCamps, setNearbyCamps] = useState<CampMarker[]>([]);
  const [activePanelTab, setActivePanelTab] = useState<'details' | 'browse'>('details');
  const [browseCategoryView, setBrowseCategoryView] = useState<'streets' | 'themed' | 'all'>('streets');
  const [categoryFilter, setCategoryFilter] = useState('');

  // Update handleCampSelect to show camp details and find nearby camps
  const handleCampSelect = useCallback((camp: CampMarker) => {
    setSelectedCamp(camp);
    setInfoDrawerVisible(true);
    setActivePanelTab('details');
    
    // Find nearby camps based on proximity
    // Use latitude/longitude directly instead of the non-existent coordinate property
    const nearby = camps
      .filter(m => {
        // Don't include the selected camp
        if (m.id === camp.id) return false;
        
        // Calculate distance using the latitude/longitude values directly
        const distance = Math.sqrt(
          Math.pow(camp.latitude - m.latitude, 2) + 
          Math.pow(camp.longitude - m.longitude, 2)
        );
        
        // Include if within reasonable distance
        return distance < 0.005; // Adjust this threshold as needed
      })
      .slice(0, 5); // Limit to 5 nearby camps
    
    setNearbyCamps(nearby);
  }, [camps]);
  
  // Format the camp location text
  const getLocationText = useCallback((camp: CampMarker): string => {
    if (!camp) return "Unknown location";
    
    if (camp.location_string) {
      return camp.location_string;
    } else if (camp.playa_location) {
      const { frontage, intersection } = camp.playa_location;
      let locationText = '';
      
      if (frontage) {
        locationText += frontage;
        
        if (intersection) {
          locationText += ` & ${intersection}`;
        }
      } else if (intersection) {
        locationText = intersection;
      } else {
        locationText = "Location details unavailable";
      }
      
      return locationText;
    }
    
    return "Unknown location";
  }, []);
  
  // Categorize camps for browsing
  const getCampCategories = useCallback(() => {
    const categories: { [key: string]: CampMarker[] } = {};
    
    // Different categorization based on view type
    if (browseCategoryView === 'streets') {
      // Group by frontage (streets)
      camps.forEach(camp => {
        if (!camp.playa_location) return;
        
        let category = "Other";
        if (camp.playa_location.frontage && camp.playa_location.frontage !== 'Unknown') {
          if (camp.playa_location.frontage === 'Esplanade') {
            category = 'Esplanade';
          } else if (/^[A-L]$/.test(camp.playa_location.frontage)) {
            category = `${camp.playa_location.frontage} Street`;
          } else if (/^\d+:\d+$/.test(camp.playa_location.frontage)) {
            category = "Plaza";
          } else {
            category = camp.playa_location.frontage;
          }
        }
        
        if (!categories[category]) {
          categories[category] = [];
        }
        categories[category].push(camp);
      });
    } else if (browseCategoryView === 'themed') {
      // Group alphabetically
      camps.forEach(camp => {
        let firstLetter = camp.name.charAt(0).toUpperCase();
        
        // Group numbers together
        if (/^\d/.test(firstLetter)) {
          firstLetter = '#';
        }
        
        if (!categories[firstLetter]) {
          categories[firstLetter] = [];
        }
        categories[firstLetter].push(camp);
      });
    } else {
      // All camps in a single category
      categories["All Camps"] = [...camps];
    }
    
    // Sort each category
    Object.keys(categories).forEach(key => {
      categories[key] = categories[key].sort((a, b) => a.name.localeCompare(b.name));
    });
    
    // Sort category keys
    const sortedCategories: { [key: string]: CampMarker[] } = {};
    
    if (browseCategoryView === 'streets') {
      // Custom sort for streets
      const streetOrder = ['Esplanade', ...Array.from({ length: 12 }, (_, i) => String.fromCharCode(65 + i) + ' Street'), 'Plaza', 'Center Camp', 'Other'];
      streetOrder.forEach(street => {
        if (categories[street]) {
          sortedCategories[street] = categories[street];
        }
      });
      
      // Add any categories not in the predefined order
      Object.keys(categories).forEach(key => {
        if (!sortedCategories[key]) {
          sortedCategories[key] = categories[key];
        }
      });
    } else {
      // Alphabetical sort for other views
      Object.keys(categories).sort((a, b) => {
        // Ensure # comes first if present
        if (a === '#') return -1;
        if (b === '#') return 1;
        return a.localeCompare(b);
      }).forEach(key => {
        sortedCategories[key] = categories[key];
      });
    }
    
    return sortedCategories;
  }, [camps, browseCategoryView]);
  
  // Add a toggle button for the browse panel
  const toggleBrowsePanel = useCallback(() => {
    setInfoDrawerVisible(prev => !prev);
    setActivePanelTab('browse');
  }, []);

  // Add zoom controls
  const handleZoomIn = () => {
    setZoomLevel(prev => Math.min(prev + 0.2, 3));
  };

  const handleZoomOut = () => {
    setZoomLevel(prev => Math.max(prev - 0.2, 0.5));
  };

  // Helper function to cluster markers based on proximity
  const clusterMarkers = useCallback((markers: CampMarker[], clusterRadius: number) => {
    if (!markers.length) return { visibleMarkers: [], clusters: [] };
    
    // Fast path - if zoom level is high enough, skip clustering
    if (zoomLevel > 2.5) {
      return { 
        visibleMarkers: markers.slice(0, 250), // Still limit max visible markers for performance
        clusters: [] 
      };
    }
    
    const clusters: Array<{
      count: number,
      latitude: number,
      longitude: number,
      color: string,
      camps: CampMarker[]
    }> = [];
    
    const processedCamps = new Set<string>();
    
    // Calculate adjusted cluster radius based on zoom level
    const adjustedRadius = clusterRadius / zoomLevel;
    
    // Create clusters first
    markers.forEach(camp => {
      if (processedCamps.has(camp.id)) return;
      
      const cluster = {
        count: 1,
        latitude: camp.latitude,
        longitude: camp.longitude,
        color: camp.color || '#FF93A4',
        camps: [camp]
      };
      
      // Find nearby camps to cluster
      markers.forEach(otherCamp => {
        if (camp.id === otherCamp.id || processedCamps.has(otherCamp.id)) return;
        
        const distance = Math.sqrt(
          Math.pow(camp.latitude - otherCamp.latitude, 2) + 
          Math.pow(camp.longitude - otherCamp.longitude, 2)
        );
        
        if (distance < adjustedRadius) {
          cluster.count++;
          cluster.latitude = (cluster.latitude * (cluster.count - 1) + otherCamp.latitude) / cluster.count;
          cluster.longitude = (cluster.longitude * (cluster.count - 1) + otherCamp.longitude) / cluster.count;
          cluster.camps.push(otherCamp);
          processedCamps.add(otherCamp.id);
        }
      });
      
      clusters.push(cluster);
      processedCamps.add(camp.id);
    });
    
    // If a cluster only contains one camp, it should be a visible marker
    const finalVisibleMarkers: CampMarker[] = [];
    const finalClusters = clusters.filter(cluster => {
      if (cluster.count === 1) {
        finalVisibleMarkers.push(cluster.camps[0]);
        return false;
      }
      return true;
    });
    
    // Limit visible markers for performance
    const maxVisibleMarkers = Math.min(200, finalVisibleMarkers.length);
    
    return { 
      visibleMarkers: finalVisibleMarkers.slice(0, maxVisibleMarkers), 
      clusters: finalClusters 
    };
  }, [zoomLevel]);

  // Optimize rendering with requestAnimationFrame
  const updateVisibleMarkers = useCallback(() => {
    if (!filteredCamps.length) return;
    
    // Cancel any pending animation frame
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current);
      rafRef.current = null;
    }
    
    // Use requestAnimationFrame for better performance
    rafRef.current = requestAnimationFrame(() => {
      const now = Date.now();
      
      // Throttle updates for better performance (no more than 4 updates per second)
      if (now - lastRenderTimeRef.current < 250) {
        rafRef.current = null;
        return;
      }
      
      const { visibleMarkers, clusters } = clusterMarkers(filteredCamps, 0.005);
      setVisibleMarkers(visibleMarkers);
      setClusters(clusters);
      lastRenderTimeRef.current = now;
      rafRef.current = null;
    });
  }, [filteredCamps, clusterMarkers]);

  // Update markers when zoom level or filtered camps change
  useEffect(() => {
    updateVisibleMarkers();
    
    return () => {
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }
    };
  }, [zoomLevel, filteredCamps, updateVisibleMarkers]);

  // Handle cluster click
  const handleClusterClick = (cluster: { camps: CampMarker[], latitude: number, longitude: number }) => {
    // If the cluster has only a few items, expand it
    if (cluster.camps.length <= 5) {
      setVisibleMarkers(prev => [...prev, ...cluster.camps]);
      setClusters(prev => prev.filter(c => c.latitude !== cluster.latitude || c.longitude !== cluster.longitude));
    } else {
      // If it's a large cluster, zoom in on it
      setZoomLevel(prev => Math.min(prev + 0.6, 3));
      setMapCenter({
        x: ((cluster.longitude - imageBounds[0][1]) / (imageBounds[1][1] - imageBounds[0][1])) * 100,
        y: ((imageBounds[1][0] - cluster.latitude) / (imageBounds[1][0] - imageBounds[0][0])) * 100
      });
    }
  };
  
  // Add this helper function to determine the primary location of a cluster
  const getPrimaryFrontage = (camps: CampMarker[]): string => {
    if (!camps || camps.length === 0) return '';
    
    // Count occurrences of each frontage
    const frontageCounts: Record<string, number> = {};
    
    camps.forEach(camp => {
      if (camp.playa_location?.frontage) {
        const frontage = camp.playa_location.frontage;
        frontageCounts[frontage] = (frontageCounts[frontage] || 0) + 1;
      }
    });
    
    // Find the most common frontage
    let maxCount = 0;
    let primaryFrontage = '';
    
    Object.entries(frontageCounts).forEach(([frontage, count]) => {
      if (count > maxCount) {
        maxCount = count;
        primaryFrontage = frontage;
      }
    });
    
    // If we have an intersection too, add it
    if (primaryFrontage) {
      // Try to find the most common intersection for this frontage
      const intersectionCounts: Record<string, number> = {};
      
      camps.forEach(camp => {
        if (camp.playa_location?.frontage === primaryFrontage && camp.playa_location?.intersection) {
          const intersection = camp.playa_location.intersection;
          intersectionCounts[intersection] = (intersectionCounts[intersection] || 0) + 1;
        }
      });
      
      // Find the most common intersection
      let maxIntersectionCount = 0;
      let primaryIntersection = '';
      
      Object.entries(intersectionCounts).forEach(([intersection, count]) => {
        if (count > maxIntersectionCount) {
          maxIntersectionCount = count;
          primaryIntersection = intersection;
        }
      });
      
      if (primaryIntersection) {
        return `${primaryFrontage} & ${primaryIntersection}`;
      }
      
      return primaryFrontage;
    }
    
    return '';
  };
  
  // Add state for gesture indicator
  const [showGestureHint, setShowGestureHint] = useState(true);

  // Hide the gesture hint after a delay or user interaction
  useEffect(() => {
    if (showGestureHint) {
      const timer = setTimeout(() => {
        setShowGestureHint(false);
      }, 5000);
      
      return () => clearTimeout(timer);
    }
  }, [showGestureHint]);

  // Add a gesture handler to hide the hint once user starts interacting
  const handleGesture = () => {
    if (showGestureHint) {
      setShowGestureHint(false);
    }
  };
  
  // Add state for search results panel
  const [showSearchResults, setShowSearchResults] = useState(false);
  const searchResultsRef = useRef<HTMLDivElement>(null);
  
  // Improved function to parse location string with more patterns 
  // Updated to handle formats seen in the 2024 BRC map
  const parseLocationString = (locationString: string): { frontage: string, intersection: string } | null => {
    if (!locationString) return null;
    
    // Normalize the string
    const normalized = locationString.trim().toUpperCase();
    console.log(`Parsing location string: ${normalized}`);
    
    // Pattern: "A & 3:15" or "ESPLANADE & 7:00" or using full street names
    const pattern1 = /([A-L]|ESPLANADE|ARCADE|BALLYHOO|CARNIVAL|DONNIKER|ERSATZ|FERRIS|GUT|HANKY|ILLUSION|JOLLY|KOOK|LAFFING)\s*[&@]\s*(\d+):(\d+)/i;
    const match1 = normalized.match(pattern1);
    if (match1) {
      const street = normalizeStreetName(match1[1]);
      return {
        frontage: street,
        intersection: `${match1[2]}:${match1[3]}`
      };
    }
    
    // Pattern: "3:15 & A" (reversed order)
    const pattern2 = /(\d+):(\d+)\s*[&@]\s*([A-L]|ESPLANADE|ARCADE|BALLYHOO|CARNIVAL|DONNIKER|ERSATZ|FERRIS|GUT|HANKY|ILLUSION|JOLLY|KOOK|LAFFING)/i;
    const match2 = normalized.match(pattern2);
    if (match2) {
      const street = normalizeStreetName(match2[3]);
      return {
        frontage: street,
        intersection: `${match2[1]}:${match2[2]}`
      };
    }
    
    // Pattern: Special locations like "CENTER CAMP", "6:00 PORTAL"
    const centerCampMatch = /CENTER\s+CAMP/i.test(normalized);
    if (centerCampMatch) {
      return {
        frontage: 'CENTER',
        intersection: '6:00'
      };
    }
    
    // Pattern: Portal streets
    const portalMatch = normalized.match(/(\d+):(\d+)\s+(PORTAL|PLAZA|GATE)/i);
    if (portalMatch) {
      return {
        frontage: 'ESPLANADE',
        intersection: `${portalMatch[1]}:${portalMatch[2]} ${portalMatch[3]}`
      };
    }
    
    // Pattern: Find both street and time separately
    const streetLetterMatch = normalized.match(/\b([A-L]|ESPLANADE)\b/i);
    const fullStreetNameMatch = Object.values(STREET_NAMES).find(name => normalized.includes(name));
    const timeMatch = normalized.match(/(\d+):(\d+)/);
    
    if (timeMatch) {
      let street = 'UNKNOWN';
      if (streetLetterMatch) {
        street = streetLetterMatch[1];
      } else if (fullStreetNameMatch) {
        street = normalizeStreetName(fullStreetNameMatch);
      }
      
      return {
        frontage: street,
        intersection: `${timeMatch[1]}:${timeMatch[2]}`
      };
    }
    
    console.log(`Could not parse location string: ${locationString}`);
    return null;
  };

  return (
    <div className="map-container" data-testid="map-container">
      <div className="playa-map-container" style={{ width: '100%', height: '100%', position: 'relative' }}>
        {/* Loading Overlay */}
        {!imageLoaded && !imageFailed && (
          <div className="fallback-container" style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            backgroundColor: '#E6CEAB', // Desert sand color
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '2rem'
          }}>
            {/* Playa style compass during loading */}
            <div className="brc-compass">
              <div className="brc-center"></div>
              <div className="brc-ring esplanade" data-label="Esplanade"></div>
              <div className="brc-ring street-a" data-label="A"></div>
              <div className="brc-ring street-c" data-label="C"></div>
              <div className="brc-ring street-e" data-label="E"></div>
              <div className="brc-ring street-g" data-label="G"></div>
              <div className="brc-ring street-i" data-label="I"></div>
              <div className="brc-ring street-k" data-label="K"></div>
              <div className="brc-time time-12" data-label="12:00"></div>
              <div className="brc-time time-3" data-label="3:00"></div>
              <div className="brc-time time-6" data-label="6:00"></div>
              <div className="brc-time time-9" data-label="9:00"></div>
            </div>
            <div className="loading-spinner"></div>
            <h3>Loading Playa Map...</h3>
          </div>
        )}
        
        {/* Error Overlay */}
        {error && (
          <div className="error-overlay">
            <p>{error}</p>
            <button onClick={() => setError(null)}>Try Again</button>
          </div>
        )}
        
        {/* Main Map Image */}
        <div className="map-wrapper" style={{ 
          position: 'relative', 
          width: '100%', 
          height: '100%',
          display: !error ? 'block' : 'none',
          overflow: 'hidden',
          cursor: 'grab',
          backgroundColor: '#F5EFE0' // Updated to match the color on the 2024 map
        }}>
          {/* Dynamic Background */}
          <DynamicBackground />
          
          {/* Gesture hint overlay */}
          {showGestureHint && imageLoaded && (
            <div 
              className="gesture-hint" 
              onClick={() => setShowGestureHint(false)}
            >
              <div className="gesture-icon drag-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M14 8a2 2 0 1 0-4 0v4" />
                  <path d="M3 21c0-2.5 2.5-4 4.5-4h9c1.5 0 2.5-1 2.5-3 0-2 0-5.5-3-5.5a1.5 1.5 0 0 0-1.5 1.5v5.5" />
                  <path d="M13 16c0-2.5-2.5-4-4-4s-4 1.5-4 4 1.5 4 4 4h6" />
                </svg>
              </div>
              <p>Drag to pan</p>
              
              <div className="gesture-icon pinch-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <circle cx="12" cy="12" r="10" />
                  <path d="M8 12h8" />
                  <path d="M12 8v8" />
                </svg>
              </div>
              <p>Pinch to zoom</p>
            </div>
          )}
          
          {/* Main map image */}
          <div 
            style={{ 
              position: 'relative',
              width: '100%',
              height: '100%',
              display: imageLoaded ? 'block' : 'none',
              transform: `scale(${zoomLevel})`,
              transformOrigin: `${mapCenter.x}% ${mapCenter.y}%`,
              transition: isDragging ? 'none' : 'transform 0.2s ease',
              zIndex: 10,
              touchAction: 'none' // Prevent browser handling of gestures
            }}
            onWheel={handleWheel}
            onPointerDown={(e) => {
              handlePointerDown(e);
              handleGesture();
            }}
            onPointerMove={handlePointerMove}
            onPointerUp={handlePointerUp}
            onPointerCancel={handlePointerUp}
          >
            <img
              src={mapImageUrl}
              alt="Playa Map"
              style={{ 
                width: '100%', 
                height: '100%', 
                objectFit: 'contain',
                filter: 'brightness(1.6) contrast(1.3)' // Adjusted for better visibility
              }}
              onLoad={handleImageLoad}
              onError={handleImageError}
              draggable={false}
            />
            
            {/* Add markers only if image is loaded */}
            {imageLoaded && visibleMarkers.map((camp) => {
              // Convert lat/long to positions on the map
              const markerLeft = ((camp.longitude - imageBounds[0][1]) / (imageBounds[1][1] - imageBounds[0][1])) * 100;
              const markerTop = ((imageBounds[1][0] - camp.latitude) / (imageBounds[1][0] - imageBounds[0][0])) * 100;
              
              return (
                <div
                  key={`marker-${camp.id}`}
                  className="map-marker futuristic-marker"
                  style={{
                    position: 'absolute',
                    left: `${markerLeft}%`,
                    top: `${markerTop}%`,
                    width: '25px',
                    height: '41px',
                    transform: 'translate(-50%, -100%)',
                    backgroundImage: `url('/images/camp-marker.png')`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    cursor: 'pointer',
                    zIndex: 100,
                    borderLeft: `3px solid ${camp.color || '#FF93A4'}`,
                    opacity: zoomLevel > 1.5 ? 1 : 0.7,
                    transition: 'opacity 0.2s ease, transform 0.2s ease',
                    filter: `drop-shadow(0 0 3px ${camp.color || '#FF93A4'})`,
                  }}
                  onClick={() => handleCampSelect(camp)}
                  title={camp.name}
                />
              );
            })}
            
            {/* Add cluster markers */}
            {imageLoaded && isClusteringEnabled && clusters.map((cluster, index) => {
              // Convert lat/long to positions on the map
              const markerLeft = ((cluster.longitude - imageBounds[0][1]) / (imageBounds[1][1] - imageBounds[0][1])) * 100;
              const markerTop = ((imageBounds[1][0] - cluster.latitude) / (imageBounds[1][0] - imageBounds[0][0])) * 100;
              
              // Determine the cluster's primary location
              const primaryFrontage = getPrimaryFrontage(cluster.camps);
              const locationLabel = primaryFrontage || '';
              
              return (
                <div
                  key={`cluster-${index}`}
                  className="cluster-marker"
                  style={{
                    position: 'absolute',
                    left: `${markerLeft}%`,
                    top: `${markerTop}%`,
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    color: '#333',
                    borderRadius: '50%',
                    border: `2px solid ${cluster.color}`,
                    transform: 'translate(-50%, -50%)',
                    cursor: 'pointer',
                    zIndex: 101,
                    boxShadow: `0 0 8px ${cluster.color}`,
                    backdropFilter: 'blur(3px)',
                    WebkitBackdropFilter: 'blur(3px)',
                    transition: 'all 0.2s ease',
                  }}
                  onClick={() => handleClusterClick(cluster)}
                  title={`Cluster with ${cluster.count} camps around ${locationLabel}`}
                >
                  <span style={{ 
                    fontSize: '14px',
                    fontWeight: 'bold',
                    textShadow: '0 0 3px white',
                    marginBottom: '2px'
                  }}>
                    {cluster.count}
                  </span>
                  {locationLabel && (
                    <span style={{ 
                      fontSize: '10px',
                      fontWeight: 'bold',
                      opacity: 0.8
                    }}>
                      {locationLabel}
                    </span>
                  )}
                </div>
              );
            })}
            
            {/* User location marker (only if image is loaded) */}
            {imageLoaded && userLocation && (
              <div
                className="user-marker"
                style={{
                  position: 'absolute',
                  left: `${((userLocation[1] - imageBounds[0][1]) / (imageBounds[1][1] - imageBounds[0][1])) * 100}%`,
                  top: `${((imageBounds[1][0] - userLocation[0]) / (imageBounds[1][0] - imageBounds[0][0])) * 100}%`,
                  width: '25px',
                  height: '25px',
                  transform: 'translate(-50%, -50%)',
                  backgroundImage: `url('/images/user-marker.png')`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  zIndex: 200,
                }}
                title="You are here"
              />
            )}
          </div>
          
          {/* Zoom controls - Google Maps style */}
          <div className="map-controls">
            <button className="zoom-btn" onClick={handleZoomIn} aria-label="Zoom in">+</button>
            <button className="zoom-btn" onClick={handleZoomOut} aria-label="Zoom out">−</button>
            <button 
              className="cluster-toggle-btn" 
              onClick={() => setIsClusteringEnabled(prev => !prev)}
              aria-label={isClusteringEnabled ? "Disable clustering" : "Enable clustering"}
              title={isClusteringEnabled ? "Disable clustering" : "Enable clustering"}
            >
              {isClusteringEnabled ? "📊" : "📍"}
            </button>
          </div>
          
          {/* Fallback image (only shown if main image failed) */}
          {imageFailed && (
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
              <img
                src={fallbackMapImageUrl}
                alt="Fallback Playa Map"
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                onLoad={handleFallbackImageLoad}
                onError={handleFallbackImageError}
              />
              
              {/* If fallback is loaded successfully, show markers */}
              {imageLoaded && visibleMarkers.map((camp) => {
                // Convert lat/long to positions on the map
                const markerLeft = ((camp.longitude - imageBounds[0][1]) / (imageBounds[1][1] - imageBounds[0][1])) * 100;
                const markerTop = ((imageBounds[1][0] - camp.latitude) / (imageBounds[1][0] - imageBounds[0][0])) * 100;
                
                return (
                  <div
                    key={`fallback-marker-${camp.id}`}
                    className="map-marker"
                    style={{
                      position: 'absolute',
                      left: `${markerLeft}%`,
                      top: `${markerTop}%`,
                      width: '25px',
                      height: '41px',
                      transform: 'translate(-50%, -100%)',
                      backgroundImage: `url('/images/camp-marker.png')`,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      cursor: 'pointer',
                      zIndex: 100,
                      borderLeft: `3px solid ${camp.color || '#FF93A4'}`,
                    }}
                    onClick={() => handleCampSelect(camp)}
                    title={camp.name}
                  />
                );
              })}
            </div>
          )}
          
          {/* Canvas fallback (when both image attempts fail) */}
          {imageFailed && !imageLoaded && (
            <div style={{ width: '100%', height: '100%' }}>
              <FallbackPlayaMap />
            </div>
          )}
        </div>
        
        {/* Camp info panel */}
        <CampInfoPanel
          visible={infoDrawerVisible}
          onClose={() => setInfoDrawerVisible(false)}
          selectedCamp={selectedCamp}
          activeTab={activePanelTab}
          setActiveTab={setActivePanelTab}
          nearbyCamps={nearbyCamps}
          onCampSelect={handleCampSelect}
          browseCategoryView={browseCategoryView}
          setBrowseCategoryView={setBrowseCategoryView}
          categoryFilter={categoryFilter}
          setCategoryFilter={setCategoryFilter}
          campCategories={getCampCategories()}
          getLocationText={getLocationText}
        />
        
        {/* Browse button with enhanced styles */}
        <button
          className="browse-camps-button"
          onClick={toggleBrowsePanel}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
            <polyline points="2 17 12 22 22 17"></polyline>
            <polyline points="2 12 12 17 22 12"></polyline>
          </svg>
          Browse Camps
        </button>
      </div>
      
      <style jsx>{`
        .browse-camps-button {
          position: absolute;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
          background: #FF93A4;
          color: white;
          border: none;
          border-radius: 30px;
          padding: 12px 24px;
          font-size: 16px;
          font-weight: 600;
          display: flex;
          align-items: center;
          gap: 8px;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
          z-index: 10;
          cursor: pointer;
          transition: all 0.2s;
        }
        
        .browse-camps-button:hover {
          transform: translateX(-50%) translateY(-2px);
          box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
        }
        
        /* Enhanced mobile styles */
        @media (max-width: 768px) {
          .browse-camps-button {
            bottom: 24px;
            font-size: 15px;
            padding: 12px 20px;
            background: rgba(255, 147, 164, 0.9);
            backdrop-filter: blur(4px);
            -webkit-backdrop-filter: blur(4px);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
          }
          
          .map-controls {
            right: 12px;
            bottom: 80px;
          }
        }
        
        /* Improved zoom controls */
        .map-controls {
          position: absolute;
          right: 16px;
          bottom: 20px;
          display: flex;
          flex-direction: column;
          gap: 8px;
          z-index: 15;
        }
        
        .zoom-btn, .cluster-toggle-btn {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: white;
          border: none;
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
          font-size: 18px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all 0.2s ease;
        }
        
        .zoom-btn:hover, .cluster-toggle-btn:hover {
          background: #f8f8f8;
          transform: translateY(-2px);
          box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
        }
        
        /* Better gesture hints */
        .gesture-hint {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: rgba(255, 255, 255, 0.9);
          border-radius: 16px;
          padding: 24px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;
          z-index: 1000;
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
          backdrop-filter: blur(8px);
          -webkit-backdrop-filter: blur(8px);
          border: 1px solid rgba(255, 255, 255, 0.2);
          max-width: 80%;
        }
        
        .gesture-hint p {
          margin: 0;
          font-size: 14px;
          font-weight: 500;
          color: #333;
        }
        
        .gesture-icon {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background: #FF93A4;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
        }
        
        .gesture-hint::after {
          content: 'Tap to dismiss';
          position: absolute;
          bottom: -24px;
          font-size: 12px;
          color: rgba(255, 255, 255, 0.8);
          background: rgba(0, 0, 0, 0.5);
          padding: 4px 12px;
          border-radius: 12px;
        }
      `}</style>
    </div>
  );
};

export default Map;