'use client';

// settings.tsx
"use client";

import React, { useState, useEffect, useContext, useCallback } from "react";
import { useGlobalState } from "./GlobalStateContext";
import { Dropdown } from "./Dropdown";
import dynamic from 'next/dynamic';
import "./Settings.css";
import { FaCog, FaBuilding, FaTelegram, FaBroadcastTower, FaMagic, FaSpinner, FaStar, FaUser, FaPalette, FaVideo, FaMusic, FaBroom } from 'react-icons/fa';

// Only keep the essential dynamic imports
const OmiAccountLink = dynamic(() => import('./OmiAccountLink').then(mod => ({ default: mod.OmiAccountLink })), {
  ssr: false,
  loading: () => <div className="loading-placeholder">Loading Omi Link...</div>
});

const TelegramLinkModal = dynamic(() => import('./TelegramLinkModal').then(mod => ({ default: mod.TelegramLinkModal })), {
  ssr: false,
  loading: () => <div className="loading-placeholder">Loading Telegram Link...</div>
});

interface Props {
  isOpen: boolean;
  onClose: () => void;
  llm: string;
  useRag?: boolean;
  similarityMetric?: any;
  showThinking?: boolean;
  setConfiguration?: (useRag: boolean, llm: string, similarityMetric: any, showThinking: boolean) => void;
}

// Generate stable ID utility (moved outside component to avoid re-creation)
const generateStableId = (prefix: string, index: number) => `${prefix}-${index}`;

export const Settings: React.FC<Props> = ({
  isOpen,
  onClose,
  llm,
  useRag = true,
  similarityMetric = "cosine",
  showThinking = false,
  setConfiguration,
}) => {
  const {
    connectedLoobricates = [],
    isAnonymous,
    userId,
  } = useGlobalState();

  const [selectedLlm, setSelectedLlm] = useState("meta-llama/Llama-3.3-70B-Instruct-Turbo-Free");
  const [selectedUseRag, setSelectedUseRag] = useState(useRag);
  const [selectedSimilarityMetric, setSelectedSimilarityMetric] = useState(similarityMetric);
  const [selectedShowThinking, setSelectedShowThinking] = useState(showThinking);
  const [activeSection, setActiveSection] = useState<string>('general');
  const [showJoinInfo, setShowJoinInfo] = useState(false);
  const [showTelegramLink, setShowTelegramLink] = useState(false);
  const [showOmiLink, setShowOmiLink] = useState(false);
  
  // States for Telegram linking - lazily initialized when needed
  const [telegramCode, setTelegramCode] = useState<{code: string; expiresAt: string} | null>(null);
  const [telegramLinkError, setTelegramLinkError] = useState<string | null>(null);
  const [isGeneratingTelegramCode, setIsGeneratingTelegramCode] = useState(false);

  useEffect(() => {
    if (isOpen) {
      // Sync all settings with current props when modal opens
      if (llm !== "meta-llama/Llama-3.3-70B-Instruct-Turbo-Free") {
        setSelectedLlm(llm);
      }
      setSelectedUseRag(useRag);
      setSelectedSimilarityMetric(similarityMetric);
      setSelectedShowThinking(showThinking);
    }
  }, [isOpen, llm, useRag, similarityMetric, showThinking]);

  // Auto-save when values change, but make sure to check if we should save
  useEffect(() => {
    // Only auto-save if the component is open and setConfiguration exists
    if (isOpen && setConfiguration) {
      // Add a small debounce to prevent excessive calls
      const timeoutId = setTimeout(() => {
        setConfiguration(
          selectedUseRag, 
          selectedLlm, 
          selectedSimilarityMetric,
          selectedShowThinking
        );
      }, 500);
      
      // Clear timeout on cleanup
      return () => clearTimeout(timeoutId);
    }
  }, [isOpen, selectedLlm, selectedUseRag, selectedSimilarityMetric, selectedShowThinking, setConfiguration]);

  const llmOptions = [
    { id: 'llama-3.3-70b', label: "Llama 3.3 70B", value: "meta-llama/Llama-3.3-70B-Instruct-Turbo-Free" },
    { id: 'gpt-3.5-turbo', label: "GPT 3.5 Turbo", value: "gpt-3.5-turbo" },
    { id: 'gpt-4', label: "GPT 4", value: "gpt-4" },
    { id: 'mixtral-8x7b', label: "Mixtral 8x7B", value: "mistralai/Mixtral-8x7B-Instruct-v0.1" },
    { id: 'mistral-7b', label: "Mistral 7B", value: "mistralai/Mistral-7B-Instruct-v0.2" },
    { id: 'deepseek-llama-70b', label: "DeepSeek Llama 70B", value: "deepseek-ai/DeepSeek-R1-Distill-Llama-70B-free" },
    { id: 'gemma-7b', label: "Gemma 7B", value: "google/gemma-7b-it" }
  ];

  const handleSave = () => {
    // Update localStorage with the current values
    localStorage.setItem('llm', selectedLlm);
    localStorage.setItem('useRag', String(selectedUseRag));
    localStorage.setItem('similarityMetric', selectedSimilarityMetric);
    localStorage.setItem('showThinking', String(selectedShowThinking));

    // Trigger a storage event for other components to detect the change
    window.dispatchEvent(new Event('storage'));

    // Call the configuration setter if provided
    if (setConfiguration) {
      setConfiguration(selectedUseRag, selectedLlm, selectedSimilarityMetric, selectedShowThinking);
    }

    // Close the settings modal
    onClose();
  };

  const handleSignIn = () => {
    window.location.reload();
  };

  const generateTelegramLinkCode = async () => {
    setIsGeneratingTelegramCode(true);
    setTelegramLinkError(null);
    
    try {
      const response = await fetch('/api/telegram/link-code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId }),
      });
      
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || 'Failed to generate linking code');
      }
      
      setTelegramCode(data);
    } catch (error) {
      console.error('Error generating Telegram link code:', error);
      setTelegramLinkError(error instanceof Error ? error.message : 'Unknown error occurred');
    } finally {
      setIsGeneratingTelegramCode(false);
    }
  };

  // Settings content is broken down into sections
  const settingsSections = [
    {
      id: 'general',
      title: 'General Settings',
      icon: <FaCog className="text-lg" />,
      content: (
        <div className="space-y-4">
          <div className="flex flex-col gap-4">
            {!isAnonymous && (
              <>
                {/* Telegram Integration Section */}
                <div className="bg-gray-800 p-4 rounded-md">
                  <div className="flex items-center justify-between mb-4">
                    <h3 className="text-lg font-medium">Telegram Integration</h3>
                    <FaTelegram className="text-2xl text-blue-400" />
                  </div>
                  
                  <div className="space-y-4">
                    <button 
                      className="base-button w-full flex items-center justify-center gap-2"
                      onClick={() => {
                        setShowTelegramLink(true);
                        generateTelegramLinkCode();
                      }}
                    >
                      <FaTelegram />
                      Link Telegram Account
                    </button>
                  </div>
                </div>

                {/* Omi Integration Section */}
                <div className="bg-gray-800 p-4 rounded-md">
                  <div className="flex items-center justify-between mb-4">
                    <h3 className="text-lg font-medium">Omi Integration</h3>
                    <svg className="text-2xl text-purple-400 w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z" />
                    </svg>
                  </div>
                  
                  <div className="space-y-4">
                    <p className="text-gray-300 mb-3">
                      Connect your Omi device to sync memories and transcriptions with your Memory Bank.
                    </p>
                    <button 
                      className="base-button w-full flex items-center justify-center gap-2"
                      onClick={() => setShowOmiLink(true)}
                    >
                      <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z" />
                      </svg>
                      Link Omi Account
                    </button>
                  </div>
                </div>
              </>
            )}

            <div className="bg-gray-800 p-4 rounded-md">
              <h3 className="text-lg font-medium mb-3">LLM Configuration</h3>
              <Dropdown
                fieldId="llm"
                label="LLM Model"
                options={llmOptions}
                value={selectedLlm}
                onSelect={setSelectedLlm}
              />
              <div className="mt-3 text-sm text-gray-400">
                Select the LLM model to use for chat interactions. Changes will take effect after saving.
              </div>
            </div>
          </div>
        </div>
      )
    },
    {
      id: 'loobricate',
      title: 'Loobricate Settings',
      icon: <FaBuilding className="text-lg" />,
      content: (
        <div className="space-y-4">
          {isAnonymous ? (
            <div className="bg-gray-800 p-4 rounded-md">
              <h3 className="text-lg font-medium mb-2">Loobricate Access</h3>
              <p className="text-gray-300 mb-3">
                Sign in to access and contribute to private Loobricates.
              </p>
              <button className="base-button" onClick={handleSignIn}>Sign In</button>
            </div>
          ) : (
            <div className="space-y-4">
              <div>
                <h3 className="text-lg font-medium mb-2">Your Loobricates</h3>
                <div className="bg-gray-800 p-4 rounded-md">
                  {connectedLoobricates.length > 0 ? (
                    <ul className="space-y-2">
                      {connectedLoobricates.map((loobricate, index) => (
                        <li 
                          key={typeof loobricate === 'object' && loobricate !== null 
                            ? loobricate.id 
                            : generateStableId('loobricate', index)}
                          className="flex justify-between items-center p-2 bg-gray-700 rounded-md"
                        >
                          <div>
                            <span className="font-medium">
                              {typeof loobricate === 'object' && loobricate !== null 
                                ? loobricate.name 
                                : 'Unknown Loobricate'}
                            </span>
                            <span className="text-sm text-gray-400 ml-2">
                              {typeof loobricate === 'object' && loobricate !== null && loobricate.role === 'admin' 
                                ? '(Admin)' 
                                : '(Member)'}
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className="text-gray-400">You haven't joined any Loobricates yet.</p>
                  )}
                </div>
              </div>
              
              <div className="space-y-2">
                <button 
                  className="base-button w-full"
                  onClick={() => setShowJoinInfo(!showJoinInfo)}
                >
                  Join a Loobricate
                </button>
                {showJoinInfo && (
                  <div className="p-3 bg-gray-800 rounded-md text-sm text-gray-300">
                    At the moment, the only way to join a Loobricate is to have an admin add you. Find an admin to join a Loobricate :)
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )
    },
  ];

  return (
    <>
      <div className={`modal-overlay ${isOpen ? "visible" : "hidden"}`}>
        <div className="modal-content">
          <div className="flex flex-col">
            <div className="flex justify-between items-center pb-4">
              <h1 className="settings-title">Settings</h1>
              <button
                onClick={onClose}
                className="text-3xl font-thin leading-8 hover:opacity-70 transition-opacity"
              >
                ×
              </button>
            </div>

            <div className="flex flex-col md:flex-row gap-6">
              {/* Settings Navigation */}
              <div className="settings-nav md:w-1/4 border-b md:border-b-0 md:border-r border-gray-700 pb-4 md:pb-0 md:pr-4">
                {settingsSections.map(section => (
                  <div
                    key={section.id}
                    onClick={() => setActiveSection(section.id)}
                    className={`settings-nav-item ${
                      activeSection === section.id ? 'active' : ''
                    }`}
                  >
                    <span className="mr-2">{section.icon}</span>
                    <span>{section.title}</span>
                  </div>
                ))}
              </div>

              {/* Settings Content */}
              <div className="settings-content md:w-3/4 overflow-y-auto">
                <div className="settings-section">
                  {settingsSections.find(s => s.id === activeSection)?.content}
                </div>
              </div>
            </div>

            <div className="flex justify-end gap-2 mt-4 pt-4 border-t border-gray-600">
              <button className="base-button" onClick={onClose}>
                Cancel
              </button>
              <button className="base-button" onClick={handleSave}>
                Save Settings
              </button>
            </div>
          </div>
        </div>

        {/* Only keep essential modals */}
        {showTelegramLink && (
          <TelegramLinkModal
            telegramCode={telegramCode}
            isLoading={isGeneratingTelegramCode}
            error={telegramLinkError}
            onClose={() => setShowTelegramLink(false)}
          />
        )}
        {showOmiLink && <OmiAccountLink onClose={() => setShowOmiLink(false)} />}
      </div>
    </>
  );
};

