'use client';

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FaMapMarkerAlt, FaUser, FaTools, FaUsers, FaCamera, FaImage, FaTimes, FaExclamationCircle } from 'react-icons/fa';
import './AddEntry.css';
import { useGlobalState } from '../components/GlobalStateContext';
import { useAddressAutocomplete } from '../hooks/useAddressAutocomplete';
import { supabase, uploadImage } from '../lib/supabase/client';
// Styles are managed via AddEntry.css and Tailwind classes

// Add this new type for tag keywords
type TagKeyword = string;

// Define the overall structure of the formData state
interface FormData {
  pseudonym: string;
  email: string;
  phone: string;
  password: string;
  title: string;
  offeringType: string;
  description: string;
  location?: string; // Make optional
  name: string;
  adminUsername: string;
  adminPassword: string;
  tags: TagKeyword[];
  loobricateId: string;
  latitude?: number | null; // Make optional
  longitude?: number | null; // Make optional
  photos: File[];
  photoUrls: string[];
  profilePhoto?: File | null;
  profilePhotoUrl?: string;
}

// Add this after your FormData interface
interface PlaceResult {
  description: string;
  place_id: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
}

// Add this interface for address suggestions
interface AddressSuggestion {
  display_name: string;
  lat: string;
  lon: string;
}

export const AddEntry: React.FC = () => {
  // Pull initial values from global state
  const { userId, pseudonym, email, phone, isAnonymous, connectedLoobricates } = useGlobalState();

  // Add debug logging for auth state
  useEffect(() => {
    console.log('[AddEntry] Auth state:', {
      userId,
      isAnonymous,
      hasUserId: !!userId,
      isAnonId: typeof userId === 'string' && userId.startsWith('anon-')
    });
  }, [userId, isAnonymous]);

  // Default form type is set to "Location"
  const [selectedType, setSelectedType] = useState<string>('Location');

  // Initialize the form data state with default values
  const [formData, setFormData] = useState<FormData>({
    pseudonym: pseudonym || 'Anonymously Contributed',
    email: email || 'Anonymously Contributed',
    phone: phone || 'N/A',
    password: 'default-password',
    title: '',
    offeringType: '',
    description: '',
    location: '',
    name: '',
    adminUsername: '',
    adminPassword: '',
    tags: [],
    loobricateId: '',
    latitude: null,
    longitude: null,
    photos: [],
    photoUrls: [],
    profilePhoto: null,
    profilePhotoUrl: '',
  });

  // Replace the currentTag state with a simpler keyword input
  const [tagInput, setTagInput] = useState<string>('');
  const [suggestedTags, setSuggestedTags] = useState<string[]>([]);

  // State to handle errors and submission states
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submissionSuccess, setSubmissionSuccess] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState(false);

  // Predefined tag categories
  const predefinedCategories = ['Select a category', 'Capacity', 'Cost', 'Specs', 'Availability', 'Add Your Own'];

  // Add state for address suggestions
  const [addressSuggestions, setAddressSuggestions] = useState<AddressSuggestion[]>([]);
  const [isSearching, setIsSearching] = useState(false);

  // Add state for success message
  const [successMessage, setSuccessMessage] = useState<string>('');

  // Create a ref for the address input
  const addressInputRef = useRef<HTMLInputElement | null>(null);

  // Update the isDisabled check to be more reliable
  const isDisabled = !userId || (typeof userId === 'string' && userId.startsWith('anon-'));

  // Add a state for tracking field-specific errors
  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});

  // Update the AuthOverlay component to show different message if there's an issue
  const AuthOverlay = () => (
    <div className="relative w-full h-full flex items-center justify-center p-4">
      <div className="absolute inset-0 bg-black/80 backdrop-blur-sm" />
      <div className="relative z-10 max-w-md w-full bg-[#1a1b26] rounded-xl shadow-lg border border-gray-800 p-6">
        {/* Lock Icon with subtle styling */}
        <div className="w-16 h-16 mb-6 mx-auto rounded-full bg-[#2a2b36] flex items-center justify-center">
          <svg className="w-8 h-8 text-gray-200" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
          </svg>
        </div>

        {/* Title */}
        <h3 className="text-2xl font-bold text-white text-center mb-4">
          {!userId ? 'Authentication Required' : 'Authentication Error'}
        </h3>

        {/* Content */}
        <div className="space-y-6">
          <p className="text-gray-300 text-center">
            {!userId ? 
              'Please sign in to add entries to the Loobrary. Your contributions help grow our community.' :
              'There was an issue with your authentication. Please try signing in again.'}
          </p>
          
          <button
            onClick={() => window.location.reload()}
            className="btn-fleshy w-full py-3"
          >
            Sign In
          </button>
          
          <p className="text-gray-400 text-sm text-center">
            {!userId ? "Don't have an account? Sign up when you sign in." : "Having trouble? Try refreshing the page."}
          </p>
        </div>
      </div>
    </div>
  );

  // Update the geocodeAddress function to use Nominatim
  const geocodeAddress = async (address: string): Promise<[number, number] | null> => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(address)}&limit=1`,
        {
          headers: {
            'Accept-Language': 'de', // Prioritize German results
            'User-Agent': 'Loob App (your@email.com)' // Replace with your contact
          }
        }
      );
      
      if (!response.ok) {
        throw new Error('Failed to geocode address');
      }
      
      const data = await response.json();
      if (data && data[0]) {
        return [parseFloat(data[0].lat), parseFloat(data[0].lon)];
      }
      return null;
    } catch (error) {
      console.error('Geocoding error:', error);
      return null;
    }
  };

  // Update searchAddresses to be non-blocking
  const searchAddresses = async (query: string) => {
    if (query.length < 3) {
      setAddressSuggestions([]);
      return;
    }

    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(query)}&countrycodes=de&limit=5`,
        {
          headers: {
            'Accept-Language': 'de',
            'User-Agent': 'Loob App (contact@loob.com)'
          }
        }
      );
      
      if (response.ok) {
        const data = await response.json();
        setAddressSuggestions(data);
      }
    } catch (error) {
      console.error('Error fetching address suggestions:', error);
    } finally {
      setIsSearching(false);
    }
  };

  // Create a debounced version of searchAddresses
  const debouncedSearch = useCallback((query: string) => {
    searchAddresses(query);
  }, [searchAddresses]);

  // Update handleAddressChange to use the ref
  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFormData(prev => ({ ...prev, location: value }));

    if (value.length >= 3) {
      setIsSearching(true);
      debouncedSearch(value);
    } else {
      setAddressSuggestions([]);
    }

    // Refocus the input to prevent losing focus
    if (addressInputRef.current) {
      addressInputRef.current.focus();
    }
  };

  // Update the handleSuggestionClick function
  const handleSuggestionClick = async (suggestion: AddressSuggestion, e: React.MouseEvent) => {
    e.preventDefault(); // Prevent default behavior
    setFormData(prev => ({
        ...prev,
        location: suggestion.display_name,
        latitude: parseFloat(suggestion.lat),
        longitude: parseFloat(suggestion.lon)
    }));
    setAddressSuggestions([]);

    // Refocus the input using the ref
    if (addressInputRef.current) {
      addressInputRef.current.focus();
    }
  };

  // Update form data state when an input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Add handleTagKeyDown function
  const handleTagKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && tagInput.trim()) {
      e.preventDefault();
      if (!formData.tags.includes(tagInput.trim())) {
        setFormData(prev => ({
          ...prev,
          tags: [...prev.tags, tagInput.trim()]
        }));
      }
      setTagInput('');
    }
  };

  // Update the handleTagInputChange function
  const handleTagInputChange = async (value: string) => {
    setTagInput(value);
    
    // Only show suggestions if we have at least 2 characters
    if (value.length >= 2) {
      // Simple tag suggestions based on predefined categories and common tags
      const commonTags = [
        // Location tags
        'indoor', 'outdoor', 'studio', 'venue', 'rehearsal',
        // Gear tags
        'audio', 'lighting', 'video', 'instrument', 'tools', 'equipment',
        // Talent tags
        'musician', 'technician', 'artist', 'producer', 'engineer',
        // Availability tags
        'weekends', 'weekdays', 'evenings', 'full-time', 'part-time',
        // Cost tags
        'free', 'paid', 'negotiable', 'hourly', 'daily'
      ];
      
      // Filter tags that match the input
      const filteredTags = commonTags.filter(tag => 
        tag.toLowerCase().includes(value.toLowerCase())
      );
      
      setSuggestedTags(filteredTags);
    } else {
      setSuggestedTags([]);
    }
  };

  // Update the handleAddTag function
  const handleAddTag = () => {
    if (formData.tags.length >= 5) {
      setError('You can add up to 5 tags only.');
      return;
    }
    if (!tagInput.trim()) {
      setError('Please enter a tag keyword.');
      return;
    }
    setFormData((prev) => ({
      ...prev,
      tags: [...prev.tags, tagInput.trim()],
    }));
    setTagInput(''); // Reset input
    setError('');
  };

  // Remove a tag by its index
  const handleTagRemove = (index: number) => {
    setFormData((prev) => ({
      ...prev,
      tags: prev.tags.filter((_, i) => i !== index), // Filter out the tag at the given index
    }));
  };

  // Update the handleTypeSelection function
  const handleTypeSelection = (type: string) => {
    setSelectedType(type);
    // Update offeringType to use 'venue' instead of 'location'
    setFormData((prev) => ({ 
      ...prev, 
      offeringType: type.toLowerCase() === 'location' ? 'venue' : type.toLowerCase() 
    }));
  };

  // Update the AddressInput component
  const AddressInput = ({ errorMessage }: { errorMessage?: string }) => {
    const {
      inputValue,
      suggestions,
      loading,
      error: addressError,
      containerRef,
      handleInputChange,
      handleSelectSuggestion,
    } = useAddressAutocomplete({
      onSelect: (address) => {
        setFormData(prev => ({
          ...prev,
          location: address.formatted,
          latitude: address.lat,
          longitude: address.lon
        }));
      }
    });

    return (
      <div className="address-input-container" ref={containerRef}>
        <div className="field-label">
          <label htmlFor="location" className="block text-sm font-medium text-gray-200">
            Location
          </label>
          <span className="field-required">Required</span>
        </div>
        <input
          type="text"
          id="location"
          name="location"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Enter an address"
          className={`form-input ${errorMessage ? 'error' : ''}`}
          autoComplete="off"
        />
        {errorMessage && (
          <div className="input-error-message">
            <FaExclamationCircle />
            <span>{errorMessage}</span>
          </div>
        )}
        {loading && (
          <div className="search-indicator">Searching...</div>
        )}
        {suggestions.length > 0 && (
          <ul className="suggestions-list">
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                className="suggestion-item"
                onClick={() => handleSelectSuggestion(suggestion)}
              >
                {suggestion.display_name}
              </li>
            ))}
          </ul>
        )}
        <p className="field-helper">
          Enter a complete address for accurate mapping
        </p>
        {formData.latitude && formData.longitude && (
          <div className="confirmed-address">
            <div className="confirmation-header">
              <span className="checkmark">✓</span>
              <span>Address Confirmed</span>
            </div>
            <p className="confirmed-address-text">{formData.location}</p>
            <p className="coordinates-text">
              Coordinates: {formData.latitude.toFixed(6)}, {formData.longitude.toFixed(6)}
            </p>
          </div>
        )}
        {addressError && (
          <p className="error-message">{addressError}</p>
        )}
      </div>
    );
  };

  // Update the renderFormFields function
  const renderFormFields = () => {
    switch (formData.offeringType.toLowerCase()) {
      case 'venue':
      case 'gear':
      case 'talent':
        return (
          <div className="space-y-6">
            {/* Error summary at the top if there are errors */}
            {Object.keys(fieldErrors).length > 0 && (
              <div className="error-summary">
                <div className="error-summary-title">
                  <FaExclamationCircle />
                  <span>Please fix the following issues:</span>
                </div>
                <ul className="error-summary-list">
                  {Object.values(fieldErrors).map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            )}
            
            <div>
              <div className="field-label">
                <label htmlFor="title" className="block text-sm font-medium text-gray-200">
                  Title
                </label>
                <span className="field-required">Required</span>
              </div>
              <input
                type="text"
                name="title"
                id="title"
                value={formData.title}
                onChange={handleInputChange}
                className={`mt-1 block w-full rounded-md border-gray-600 bg-gray-700 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm form-input ${fieldErrors.title ? 'error' : ''}`}
                placeholder={`Enter a title for your ${selectedType.toLowerCase()} offering`}
              />
              {renderFieldError('title')}
            </div>

            <div>
              <div className="field-label">
                <label htmlFor="description" className="block text-sm font-medium text-gray-200">
                  Description
                </label>
                <span className="field-required">Required</span>
              </div>
              <textarea
                name="description"
                id="description"
                value={formData.description}
                onChange={handleInputChange}
                rows={4}
                className={`mt-1 block w-full rounded-md border-gray-600 bg-gray-700 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm form-input ${fieldErrors.description ? 'error' : ''}`}
                placeholder={`Describe your ${selectedType.toLowerCase()} offering`}
              />
              {renderFieldError('description')}
            </div>

            {/* Loobricate Selection */}
            <div>
              <div className="field-label">
                <label htmlFor="loobricateId" className="block text-sm font-medium text-gray-200">
                  Loobricate Community
                </label>
                <span className="field-required">Required</span>
              </div>
              <select
                name="loobricateId"
                id="loobricateId"
                value={formData.loobricateId || ''}
                onChange={handleInputChange}
                className={`mt-1 block w-full rounded-md border-gray-600 bg-gray-700 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm form-input ${fieldErrors.loobricateId ? 'error' : ''}`}
              >
                <option value="">Select a Loobricate</option>
                {connectedLoobricates && Array.isArray(connectedLoobricates) ? 
                  connectedLoobricates
                    .filter(loobricate => 
                      typeof loobricate === 'object' && 
                      loobricate !== null && 
                      'id' in loobricate &&
                      'name' in loobricate &&
                      typeof loobricate.name === 'string' && 
                      loobricate.name.trim() !== ''
                    )
                    .map(loobricate => {
                      // This type assertion is safe because of our filter above
                      const loob = loobricate as { id: string, name: string };
                      return (
                        <option 
                          key={loob.id} 
                          value={loob.id}
                        >
                          {loob.name}
                        </option>
                      );
                    })
                  : null
                }
              </select>
              {renderFieldError('loobricateId')}
            </div>

            {/* Photo Upload Section */}
            <div>
              <div className="field-label">
                <label className="block text-sm font-medium text-gray-200">
                  Photos
                </label>
                <span className="field-required">At least one required</span>
              </div>
              <div className={`photo-upload-container ${fieldErrors.photos ? 'error' : ''}`}>
                {renderPhotoUpload()}
              </div>
              {renderFieldError('photos')}
            </div>

            {/* Location Input */}
            <div>
              <div className="field-label">
                <label className="block text-sm font-medium text-gray-200">
                  Location
                </label>
              </div>
              <AddressInput errorMessage={fieldErrors.location} />
            </div>

            {/* Tags Input */}
            <div>
              <label className="block text-sm font-medium text-gray-200">
                Tags
              </label>
              <div className="mt-1 flex flex-wrap gap-2">
                {formData.tags.map((tag, index) => (
                  <span
                    key={index}
                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-700 text-gray-200"
                  >
                    {tag}
                    <button
                      type="button"
                      onClick={() => handleTagRemove(index)}
                      className="ml-1 inline-flex items-center p-0.5 rounded-full text-gray-400 hover:text-gray-200 focus:outline-none"
                    >
                      ×
                    </button>
                  </span>
                ))}
              </div>
              <input
                type="text"
                placeholder="Add tags (press Enter after each tag)"
                value={tagInput}
                onChange={(e) => handleTagInputChange(e.target.value)}
                onKeyDown={handleTagKeyDown}
                className="mt-1 block w-full rounded-md border-gray-600 bg-gray-700 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm form-input"
              />
            </div>
          </div>
        );
      case 'loobricate':
        return (
          <>
            {/* Error summary at the top if there are errors */}
            {Object.keys(fieldErrors).length > 0 && (
              <div className="error-summary">
                <div className="error-summary-title">
                  <FaExclamationCircle />
                  <span>Please fix the following issues:</span>
                </div>
                <ul className="error-summary-list">
                  {Object.values(fieldErrors).map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            )}
            
            <p className="info-text">Provide all required information for Loobricate setup.</p>
            
            <div className="profile-photo-section mb-6">
              <h3 className="text-lg font-bold mb-2">Community Profile Photo</h3>
              <div 
                className="profile-photo-container cursor-pointer"
                onClick={() => document.getElementById('profile-photo-input')?.click()}
              >
                {formData.profilePhotoUrl ? (
                  <img 
                    src={formData.profilePhotoUrl} 
                    alt="Profile preview" 
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="profile-photo-placeholder">
                    <FaCamera className="w-12 h-12 opacity-50" />
                  </div>
                )}
                <div className="profile-photo-overlay">
                  <span className="upload-button">
                    <FaCamera className="inline-block mr-2" />
                    Choose Photo
                  </span>
                </div>
              </div>
              <input
                id="profile-photo-input"
                type="file"
                accept="image/*"
                onChange={handleProfilePhotoUpload}
                className="hidden"
                data-testid="profile-photo-upload"
              />
              <p className="text-sm text-gray-400 mt-2">
                Upload a square photo (max 5MB)
              </p>
            </div>

            <div className="field-label">
              <label htmlFor="name" className="block text-sm font-medium text-gray-200">
                Loobricate Name
              </label>
              <span className="field-required">Required</span>
            </div>
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Loobricate Name"
              value={formData.name || ''}
              onChange={handleInputChange}
              className={`form-input ${fieldErrors.name ? 'error' : ''}`}
            />
            {renderFieldError('name')}
            
            <div className="field-label">
              <label htmlFor="description" className="block text-sm font-medium text-gray-200">
                Description
              </label>
              <span className="field-required">Required</span>
            </div>
            <textarea
              name="description"
              id="description"
              placeholder="Describe the Loobricate."
              value={formData.description || ''}
              onChange={handleInputChange}
              className={`form-input ${fieldErrors.description ? 'error' : ''}`}
            />
            {renderFieldError('description')}
            
            <AddressInput errorMessage={fieldErrors.location} />
            
            <div className="field-label">
              <label htmlFor="adminUsername" className="block text-sm font-medium text-gray-200">
                Admin Username
              </label>
              <span className="field-required">Required</span>
            </div>
            <input
              type="text"
              name="adminUsername"
              id="adminUsername"
              placeholder="Admin Username"
              value={formData.adminUsername || ''}
              onChange={handleInputChange}
              className={`form-input ${fieldErrors.adminUsername ? 'error' : ''}`}
            />
            {renderFieldError('adminUsername')}
            
            <div className="field-label">
              <label htmlFor="adminPassword" className="block text-sm font-medium text-gray-200">
                Admin Password
              </label>
              <span className="field-required">Required (min 8 characters)</span>
            </div>
            <input
              type="password"
              name="adminPassword"
              id="adminPassword"
              placeholder="Admin Password"
              value={formData.adminPassword || ''}
              onChange={handleInputChange}
              className={`form-input ${fieldErrors.adminPassword ? 'error' : ''}`}
            />
            {renderFieldError('adminPassword')}
          </>
        );
      default:
        return null;
    }
  };

  // Add these validation functions
  const validateForm = (): string[] => {
    const errors: string[] = [];
    const newFieldErrors: Record<string, string> = {};

    if (selectedType === 'Loobricate') {
      if (!formData.location || (!formData.latitude && !formData.longitude)) {
        errors.push('Please select a valid address from the suggestions');
        newFieldErrors.location = 'Please select a valid address from the suggestions';
      }
      if (!formData.name?.trim()) {
        errors.push('Loobricate name is required');
        newFieldErrors.name = 'Loobricate name is required';
      }
      if (!formData.adminUsername?.trim()) {
        errors.push('Admin username is required');
        newFieldErrors.adminUsername = 'Admin username is required';
      }
      if (!formData.adminPassword?.trim()) {
        errors.push('Admin password is required');
        newFieldErrors.adminPassword = 'Admin password is required';
      } else if (formData.adminPassword?.length < 8) {
        errors.push('Password must be at least 8 characters long');
        newFieldErrors.adminPassword = 'Password must be at least 8 characters long';
      }
    } else {
      if (!formData.loobricateId) {
        errors.push('Please select a Loobricate community');
        newFieldErrors.loobricateId = 'Please select a Loobricate community';
      }
      if (!formData.title?.trim()) {
        errors.push(`${selectedType} name is required`);
        newFieldErrors.title = `${selectedType} name is required`;
      }
    }

    if (!formData.description?.trim()) {
      errors.push('Description is required');
      newFieldErrors.description = 'Description is required';
    }

    // Add photo validation if needed
    if (formData.offeringType !== 'loobricate' && formData.photoUrls.length === 0) {
      errors.push('Please add at least one photo');
      newFieldErrors.photos = 'Please add at least one photo';
    }

    setFieldErrors(newFieldErrors);
    return errors;
  };

  // Add this after the renderFormFields function
  const handlePhotoUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    const remainingSlots = 4 - formData.photos.length;
    
    if (remainingSlots <= 0) {
      alert('Maximum 4 photos allowed');
      return;
    }

    const newFiles = files.slice(0, remainingSlots);
    const validFiles = newFiles.filter(file => {
      if (!file.type.startsWith('image/')) {
        alert('Please upload only image files');
        return false;
      }
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        alert('Image size should be less than 5MB');
        return false;
      }
      return true;
    });

    if (validFiles.length === 0) return;

    setIsUploading(true);
    try {
      const uploadPromises = validFiles.map(async (file) => {
        const { url, path } = await uploadImage(
          file,
          'entry-photos',
          formData.offeringType.toLowerCase(),
          userId
        );
        return { url, path };
      });

      const results = await Promise.all(uploadPromises);
      
      setFormData(prev => ({
        ...prev,
        photos: [...prev.photos, ...validFiles],
        photoUrls: [...prev.photoUrls, ...results.map(r => r.url)]
      }));
    } catch (error) {
      console.error('Error uploading photos:', error);
      alert('Failed to upload photos. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  const handleRemovePhoto = (index: number) => {
    setFormData(prev => ({
      ...prev,
      photos: prev.photos.filter((_, i) => i !== index),
      photoUrls: prev.photoUrls.filter((_, i) => i !== index)
    }));
  };

  // Add profile photo upload handler
  const handleProfilePhotoUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    try {
      if (!file.type.startsWith('image/')) {
        setError('Please upload an image file');
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        setError('Image size should be less than 5MB');
        return;
      }

      setFormData(prev => ({
        ...prev,
        profilePhoto: file,
        profilePhotoUrl: URL.createObjectURL(file)
      }));
    } catch (error) {
      console.error('Error handling profile photo:', error);
      setError('Failed to process profile photo');
    }
  };

  // Update the handleSubmit function to handle photo uploads
  const handleSubmit = async () => {
    const errors = validateForm();
    if (errors.length > 0) {
      setError(errors.join('\n'));
      // Scroll to the first error field
      const firstErrorField = document.querySelector('.form-input.error');
      if (firstErrorField) {
        firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return;
    }

    setIsSubmitting(true);
    setError(null);
    setFieldErrors({});

    try {
      // Handle Loobricate creation
      if (selectedType === 'Loobricate') {
        const loobricateData = {
          name: formData.name,
          description: formData.description,
          location: formData.location,
          latitude: formData.latitude,
          longitude: formData.longitude,
          adminUsername: formData.adminUsername,
          adminPassword: formData.adminPassword,
          profilePhoto: formData.profilePhotoUrl,
          createdBy: userId,
          creatorPseudonym: pseudonym,
          status: 'active'
        };

        const loobricateResponse = await fetch('/api/loobricates', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(loobricateData),
        });

        if (!loobricateResponse.ok) {
          throw new Error('Failed to create Loobricate');
        }

        const loobricate = await loobricateResponse.json();
        setSuccessMessage('Loobricate created successfully!');
      } 
      // Handle resource entry creation
      else {
        const entryData = {
          title: formData.title,
          description: formData.description,
          offeringType: formData.offeringType.toLowerCase(),
          location: formData.location,
          tags: formData.tags,
          photos: formData.photoUrls,
          createdBy: userId,
          creatorPseudonym: pseudonym,
          loobricateId: formData.loobricateId,
          latitude: formData.latitude,
          longitude: formData.longitude,
          status: 'available'
        };

        const response = await fetch('/api/entries', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(entryData),
        });

        if (!response.ok) {
          throw new Error('Failed to create entry');
        }

        setSuccessMessage('Entry added successfully!');
      }

      // Reset form after successful submission
      resetForm();
    } catch (error) {
      console.error('Error submitting form:', error);
      setError(error instanceof Error ? error.message : 'Failed to submit form');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Update resetForm to handle both types
  const resetForm = () => {
    setFormData({
      pseudonym: pseudonym || 'Anonymously Contributed',
      email: email || 'Anonymously Contributed',
      phone: phone || 'N/A',
      password: 'default-password',
      title: '',
      offeringType: '',
      description: '',
      location: '',
      name: '',
      adminUsername: '',
      adminPassword: '',
      tags: [],
      loobricateId: '',
      latitude: null,
      longitude: null,
      photos: [],
      photoUrls: [],
      profilePhoto: null,
      profilePhotoUrl: '',
    });
    setTagInput('');
    setSuggestedTags([]);
    setError('');
    setFieldErrors({});
    setSubmissionSuccess(false);
  };

  // Update the renderPhotoUpload function to include error state
  const renderPhotoUpload = () => {
    return (
      <div className="photo-upload-section">
        <div className="photo-grid">
          {formData.photoUrls.map((url, index) => (
            <div key={index} className="photo-item">
              <img src={url} alt={`Upload ${index + 1}`} />
              <button
                type="button"
                onClick={() => handleRemovePhoto(index)}
                className="remove-photo"
              >
                <FaTimes />
              </button>
            </div>
          ))}
          
          {formData.photoUrls.length < 4 && (
            <div 
              className="photo-upload-placeholder"
              onClick={() => document.getElementById('photo-upload')?.click()}
            >
              <FaImage className="upload-icon" />
              <span>Add Photo</span>
            </div>
          )}
        </div>
        
        <input
          id="photo-upload"
          type="file"
          accept="image/*"
          onChange={handlePhotoUpload}
          multiple={formData.photoUrls.length < 3}
          className="hidden"
          data-testid="photo-upload"
        />
        
        <p className="text-xs text-gray-400 mt-2">
          Upload up to 4 photos (max 5MB each)
        </p>
      </div>
    );
  };

  // Add a helper function to render field error messages
  const renderFieldError = (fieldName: string) => {
    if (!fieldErrors[fieldName]) return null;
    
    return (
      <div className="input-error-message">
        <FaExclamationCircle />
        <span>{fieldErrors[fieldName]}</span>
      </div>
    );
  };

  return (
    <div className="add-entry-container relative min-h-[600px]">
      {isDisabled && <AuthOverlay />}
      <div className={`transition-opacity duration-200 ${isDisabled ? 'opacity-25 pointer-events-none blur-[2px]' : 'opacity-100'}`}>
        <div className="entry-type-sections">
          <div className="loobricate-section">
            <h2 className="section-title">Create a Loobricate</h2>
            <div className="entry-type-icons">
              <FaUsers
                className={`icon loobricate-icon ${selectedType === 'Loobricate' ? 'active' : ''}`}
                onClick={() => handleTypeSelection('Loobricate')}
              />
            </div>
            <p className="loobricate-description">
              Create a dedicated space for your community to share and collaborate
            </p>
          </div>

          <div className="loobrary-section">
            <h2 className="section-title">Add to Loobrary</h2>
            <p className="section-description">
              Add resources to an existing Loobricate community
            </p>
            <div className="entry-type-icons">
              <FaMapMarkerAlt
                className={`icon ${selectedType === 'Location' ? 'active' : ''}`}
                onClick={() => handleTypeSelection('Location')}
              />
              <FaUser
                className={`icon ${selectedType === 'Talent' ? 'active' : ''}`}
                onClick={() => handleTypeSelection('Talent')}
              />
              <FaTools
                className={`icon ${selectedType === 'Gear' ? 'active' : ''}`}
                onClick={() => handleTypeSelection('Gear')}
              />
            </div>
          </div>
        </div>

        {selectedType && (
          <>
            <h2 className="subtitle">
              {selectedType === 'Loobricate' 
                ? 'Spawn Loobricate' 
                : selectedType === 'Location' 
                  ? 'Add Venue'
                  : `Add ${selectedType}`}
            </h2>
            <div className="form-container">
              {selectedType !== 'Loobricate' && (
                <div className="loobricate-select-container">
                  <label htmlFor="loobricateId">Select Loobricate Community *</label>
                  <select
                    id="loobricateId"
                    name="loobricateId"
                    value={formData.loobricateId}
                    onChange={handleInputChange}
                    className="form-input"
                    required
                  >
                    <option value="">Select a Loobricate...</option>
                    {connectedLoobricates && Array.isArray(connectedLoobricates) ? 
                      connectedLoobricates
                        .filter(loobricate => 
                          typeof loobricate === 'object' && 
                          loobricate !== null && 
                          'id' in loobricate &&
                          'name' in loobricate &&
                          typeof loobricate.name === 'string' && 
                          loobricate.name.trim() !== ''
                        )
                        .map(loobricate => {
                          // This type assertion is safe because of our filter above
                          const loob = loobricate as { id: string, name: string };
                          return (
                            <option 
                              key={loob.id} 
                              value={loob.id}
                            >
                              {loob.name}
                            </option>
                          );
                        })
                      : null
                    }
                  </select>
                  <p className="field-description">
                    All entries must be associated with a Loobricate community
                  </p>
                </div>
              )}
              {renderFormFields()}
              <button className="btn-fleshy w-full" onClick={handleSubmit} disabled={isSubmitting}>
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </>
        )}

        {successMessage && (
          <div className="message success-container">
            <p className="success-message">{successMessage}</p>
            <p className="success-help">
              {selectedType === 'Loobricate'
                ? 'Your Loobricate is ready! You can now start adding entries to your community.'
                : 'Your entry has been added successfully! Add more or explore the Loobrary.'}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

// Simple debounce utility function
function debounce(func: Function, wait: number) {
  let timeout: NodeJS.Timeout;
  return function executedFunction(...args: any[]) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

