import React, { useState } from 'react';
import { useGlobalState } from './GlobalStateContext';
import type { GlobalState } from './GlobalStateContext';

interface SendOmiUpdateProps {
  onClose: () => void;
  isOpen: boolean;
}

type TabType = 'memory' | 'conversation';

export const SendOmiUpdate: React.FC<SendOmiUpdateProps> = ({ onClose, isOpen }) => {
  const { user } = useGlobalState() as GlobalState;
  const [activeTab, setActiveTab] = useState<TabType>('memory');
  
  // Memory states
  const [memoryContent, setMemoryContent] = useState('');
  const [memoryTags, setMemoryTags] = useState('');
  
  // Conversation states
  const [conversationContent, setConversationContent] = useState('');
  const [conversationTitle, setConversationTitle] = useState('');
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<{
    success: boolean;
    message: string;
  } | null>(null);

  const handleMemorySubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!memoryContent.trim()) {
      setSubmitStatus({
        success: false,
        message: 'Please enter content for your memory'
      });
      return;
    }
    
    try {
      setIsSubmitting(true);
      
      // Parse tags from comma-separated string to array
      const tagsArray = memoryTags
        ? memoryTags.split(',').map(tag => tag.trim()).filter(tag => tag.length > 0)
        : [];
      
      const response = await fetch('/api/omi-create-memory', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user.userId,
          content: memoryContent.trim(),
          tags: tagsArray,
          source: 'other'
        }),
      });
      
      const data = await response.json();
      
      if (response.ok) {
        setSubmitStatus({
          success: true,
          message: 'Memory created successfully in your Omi account'
        });
        
        // Reset form after successful submission
        setMemoryContent('');
        setMemoryTags('');
        
        // Close modal after 2 seconds if successful
        setTimeout(() => {
          onClose();
          setSubmitStatus(null);
        }, 2000);
      } else {
        setSubmitStatus({
          success: false,
          message: data.error || 'Failed to create memory. Please try again.'
        });
      }
    } catch (error) {
      console.error('Error creating memory in Omi:', error);
      setSubmitStatus({
        success: false,
        message: 'An unexpected error occurred. Please try again.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleConversationSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!conversationContent.trim()) {
      setSubmitStatus({
        success: false,
        message: 'Please enter content for your conversation'
      });
      return;
    }
    
    try {
      setIsSubmitting(true);
      
      const response = await fetch('/api/omi-create-conversation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user.userId,
          content: conversationContent.trim(),
          title: conversationTitle.trim() || 'Conversation from Loob',
          source: 'message'
        }),
      });
      
      const data = await response.json();
      
      if (response.ok) {
        setSubmitStatus({
          success: true,
          message: 'Conversation created successfully in your Omi account'
        });
        
        // Reset form after successful submission
        setConversationContent('');
        setConversationTitle('');
        
        // Close modal after 2 seconds if successful
        setTimeout(() => {
          onClose();
          setSubmitStatus(null);
        }, 2000);
      } else {
        setSubmitStatus({
          success: false,
          message: data.error || 'Failed to create conversation. Please try again.'
        });
      }
    } catch (error) {
      console.error('Error creating conversation in Omi:', error);
      setSubmitStatus({
        success: false,
        message: 'An unexpected error occurred. Please try again.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 px-4 overflow-y-auto">
      <div className="absolute inset-0 bg-black/70 backdrop-blur-sm" onClick={onClose}></div>
      
      <div className="bg-gray-900 rounded-xl w-full max-w-md p-6 m-4 relative z-10 border border-gray-800 shadow-xl max-h-[90vh] overflow-y-auto">
        <div className="sticky top-0 right-0 flex justify-between items-center mb-4 pb-2 border-b border-gray-800 bg-gray-900">
          <h2 className="text-xl font-bold flex items-center gap-2">
            <span className="text-blue-400 text-2xl">🔄</span> Create in Omi
          </h2>
          <button 
            onClick={onClose}
            className="w-8 h-8 flex items-center justify-center rounded-full hover:bg-gray-800 text-gray-400 hover:text-white transition-colors"
            aria-label="Close modal"
          >
            ✕
          </button>
        </div>
        
        {!user.omiUid ? (
          <div className="p-4 bg-yellow-500/10 border border-yellow-500/30 rounded-lg mb-4">
            <p className="text-yellow-200 text-sm">
              You need to connect your Omi device first. Check the Omi Integration section in your profile.
            </p>
          </div>
        ) : (
          <>
            {/* Tabs */}
            <div className="flex border-b border-gray-800 mb-6">
              <button
                className={`flex-1 px-4 py-3 font-medium text-sm ${
                  activeTab === 'memory'
                    ? 'text-blue-400 border-b-2 border-blue-400 bg-blue-400/5'
                    : 'text-gray-400 hover:text-gray-300 hover:bg-gray-800/50'
                }`}
                onClick={() => setActiveTab('memory')}
              >
                <div className="flex flex-col items-center gap-1">
                  <span className="text-lg">💭</span>
                  <span>Memory</span>
                </div>
              </button>
              <button
                className={`flex-1 px-4 py-3 font-medium text-sm ${
                  activeTab === 'conversation'
                    ? 'text-green-400 border-b-2 border-green-400 bg-green-400/5'
                    : 'text-gray-400 hover:text-gray-300 hover:bg-gray-800/50'
                }`}
                onClick={() => setActiveTab('conversation')}
              >
                <div className="flex flex-col items-center gap-1">
                  <span className="text-lg">💬</span>
                  <span>Conversation</span>
                </div>
              </button>
            </div>
            
            {/* Memory Form */}
            {activeTab === 'memory' && (
              <form onSubmit={handleMemorySubmit} className="space-y-5">
                <div className="border-l-2 border-blue-400/20 pl-3">
                  <p className="text-sm text-blue-300 mb-3">
                    Create a single, specific memory that Omi will remember about you.
                    Great for preferences, facts, or important details.
                  </p>
                </div>
                
                <div>
                  <label htmlFor="memoryContent" className="block text-sm font-medium text-blue-300 mb-2">
                    Memory Content*
                  </label>
                  <textarea
                    id="memoryContent"
                    value={memoryContent}
                    onChange={(e) => setMemoryContent(e.target.value)}
                    placeholder="Enter a specific fact or memory (e.g., 'I prefer window seats when flying')"
                    className="w-full rounded-lg bg-gray-800/80 border border-blue-500/30 px-4 py-3 text-sm focus:ring-blue-500 focus:border-blue-500"
                    rows={4}
                    maxLength={500}
                    required
                  />
                  <p className="text-xs text-gray-500 mt-1">
                    {memoryContent.length}/500 characters
                  </p>
                </div>
                
                <div>
                  <label htmlFor="memoryTags" className="block text-sm font-medium text-blue-300 mb-2">
                    Tags (Optional)
                  </label>
                  <input
                    type="text"
                    id="memoryTags"
                    value={memoryTags}
                    onChange={(e) => setMemoryTags(e.target.value)}
                    placeholder="food, preferences, travel (comma-separated)"
                    className="w-full rounded-lg bg-gray-800/80 border border-blue-500/30 px-4 py-3 text-sm focus:ring-blue-500 focus:border-blue-500"
                  />
                  <p className="text-xs text-gray-500 mt-1">
                    Add comma-separated tags to help organize your memories
                  </p>
                </div>
                
                <div className="mt-8 sticky bottom-0 pt-3 bg-gray-900 border-t border-gray-800">
                  {submitStatus && (
                    <div className={`mb-4 p-3 rounded-lg ${
                      submitStatus.success 
                        ? 'bg-green-500/20 border border-green-500/30 text-green-200' 
                        : 'bg-red-500/20 border border-red-500/30 text-red-200'
                    }`}>
                      <p className="text-sm">{submitStatus.message}</p>
                    </div>
                  )}
                  
                  <div className="flex gap-3">
                    <button
                      type="button"
                      onClick={onClose}
                      className="flex-1 px-4 py-2 rounded-lg bg-gray-800 hover:bg-gray-700 text-gray-300 text-sm font-medium transition-colors"
                      disabled={isSubmitting}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="flex-1 px-4 py-2 rounded-lg bg-blue-600 hover:bg-blue-500 text-white text-sm font-medium transition-colors flex items-center justify-center"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <>
                          <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                          Saving...
                        </>
                      ) : (
                        'Create Memory'
                      )}
                    </button>
                  </div>
                </div>
              </form>
            )}
            
            {/* Conversation Form */}
            {activeTab === 'conversation' && (
              <form onSubmit={handleConversationSubmit} className="space-y-5">
                <div className="border-l-2 border-green-400/20 pl-3">
                  <p className="text-sm text-green-300 mb-3">
                    Create a new conversation or send an update to your Omi device.
                    Great for summaries, reminders, or important messages.
                  </p>
                </div>
                
                <div>
                  <label htmlFor="conversationTitle" className="block text-sm font-medium text-green-300 mb-2">
                    Title (Optional)
                  </label>
                  <input
                    type="text"
                    id="conversationTitle"
                    value={conversationTitle}
                    onChange={(e) => setConversationTitle(e.target.value)}
                    placeholder="Give your message a title"
                    className="w-full rounded-lg bg-gray-800/80 border border-green-500/30 px-4 py-3 text-sm focus:ring-green-500 focus:border-green-500"
                  />
                </div>
                
                <div>
                  <label htmlFor="conversationContent" className="block text-sm font-medium text-green-300 mb-2">
                    Message Content*
                  </label>
                  <textarea
                    id="conversationContent"
                    value={conversationContent}
                    onChange={(e) => setConversationContent(e.target.value)}
                    placeholder="Enter your message here..."
                    className="w-full rounded-lg bg-gray-800/80 border border-green-500/30 px-4 py-3 text-sm focus:ring-green-500 focus:border-green-500"
                    rows={6}
                    maxLength={1000}
                    required
                  />
                  <p className="text-xs text-gray-500 mt-1">
                    {conversationContent.length}/1000 characters
                  </p>
                </div>
                
                <div className="mt-8 sticky bottom-0 pt-3 bg-gray-900 border-t border-gray-800">
                  {submitStatus && (
                    <div className={`mb-4 p-3 rounded-lg ${
                      submitStatus.success 
                        ? 'bg-green-500/20 border border-green-500/30 text-green-200' 
                        : 'bg-red-500/20 border border-red-500/30 text-red-200'
                    }`}>
                      <p className="text-sm">{submitStatus.message}</p>
                    </div>
                  )}
                  
                  <div className="flex gap-3">
                    <button
                      type="button"
                      onClick={onClose}
                      className="flex-1 px-4 py-2 rounded-lg bg-gray-800 hover:bg-gray-700 text-gray-300 text-sm font-medium transition-colors"
                      disabled={isSubmitting}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="flex-1 px-4 py-2 rounded-lg bg-green-600 hover:bg-green-500 text-white text-sm font-medium transition-colors flex items-center justify-center"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <>
                          <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                          Saving...
                        </>
                      ) : (
                        'Send Message'
                      )}
                    </button>
                  </div>
                </div>
              </form>
            )}
          </>
        )}
      </div>
    </div>
  );
}; 