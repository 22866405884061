'use client';

import React, { useEffect, useState } from 'react';
import styles from './ResourceUploadTooltip.module.css';
import { FaInfoCircle } from 'react-icons/fa';

interface ResourceUploadTooltipProps {
  isVisible: boolean;
  onClose: () => void;
  audioButtonRef: React.RefObject<HTMLDivElement>;
}

export const ResourceUploadTooltip: React.FC<ResourceUploadTooltipProps> = ({
  isVisible,
  onClose,
  audioButtonRef
}) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (isVisible && audioButtonRef.current) {
      const rect = audioButtonRef.current.getBoundingClientRect();
      setPosition({
        top: rect.top - 30, // Position just above the button
        left: rect.left + (rect.width / 2)
      });
    }
  }, [isVisible, audioButtonRef]);

  useEffect(() => {
    // Reset expanded state when tooltip is hidden
    if (!isVisible) {
      setIsExpanded(false);
    }
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <>
      <div 
        className={styles.tooltipContainer}
        style={{
          top: `${position.top}px`,
          left: `${position.left}px`,
          transform: 'translateX(-50%)',
        }}
      >
        <div className={styles.connector} />
        <div 
          className={styles.infoIcon}
          onClick={() => setIsExpanded(true)}
          role="button"
          aria-label="Show resource upload information"
        >
          <FaInfoCircle />
        </div>
      </div>

      {isExpanded && (
        <div className={styles.expandedTooltip}>
          <h4 className={styles.title}>Add to LOOBRARY</h4>
          <p className={styles.instructions}>
            Click and hold the microphone to describe your resource. Include:
            <br />
            • Type (venue, gear, or talent)
            <br />
            • A clear title
            <br />
            • Detailed description
          </p>
          <div className={styles.example}>
            Example: "I have a professional recording studio with a vocal booth, 
            mixing desk, and space for up to 5 musicians..."
          </div>
          <button 
            className={styles.closeButton} 
            onClick={() => {
              setIsExpanded(false);
              onClose();
            }}
          >
            Got it
          </button>
        </div>
      )}
    </>
  );
}

