'use client';

import React, { useEffect, useState } from "react";

export const Footer = ({ className = "" }) => {
  const [isMobile, setIsMobile] = useState(false);
  
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    // Initial check
    checkIfMobile();
    
    // Add event listener for window resize
    window.addEventListener('resize', checkIfMobile);
    
    // Cleanup
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);
  
  // Don't render the footer at all on mobile
  if (isMobile) {
    return null;
  }
  
  return (
    <footer
      className={`footer-container ${className} hidden-on-mobile`}
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        height: "var(--footer-height)",
        background: "var(--background-primary)",
        borderTop: "1px solid rgba(255, 255, 255, 0.1)",
        backdropFilter: "blur(8px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 var(--side-padding)",
        zIndex: 100,
      }}
    >
      {/* Left Section: Link to Labs */}
      <a
        href="https://www.seks.design/looblabs/"
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center space-x-2 hover:text-gray-300 transition"
        aria-label="Visit Loob Labs"
      >
        <span>▲</span>
        <span>Loob Labs</span>
      </a>

      {/* Center Section: Separator (hidden on small screens) */}
      <span className="hidden md:inline">|</span>

      {/* Right Section: Powered By */}
      <div className="flex items-center space-x-2">
        <span>Powered by</span>
        <a
          href="https://seks.design"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-gray-300 transition"
          aria-label="Visit Seks Design"
        >
          Seks Design
        </a>
      </div>
    </footer>
  );
};

