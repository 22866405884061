import React from 'react';
import { motion } from 'framer-motion';

interface LoadingStateProps {
  type?: 'full' | 'inline' | 'overlay';
  message?: string;
  isLoading: boolean;
  children?: React.ReactNode;
}

export const LoadingState: React.FC<LoadingStateProps> = ({ 
  type = 'inline', 
  message = 'Loading...', 
  isLoading, 
  children 
}) => {
  // Mycelial-inspired loading animation
  const loadingVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      transition: {
        duration: 0.3,
        ease: "easeIn"
      }
    }
  };

  if (!isLoading) return <>{children}</>;

  const LoadingContent = (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={loadingVariants}
      className={`loading-state loading-state--${type}`}
    >
      <div className="loading-state__animation">
        {/* Mycelial network animation */}
        <svg className="loading-network" viewBox="0 0 100 100">
          <motion.path
            d="M20,50 C20,20 80,20 80,50 C80,80 20,80 20,50"
            stroke="currentColor"
            strokeWidth="2"
            fill="none"
            animate={{
              pathLength: [0, 1],
              pathOffset: [0, 1]
            }}
            transition={{
              duration: 2,
              ease: "linear",
              repeat: Infinity
            }}
          />
          {/* Additional animated paths for network effect */}
          <motion.circle
            cx="50"
            cy="50"
            r="4"
            fill="currentColor"
            animate={{
              scale: [1, 1.2, 1],
              opacity: [1, 0.7, 1]
            }}
            transition={{
              duration: 1.5,
              repeat: Infinity
            }}
          />
        </svg>
      </div>
      <motion.p
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="loading-state__message"
      >
        {message}
      </motion.p>
    </motion.div>
  );

  if (type === 'overlay') {
    return (
      <div className="loading-state__overlay">
        {LoadingContent}
        <div className="loading-state__content--blurred">
          {children}
        </div>
      </div>
    );
  }

  return LoadingContent;
};

// Add to your global CSS or as a CSS module
const styles = `
.loading-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
}

.loading-state--full {
  min-height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--background-primary);
  z-index: 50;
}

.loading-state--inline {
  min-height: 200px;
}

.loading-state__overlay {
  position: relative;
}

.loading-state__content--blurred {
  filter: blur(4px);
  pointer-events: none;
}

.loading-state__animation {
  width: 60px;
  height: 60px;
  color: var(--primary-color);
}

.loading-state__message {
  font-size: 0.875rem;
  color: var(--text-secondary);
  margin: 0;
}

.loading-network {
  width: 100%;
  height: 100%;
}
`; 