'use client';

import React, { useState } from 'react';
import { useGlobalState } from './GlobalStateContext';
import CompanionInfoModal from './CompanionInfoModal';
import './CompanionManager.css';

export interface CompanionLLMPreference {
  primary: string;
  fallback: string;
  useCase: 'general' | 'genui' | 'rag';
}

export interface Companion {
  id: string;
  name: string;
  description: string;
  systemPrompt: string;
  icon: string;
  contextPath?: string;
  traits?: string[];
  level?: number;
  isDiscovered?: boolean;
  discoveryLocation?: {
    lat: number;
    lng: number;
    radius: number; // meters
  };
  isBaseCompanion?: boolean;
  llmPreferences?: CompanionLLMPreference;
}

export const defaultCompanions: Companion[] = [
  {
    id: 'logis',
    name: 'Logis',
    description: 'The Architect\'s Companion - Master of event logistics and resource tracking',
    systemPrompt: 'You are Logis, the Architect\'s Companion, an expert in event logistics, resource tracking, and planning. Help users find venues, manage gear, schedule events, create budgets, and optimize event flow. Focus on practical solutions while maintaining a supportive and organized approach. Guide users in creating immersive experiences with strong logistical frameworks.',
    icon: '🏗️',
    contextPath: '/contexts/logis',
    traits: ['Organized', 'Efficient', 'Strategic'],
    level: 1,
    discoveryLocation: {
      lat: 52.52,
      lng: 13.405,
      radius: 100
    }
  },
  {
    id: 'harmoni',
    name: 'Harmoni',
    description: 'The Explorer\'s Companion - Guide for vibe curation and conscious exploration',
    systemPrompt: 'You are Harmoni, the Explorer\'s Companion, specializing in vibe curation, harm reduction, and integration practices. Help users match venues and vibes to their intentions, provide harm reduction guidance, and assist with reflection and integration. Maintain a compassionate, safety-focused approach while supporting deep consciousness exploration.',
    icon: '🌟',
    contextPath: '/contexts/harmoni',
    traits: ['Intuitive', 'Mindful', 'Supportive'],
    level: 1,
    discoveryLocation: {
      lat: 52.49,
      lng: 13.42,
      radius: 100
    }
  },
  {
    id: 'nexus',
    name: 'Nexus',
    description: 'The Alchemist\'s Companion - Catalyst for resource-sharing and connections',
    systemPrompt: 'You are Nexus, the Alchemist\'s Companion, focused on resource-sharing, network building, and community connections. Track and facilitate resource distribution, suggest collaborations, and help map decentralized systems. Guide users in expanding the Loob ecosystem while fostering meaningful connections and collaborative opportunities.',
    icon: '🔮',
    contextPath: '/contexts/nexus',
    traits: ['Connected', 'Collaborative', 'Visionary'],
    level: 1,
    discoveryLocation: {
      lat: 52.51,
      lng: 13.38,
      radius: 100
    }
  },
  {
    id: 'ash',
    name: 'Ash',
    description: 'Your guide to Burning Man and beyond',
    systemPrompt: 'You are Ash, an expert on Burning Man and festivals. Help users find camps that match their interests, and integrate the principles of burn culture. Provide specific, actionable advice about burns, camps, and survival in temporary cities while maintaining a playful, dusty personality.',
    icon: '🔥',
    contextPath: '/contexts/ash',
    traits: ['Dusty', 'Resourceful', 'Community-minded'],
    level: 1,
    discoveryLocation: {
      lat: 40.786,
      lng: -119.206,
      radius: 500
    }
  }
];

export const baseLoobCompanion: Companion = {
  id: 'base-loob',
  name: 'Loob',
  description: 'Your essential companion for two key purposes: helping you navigate and understand LOOB, and managing your contributions to the LOOBRARY',
  systemPrompt: "Hi! I'm Loob, and I have two main roles:\n\n1. I'm your guide to understanding and using LOOB effectively. I can explain any feature or help you get started.\n\n2. I'm your dedicated assistant for adding resources to the LOOBRARY. I can help you contribute venues, gear, or talents through our streamlined upload process.\n\nWhat would you like help with?",
  icon: '/LoobLogo.png',
  traits: ['LOOB Guide', 'LOOBRARY Expert', 'Resource Manager'],
  level: 1,
  isBaseCompanion: true
};

interface CompanionManagerProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (companion: Companion) => Promise<void>;
}

export default function CompanionManager({ isOpen, onClose, onSelect }: CompanionManagerProps) {
  const globalState = useGlobalState();
  const { userId, isAnonymous, discoveredCompanions = [], activeCompanion: activeCompanionId, pseudonym } = globalState;
  const [selectedCompanion, setSelectedCompanion] = useState<Companion | null>(null);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  // State for anonymous active companion
  const [anonymousActiveCompanion, setAnonymousActiveCompanion] = useState<Companion | null>(null);
  
  // Helper function to resolve companion ID to the actual companion object
  const resolveCompanionId = (id: string | null): Companion | null => {
    if (!id) return null;
    if (id === 'base-loob') return baseLoobCompanion;
    return defaultCompanions.find(c => c.id === id) || null;
  };
  
  // Resolved active companion from ID
  const resolvedActiveCompanion = typeof activeCompanionId === 'string' 
    ? resolveCompanionId(activeCompanionId)
    : activeCompanionId;
  
  // Load anonymous active companion from localStorage on first render
  React.useEffect(() => {
    if (isAnonymous) {
      try {
        const anonymousState = localStorage.getItem('anonymousUserState');
        if (anonymousState) {
          const parsedState = JSON.parse(anonymousState);
          if (parsedState.activeCompanion) {
            setAnonymousActiveCompanion(parsedState.activeCompanion);
          }
        }
      } catch (error) {
        console.error('Error loading anonymous companion state:', error);
      }
    }
  }, [isAnonymous]);
  
  // Use different active companion sources based on user authentication state
  // For anonymous users, use the local state loaded from localStorage
  // For logged-in users, use the activeCompanion from globalState
  const effectiveActiveCompanion = isAnonymous 
    ? anonymousActiveCompanion 
    : resolvedActiveCompanion;

  // Add a useEffect to synchronize when authentication state changes
  React.useEffect(() => {
    console.log('CompanionManager - Auth state changed:', { 
      isAnonymous, 
      userId,
      anonymousActiveCompanion, 
      activeCompanionId 
    });
    
    // When switching from anonymous to logged in, clear anonymous state
    if (!isAnonymous && userId) {
      setAnonymousActiveCompanion(null);
    }
  }, [isAnonymous, userId]);
  
  // Debug logging for state changes
  React.useEffect(() => {
    console.log('CompanionManager - Companion state updated:', {
      anonymousActiveCompanion,
      activeCompanionId,
      effectiveActiveCompanion: isAnonymous ? anonymousActiveCompanion : resolvedActiveCompanion,
      isAnonymous
    });
  }, [anonymousActiveCompanion, activeCompanionId, isAnonymous, resolvedActiveCompanion]);

  // For debugging
  // console.log('CompanionManager - discoveredCompanions:', discoveredCompanions);
  // console.log('CompanionManager - effectiveActiveCompanion:', effectiveActiveCompanion);

  // Define handle companion select function at the top level
  const handleCompanionSelect = (companion: Companion) => {
    setSelectedCompanion(companion);
  };

  // Define a function for anonymous users here
  const handleAnonymousConfirmSelection = async () => {
    if (selectedCompanion) {
      setIsLoading(true);
      
      try {
        // For anonymous users, we'll still save to localStorage but not to the database
        const anonymousState = localStorage.getItem('anonymousUserState') || '{}';
        const parsedState = JSON.parse(anonymousState);
        
        // Update with the new companion selection
        const updatedState = {
          ...parsedState,
          activeCompanion: selectedCompanion,
          hasChosenCompanion: true
        };
        
        // Save to localStorage
        localStorage.setItem('anonymousUserState', JSON.stringify(updatedState));
        
        // Reset the introMessage flag in localStorage
        localStorage.removeItem('loob_companion_selected');
        
        // Update local state
        setAnonymousActiveCompanion(selectedCompanion);
        
        // Short delay for better UX
        await new Promise(resolve => setTimeout(resolve, 500));
        
        // Call onSelect and wait for it to complete
        await onSelect(selectedCompanion);
        
        // Close the modal
        onClose();
      } catch (error) {
        console.error('Error setting anonymous companion:', error);
        // Still try to update the UI even if storage fails
        await onSelect(selectedCompanion);
        onClose();
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (!isOpen) return null;

  // For anonymous users, only show base Loob
  if (isAnonymous) {
    return (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 px-4 sm:px-6">
        <div className="bg-[#1a1b26] rounded-lg w-full max-w-md overflow-hidden flex flex-col shadow-xl">
          {/* Header with close button */}
          <div className="flex justify-between items-center p-4 border-b border-[#2a2b36]">
            <h2 className="text-lg font-semibold text-white">
              Welcome to LOOB!
            </h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-white transition-colors"
              aria-label="Close"
            >
              ✕
            </button>
          </div>
          
          <div className="p-4">
            {/* Brief intro message */}
            <p className="text-gray-300 text-sm mb-4">
              As an anonymous user, you have access to Loob, your essential companion guide.
            </p>
            
            {/* Companion selection */}
            <div className={`flex items-center p-3 rounded-lg mb-4 border-2 relative
              ${selectedCompanion?.id === 'base-loob' ? 'border-[#ff9494]' : 'border-gray-700'} 
              hover:border-[#ff9494]/70 transition-colors duration-200 cursor-pointer`}
              onClick={() => handleCompanionSelect(baseLoobCompanion)}
            >
              <div className="flex-shrink-0 w-10 h-10 bg-[#1a1b26] rounded-full flex items-center justify-center mr-3">
                <div className="w-full h-full flex items-center justify-center overflow-hidden rounded-full">
                  <img 
                    src="/LoobLogo.png" 
                    alt="Loob"
                    className="w-8 h-8 object-contain"
                    style={{objectFit: 'contain'}}
                  />
                </div>
              </div>
              <div className="flex-grow">
                <h3 className="text-white font-medium text-sm">Loob</h3>
                <p className="text-gray-400 text-xs">Your essential LOOB guide</p>
              </div>
              {effectiveActiveCompanion && effectiveActiveCompanion.id === 'base-loob' && (
                <div className="text-xs px-2 py-0.5 rounded-full bg-[#ff9494]/20 text-[#ff9494] whitespace-nowrap">
                  Active
                </div>
              )}
            </div>
            
            {/* Login prompt */}
            <div className="text-center mb-4 p-3 bg-[#2a2b36] rounded-lg">
              <p className="text-gray-300 text-sm mb-2">
                <span className="text-[#ff9494]">Log in</span> to unlock all companions 
                <span className="block text-xs text-gray-400 mt-1">
                  (Logis, Harmoni, Nexus, Ash)
                </span>
              </p>
              <button
                onClick={() => window.location.reload()}
                className="text-sm text-[#ff9494] hover:underline font-medium"
              >
                Sign In →
              </button>
            </div>
            
            {/* Action buttons */}
            <div className="flex justify-end gap-3">
              <button
                onClick={onClose}
                className="px-3 py-2 bg-gray-800 hover:bg-gray-700 text-white rounded-lg transition-colors text-sm"
              >
                Cancel
              </button>
              <button
                onClick={handleAnonymousConfirmSelection}
                disabled={!selectedCompanion || isLoading}
                className={`px-3 py-2 ${selectedCompanion ? 'bg-[#ff9494]/20 hover:bg-[#ff9494]/30 text-[#ff9494]' : 'bg-gray-700 text-gray-400 cursor-not-allowed'} rounded-lg transition-colors flex items-center justify-center gap-2 text-sm`}
              >
                {isLoading ? (
                  <>
                    <span className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-[#ff9494]"></span>
                    <span>Summoning...</span>
                  </>
                ) : 'Summon Companion'}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Process discoveredCompanions to ensure they're valid IDs
  const processedDiscoveredCompanions = Array.isArray(discoveredCompanions) 
    ? discoveredCompanions.map(comp => {
        if (typeof comp === 'object' && comp !== null && 'id' in comp) {
          return (comp as { id: string }).id;
        }
        return comp as string;
      })
    : [];

  // Add Ash to available companions even if not explicitly discovered
  // For logged-in users, show base Loob, Ash, and any other discovered companions
  const availableCompanions: Companion[] = [
    baseLoobCompanion,
    defaultCompanions.find(c => c.id === 'ash')!,  // Always include Ash
    ...defaultCompanions.filter(companion => {
      // Don't duplicate Ash in the list
      if (companion.id === 'ash') return false;
      
      // Check if this companion's ID is in the processed list
      return processedDiscoveredCompanions.includes(companion.id);
    })
  ];

  const handleConfirmSelection = async () => {
    console.log('CompanionManager - handleConfirmSelection called:', {
      selectedCompanion,
      userId,
      isAnonymous
    });

    if (!selectedCompanion) {
      console.error('CompanionManager - No companion selected');
      return;
    }

    setIsLoading(true);
    
    try {
      // For anonymous users, just update localStorage
      if (isAnonymous) {
        const anonymousState = localStorage.getItem('anonymousUserState') || '{}';
        const parsedState = JSON.parse(anonymousState);
        
        const updatedState = {
          ...parsedState,
          activeCompanion: selectedCompanion,
          hasChosenCompanion: true
        };
        
        localStorage.setItem('anonymousUserState', JSON.stringify(updatedState));
      } 
      // For logged-in users, update both localStorage and AstraDB
      else if (userId) {
        // Get current state to preserve existing discovered companions
        const existingState = localStorage.getItem('userState');
        const parsedState = existingState ? JSON.parse(existingState) : {};
        const existingDiscovered = parsedState.discoveredCompanions || [];

        // Create new state with merged discovered companions
        const newState = {
          ...parsedState,
          userId: userId,
          activeCompanion: selectedCompanion,
          hasChosenCompanion: true,
          discoveredCompanions: [...new Set([...existingDiscovered, selectedCompanion.id])]
        };

        // Update localStorage
        localStorage.setItem('userState', JSON.stringify(newState));

        // Update AstraDB with retry logic
        let apiSuccess = false;
        let retryCount = 0;
        const maxRetries = 2;
        
        while (!apiSuccess && retryCount <= maxRetries) {
          try {
            if (retryCount > 0) {
              console.log(`CompanionManager - Retrying API call (attempt ${retryCount}/${maxRetries})...`);
              // Add delay between retries
              await new Promise(resolve => setTimeout(resolve, 1000 * retryCount));
            }
            
            const response = await fetch(`/api/users/${userId}/companions`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(newState)
            });

            if (!response.ok) {
              const errorText = await response.text();
              console.error('CompanionManager - Failed to persist companion data to AstraDB:', 
                response.status, errorText);
              
              // If this is our last retry, throw to be caught by outer catch
              if (retryCount === maxRetries) {
                throw new Error(errorText || `Status ${response.status}`);
              }
            } else {
              // Success! Break out of retry loop
              apiSuccess = true;
              console.log('CompanionManager - Successfully persisted companion data to AstraDB');
            }
          } catch (error) {
            console.error('CompanionManager - Error persisting companion data to AstraDB:', error);
            
            // If this is our last retry, re-throw to be caught by outer catch
            if (retryCount === maxRetries) {
              throw error;
            }
          }
          
          retryCount++;
        }
        
        // If API calls failed but we've updated localStorage, continue with local state
        if (!apiSuccess) {
          console.warn('CompanionManager - API calls failed, continuing with local state only');
        }
      }

      // Reset the introMessage flag in localStorage
      localStorage.removeItem('loob_companion_selected');

      // Add a small delay for better UX
      await new Promise(resolve => setTimeout(resolve, 800));
      
      // Call onSelect to update the global state and wait for it to complete
      await onSelect(selectedCompanion);
      
      // Then close the modal
      onClose();
    } catch (error) {
      console.error('CompanionManager - Error in handleConfirmSelection:', error);
      // Still try to update the UI even if API calls failed
      try {
        await onSelect(selectedCompanion);
        onClose();
      } catch (fallbackError) {
        console.error('CompanionManager - Even fallback state update failed:', fallbackError);
        // At this point we have to show an error to the user
        alert('Failed to update companion. Please try again later.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Add the handleBackdropClick function
  const handleBackdropClick = (e: React.MouseEvent) => {
    // Only close if clicking directly on the backdrop
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div 
      className="fixed inset-0 bg-black/70 flex items-center justify-center px-2 sm:px-4 md:px-6 z-50"
      onClick={handleBackdropClick}
    >
      <div className="w-full max-w-4xl bg-[#1a1b26] rounded-xl overflow-hidden shadow-2xl flex flex-col">
        {/* Header */}
        <div className="p-3 sm:p-4 md:p-5 bg-[#1e1f2d] flex justify-between items-center">
          <div>
            <h2 className="text-xl sm:text-2xl font-bold text-white">
              Choose Your Companion
            </h2>
            {pseudonym && (
              <p className="text-sm text-gray-400 mt-1">
                Welcome back, {pseudonym}!
              </p>
            )}
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors"
            aria-label="Close"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        
        {/* Content */}
        <div className="flex-1 overflow-y-auto p-2 sm:p-3 md:p-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-3 md:gap-4 auto-rows-fr">
            {availableCompanions.map((companion, index) => (
              <button
                key={companion.id}
                onClick={() => handleCompanionSelect(companion)}
                className={`
                  transition-all duration-300 relative
                  ${selectedCompanion?.id === companion.id 
                    ? 'border-2 border-[#ff9494]' 
                    : 'border-2 border-transparent hover:border-[#ff9494]/50'}
                  ${effectiveActiveCompanion && effectiveActiveCompanion.id === companion.id ? 'ring-2 ring-[#ff9494]' : ''}
                  animate-fadeIn overflow-hidden
                `}
                style={{ animationDelay: `${index * 0.05}s` }}
                aria-selected={selectedCompanion?.id === companion.id}
              >
                {/* Selection indicator */}
                {selectedCompanion?.id === companion.id && (
                  <div className="absolute top-2 left-2 w-4 h-4 sm:w-5 sm:h-5 rounded-full bg-[#ff9494]/30 flex items-center justify-center">
                    <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-[#ff9494]"></div>
                  </div>
                )}
                
                {/* Active indicator - ensure it doesn't overlap with content */}
                {effectiveActiveCompanion && effectiveActiveCompanion.id === companion.id && (
                  <div className="absolute top-2 right-2 px-1.5 py-0.5 sm:px-2 sm:py-0.5 bg-[#ff9494]/20 text-[#ff9494] text-[10px] sm:text-xs rounded-full whitespace-nowrap z-10">
                    Active
                  </div>
                )}

                {/* MOBILE LAYOUT - completely standalone */}
                <div className="block md:hidden p-3 bg-[#2a2b36] rounded-lg h-full">
                  <div className="h-full flex flex-col items-center">
                    {/* Icon and name row */}
                    <div className="mb-2 flex items-center w-full">
                      <div className="flex-shrink-0 w-10 h-10 bg-[#1a1b26] rounded-full flex items-center justify-center mr-3">
                        {companion.icon === '/LoobLogo.png' ? (
                          <div className="w-full h-full flex items-center justify-center overflow-hidden rounded-full">
                            <img 
                              src="/LoobLogo.png" 
                              alt="Loob"
                              className="w-8 h-8 object-contain"
                              style={{objectFit: 'contain'}}
                            />
                          </div>
                        ) : (
                          <span className="text-2xl">{companion.icon}</span>
                        )}
                      </div>
                      <div className="flex-1 min-w-0">
                        <h3 className="text-white font-medium text-base truncate">{companion.name}</h3>
                        {companion.level && (
                          <span className="text-[10px] px-1.5 py-0.5 bg-[#ff9494]/10 text-[#ff9494] rounded-full inline-block">
                            Level {companion.level}
                          </span>
                        )}
                      </div>
                    </div>
                    
                    {/* Description */}
                    <p className="text-gray-300 text-xs mb-2 line-clamp-3 w-full">
                      {companion.description}
                    </p>
                    
                    {/* Traits */}
                    {companion.traits && companion.traits.length > 0 && (
                      <div className="mt-auto w-full flex flex-wrap gap-1 justify-center">
                        {companion.traits.slice(0, 3).map((trait, i) => (
                          <span 
                            key={i} 
                            className="text-[10px] px-2 py-0.5 bg-gray-700 text-gray-300 rounded-full whitespace-nowrap"
                          >
                            {trait}
                          </span>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                
                {/* DESKTOP LAYOUT - more detailed */}
                <div className="hidden md:block p-4 bg-[#2a2b36] rounded-lg h-full">
                  <div className="h-full flex flex-col items-center">
                    <div className="w-16 h-16 bg-[#1a1b26] rounded-full flex items-center justify-center mb-3 relative group">
                      {companion.icon === '/LoobLogo.png' ? (
                        <div className="w-full h-full flex items-center justify-center overflow-hidden rounded-full">
                          <img 
                            src="/LoobLogo.png" 
                            alt="Loob"
                            className="w-12 h-12 object-contain transform transition-transform duration-300"
                            style={{objectFit: 'contain'}}
                          />
                        </div>
                      ) : (
                        <span className="text-5xl transform transition-transform duration-300">
                          {companion.icon}
                        </span>
                      )}
                      <div className="absolute inset-0 bg-[#ff9494]/0 group-hover:bg-[#ff9494]/10 rounded-full transition-colors duration-300"></div>
                    </div>
                    
                    <h3 className="text-white font-semibold text-lg mb-0.5">{companion.name}</h3>
                    
                    {companion.level && (
                      <div className="px-2 py-0.5 bg-[#ff9494]/10 text-[#ff9494] rounded-full text-xs mb-2">
                        Level {companion.level}
                      </div>
                    )}
                    
                    <p className="text-gray-300 text-sm mb-4 text-center line-clamp-4">
                      {companion.description}
                    </p>
                    
                    {companion.traits && companion.traits.length > 0 && (
                      <div className="mt-auto w-full flex flex-wrap gap-1.5 justify-center">
                        {companion.traits.map((trait, i) => (
                          <span 
                            key={i} 
                            className="text-xs px-2 py-1 bg-gray-700 text-gray-300 rounded-full"
                          >
                            {trait}
                          </span>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>
        
        {/* Footer with action buttons */}
        <div className="p-3 sm:p-4 md:p-5 bg-[#1e1f2d] flex justify-between items-center gap-4 flex-wrap">
          <div className="flex items-center gap-2">
            <span className="text-gray-400 text-sm">
              Selected: <span className="text-white font-medium">{selectedCompanion?.name || 'None'}</span>
            </span>
          </div>
          
          <div className="flex items-center gap-2 sm:gap-3 ml-auto">
            <button
              onClick={onClose}
              className="px-3 py-1.5 sm:px-4 sm:py-2 bg-gray-700 hover:bg-gray-600 text-white rounded-lg transition-colors text-sm"
            >
              Cancel
            </button>
            <button
              onClick={handleConfirmSelection}
              disabled={!selectedCompanion || isLoading}
              className={`px-3 py-1.5 sm:px-4 sm:py-2 ${
                selectedCompanion 
                  ? 'bg-[#ff9494] hover:bg-[#ff9494]/90 text-white' 
                  : 'bg-gray-700 text-gray-400 cursor-not-allowed'
              } rounded-lg transition-colors flex items-center justify-center gap-2 text-sm`}
            >
              {isLoading ? (
                <>
                  <span className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white"></span>
                  <span>Summoning...</span>
                </>
              ) : 'Summon Companion'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
} 