'use client';

import React, { useState, useEffect } from "react";
import { GearProfile } from "./GearProfile"; // Import the GearProfile component with named import

export const NFCReader: React.FC = () => {
  const [isScanning, setIsScanning] = useState(false);
  const [nfcMessage, setNfcMessage] = useState<string | null>(null);
  const [chipFound, setChipFound] = useState(false);
  const [itemData, setItemData] = useState<any | null>(null); // State for item data
  const [showItemProfile, setShowItemProfile] = useState(false); // Show/hide ItemProfile

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (isScanning) {
      // Simulate scanning and finding a chip
      timeout = setTimeout(() => {
        setChipFound(true); // Simulate chip found
        setIsScanning(false);
        setNfcMessage("Sample NFC Data: Current Location: Library, Status: Checked In");

        // Mock item data after a successful scan
        setItemData({
          id: "item-001",
          name: "You've Unlocked a New Loobricate.",
          description:
            "Moos is a community exploring advancements and challenges in Artificial Intelligence.",
          availability: "Checked In",
          history: [
            { action: "Open Decks", date: "2025-01-10", location: "MOOS" },
            { action: "Experience Design Studio", date: "2025-01-07", location: "Online" },
          ],
        });
        setShowItemProfile(true); // Automatically show the ItemProfile modal
      }, 3000); // Mock a 3-second scan delay
    }

    return () => clearTimeout(timeout);
  }, [isScanning]);

  // Add effect for mobile viewport height
  useEffect(() => {
    // Set viewport height for mobile
    const setViewportHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // Initial call
    setViewportHeight();

    // Event listener for resize
    window.addEventListener('resize', setViewportHeight);

    // Cleanup
    return () => window.removeEventListener('resize', setViewportHeight);
  }, []);

  const handleScanClick = () => {
    setIsScanning(true);
    setChipFound(false);
    setNfcMessage(null); // Reset previous message
    setShowItemProfile(false); // Reset modal visibility
  };

  const handleCloseItemProfile = () => {
    setShowItemProfile(false); // Close the item profile modal
  };

  return (
    <div className="nfc-reader-container flex flex-col items-center justify-center min-h-screen px-4 py-6 w-full max-w-screen-md mx-auto" style={{ minHeight: 'calc(var(--vh, 1vh) * 100)' }}>
      {/* Scanning Animation */}
      <div className="relative flex items-center justify-center w-40 h-40 my-4">
        {/* Enhanced Pulsing Animation - Multiple rings for layered effect */}
        {isScanning && (
          <>
            <div className="absolute w-full h-full rounded-full border-2 border-primary animate-ping opacity-20" style={{ animationDuration: '1.5s' }}></div>
            <div className="absolute w-[90%] h-[90%] rounded-full border-2 border-primary animate-ping opacity-30" style={{ animationDuration: '2s' }}></div>
            <div className="absolute w-[80%] h-[80%] rounded-full border-2 border-primary animate-ping opacity-40" style={{ animationDuration: '2.5s' }}></div>
          </>
        )}
        {/* Main Button with App Theme Gradient */}
        <div className="relative z-10 w-28 h-28 rounded-full bg-gradient-to-r from-primary to-secondary flex items-center justify-center shadow-lg">
          <button
            onClick={handleScanClick}
            className="inline-flex items-center justify-center px-4 py-2 rounded-full font-medium transition-all focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary/50 disabled:opacity-50 disabled:cursor-not-allowed bg-black/80 text-white hover:bg-black/90 text-sm"
            disabled={isScanning}
            aria-label={isScanning ? "Scanning in progress" : "Scan NFC"}
          >
            {isScanning ? "Scanning..." : "Scan NFC"}
          </button>
        </div>
      </div>

      {/* Status Message */}
      <div className="mt-4 text-center w-full max-w-xs px-2">
        {isScanning && <p className="text-primary font-medium">Searching for NFC chip...</p>}
        {!isScanning && !chipFound && nfcMessage === null && (
          <p className="text-gray-500">Click the button to start scanning.</p>
        )}
        {!isScanning && !chipFound && nfcMessage === "" && (
          <p className="text-red-500">No chip found. Try again.</p>
        )}
      </div>

      {/* Item Profile Modal */}
      {showItemProfile && itemData && (
        <div className="w-full max-w-md">
          <GearProfile gear={itemData} onClose={handleCloseItemProfile} />
        </div>
      )}
    </div>
  );
}