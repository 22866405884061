'use client';

import React, { useState, useEffect } from 'react';
import { useGlobalState } from './GlobalStateContext';

interface Quest {
  text: string;
  explanation?: string;
  cheatCode?: {
    number: number;
    description: string;
  };
  completed?: boolean;
  createdAt?: string;
}

interface DailyQuestProps {
  hasDumpedToday: boolean;
  onOpenDump: () => void;
  onClose: () => void;
}

export const DailyQuest: React.FC<DailyQuestProps> = ({ hasDumpedToday, onOpenDump, onClose }) => {
  const { userId } = useGlobalState();
  const [quest, setQuest] = useState<Quest | null>(null);
  const [loading, setLoading] = useState(true);
  const [toast, setToast] = useState<{ message: string; visible: boolean }>({ message: '', visible: false });

  const checkQuestExpiry = (questCreatedAt: string) => {
    const createdDate = new Date(questCreatedAt);
    const now = new Date();
    
    // Reset at midnight UTC
    const midnight = new Date(now);
    midnight.setUTCHours(0, 0, 0, 0);
    
    return createdDate < midnight;
  };

  const fetchQuest = async () => {
    if (!userId) return;
    
    setLoading(true);
    try {
      const response = await fetch(`/api/daily-quest?userId=${userId}`);
      const data = await response.json();
      
      if (response.ok) {
        // If quest exists but is expired, don't set it
        if (data.createdAt && checkQuestExpiry(data.createdAt)) {
          setQuest(null);
        } else {
          setQuest({
            text: data.quest,
            explanation: data.explanation,
            cheatCode: data.cheatCode,
            completed: false,
            createdAt: data.createdAt
          });
        }
      } else {
        console.error('Failed to fetch quest:', data.error);
      }
    } catch (error) {
      console.error('Error fetching quest:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch quest when component mounts or hasDumpedToday changes
  useEffect(() => {
    if (hasDumpedToday) {
      fetchQuest();
    }
  }, [hasDumpedToday, userId]);

  // Check for quest expiry every minute
  useEffect(() => {
    if (!quest?.createdAt) return;

    const checkInterval = setInterval(() => {
      if (checkQuestExpiry(quest.createdAt!)) {
        setQuest(null);
      }
    }, 60000); // Check every minute

    return () => clearInterval(checkInterval);
  }, [quest?.createdAt]);

  const handleRefresh = () => {
    fetchQuest();
  };

  const handleComplete = async () => {
    // Show toast message
    setToast({ message: '✨ Feature coming soon!', visible: true });
    
    // Hide toast after 3 seconds
    setTimeout(() => {
      setToast(prev => ({ ...prev, visible: false }));
    }, 3000);
  };

  const handleShare = async () => {
    if (!quest) return;

    const shareText = `Loob gave me this quest today. Want to help me complete it?\n\n"${quest.text}"\n\n${
      quest.cheatCode 
        ? `Inspired by Cheat Code #${quest.cheatCode.number}: ${quest.cheatCode.description}`
        : ''
    }`;

    try {
      if (navigator.share) {
        await navigator.share({
          title: 'My Daily Quest from Loob',
          text: shareText,
          url: window.location.origin
        });
      } else {
        // Fallback for browsers that don't support Web Share API
        await navigator.clipboard.writeText(shareText);
        // You might want to show a toast notification here
        console.log('Copied to clipboard!');
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  // Locked state
  if (!hasDumpedToday) {
    return (
      <button 
        onClick={onOpenDump}
        className="w-full bg-gradient-to-br from-gray-800 to-gray-900 rounded-2xl p-6 shadow-lg border border-gray-800/30 space-y-2 text-left transition-all hover:border-gray-700/50 group"
      >
        <div className="flex justify-between items-start">
          <h2 className="text-xl font-semibold text-gray-400">Daily Quest</h2>
          <span className="text-sm px-3 py-1 rounded-full bg-gray-800 text-gray-500 border border-gray-700/50">
            Locked
          </span>
        </div>
        
        <p className="text-gray-500 text-sm leading-relaxed">
          Share your thoughts in a Daily Dump first so Loob can better understand where you're at and suggest a meaningful quest.
        </p>

        <div className="flex items-center gap-2 text-gray-400 group-hover:text-gray-300 transition-colors">
          <span className="text-sm">Start Daily Dump</span>
          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </div>
      </button>
    );
  }

  // Loading state
  if (loading) {
    return (
      <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-50 p-4">
        <div className="w-full max-w-xl bg-gradient-to-br from-gray-800 to-gray-900 rounded-2xl p-6 shadow-lg border border-gray-800 space-y-4 animate-pulse">
          <div className="h-7 bg-gray-800 rounded-lg w-1/3"></div>
          <div className="h-4 bg-gray-800 rounded w-3/4"></div>
          <div className="h-4 bg-gray-800 rounded w-1/2"></div>
        </div>
      </div>
    );
  }

  // Active quest state
  return (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="w-full max-w-xl bg-gradient-to-br from-gray-800 to-gray-900 rounded-2xl p-6 shadow-lg border border-gray-800 space-y-4">
        {toast.visible && (
          <div className="absolute top-4 left-1/2 transform -translate-x-1/2 px-4 py-2 bg-white/10 backdrop-blur-md rounded-full text-white text-sm animate-fade-in-out">
            {toast.message}
          </div>
        )}
        
        <div className="flex justify-between items-start mb-4">
          <h2 className="text-xl font-semibold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
            Today's Quest
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors w-8 h-8 flex items-center justify-center rounded-lg hover:bg-white/10"
          >
            ×
          </button>
        </div>

        {quest && (
          <div className="space-y-6">
            <div className="space-y-4">
              <p className="text-gray-200 text-lg leading-relaxed">
                {quest.text}
              </p>
              
              {quest.explanation && (
                <p className="text-gray-400 text-sm italic leading-relaxed border-l-2 border-purple-500/30 pl-4">
                  {quest.explanation}
                </p>
              )}
            </div>

            {quest.cheatCode && (
              <div className="text-sm bg-white/5 rounded-lg p-4 border border-white/10 space-y-2 overflow-hidden">
                <div className="flex items-center gap-2 text-gray-400">
                  <span className="gradient-text font-semibold">
                    Cheat Code {quest.cheatCode.number}
                  </span>
                </div>
                <p className="text-gray-500 italic leading-relaxed overflow-y-auto max-h-24">
                  {quest.cheatCode.description}
                </p>
              </div>
            )}

            <div className="flex flex-wrap gap-3 pt-2">
              <button
                onClick={handleComplete}
                disabled={quest.completed}
                className={`btn-gradient flex-1 ${
                  quest.completed
                    ? 'opacity-60 cursor-not-allowed'
                    : ''
                }`}
              >
                {quest.completed ? '✓ Completed' : 'Mark Complete'}
              </button>
              
              <button
                onClick={handleShare}
                className="btn-fleshy flex items-center gap-2"
              >
                <svg 
                  className="w-5 h-5" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
                  />
                </svg>
                Share Quest
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

