'use client';

import React from 'react';
import { FaImage, FaUser, FaMapMarkerAlt } from 'react-icons/fa';
import './ListingsCards.css';

export interface Listing {
  id: string;
  title: string;
  description: string;
  type: string;
  photos: {
    gallery: string[];
  };
  author: {
    name: string;
    location?: string;
  };
  metadata: {
    createdAt: string;
  };
  tags?: string[];
  location?: {
    address?: string;
    lat?: number;
    lon?: number;
  };
}

export interface ListingCardProps {
  /**
   * The listing data to display
   */
  listing: Listing;
  
  /**
   * Callback when the listing card is clicked
   */
  onClick?: (listing: Listing) => void;
  
  /**
   * The display mode of the card
   */
  mode?: 'grid' | 'list' | 'compact' | 'chat';
  
  /**
   * Whether to show the view details button
   */
  showViewButton?: boolean;
  
  /**
   * Custom CSS class to apply to the card
   */
  className?: string;
  
  /**
   * Whether to truncate the description
   */
  truncateDescription?: boolean;
  
  /**
   * Number of lines to show in the description when truncated
   */
  descriptionLines?: number;
  
  /**
   * Whether to show the author information
   */
  showAuthor?: boolean;
  
  /**
   * Whether to show the date information
   */
  showDate?: boolean;
}

export const ListingCard: React.FC<ListingCardProps> = ({
  listing,
  onClick,
  mode = 'grid',
  showViewButton = true,
  className = '',
  truncateDescription = true,
  descriptionLines = 3,
  showAuthor = true,
  showDate = true,
}) => {
  // Generate CSS classes based on mode
  const cardClasses = [
    'listing-card',
    `listing-card-${mode}`,
    className
  ].filter(Boolean).join(' ');
  
  // Set description style based on truncation settings
  const descriptionStyle = truncateDescription ? {
    display: '-webkit-box',
    WebkitLineClamp: descriptionLines,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  } as React.CSSProperties : {};
  
  // Format date for display
  const formattedDate = new Date(listing.metadata.createdAt).toLocaleDateString();
  
  // Handle card click
  const handleCardClick = () => {
    if (onClick) {
      onClick(listing);
    }
  };
  
  // Handle view button click (prevents propagation)
  const handleViewButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onClick) {
      onClick(listing);
    }
  };
  
  return (
    <div className={cardClasses} onClick={handleCardClick}>
      <div className="listing-image">
        {listing.photos.gallery[0] ? (
          <img 
            src={listing.photos.gallery[0]} 
            alt={listing.title}
            loading="lazy"
          />
        ) : (
          <div className="listing-image-placeholder">
            <FaImage />
          </div>
        )}
      </div>
      
      <div className="listing-content">
        <div className="listing-header">
          <h3 className="listing-title">{listing.title}</h3>
          <span className="listing-type">{listing.type}</span>
        </div>
        
        {showAuthor && (
          <div className="listing-meta">
            <span>
              <FaUser />
              {listing.author.name}
            </span>
            {listing.author.location && (
              <span>
                <FaMapMarkerAlt />
                {listing.author.location}
              </span>
            )}
          </div>
        )}
        
        <p className="listing-description" style={descriptionStyle}>
          {listing.description}
        </p>
        
        <div className="listing-footer">
          {showDate && (
            <span className="listing-date">{formattedDate}</span>
          )}
          
          {showViewButton && (
            <button 
              className="btn-gradient listing-view-btn"
              onClick={handleViewButtonClick}
            >
              View Details
            </button>
          )}
        </div>
      </div>
    </div>
  );
}; 