"use client";

import React, { useState, useEffect, useRef } from "react";
import { useGlobalState } from './GlobalStateContext';
import { MemoryBank } from './MemoryBank';
import { ImmersiveAudioRecorder } from './ImmersiveAudioRecorder';

interface CreateMemoryProps {
  onClose: () => void;
  onDumpComplete?: () => void;
  isOpen: boolean;
}

export const CreateMemory: React.FC<CreateMemoryProps> = ({ onClose, onDumpComplete, isOpen }) => {
  const {
    userId,
    pseudonym,
    isRecording,
    isProcessing,
    transcription,
    setIsRecording,
    setIsProcessing,
    setTranscription,
  } = useGlobalState();
  const [dumpText, setDumpText] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showMemoryBank, setShowMemoryBank] = useState(false);
  const [showImmersiveRecorder, setShowImmersiveRecorder] = useState(false);
  const modalContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (transcription) {
      setDumpText(transcription);
    }
  }, [transcription]);

  // Prevent background scrolling when modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);
  
  // Handle immersive audio recording completion
  const handleImmersiveRecordingComplete = async (audioBlob: Blob) => {
    setIsRecording(false);
    setIsProcessing(true);
    setError(null);
    const formData = new FormData();
    formData.append("audio", audioBlob, "audio.webm");

    try {
      const response = await fetch("/api/transcribe", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) throw new Error(`Server responded with ${response.status}`);
      const data = await response.json();
      setDumpText(data.transcription);
    } catch (err) {
      setError("Failed to transcribe audio. Please try again.");
    } finally {
      setIsProcessing(false);
    }
  };
  
  // Handle saving the dump
  const handleSave = async () => {
    if (!dumpText.trim()) return;

    setIsSaving(true);
    setError(null);

    try {
      // First save the memory
      const dumpResponse = await fetch("/api/daily-dumps", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          content: dumpText,
          userId,
          pseudonym,
          timestamp: new Date().toISOString()
        }),
      });

      if (!dumpResponse.ok) throw new Error(`Server responded with ${dumpResponse.status}`);

      // Then send to companions for processing
      const companionResponse = await fetch("/api/companions/process-thought", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          content: dumpText,
          userId,
          pseudonym,
          timestamp: new Date().toISOString(),
          source: 'loob',
          type: 'memory'
        }),
      });

      if (!companionResponse.ok) {
        console.warn('Companion processing warning:', await companionResponse.text());
        // Don't throw error here - we still want to count the dump as successful
      }

      setSaveSuccess(true);
      setDumpText("");
      
      if (onDumpComplete) {
        onDumpComplete();
      }
    } catch (err) {
      setError("Failed to save your memory. Please try again.");
      console.error('Error saving memory:', err);
    } finally {
      setIsSaving(false);
    }
  };
  
  // Early return if not open
  if (!isOpen) return null;

  // Show archive if requested
  if (showMemoryBank) {
    return <MemoryBank 
      onClose={() => setShowMemoryBank(false)} 
      setView={(view: string) => {
        setShowMemoryBank(false);
        if (onClose) onClose();
        // Assume parent has access to global navigation
        window.dispatchEvent(new CustomEvent('setView', { detail: { view } }));
      }} 
    />;
  }
  
  // Show immersive recorder if active
  if (showImmersiveRecorder) {
    return (
      <ImmersiveAudioRecorder 
        isOpen={showImmersiveRecorder}
        onClose={() => setShowImmersiveRecorder(false)}
        onRecordingComplete={handleImmersiveRecordingComplete}
      />
    );
  }

  return (
    <div
      role="dialog"
      aria-modal="true"
      aria-labelledby="create-memory-title"
      className="create-memory-modal-overlay"
    >
      <div className="create-memory-container" ref={modalContentRef}>
        <div className="create-memory-header">
          <h2 id="create-memory-title" className="create-memory-title">
            Create a Memory
          </h2>
          <div className="create-memory-header-buttons">
            <button
              aria-label="view memory bank"
              className="create-memory-btn-secondary"
              onClick={() => setShowMemoryBank(true)}
            >
              View Memory Bank
            </button>
            <button
              aria-label="close"
              className="create-memory-close-button"
              onClick={onClose}
            >
              ×
            </button>
          </div>
        </div>

        <div className="create-memory-body">
          {saveSuccess ? (
            <div className="create-memory-success-container">
              <div className="create-memory-success-icon">✓</div>
              <p className="create-memory-success-message">Your memory has been saved!</p>
              <button
                onClick={() => {
                  setSaveSuccess(false);
                  if (onDumpComplete) onDumpComplete();
                }}
                className="create-memory-btn-primary"
              >
                Close
              </button>
            </div>
          ) : (
            <>
              <div className="create-memory-audio-section">
                <div className="create-memory-audio-controls-wrapper">
                  {/* Single audio recording button that opens the immersive recorder */}
                  <button 
                    onClick={() => setShowImmersiveRecorder(true)}
                    className="create-memory-audio-recorder-button"
                    aria-label="Record voice message"
                  >
                    <div className="create-memory-audio-button-content">
                      <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        viewBox="0 0 24 24" 
                        fill="currentColor" 
                        className="w-6 h-6"
                      >
                        <path d="M12 15c1.93 0 3.5-1.57 3.5-3.5V5c0-1.93-1.57-3.5-3.5-3.5S8.5 3.07 8.5 5v6.5c0 1.93 1.57 3.5 3.5 3.5z"/>
                        <path d="M12 16.5c-2.76 0-5-2.24-5-5H5c0 3.53 2.61 6.43 6 6.92V21h2v-2.58c3.39-.49 6-3.39 6-6.92h-2c0 2.76-2.24 5-5 5z"/>
                      </svg>
                      <span className="ml-2">Record Your Memory</span>
                    </div>
                  </button>
                </div>
              </div>
              <div className="create-memory-form-group">
                <textarea
                  aria-label="memory text"
                  className="create-memory-textarea"
                  placeholder="What would you like to remember?"
                  value={dumpText}
                  onChange={(e) => setDumpText(e.target.value)}
                  maxLength={2000}
                />
                <div role="status" className="create-memory-character-count" aria-live="polite">
                  {dumpText.length}/2000 characters
                </div>
              </div>
              {error && (
                <div role="alert" className="create-memory-error-message">
                  {error}
                </div>
              )}
              <div className="create-memory-button-container">
                <button
                  aria-label="save memory"
                  className="create-memory-btn-primary"
                  onClick={handleSave}
                  disabled={!dumpText.trim() || isSaving}
                >
                  {isSaving ? "Saving..." : "Save Memory"}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      
      <style jsx>{`
        .create-memory-modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.8);
          backdrop-filter: blur(4px);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1000;
          padding: 1rem;
          overflow-y: auto;
          touch-action: none;
        }
        
        .create-memory-container {
          background: linear-gradient(to bottom right, #1a1a1a, #2d2d2d);
          color: white;
          border-radius: 1rem;
          box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.5);
          width: 100%;
          max-width: 42rem;
          max-height: 90vh;
          border: 1px solid rgba(255, 255, 255, 0.1);
          display: flex;
          flex-direction: column;
          position: relative;
          z-index: 1001;
          overflow: hidden;
        }
        
        .create-memory-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1.5rem;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          z-index: 1002;
          position: relative;
        }
        
        .create-memory-title {
          font-size: 1.5rem;
          font-weight: 600;
          background: linear-gradient(to right, #e6b3cc, #ffccb3);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin: 0;
        }
        
        .create-memory-header-buttons {
          display: flex;
          gap: 1rem;
          align-items: center;
        }
        
        .create-memory-btn-secondary {
          padding: 0.5rem 1rem;
          background: rgba(255, 255, 255, 0.1);
          color: white;
          border: 1px solid rgba(255, 255, 255, 0.2);
          border-radius: 0.5rem;
          font-weight: 500;
          transition: all 0.2s;
          cursor: pointer;
        }
        
        .create-memory-btn-secondary:hover {
          background: rgba(255, 255, 255, 0.15);
        }
        
        .create-memory-btn-primary {
          padding: 0.5rem 1rem;
          background: linear-gradient(to right, #e6b3cc, #ffccb3);
          color: #1a1a1a;
          border: none;
          border-radius: 0.5rem;
          font-weight: 500;
          transition: all 0.2s;
          cursor: pointer;
        }
        
        .create-memory-btn-primary:hover:not(:disabled) {
          background: linear-gradient(to right, #e6b3cc, #ffccb3);
          transform: translateY(-1px);
          box-shadow: 0 4px 8px rgba(230, 179, 204, 0.3);
        }
        
        .create-memory-btn-primary:disabled {
          opacity: 0.6;
          cursor: not-allowed;
        }
        
        .create-memory-close-button {
          font-size: 1.5rem;
          color: rgba(255, 255, 255, 0.6);
          background: none;
          border: none;
          transition: color 0.2s;
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          border-radius: 50%;
          cursor: pointer;
          z-index: 1003;
        }
        
        .create-memory-close-button:hover {
          color: white;
          background: rgba(255, 255, 255, 0.1);
        }
        
        .create-memory-body {
          padding: 1.5rem;
          overflow-y: auto;
          flex: 1;
          z-index: 1001;
        }
        
        .create-memory-audio-section {
          margin: 1rem 0;
        }
        
        .create-memory-audio-controls-wrapper {
          display: flex;
          justify-content: center;
        }
        
        .create-memory-audio-recorder-button {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 12px 24px;
          background: linear-gradient(to right, rgba(230, 179, 204, 0.2), rgba(255, 204, 179, 0.2));
          border: 1px solid rgba(230, 179, 204, 0.3);
          border-radius: 50px;
          color: #f5f5f5;
          font-weight: 500;
          transition: all 0.2s ease;
          width: auto;
          min-width: 220px;
          cursor: pointer;
          z-index: 1002;
          position: relative;
          -webkit-tap-highlight-color: transparent;
        }
        
        @media (hover: hover) {
          .create-memory-audio-recorder-button:hover {
            background: linear-gradient(to right, rgba(230, 179, 204, 0.3), rgba(255, 204, 179, 0.3));
            transform: translateY(-2px);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          }
        }
        
        .create-memory-audio-recorder-button:active {
          background: linear-gradient(to right, rgba(230, 179, 204, 0.4), rgba(255, 204, 179, 0.4));
          transform: translateY(1px);
        }
        
        .create-memory-audio-button-content {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        
        .create-memory-form-group {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          position: relative;
          z-index: 1001;
        }
        
        .create-memory-textarea {
          width: 100%;
          min-height: 150px;
          padding: 0.75rem;
          background: rgba(255, 255, 255, 0.05);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 0.5rem;
          color: white;
          resize: vertical;
          transition: border-color 0.2s;
          font-family: inherit;
          line-height: 1.5;
        }
        
        .create-memory-textarea:focus {
          outline: none;
          border-color: rgba(230, 179, 204, 0.5);
          box-shadow: 0 0 0 2px rgba(230, 179, 204, 0.25);
        }
        
        .create-memory-character-count {
          text-align: right;
          font-size: 0.75rem;
          color: rgba(255, 255, 255, 0.6);
        }
        
        .create-memory-button-container {
          display: flex;
          justify-content: flex-end;
          gap: 1rem;
          margin-top: 1rem;
          position: relative;
          z-index: 1002;
        }
        
        .create-memory-error-message {
          background: rgba(239, 68, 68, 0.1);
          border: 1px solid rgba(239, 68, 68, 0.2);
          color: #ef4444;
          padding: 1rem;
          border-radius: 0.5rem;
          text-align: center;
          margin: 1rem 0;
        }
        
        .create-memory-success-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          padding: 2rem 0;
        }
        
        .create-memory-success-icon {
          width: 64px;
          height: 64px;
          background: rgba(34, 197, 94, 0.1);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 2rem;
          color: #22c55e;
          border: 2px solid rgba(34, 197, 94, 0.2);
        }
        
        .create-memory-success-message {
          font-size: 1.2rem;
          text-align: center;
          color: #f5f5f5;
        }
        
        /* Mobile-specific styles */
        @media (max-width: 767px) {
          .create-memory-container {
            width: 95%;
            max-height: 85vh;
            border-radius: 12px;
          }
          
          .create-memory-header {
            padding: 1rem;
          }
          
          .create-memory-header-buttons {
            gap: 8px;
          }
          
          .create-memory-close-button {
            width: 36px;
            height: 36px;
            font-size: 24px;
            background-color: rgba(60, 60, 60, 0.3);
            margin-left: 8px;
          }
          
          .create-memory-audio-recorder-button {
            width: 100%;
            min-width: unset;
            padding: 14px 20px;
          }
          
          .create-memory-textarea {
            min-height: 120px;
          }
          
          .create-memory-button-container {
            flex-direction: column-reverse;
          }
          
          .create-memory-btn-primary,
          .create-memory-btn-secondary {
            width: 100%;
            padding: 0.75rem;
            text-align: center;
          }
        }
      `}</style>
    </div>
  );
}; 