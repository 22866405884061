import { useState, useEffect, useRef } from 'react';
import { useGlobalState } from '../components/GlobalStateContext';
import { SmitheryKnowledgeGraph, createKnowledgeGraphClient } from '../lib/smithery';

// The API key is now handled on the server side through proxy endpoints
// We don't need to access it from the client anymore

/**
 * React hook for accessing the Knowledge Graph functionality
 */
export const useKnowledgeGraph = () => {
  const { user } = useGlobalState();
  const [kgClient, setKgClient] = useState<SmitheryKnowledgeGraph | null>(null);
  const [isReady, setIsReady] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const clientRef = useRef<SmitheryKnowledgeGraph | null>(null);
  const initAttemptedRef = useRef(false);

  useEffect(() => {
    if (!user || !user.userId) {
      // Don't set error - we might get a user later
      return;
    }

    // Avoid re-initializing if we already have a client for this user
    if (clientRef.current && initAttemptedRef.current) {
      return;
    }

    // Mark that we've attempted initialization
    initAttemptedRef.current = true;

    try {
      console.log('Initializing knowledge graph client for user:', user.userId);
      // The API key will be passed as an empty string since we're using server-side proxies
      const client = createKnowledgeGraphClient('', user.userId);
      
      // Store reference to avoid recreating
      clientRef.current = client;
      setKgClient(client);
      setIsReady(true);
      setError(null);
    } catch (err) {
      console.error('Error initializing knowledge graph client:', err);
      setError('Failed to initialize knowledge graph client - ' + (err instanceof Error ? err.message : String(err)));
      // Ensure we don't have a partially initialized client
      clientRef.current = null;
      setKgClient(null);
      setIsReady(false);
    }
  }, [user?.userId]);

  /**
   * Add a memory to the knowledge graph
   */
  const addMemory = async (content: string, source: string, metadata?: Record<string, any>) => {
    const client = clientRef.current || kgClient;
    
    if (!client) {
      console.warn('Knowledge graph client not initialized');
      return null;
    }
    
    try {
      return await client.addMemoryNode({
        content,
        source,
        metadata,
        timestamp: new Date().toISOString()
      });
    } catch (err) {
      console.error('Error adding memory to knowledge graph:', err);
      return null;
    }
  };

  /**
   * Query related memories from the knowledge graph
   */
  const queryMemories = async (query: string, limit: number = 5) => {
    const client = clientRef.current || kgClient;
    
    if (!client) {
      console.warn('Knowledge graph client not initialized for query');
      return [];
    }
    
    try {
      return await client.queryKnowledgeGraph(query, limit);
    } catch (err) {
      console.error('Error querying knowledge graph:', err);
      return [];
    }
  };

  /**
   * Get connections for a specific memory
   */
  const getConnections = async (memoryId: string) => {
    const client = clientRef.current || kgClient;
    
    if (!client) {
      console.warn('Knowledge graph client not initialized for connections');
      return { nodes: [], links: [] };
    }
    
    try {
      return await client.getMemoryConnections(memoryId);
    } catch (err) {
      console.error('Error getting memory connections:', err);
      return { nodes: [], links: [] };
    }
  };

  /**
   * Generate insights based on current context
   */
  const getInsights = async (context: string) => {
    const client = clientRef.current || kgClient;
    
    if (!client) {
      console.warn('Knowledge graph client not initialized for insights');
      return '';
    }
    
    try {
      const insights = await client.generateInsights(context);
      return insights?.insights || '';
    } catch (err) {
      console.error('Error generating insights:', err);
      return '';
    }
  };

  return {
    isReady,
    error,
    addMemory,
    queryMemories,
    getConnections,
    getInsights
  };
}; 