'use client';

import React, { useEffect, useState, useCallback, FC, useMemo, useRef } from 'react';
import './Profile.css';
import { LoobricateProfile } from './LoobricateProfile';
import { LoobricateAdminPanel } from './LoobricateAdminPanel';
import { useGlobalState } from './GlobalStateContext';
import type { GlobalState, User, Companion } from './GlobalStateContext';
import type { Loobricate } from './GlobalStateContext';
import type { LoobricateData } from '../types/loobricate';
import { DailyDump } from './DailyDump';
import { DailyQuest } from './DailyQuest';
import { VibeEntity } from './VibeEntity';
import { FaImage, FaMapMarkerAlt, FaSort, FaFilter, FaPlus, FaEdit } from 'react-icons/fa';
import { uploadImage } from '../lib/supabase/client';
import { EditEntryModal } from './EditEntryModal';
import { LoadingState } from './LoadingState';
import { ViewType } from './HomePage';
import { SendOmiUpdate } from './SendOmiUpdate';
import CompanionManager from './CompanionManager';
import { useVirtualizer } from '@tanstack/react-virtual';

// Conversion helper
const convertToLoobricate = (data: LoobricateData): Loobricate => ({
  id: data.id,
  name: data.name,
  description: data.description,
  address: `${data.addressLine1}, ${data.city}`,
  adminUsername: data.adminUsername,
  tags: data.tags,
  type: (data.type || 'community') as 'community' | 'venue' | 'gear' | 'talent',
  members: data.members,
  admins: data.admins,
  email: undefined,
  location: undefined,
  dataType: 'loobricate' as const
});

const convertToLoobricateData = (loobricate: Loobricate): LoobricateData => {
  const [addressLine1, city] = loobricate.address?.split(', ') || ['', ''];
  return {
    id: loobricate.id,
    name: loobricate.name,
    description: loobricate.description || '',
    addressLine1,
    city,
    adminUsername: loobricate.adminUsername || '',
    tags: loobricate.tags || [],
    type: loobricate.type || 'community',
    members: loobricate.members || [],
    admins: loobricate.admins || [],
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    status: 'active',
    profilePhoto: loobricate.profilePhoto,
    profilePhotoPath: loobricate.profilePhotoPath,
    coverPhoto: loobricate.coverPhoto,
    coverPhotoPath: loobricate.coverPhotoPath
  };
};

// Add type for the connected loobricate from API
interface ConnectedLoobricate {
  id: string;
  name: string;
  type: string;
  role: string;
}

// Add interface for Entry type
interface Entry {
  id: string;
  title: string;
  description: string;
  offeringType: string;
  photos: string[];
  createdAt: string;
  status: 'available' | 'unavailable' | 'maintenance';
  loobricateId: string;
  createdBy: string;
  creatorPseudonym: string;
  location?: string | { lat: number; lon: number };
  loobricateCount?: number;
}

interface ProfileProps {
  toggleView: (view: ViewType) => void;
  setActiveLoobricate: (loobricate: Loobricate | null) => void;
}

// Add EntryCard component outside the main component
const EntryCard: FC<{
  entry: Entry;
  onEdit: (entry: Entry) => void;
}> = React.memo(({ entry, onEdit }) => (
  <div className="entry-card">
    <div className="entry-header flex justify-between items-start">
      <div>
        <h3 className="entry-title mb-1">{entry.title}</h3>
        <span className="entry-type-badge">
          {entry.offeringType}
        </span>
      </div>
      <button
        className="edit-btn p-2 hover:bg-gray-700 rounded-full transition-colors"
        onClick={(e) => {
          e.stopPropagation();
          onEdit(entry);
        }}
        aria-label="Edit entry"
      >
        <FaEdit />
      </button>
    </div>
    
    <div className="entry-image aspect-video">
      {entry.photos && entry.photos.length > 0 ? (
        <img
          src={entry.photos[0]}
          alt={entry.title}
          loading="lazy"
          className="w-full h-full object-cover rounded-lg"
          decoding="async"
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.src = '/placeholder-image.jpg';
          }}
        />
      ) : (
        <div className="entry-image-placeholder w-full h-full flex items-center justify-center bg-gray-800 rounded-lg">
          <FaImage className="text-3xl text-gray-600" />
        </div>
      )}
    </div>
    
    <div className="entry-details p-3">
      <p className="entry-description text-sm text-gray-300 line-clamp-2">
        {entry.description}
      </p>
      {entry.location && (
        <div className="entry-location mt-2 flex items-center text-xs text-gray-400">
          <FaMapMarkerAlt className="mr-1" />
          <span>{typeof entry.location === 'string' ? entry.location : 'Mapped location'}</span>
        </div>
      )}
    </div>
  </div>
));

EntryCard.displayName = 'EntryCard';

export const Profile: FC<ProfileProps> = ({ toggleView, setActiveLoobricate }) => {
  const globalState = useGlobalState() as GlobalState;
  const { user, setUser, setView } = globalState;
  const [entries, setEntries] = useState<Entry[]>([]);
  const [recentDiscoveries, setRecentDiscoveries] = useState<any[]>([]);
  const [routeMessage, setRouteMessage] = useState<string>('');
  const [showDailyDumpModal, setShowDailyDumpModal] = useState<boolean>(false);
  const [showDailyQuestModal, setShowDailyQuestModal] = useState(false);
  const [loadingState, setLoadingState] = useState<'idle' | 'loading' | 'error' | 'success'>('idle');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [hasLoadedInitialData, setHasLoadedInitialData] = useState(false);
  const [hasDumpedToday, setHasDumpedToday] = useState(false);
  const [sortOrder, setSortOrder] = useState<'newest' | 'oldest'>('newest');
  const [filterType, setFilterType] = useState<string>('all');

  // Remove duplicate state
  const [selectedLoobricate, setSelectedLoobricate] = useState<Loobricate | null>(null);
  const [showLoobricateModal, setShowLoobricateModal] = useState(false);
  const [isLoobricateLoading, setIsLoobricateLoading] = useState(false);

  // Add state for edit modal
  const [editingEntry, setEditingEntry] = useState<Entry | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isEntriesLoading, setIsEntriesLoading] = useState(false);

  // Add this new state for the SendOmiUpdate modal
  const [showOmiUpdateModal, setShowOmiUpdateModal] = useState(false);
  // Add state for CompanionManager modal
  const [showCompanionManager, setShowCompanionManager] = useState(false);

  // Add ref for virtualization
  const parentRef = React.useRef<HTMLDivElement>(null);

  // Add cache state with request tracking
  const [entriesCache, setEntriesCache] = useState<{
    data: Entry[];
    timestamp: number;
    requestId: string;
  } | null>(null);
  const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds
  const [isRefreshing, setIsRefreshing] = useState(false);
  const refreshTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

  // Add loading state
  const [isLoading, setIsLoading] = useState(false);

  // Add error state
  const [error, setError] = useState<Error | null>(null);

  // Add pagination state
  const ITEMS_PER_PAGE = 6;
  const [displayCount, setDisplayCount] = useState(ITEMS_PER_PAGE);

  // Add state to track if any modal is open
  const isAnyModalOpen = showDailyDumpModal || showDailyQuestModal || isEditModalOpen || 
                         showLoobricateModal || showOmiUpdateModal || showCompanionManager;
  
  // Add effect to prevent body scrolling when modal is open
  useEffect(() => {
    if (isAnyModalOpen) {
      // Get current scroll position
      const scrollY = window.scrollY;
      
      // Save the original body styles
      const originalOverflow = document.body.style.overflow;
      const originalHeight = document.body.style.height;
      const originalPosition = document.body.style.position;
      const originalTop = document.body.style.top;
      const originalWidth = document.body.style.width;
      const originalTouchAction = document.body.style.touchAction;
      
      // Apply fixed positioning to prevent scrolling
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100%';
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY}px`;
      document.body.style.width = '100%';
      document.body.style.touchAction = 'none'; // Prevent touch scrolling on mobile
      
      // Add a class for potential CSS targeting
      document.body.classList.add('modal-open');
      
      return () => {
        // Restore original body styles when component unmounts or modal closes
        document.body.style.overflow = originalOverflow;
        document.body.style.height = originalHeight;
        document.body.style.position = originalPosition;
        document.body.style.top = originalTop;
        document.body.style.width = originalWidth;
        document.body.style.touchAction = originalTouchAction;
        document.body.classList.remove('modal-open');
        
        // Restore scroll position
        window.scrollTo(0, scrollY);
      };
    }
  }, [isAnyModalOpen]);

  // Add function to refresh entries with caching and request deduplication
  const refreshEntries = useCallback(async (force = false) => {
    // Temporarily disabled user listings fetch
    /*
    if (user.isAnonymous || !user.userId || user.userId.startsWith('anon-') || !user.pseudonym) return;
    
    // Generate a unique request ID
    const requestId = `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
    
    // Skip if already refreshing unless forced
    if (isRefreshing && !force) {
      return;
    }
    
    // Check cache first
    const now = Date.now();
    if (entriesCache && !force && (now - entriesCache.timestamp) < CACHE_DURATION) {
      setEntries(entriesCache.data);
      return;
    }
    
    try {
      setIsRefreshing(true);
      setIsEntriesLoading(true);
      setIsLoading(true);
      setError(null);
      
      // Add timeout for fetch
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 8000); // 8 second timeout
      
      const response = await fetch(`/api/user-listings?pseudonym=${encodeURIComponent(user.pseudonym)}`, {
        headers: {
          'Cache-Control': force ? 'no-cache' : 'max-age=300', // 5 minutes cache
          'X-Request-ID': requestId // Add request ID for tracking
        },
        signal: controller.signal
      });
      
      clearTimeout(timeoutId);
      
      if (!response.ok) {
        console.warn(`Failed to fetch user entries: ${response.status} ${response.statusText}`);
        // Don't throw - use previous entries if available
        if (entriesCache?.data?.length) {
          setEntries(entriesCache.data);
        }
        return;
      }
      
      const data = await response.json();
      if (data.entries) {
        // Only update if this is still the most recent request
        if (!entriesCache || requestId === entriesCache.requestId) {
          setEntries(data.entries);
          setEntriesCache({
            data: data.entries,
            timestamp: now,
            requestId
          });
        }
      }
    } catch (error) {
      // Use cached entries if available
      if (entriesCache?.data?.length) {
        setEntries(entriesCache.data);
      }
      
      const err = error instanceof Error ? error : new Error('Failed to refresh entries');
      console.warn('Error refreshing entries:', err);
      
      // Only set error if it's not an abort error (which we caused with the timeout)
      if (!(error instanceof DOMException && error.name === 'AbortError')) {
        setError(err);
      }
      
      // Don't show users transient network errors
      if (error instanceof TypeError && error.message.includes('Failed to fetch')) {
        setErrorMessage('Connection issue. Using cached data.');
      } else {
        setErrorMessage(err.message);
      }
    } finally {
      setIsRefreshing(false);
      setIsEntriesLoading(false);
      setIsLoading(false);
    }
    */
    
    // Use placeholder data instead of fetching
    console.log('User listings fetch disabled - using placeholders');
    setEntries([]);
    setIsRefreshing(false);
    setIsEntriesLoading(false);
    setIsLoading(false);
    
  }, [user.isAnonymous, user.userId, user.pseudonym, entriesCache, isRefreshing, CACHE_DURATION]);

  // Update effect to use longer interval and respect cache
  useEffect(() => {
    // Temporarily disabled refresh interval for user listings
    /*
    if (user.isAnonymous || !user.userId || user.userId.startsWith('anon-') || !user.pseudonym) return;

    // Clear any existing timeout
    if (refreshTimeoutRef.current) {
      clearTimeout(refreshTimeoutRef.current);
    }

    // Initial refresh
    refreshEntries(true);

    // Set up periodic refresh every 5 minutes
    const refreshInterval = setInterval(() => {
      refreshEntries(false);
    }, CACHE_DURATION);

    return () => {
      clearInterval(refreshInterval);
      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
      }
    };
    */
    
    // Just call once to set empty entries
    refreshEntries(true);
    
  }, [refreshEntries, user.isAnonymous, user.userId, user.pseudonym]);

  // Transform raw loobricates into normalized form, handling potential invalid data
  const connectedLoobricates = React.useMemo(() => 
    (user.connectedLoobricates || [])
      .map(loobricate => {
        // Handle string IDs (temporary data) silently
        if (typeof loobricate === 'string') return null;
        
        // Ensure loobricate object has required fields
        if (!loobricate?.id || !loobricate?.name) {
          console.debug('Skipping invalid loobricate:', loobricate);
          return null;
        }
        
        // Convert to Loobricate type
        return {
          id: loobricate.id,
          name: loobricate.name,
          description: loobricate.description || '',
          address: loobricate.address || '',  // Use existing address or empty string
          adminUsername: loobricate.adminUsername || '',
          tags: loobricate.tags || [],
          type: (loobricate.type || 'community') as 'community' | 'venue' | 'gear' | 'talent',
          members: loobricate.members || [],
          admins: loobricate.admins || [],
          email: undefined,
          location: undefined,
          dataType: 'loobricate' as const,
          isLoobricate: true,
          role: loobricate.admins?.includes(user.userId || '') ? 'admin' : 'member',
          profilePhoto: loobricate.profilePhoto,
          profilePhotoPath: loobricate.profilePhotoPath,
          coverPhoto: loobricate.coverPhoto,
          coverPhotoPath: loobricate.coverPhotoPath
        } as Loobricate;
      })
      .filter((l): l is NonNullable<typeof l> => l !== null)
  , [user.connectedLoobricates, user.userId]);

  useEffect(() => {
    const fetchUserData = async () => {
      // Skip if we've already loaded the data
      if (hasLoadedInitialData) return;

      // Handle anonymous users
      if (user.isAnonymous || !user.userId || user.userId.startsWith('anon-')) {
        setRouteMessage('You are browsing anonymously. Sign in to access more features.');
        setLoadingState('success');
        setHasLoadedInitialData(true);
        return;
      }

      try {
        setLoadingState('loading');
        console.log('Fetching data for user:', user.userId);
        const response = await fetch(`/api/profile?uid=${encodeURIComponent(user.userId)}`);
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        
        if (data) {
          console.log('Profile - Received user data:', data);
          
          // Only update if we have valid data
          if (data.user) {
            const shouldUpdateGlobal = 
              data.user.pseudonym !== user.pseudonym ||
              data.user.email !== user.email ||
              data.user.phone !== user.phone ||
              JSON.stringify(data.connectedLoobricates) !== JSON.stringify(user.connectedLoobricates);

            if (shouldUpdateGlobal) {
              setUser({
                userId: user.userId,
                pseudonym: data.user.pseudonym,
                email: data.user.email,
                phone: data.user.phone,
                isAnonymous: false,
                connectedLoobricates: data.connectedLoobricates || [],
                activeCompanion: data.user.activeCompanion || user.activeCompanion,
                activeLoobricate: data.user.activeLoobricate || null,
                hasChosenCompanion: data.user.hasChosenCompanion ?? user.hasChosenCompanion,
                discoveredCompanions: data.user.discoveredCompanions || user.discoveredCompanions || []
              });
            }
          }
          
          // Skip entries data - temporarily disabled
          /*
          if (data.entries) {
            // Sort entries by creation date, newest first
            const sortedEntries = data.entries.sort((a: Entry, b: Entry) => 
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
            setEntries(sortedEntries);
          }
          */
          
          setRecentDiscoveries(data.discoveries || []);
          setLoadingState('success');
          setHasLoadedInitialData(true);
          setRouteMessage('');
        } else {
          console.error('Failed to fetch user data:', data.error);
          setErrorMessage(data.error || 'Failed to fetch user data');
          setLoadingState('error');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setErrorMessage(error instanceof Error ? error.message : 'An unexpected error occurred');
        setLoadingState('error');
      }
    };

    fetchUserData();
  }, [user.userId, user.isAnonymous, hasLoadedInitialData, user.pseudonym, user.email, user.phone, user.connectedLoobricates, setUser]);

  // Add effect to check if user has dumped today
  const checkDumpStatus = useCallback(async () => {
    if (!user.userId) return;
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 5000); // 5 second timeout
      
      const response = await fetch(`/api/daily-dumps/today?userId=${encodeURIComponent(user.userId)}`, {
        signal: controller.signal
      });
      
      clearTimeout(timeoutId);
      
      if (!response.ok) {
        console.warn(`Failed to check dump status: ${response.status} ${response.statusText}`);
        // Don't throw here, just set to false as a fallback
        setHasDumpedToday(false);
        return;
      }
      
      const data = await response.json();
      setHasDumpedToday(data?.hasDumped ?? false);
    } catch (error) {
      // Log error but don't show to user, fallback to not dumped
      console.warn('Error checking dump status:', error);
      setHasDumpedToday(false);
    }
  }, [user.userId]);

  useEffect(() => {
    checkDumpStatus();
  }, [checkDumpStatus]);

  // Log Out Button Handler
  const handleLogOut = () => {
    const emptyUser: User = {
      userId: '',
      pseudonym: '',
      email: '',
      phone: '',
      isAnonymous: true,
      connectedLoobricates: [],
      activeCompanion: null,
      activeLoobricate: null,
      hasChosenCompanion: false,
      discoveredCompanions: []
    };
    setUser(emptyUser);
    window.location.reload();
  };

  // Placeholder for button clicks
  const handlePlaceholderClick = () => {
    alert('Feature coming soon!');
  };

  // Update the handleLoobricateClick function
  const handleLoobricateClick = async (loobricate: Loobricate) => {
    try {
      setIsLoobricateLoading(true);
      const response = await fetch(`/api/auth/loobricate-auth`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: loobricate.adminUsername,
          loobricateId: loobricate.id
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to authenticate with loobricate');
      }

      const data = await response.json();
      setSelectedLoobricate(data);
      setShowLoobricateModal(true);
      if (setActiveLoobricate) {
        setActiveLoobricate(data);
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to authenticate with loobricate';
      alert(errorMessage);
      console.error('Error authenticating with loobricate:', error);
    } finally {
      setIsLoobricateLoading(false);
    }
  };

  // Handle modal close
  const handleModalClose = () => {
    setSelectedLoobricate(null);
    setShowLoobricateModal(false);
  };

  // Handle Loobricate update
  const handleLoobricateUpdate = (updatedData: LoobricateData) => {
    if (!selectedLoobricate) return;

    // Update the loobricate in the global state
    const updatedLoobricates = user.connectedLoobricates.map(l => {
      if (typeof l === 'string') return l;
      return l.id === updatedData.id ? {
        ...l,
        name: updatedData.name,
        description: updatedData.description,
        address: `${updatedData.addressLine1}, ${updatedData.city}`,
        adminUsername: updatedData.adminUsername,
        tags: updatedData.tags,
        members: updatedData.members,
        admins: updatedData.admins,
        type: (updatedData.type || 'community') as 'community' | 'venue' | 'gear' | 'talent',
        profilePhoto: updatedData.profilePhoto,
        profilePhotoPath: updatedData.profilePhotoPath,
        coverPhoto: updatedData.coverPhoto,
        coverPhotoPath: updatedData.coverPhotoPath
      } : l;
    });

    setUser({
      ...user,
      connectedLoobricates: updatedLoobricates
    });
    setSelectedLoobricate(prevState => 
      prevState ? {
        ...prevState,
        name: updatedData.name,
        description: updatedData.description,
        address: `${updatedData.addressLine1}, ${updatedData.city}`,
        adminUsername: updatedData.adminUsername,
        tags: updatedData.tags,
        members: updatedData.members,
        admins: updatedData.admins,
        type: (updatedData.type || 'community') as 'community' | 'venue' | 'gear' | 'talent',
        profilePhoto: updatedData.profilePhoto,
        profilePhotoPath: updatedData.profilePhotoPath,
        coverPhoto: updatedData.coverPhoto,
        coverPhotoPath: updatedData.coverPhotoPath
      } : null
    );
  };

  // Add placeholder badges for anonymous users
  const placeholderBadges = [
    { id: '1', name: '?' },
    { id: '2', name: '?' },
    { id: '3', name: '?' }
  ];

  const handleLoginClick = () => {
    window.location.reload();
  };

  // Memoize filtered and sorted entries with pagination
  const filteredAndSortedEntries = useMemo(() => {
    let filtered = [...entries];
    
    // Apply type filter
    if (filterType !== 'all') {
      filtered = filtered.filter(entry => entry.offeringType.toLowerCase() === filterType);
    }
    
    // Apply sort
    filtered.sort((a, b) => {
      const dateA = new Date(a.createdAt).getTime();
      const dateB = new Date(b.createdAt).getTime();
      return sortOrder === 'newest' ? dateB - dateA : dateA - dateB;
    });
    
    return filtered;
  }, [entries, filterType, sortOrder]);

  // Get paginated entries
  const displayedEntries = useMemo(() => {
    return filteredAndSortedEntries.slice(0, displayCount);
  }, [filteredAndSortedEntries, displayCount]);

  // Handle load more
  const handleLoadMore = () => {
    setDisplayCount(prev => prev + ITEMS_PER_PAGE);
  };

  // Add function to handle edit submission
  const handleEditSubmit = async (updatedEntry: Entry) => {
    try {
      console.log('Updating entry:', updatedEntry);
      setIsUpdating(true);
      
      // Prepare the data for the API
      const entryData = {
        title: updatedEntry.title,
        description: updatedEntry.description,
        status: updatedEntry.status,
        photos: updatedEntry.photos,
        location: updatedEntry.location
      };
      
      const response = await fetch(`/api/entries/${updatedEntry.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(entryData),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to update entry');
      }
      
      const updatedData = await response.json();
      
      setEntries(prevEntries => 
        prevEntries.map(entry => 
          entry.id === updatedEntry.id ? {
            ...entry,
            ...updatedData,
            photos: Array.isArray(updatedData.photos) 
              ? updatedData.photos 
              : updatedData.photos?.gallery || entry.photos || []
          } : entry
        )
      );
      
      setIsEditModalOpen(false);
      setEditingEntry(null);
    } catch (error) {
      console.error('Error updating entry:', error);
      alert(error instanceof Error ? error.message : 'Failed to update entry. Please try again.');
    } finally {
      setIsUpdating(false);
    }
  };

  // Add a helper function to get the user join date
  const getUserJoinDate = () => {
    if (!hasLoadedInitialData) return '...';
    if (user.isAnonymous) return new Date().toLocaleDateString();
    
    if (entries && entries.length > 0) {
      const oldestEntry = [...entries].sort((a, b) => 
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      )[0];
      return new Date(oldestEntry.createdAt).toLocaleDateString();
    }
    
    const approxDate = new Date();
    approxDate.setMonth(approxDate.getMonth() - 1);
    return approxDate.toLocaleDateString();
  };

  // Add function to handle companion selection
  const handleCompanionSelect = async (companion: Companion) => {
    try {
      console.log('Profile: Setting active companion:', companion.name);
      await globalState.setActiveCompanion(companion);
      console.log('Profile: Successfully set active companion:', companion.name);
      return Promise.resolve();
    } catch (error) {
      console.error('Profile: Error setting active companion:', error);
      return Promise.reject(error);
    }
  };

  // Add useEffect to handle mobile viewport height
  useEffect(() => {
    // Function to set the --vh CSS variable
    const setViewportHeight = () => {
      // First we get the viewport height and multiply it by 1% to get a value for a vh unit
      const vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // Detect iOS for special handling
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
    if (isIOS) {
      document.body.classList.add('ios-device');
    }

    // Call the function initially and on resize
    setViewportHeight();
    window.addEventListener('resize', setViewportHeight);
    window.addEventListener('orientationchange', setViewportHeight);

    // Clean up on component unmount
    return () => {
      window.removeEventListener('resize', setViewportHeight);
      window.removeEventListener('orientationchange', setViewportHeight);
      if (isIOS) {
        document.body.classList.remove('ios-device');
      }
    };
  }, []);

  if (isLoading) {
    return <LoadingState type="full" message="Loading profile..." isLoading={true} />;
  }

  return (
    <div className="profile-container scroll-enabled">
      {/* User Profile Header - Simplified header for better mobile display */}
      <section className="profile-header">
        <div className="profile-header-content">
          <h1 className="profile-pseudonym">{user.pseudonym || 'Anonymous'}</h1>
          <p className="text-sm text-center text-gray-300 mb-1">
            {user.isAnonymous 
              ? 'Viewing as guest' 
              : `Loobricating since ${getUserJoinDate()}`
            }
          </p>
          {errorMessage && (
            <div className="network-error-message text-xs text-center my-1 px-2 py-1 bg-red-900/30 rounded-md mx-auto max-w-xs">
              <span className="text-red-300">{errorMessage}</span>
            </div>
          )}
        </div>
      </section>

      {/* Mobile-first content container with improved overflow handling */}
      <div className="profile-content-container">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-3">
          {/* Column 1: Memory & Companions */}
          <div className="space-y-3 md:col-span-4 order-1">
            {/* Memory & Companions Section */}
            <section className="profile-section memory-companions-section">
              <h2 className="section-title flex items-center gap-2 text-lg md:text-xl mb-3">
                <span>🧠</span> Memory & Companions
              </h2>
              
              {/* Memory Bank Access - More compact for mobile */}
              <div className="mb-4">
                <div className="p-2 bg-gray-800/50 rounded-lg mb-3">
                  <p className="text-gray-300 text-sm mb-2">Access your personal and shared memories from various sources.</p>
                  
                  <div className="buttons-container flex flex-col gap-2">
                    <button 
                      className="daily-dump-button px-3 py-2 rounded-lg transition-colors"
                      onClick={() => setShowDailyDumpModal(true)}
                      disabled={isLoading}
                    >
                      Unload Your Mind
                    </button>
                    <button 
                      className="daily-quest-button px-3 py-2 rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                      onClick={() => setShowDailyQuestModal(true)}
                      disabled={isLoading || hasDumpedToday}
                    >
                      Daily Quest {hasDumpedToday ? '(✓)' : ''}
                    </button>
                  </div>
                </div>
                
                <div className="memory-stats grid grid-cols-2 gap-2 mb-4">
                  <div className="stat-card p-2 bg-gray-800/30 rounded-lg text-center">
                    <div className="stat-value text-lg font-bold text-gray-200">
                      <span title="Entries feature temporarily disabled">--</span>
                    </div>
                    <div className="stat-label text-xs text-gray-400">
                      Memories
                    </div>
                  </div>
                  <div className="stat-card p-2 bg-gray-800/30 rounded-lg text-center">
                    <div className="stat-value text-lg font-bold text-gray-200">
                      {recentDiscoveries.length}
                    </div>
                    <div className="stat-label text-xs text-gray-400">
                      Discoveries
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Companions Section - More compact */}
              <div className="border-t border-gray-800 pt-3 mb-4">
                <h3 className="text-base font-medium text-gray-200 mb-2 flex items-center gap-2">
                  <span>👤</span> Companions
                </h3>
                
                <div className="p-2 bg-gray-800/50 rounded-lg mb-3">
                  <p className="text-gray-300 text-sm mb-1">Your companions can access your shared memories and help guide your journey.</p>
                  <p className="text-xs text-gray-400">
                    Companions assist with discovery and organization.
                  </p>
                </div>
                
                <button 
                  onClick={() => setShowCompanionManager(true)}
                  className="companion-manager-button w-full text-center py-2 px-3 rounded-lg transition-colors"
                >
                  Manage Companions
                </button>
              </div>
              
              {/* Omi Integration Section - More compact */}
              <div className="border-t border-gray-800 pt-3">
                <h3 className="text-base font-medium text-gray-200 mb-2 flex items-center gap-2">
                  <span>🎙️</span> Omi Integration
                </h3>
                
                <div className="bg-gray-800/50 p-2 rounded-lg mb-3">
                  <p className="text-sm text-gray-300 mb-1">
                    Connect with Omi to enhance your Memory Bank:
                  </p>
                  <ul className="list-disc pl-4 text-xs text-gray-300">
                    <li>Create memories in your Omi account</li>
                    <li>Send updates to your Omi device</li>
                    <li>Sync information between platforms</li>
                  </ul>
                </div>
                
                <div className="space-y-2">
                  <button 
                    onClick={() => setShowOmiUpdateModal(true)}
                    className="omi-update-button w-full py-2 px-3 rounded-lg flex items-center justify-center gap-2 transition-colors"
                  >
                    <span>🔄</span> Send Update or Create Memory
                  </button>
                  
                  <a 
                    href="https://docs.omi.me/docs/developer/apps/Introduction"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block text-center w-full py-2 px-3 rounded-lg text-sm font-medium bg-gray-700 hover:bg-gray-600 text-gray-200 transition-colors"
                  >
                    Learn About Omi
                  </a>
                </div>
              </div>
            </section>
          </div>
          
          {/* Column 2: Main Content - Camps and Discoveries */}
          <div className="space-y-3 md:col-span-8 order-2">
            {/* My Camps Section */}
            <section className="profile-section">
              <h2 className="section-title flex items-center gap-2 text-lg md:text-xl mb-3">
                <span>👥</span> My Camps
              </h2>
              
              {/* Camps list - Mobile-first grid */}
              <div className="loobricates-grid">
                {connectedLoobricates
                  .filter(loobricate => loobricate.type === 'community' || loobricate.type === 'venue')
                  .map((loobricate) => (
                    <div
                      key={loobricate.id}
                      className="loobricate-card"
                      onClick={() => handleLoobricateClick(loobricate)}
                      role="button"
                      tabIndex={0}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          handleLoobricateClick(loobricate);
                        }
                      }}
                      aria-label={`View ${loobricate.name} profile`}
                    >
                      <div className="loobricate-avatar mb-1">
                        {loobricate.profilePhoto ? (
                          <img 
                            src={loobricate.profilePhoto} 
                            alt={loobricate.name} 
                            loading="lazy"
                            className="w-12 h-12 sm:w-16 sm:h-16 object-cover rounded-full"
                          />
                        ) : (
                          <div className="loobricate-avatar-placeholder w-12 h-12 sm:w-16 sm:h-16">
                            {loobricate.name.charAt(0).toUpperCase()}
                          </div>
                        )}
                      </div>
                      <div className="loobricate-name text-center text-sm sm:text-base">
                        {loobricate.name}
                        {loobricate.admins && 
                         Array.isArray(loobricate.admins) && 
                         user.userId && 
                         loobricate.admins.some(adminId => adminId === user.userId) && (
                          <span className="admin-badge text-xs">Admin</span>
                        )}
                      </div>
                      <div className="text-xs text-gray-400 mt-1 text-center">
                        {loobricate.type === 'community' ? 'Community' : 'Venue'}
                      </div>
                    </div>
                  ))}
                  
                {/* Add new camp button */}
                <div 
                  className="loobricate-card add-card"
                  onClick={() => setView('AddEntry')}
                  role="button"
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      setView('AddEntry');
                    }
                  }}
                >
                  <div className="add-icon text-xl mb-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <line x1="12" y1="5" x2="12" y2="19"></line>
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                  </div>
                  <div className="font-medium text-sm sm:text-base">Add Camp</div>
                  <div className="text-xs text-gray-400 mt-1">Join or create</div>
                </div>
              </div>
            </section>
            
            {/* Recent Discoveries Section */}
            <section className="profile-section">
              <h2 className="section-title flex items-center gap-2 text-lg md:text-xl mb-3">
                <span>✨</span> Recent Discoveries
              </h2>
              
              {recentDiscoveries && recentDiscoveries.length > 0 ? (
                <div className="discoveries-grid">
                  {recentDiscoveries.map((discovery, index) => (
                    <div key={index} className="discovery-card">
                      <div className="discovery-title text-base font-medium">
                        {discovery.title || discovery.name || 'Unnamed Discovery'}
                      </div>
                      <div className="discovery-type text-xs text-gray-400 mb-1">
                        {discovery.type || 'Location'}
                      </div>
                      <div className="discovery-description text-sm text-gray-300 line-clamp-2">
                        {discovery.description || 'No description available'}
                      </div>
                      <div className="discovery-date text-xs text-gray-500 mt-1">
                        {new Date(discovery.discoveredAt || discovery.createdAt || Date.now()).toLocaleDateString()}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="empty-discoveries text-center py-4">
                  <p className="text-gray-400 text-sm">No discoveries yet</p>
                  <p className="text-xs text-gray-500 mt-1">
                    Explore to discover communities, art, and events!
                  </p>
                  <button 
                    className="mt-3 px-3 py-2 bg-gray-700 hover:bg-gray-600 rounded-lg text-gray-200 text-xs transition-colors"
                    onClick={() => setView('Map')}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="inline mr-1 h-3 w-3" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                      <circle cx="12" cy="10" r="3"></circle>
                    </svg> 
                    Explore Map
                  </button>
                </div>
              )}
            </section>
                  </div>
                </div>
                
        {/* Offerings section remains commented out */}
      </div>

      {/* Modal container with fixed positioning */}
      <div className="modal-container fixed inset-0 z-50 pointer-events-none flex items-center justify-center overflow-hidden">
        {/* Modals */}
        {showDailyDumpModal && (
          <div 
            className="pointer-events-auto fixed inset-0 bg-black/70 flex items-center justify-center p-4 overflow-y-auto"
            onClick={(e) => {
              // Close modal when clicking outside (on the background)
              if (e.target === e.currentTarget) {
                setShowDailyDumpModal(false);
              }
            }}
          >
            <DailyDump 
              onClose={() => setShowDailyDumpModal(false)} 
              onDumpComplete={() => {
                setShowDailyDumpModal(false);
                setHasDumpedToday(true);
                refreshEntries(true);
              }}
              isOpen={showDailyDumpModal}
            />
          </div>
        )}

        {showDailyQuestModal && (
          <div 
            className="pointer-events-auto fixed inset-0 bg-black/70 flex items-center justify-center p-4 overflow-y-auto"
            onClick={(e) => {
              if (e.target === e.currentTarget) {
                setShowDailyQuestModal(false);
              }
            }}
          >
            <DailyQuest 
              onClose={() => setShowDailyQuestModal(false)} 
              hasDumpedToday={hasDumpedToday}
              onOpenDump={() => {
                setShowDailyQuestModal(false);
                setShowDailyDumpModal(true);
              }}
            />
          </div>
        )}

        {isEditModalOpen && editingEntry && (
          <div 
            className="pointer-events-auto fixed inset-0 bg-black/70 flex items-center justify-center p-4 overflow-y-auto"
            onClick={(e) => {
              if (e.target === e.currentTarget && !isUpdating) {
                setIsEditModalOpen(false);
                setEditingEntry(null);
              }
            }}
          >
            <EditEntryModal
              entry={editingEntry}
              onClose={() => {
                setIsEditModalOpen(false);
                setEditingEntry(null);
              }}
              onSubmit={handleEditSubmit}
              isUpdating={isUpdating}
            />
          </div>
        )}

        {showLoobricateModal && selectedLoobricate && (
          <div 
            className="pointer-events-auto fixed inset-0 bg-black/70 flex items-center justify-center p-4 overflow-y-auto"
            onClick={(e) => {
              if (e.target === e.currentTarget) {
                handleModalClose();
              }
            }}
          >
            <LoobricateProfile
              loobricate={selectedLoobricate}
              onClose={handleModalClose}
              isAdmin={selectedLoobricate.admins?.includes(user.userId || '')}
              onUpdate={handleLoobricateUpdate}
            />
          </div>
        )}

        {showOmiUpdateModal && (
          <div 
            className="pointer-events-auto fixed inset-0 bg-black/70 flex items-center justify-center p-4 overflow-y-auto"
            onClick={(e) => {
              if (e.target === e.currentTarget) {
                setShowOmiUpdateModal(false);
              }
            }}
          >
            <SendOmiUpdate
              isOpen={showOmiUpdateModal}
              onClose={() => setShowOmiUpdateModal(false)}
            />
          </div>
        )}

        {showCompanionManager && (
          <div 
            className="pointer-events-auto fixed inset-0 bg-black/70 flex items-center justify-center p-4 overflow-y-auto"
            onClick={(e) => {
              if (e.target === e.currentTarget) {
                setShowCompanionManager(false);
              }
            }}
          >
            <CompanionManager
              isOpen={showCompanionManager}
              onClose={() => setShowCompanionManager(false)}
              onSelect={handleCompanionSelect}
            />
          </div>
        )}
      </div>
    </div>
  );
};

