'use client';

import React, { useState, useEffect } from 'react';
import { ImmersiveAudioRecorder } from './ImmersiveAudioRecorder';
import { useGlobalState } from './GlobalStateContext';
import { parseTranscription, shouldSaveAsMemory, createMemoryEntry, IntentType } from '../services/intentParser';
import './EnhancedImmersiveRecorder.css';

interface EnhancedImmersiveRecorderProps {
  isOpen: boolean;
  onClose: () => void;
  onMemoryCreated?: (memory: any) => void;
}

export const EnhancedImmersiveRecorder: React.FC<EnhancedImmersiveRecorderProps> = ({
  isOpen,
  onClose,
  onMemoryCreated
}) => {
  // Component state
  const [phase, setPhase] = useState<'options' | 'recording' | 'processing' | 'confirmation'>('options');
  const [selectedOption, setSelectedOption] = useState<IntentType | null>(null);
  const [showRecorder, setShowRecorder] = useState(false);
  const [transcription, setTranscription] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [memoryData, setMemoryData] = useState<any | null>(null);
  
  // Get user data from global state
  const { userId, activeCompanion } = useGlobalState();
  
  // Reset component when opened or closed
  useEffect(() => {
    if (isOpen) {
      // Always start with options phase when component is opened
      setPhase('options');
      setSelectedOption(null);
      setShowRecorder(false);
      setTranscription('');
      setIsProcessing(false);
      setError(null);
      setMemoryData(null);
    }
  }, [isOpen]);

  // Early return if not open - AFTER all hooks are called
  if (!isOpen) return null;
  
  // Clean up function to reset all state
  const resetComponentState = () => {
    setPhase('options');
    setSelectedOption(null);
    setShowRecorder(false);
    setTranscription('');
    setIsProcessing(false);
    setError(null);
    setMemoryData(null);
  };
  
  // Handle option selection
  const handleOptionSelect = (option: IntentType) => {
    setSelectedOption(option);
    setPhase('recording');
    setShowRecorder(true);
  };
  
  // Handle recorder close
  const handleRecorderClose = () => {
    resetComponentState();
    onClose();
  };
  
  // Handle audio recording completion
  const handleRecordingComplete = async (audioBlob: Blob) => {
    if (!audioBlob || audioBlob.size === 0) {
      console.error('Received empty or invalid audio blob');
      setError('No audio data was recorded. Please try again.');
      setShowRecorder(false);
      setPhase('options');
      return;
    }
    
    if (!selectedOption) {
      setError('No option selected');
      setShowRecorder(false);
      setPhase('options');
      return;
    }
    
    // Start processing
    setShowRecorder(false);
    setPhase('processing');
    setIsProcessing(true);
    
    try {
      // Detect device type
      const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent);
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      
      // Create a properly typed blob for device compatibility
      let processedBlob = audioBlob;
      let audioType = audioBlob.type;
      
      // If blob has no type or is iOS, we need to ensure it has the right format
      if (!audioType || audioType === '') {
        // Default MIME types based on device
        audioType = isIOS ? 'audio/mp4' : (isMobile ? 'audio/webm' : 'audio/webm;codecs=opus');
        processedBlob = new Blob([audioBlob], { type: audioType });
        console.log(`Applied MIME type ${audioType} to untyped audio blob`);
      }
      
      // For iOS specifically, ensure we're using a compatible format
      if (isIOS && !audioType.includes('mp4') && !audioType.includes('m4a') && !audioType.includes('aac')) {
        audioType = 'audio/mp4';
        processedBlob = new Blob([audioBlob], { type: audioType });
        console.log(`Repackaged iOS audio to ${audioType} for better compatibility`);
      }

      // Ensure Android mobile devices use a properly typed format that Whisper accepts
      if (isMobile && !isIOS) {
        // Android devices should use webm or mp3 formats for best compatibility
        if (!audioType.includes('webm') && !audioType.includes('mp3')) {
          // Prefer webm for Whisper API
          audioType = 'audio/webm';
          processedBlob = new Blob([audioBlob], { type: audioType });
          console.log(`Repackaged Android audio to ${audioType} for better Whisper compatibility`);
        }
      }

      // Whisper API prefers these formats for best results (in order of preference)
      const preferredWhisperFormats = ['audio/webm', 'audio/mp3', 'audio/mp4', 'audio/m4a', 'audio/wav'];
      const isPreferredFormat = preferredWhisperFormats.some(format => audioType.includes(format.split('/')[1]));
      
      if (!isPreferredFormat) {
        // If not a preferred format, use a safe default based on device
        const safeFormat = isIOS ? 'audio/mp4' : 'audio/webm';
        processedBlob = new Blob([audioBlob], { type: safeFormat });
        console.log(`Converting to Whisper-preferred format: ${safeFormat}`);
        audioType = safeFormat;
      }

      // Log details about the audio we're about to transcribe
      console.log('Processing audio recording:', { 
        originalType: audioBlob.type || 'none',
        processedType: processedBlob.type,
        size: processedBlob.size,
        sizeInMB: (processedBlob.size / (1024 * 1024)).toFixed(2) + ' MB',
        isIOS,
        isMobile
      });
      
      // Sanity check for audio size
      if (processedBlob.size < 1000) {
        console.warn('Audio blob is suspiciously small:', processedBlob.size, 'bytes');
      }
      
      // Create form data for the audio
      const formData = new FormData();
      
      // Generate a unique session ID with a device identifier
      const devicePrefix = isIOS ? 'ios' : (isMobile ? 'mobile' : 'desktop');
      const sessionId = `${devicePrefix}-${Date.now()}`;
      
      // Add a file extension to help the server identify the format
      let extension = '.webm';
      if (audioType.includes('mp4') || audioType.includes('m4a')) {
        extension = '.m4a';
      } else if (audioType.includes('wav')) {
        extension = '.wav';
      } else if (audioType.includes('aac')) {
        extension = '.aac';
      } else if (audioType.includes('mp3')) {
        extension = '.mp3';
      }
      
      const filename = `recording-${sessionId}${extension}`;
      console.log(`Using filename ${filename} for transcription`);
      
      // Add file to form with proper filename
      formData.append('audio', processedBlob, filename);
      
      // Add metadata to help server-side processing
      formData.append('sessionId', sessionId);
      formData.append('chunkIndex', '0');
      formData.append('totalChunks', '1');
      formData.append('deviceInfo', isIOS ? 'iOS' : (isMobile ? 'mobile' : 'desktop'));
      formData.append('mimeType', processedBlob.type);
      formData.append('fileSize', processedBlob.size.toString());
      
      // Increase timeout for larger files - use 45 seconds base plus 15 seconds per MB
      const fileSize = processedBlob.size / (1024 * 1024); // Size in MB
      const timeout = Math.max(45000, 45000 + (fileSize * 15000));
      
      console.log(`Setting transcription timeout to ${Math.round(timeout/1000)} seconds for ${fileSize.toFixed(2)}MB file`);
      
      // Clear transcription timeout
      const timeoutId = setTimeout(() => {
        console.error(`Transcription timed out after ${Math.round(timeout/1000)} seconds`);
        setError('Transcription is taking longer than expected. Please try again with a shorter recording.');
        setIsProcessing(false);
        setPhase('options');
      }, timeout);
      
      // Send audio for transcription as a single complete file
      console.log('Sending audio for transcription...');
      const response = await fetch('/api/transcribe', {
        method: 'POST',
        body: formData
      });
      
      // Clear timeout since we got a response
      clearTimeout(timeoutId);
      
      if (!response.ok) {
        let errorText = '';
        try {
          errorText = await response.text();
        } catch (e) {
          errorText = `Status ${response.status} ${response.statusText}`;
        }
        
        console.error('Transcription failed:', {
          status: response.status,
          statusText: response.statusText,
          error: errorText,
          url: response.url
        });
        
        // Provide a more helpful error message based on the status code
        let userErrorMessage = 'Transcription failed. ';
        
        if (response.status === 413) {
          userErrorMessage += 'Your recording was too large. Please try a shorter recording.';
        } else if (response.status === 415) {
          userErrorMessage += 'Audio format not supported. Please try again.';
        } else if (response.status >= 500) {
          userErrorMessage += 'Server error. Please try again later.';
        } else {
          userErrorMessage += errorText || 'Unknown error.';
        }
        
        throw new Error(userErrorMessage);
      }
      
      // Get transcription data
      let data;
      try {
        data = await response.json();
      } catch (e) {
        console.error('Failed to parse transcription response:', e);
        throw new Error('Invalid response from transcription service. Please try again.');
      }
      
      const transcriptionText = data.transcription;
      
      if (!transcriptionText) {
        throw new Error('No transcription returned from API');
      }
      
      console.log('Transcription received:', { 
        length: transcriptionText.length,
        excerpt: transcriptionText.substring(0, 50) + (transcriptionText.length > 50 ? '...' : '')
      });
      
      setTranscription(transcriptionText);
      
      // Only after we have a successful transcription, process the intent
      try {
        // Parse the transcription based on selected intent
        const parsedIntent = await parseTranscription(
          transcriptionText,
          selectedOption
        );
        
        // Check if this should be saved as a memory
        if (shouldSaveAsMemory(parsedIntent.intent, parsedIntent.processedText)) {
          // Create memory object
          const memory = createMemoryEntry(
            userId || 'anonymous',
            parsedIntent.intent,
            parsedIntent.processedText,
            parsedIntent.keywords
          );
          
          // Save memory to database
          const saveResponse = await fetch('/api/memories', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(memory)
          });
          
          if (!saveResponse.ok) {
            // Log error but continue - transcription still usable
            console.error('Failed to save memory:', await saveResponse.text());
          } else {
            // Set memory data for confirmation
            const responseData = await saveResponse.json();
            setMemoryData({
              ...memory,
              ...responseData
            });
            
            // Call callback if provided
            if (onMemoryCreated) {
              onMemoryCreated(memory);
            }
          }
        }
      } catch (intentError) {
        // Log intent processing error but don't fail the entire operation
        console.error('Error processing intent:', intentError);
        // The user can still see and use the transcription
      }
      
      // Update phase to confirmation - regardless of intent processing success
      setPhase('confirmation');
    } catch (err) {
      console.error('Error processing recording:', err);
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
      setPhase('options');
    } finally {
      setIsProcessing(false);
    }
  };
  
  // Handle final completion and close
  const handleComplete = () => {
    resetComponentState();
    onClose();
  };
  
  // Render option selection phase with a horizontal row layout
  if (phase === 'options') {
    return (
      <div className="enhanced-recorder-overlay">
        <div className="options-horizontal-container">
          <button 
            className="option-card"
            onClick={() => handleOptionSelect('want-to-know')}
            aria-label="I want you to know"
          >
            <div className="option-icon">💭</div>
            <h3>I want you to know...</h3>
          </button>
          
          <button 
            className="option-card"
            onClick={() => handleOptionSelect('looking-for')}
            aria-label="I'm looking for"
          >
            <div className="option-icon">🔍</div>
            <h3>I'm looking for...</h3>
          </button>
          
          <button 
            className="option-card"
            onClick={() => handleOptionSelect('something-else')}
            aria-label="Something else"
          >
            <div className="option-icon">✨</div>
            <h3>Something else</h3>
          </button>
          
          <button 
            className="close-overlay-button floating"
            onClick={onClose}
            aria-label="Close"
          >
            <span>×</span>
          </button>
        </div>
      </div>
    );
  }
  
  // Update the recording phase to use our own custom UI instead of ImmersiveAudioRecorder
  if (phase === 'recording') {
    return (
      <div className="enhanced-recorder-overlay recording-phase">
        <button 
          className="close-overlay-button recording-close"
          onClick={() => {
            resetComponentState();
            onClose();
          }}
          aria-label="Cancel recording"
        >
          <span>×</span>
        </button>
        
        <ImmersiveAudioRecorder 
          isOpen={true}
          onClose={() => {
            resetComponentState();
            onClose();
          }}
          onRecordingComplete={handleRecordingComplete}
          className="enhanced-recorder-integration"
        />
        
        {selectedOption && (
          <div className="recording-type-indicator">
            {selectedOption === 'want-to-know' && '💭 I want you to know...'}
            {selectedOption === 'looking-for' && '🔍 I\'m looking for...'}
            {selectedOption === 'something-else' && '✨ Something else...'}
          </div>
        )}
      </div>
    );
  }
  
  // Show processing state with a minimal, sleek design
  if (phase === 'processing') {
    return (
      <div className="enhanced-recorder-overlay">
        <div className="modal-floating-card">
          <div className="processing-content">
            <div className="processing-spinner"></div>
            <h3>Processing your audio</h3>
          </div>
        </div>
      </div>
    );
  }
  
  // Show confirmation phase with the same sleek design
  if (phase === 'confirmation') {
    return (
      <div className="enhanced-recorder-overlay">
        <div className="modal-floating-card confirmation-card">
          <div className="recorder-header">
            <h2>Is this what you meant?</h2>
            <button 
              className="close-overlay-button small"
              onClick={handleComplete}
              aria-label="Close"
            >
              <span>×</span>
            </button>
          </div>
          
          <div className="confirmation-content">
            <div className="memory-preview">
              <div className="memory-type">
                {selectedOption === 'want-to-know' && '💭 I want you to know'}
                {selectedOption === 'looking-for' && '🔍 I\'m looking for'}
                {selectedOption === 'something-else' && '✨ Something else'}
              </div>
              
              <div className="memory-text">
                {transcription}
              </div>
            </div>
            
            <div className="confirmation-actions">
              <button 
                className="action-button confirm"
                onClick={handleComplete}
              >
                Yes, save this
              </button>
              
              <button 
                className="action-button retry"
                onClick={() => {
                  resetComponentState();
                  setPhase('options');
                }}
              >
                No, try again
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  // Show error state with minimal design
  if (error) {
    return (
      <div className="enhanced-recorder-overlay">
        <div className="modal-floating-card error-card">
          <div className="recorder-header">
            <h2>Error</h2>
            <button 
              className="close-overlay-button small" 
              onClick={handleComplete}
              aria-label="Close"
            >
              <span>×</span>
            </button>
          </div>
          
          <div className="error-content">
            <div className="error-icon">❌</div>
            <p>{error}</p>
            <button 
              className="action-button retry"
              onClick={() => {
                setError(null);
                setPhase('options');
              }}
            >
              Try Again
            </button>
          </div>
        </div>
      </div>
    );
  }
  
  // Fallback for any other state
  return null;
}; 