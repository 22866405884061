"use client";

import React, { useEffect, useRef, useState, useCallback } from "react";
import { Bubble } from './Bubble';
import { ResourceUploadTooltip } from './ResourceUploadTooltip';
import { useChat } from "ai/react";
import { PromptSuggestionsRow } from "./PromptSuggestions/PromptSuggestionsRow";
import { AudioRecorder } from "./AudioRecorder";
import { ImmersiveAudioRecorder } from "./ImmersiveAudioRecorder";
import CompanionManager, { Companion, defaultCompanions, baseLoobCompanion } from "./CompanionManager";
import "./ChatModal.css";
import { useGlobalState } from "./GlobalStateContext";
import useConfiguration from '../app/hooks/useConfiguration';
import { ResourceUploadModal } from './ResourceUploadModal';
import { parseResourceDescription, inferOfferingType } from '../lib/services/resourceParser';
import { EnhancedImmersiveRecorder } from './EnhancedImmersiveRecorder';
import { GenUICard, CardAction } from './GenUICard';
import LLMResponseFormatter from '../lib/LLMResponseFormatter';
import { useKnowledgeGraph } from '../hooks/useKnowledgeGraph';

interface ChatModalProps {
  onConfigureOpen?: () => void;
  showModal?: () => void;
}

interface ResourceData {
  title: string;
  description: string;
  offeringType: 'venue' | 'gear' | 'talent';
  tags: string[];
  loobricateId?: string;
}

interface ResourceUploadState {
  isInProgress: boolean;
  currentStep: 'type' | 'title' | 'description' | 'tags' | 'loobricates' | 'confirm';
  collectedData: {
    offeringType?: 'venue' | 'gear' | 'talent';
    title?: string;
    description?: string;
    tags?: string[];
    loobricateId?: string;
  };
}

// Interface for custom UI elements in chat messages
interface MessageUIElements {
  type?: string;
  elements?: React.ReactNode[];
  memoryData?: MemoryData;
}

// Extended message interface to include UI elements
interface ExtendedChatMessage {
  role: 'user' | 'assistant' | 'system';
  content: string;
  id?: string;
  ui?: MessageUIElements;
}

// Add this interface after the ExtendedChatMessage interface
interface MemoryData {
  id: string;
  type: 'want-to-know' | 'looking-for' | 'something-else';
  content: string;
  keywords: string[];
  createdAt: string;
}

// Add this CSS to your styles
const typingIndicatorStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  padding: '8px 12px',
  borderRadius: '20px',
  backgroundColor: '#f0f0f0',
  width: 'fit-content',
  margin: '8px 0',
};

const dotStyles = {
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  backgroundColor: '#666',
  animation: 'typing 1s infinite ease-in-out',
};

const messageBubbleStyles = {
  maxWidth: '80%',
  padding: '8px 12px',
  borderRadius: '20px',
  margin: '4px 0',
  wordBreak: 'break-word' as const,
  animation: 'fadeIn 0.3s ease-in-out',
  backdropFilter: 'blur(8px)',
  WebkitBackdropFilter: 'blur(8px)',
};

const userMessageStyles = {
  ...messageBubbleStyles,
  background: 'linear-gradient(135deg, rgba(255, 170, 180, 0.85), rgba(255, 190, 190, 0.85))',
  color: '#1a1a1a',
  marginLeft: 'auto',
  borderBottomRightRadius: '4px',
  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.07)',
};

const assistantMessageStyles = {
  ...messageBubbleStyles,
  backgroundColor: 'rgba(42, 43, 54, 0.8)',
  color: '#ffffff',
  marginRight: 'auto',
  borderBottomLeftRadius: '4px',
  border: '1px solid rgba(255, 255, 255, 0.1)',
};

const TypingIndicator = () => (
  <div className="flex items-center space-x-2 p-4 bg-gray-100/5 backdrop-blur-sm rounded-lg mx-4 mb-2">
    <div className="flex space-x-2">
      <div className="w-2.5 h-2.5 bg-pink-300/70 rounded-full animate-bounce" style={{ animationDelay: '0ms' }} />
      <div className="w-2.5 h-2.5 bg-pink-300/70 rounded-full animate-bounce" style={{ animationDelay: '150ms' }} />
      <div className="w-2.5 h-2.5 bg-pink-300/70 rounded-full animate-bounce" style={{ animationDelay: '300ms' }} />
    </div>
  </div>
);

const getIntroMessage = (activeCompanion: Companion | null, isAnonymous: boolean, pseudonym: string = '') => {
  // Check if this is a returning user with localStorage
  let isReturningUser = false;
  
  try {
    isReturningUser = localStorage.getItem('loob_companion_selected') === 'true';
  } catch (error) {
    console.error("Error reading from localStorage:", error);
    // Fallback to false if localStorage fails
  }
  
  // Add debug logging
  console.log("getIntroMessage called with:", { 
    companion: activeCompanion?.id, 
    isAnonymous, 
    pseudonym, 
    isReturningUser 
  });
  
  // Use the pseudonym provided as prop, with empty string fallback
  const username = pseudonym || '';
  const userGreeting = username ? `Hey ${username}! ` : 'Hey! ';
  
  // If no active companion, return empty string
  if (!activeCompanion) {
    console.log("No active companion found, returning empty intro");
    return '';
  }
  
  // First-time user messages
  if (!isReturningUser) {
    switch (activeCompanion.id) {
      case 'logis':
        return `${userGreeting}I'm Logis. Need help with planning stuff? I'm here to assist with event logistics and resource tracking.`;
      case 'harmoni':
        return `${userGreeting}Harmoni here. Ready to explore vibes and curate experiences together?`;
      case 'nexus':
        return `${userGreeting}Nexus here. Let's connect resources and build something amazing!`;
      case 'ash':
        return `${userGreeting}I'm Ash. Ready to dive into burn culture and festival planning?`;
      default:
        return `${userGreeting}I'm Loob, your guide to the LOOBRARY. What would you like to explore?`;
    }
  } 
  // Returning user messages - construct the greeting first
  else {
    switch (activeCompanion.id) {
      case 'logis':
        return `${userGreeting}Welcome back! Ready to work on some logistics?`;
      case 'harmoni':
        return `${userGreeting}Great to see you again! What vibes are we exploring today?`;
      case 'nexus':
        return `${userGreeting}Back for more connections! Let's make some magic happen!`;
      case 'ash':
        return `${userGreeting}Welcome back to the dust! What's on your mind?`;
      default:
        return `${userGreeting}Welcome back to LOOB! How can I assist you today?`;
    }
  }
};

// Helper function to find a companion by ID
const findCompanionById = (id: string | null | Companion): Companion | null => {
  // If already a companion object, return it directly
  if (id && typeof id === 'object' && 'id' in id) {
    return id as Companion;
  }
  
  // Handle string ID cases
  const stringId = id as string;
  if (!stringId) return null;
  
  // Check if it's the base Loob
  if (stringId === 'base-loob' || stringId === 'loob') {
    return baseLoobCompanion;
  }
  
  // Check in default companions
  return defaultCompanions.find(c => c.id === stringId) || null;
};

export const ChatModal = ({ onConfigureOpen, showModal }: ChatModalProps) => {
  const { 
    activeLoobricate, 
    userId, 
    activeCompanion: activeCompanionId, 
    setActiveCompanion, 
    isAnonymous, 
    pseudonym, 
    email, 
    phone, 
    connectedLoobricates,
    user
  } = useGlobalState();
  
  // Add logging to debug auth state
  useEffect(() => {
    console.log("Auth state in ChatModal:", {
      userId,
      pseudonym,
      isAnonymous,
      activeCompanionId
    });
  }, [userId, pseudonym, isAnonymous, activeCompanionId]);
  
  // Resolve the companion object from the ID
  const [resolvedCompanion, setResolvedCompanion] = useState<Companion | null>(
    findCompanionById(activeCompanionId) || baseLoobCompanion // Default to baseLoobCompanion if none found
  );
  
  // Update resolved companion when ID changes
  useEffect(() => {
    // Always provide a default companion (base Loob) if none is found
    const foundCompanion = findCompanionById(activeCompanionId);
    setResolvedCompanion(foundCompanion || baseLoobCompanion);
  }, [activeCompanionId]);
  
  const { llm } = useConfiguration();
  const [showCompanionManager, setShowCompanionManager] = useState(false);
  const [showResourceTooltip, setShowResourceTooltip] = useState(false);
  const [showResourceModal, setShowResourceModal] = useState(false);
  const [resourceData, setResourceData] = useState<ResourceData | null>(null);
  const [showImmersiveRecorder, setShowImmersiveRecorder] = useState(false);
  const audioButtonRef = useRef<HTMLDivElement>(null);
  
  // Add these new state variables
  const [showEnhancedRecorder, setShowEnhancedRecorder] = useState(false);
  const [memoryCreated, setMemoryCreated] = useState<MemoryData | null>(null);
  
  // Update the useChat implementation with proper types and configuration
  const { 
    append: originalAppend, 
    messages: originalMessages, 
    input, 
    handleInputChange, 
    handleSubmit, 
    isLoading, 
    setMessages,
    error
  } = useChat({
    api: '/api/chat',
    body: {
      userId: userId || 'anonymous',
      connectedLoobricates: activeLoobricate ? [activeLoobricate.id] : [],
      companion: resolvedCompanion,
      contextPath: resolvedCompanion?.contextPath,
      llm: llm
    },
    id: resolvedCompanion?.id,
    onError: (error) => {
      console.error('Chat error:', error);
    },
    onFinish: (message) => {
      console.log('Chat finished:', message);
    },
    onResponse: (response) => {
      console.log('Chat response:', response);
    }
  });
  
  // Create a typed wrapper for append function
  const append = useCallback((message: ExtendedChatMessage) => {
    originalAppend(message);
  }, [originalAppend]);
  
  // Cast messages to our extended type
  const messages = originalMessages as ExtendedChatMessage[];
  
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const latestMessageRef = useRef<HTMLDivElement>(null);
  const [showIntroMessage, setShowIntroMessage] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [resourceUpload, setResourceUpload] = useState<ResourceUploadState>({
    isInProgress: false,
    currentStep: 'type',
    collectedData: {}
  });

  const [retryTranscription, setRetryTranscription] = useState<{
    messageId: string;
    retry: () => void;
  } | null>(null);

  const { isReady: isKgReady, addMemory, queryMemories, getInsights } = useKnowledgeGraph();
  
  // Use a more efficient scrollToBottom implementation
  const scrollToBottom = useCallback(() => {
    if (messagesContainerRef.current) {
      const container = messagesContainerRef.current;
      // Use requestAnimationFrame for smoother scrolling
      requestAnimationFrame(() => {
        // Use a smoother scroll with behavior: smooth for a better UX
        container.scrollTo({
          top: container.scrollHeight,
          behavior: 'smooth'
        });
        setShowScrollButton(false);
      });
    }
  }, []);

  // Now place the viewport handling effect after scrollToBottom is defined
  useEffect(() => {
    // Function to update CSS variables for viewport height
    const updateViewportHeight = () => {
      // Set a CSS variable for viewport height that works across devices
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // Function to handle virtual keyboard visibility
    const handleVisualViewportResize = () => {
      // When virtual keyboard appears, adjust the container height
      const visibleHeight = window.visualViewport?.height || window.innerHeight;
      document.documentElement.style.setProperty('--visible-height', `${visibleHeight}px`);
      
      // Calculate keyboard height (if visible)
      const keyboardHeight = window.innerHeight - visibleHeight;
      const isKeyboardVisible = keyboardHeight > 100; // Threshold to detect keyboard
      
      // Apply the adjustment to the chat container
      const container = document.querySelector('.chat-modal-container');
      if (container) {
        container.setAttribute('style', `height: ${visibleHeight}px; overflow: hidden;`);
      }
      
      // Adjust the message container to make room for input
      const messagesContainer = document.querySelector('.native-scroll-container');
      if (messagesContainer && isKeyboardVisible) {
        // When keyboard is visible, ensure messages have proper bottom margin
        messagesContainer.setAttribute('style', `max-height: calc(${visibleHeight}px - 100px);`);
      } else if (messagesContainer) {
        messagesContainer.setAttribute('style', '');
      }
      
      // Handle prompt suggestions visibility when keyboard is visible
      const promptContainer = document.querySelector('.prompt-suggestions-container');
      if (promptContainer) {
        if (isKeyboardVisible && visibleHeight < 500) {
          // Hide prompt suggestions when keyboard is visible and space is limited
          promptContainer.setAttribute('style', 'display: none;');
        } else {
          promptContainer.setAttribute('style', '');
        }
      }
      
      // Ensure input container stays visible and properly positioned above keyboard
      const inputContainer = document.querySelector('.chatbot-input-container');
      if (inputContainer) {
        if (isKeyboardVisible) {
          inputContainer.setAttribute('style', 
            `position: sticky; bottom: 0; z-index: 50; backdrop-filter: blur(8px); 
             border-top: 1px solid rgba(255, 255, 255, 0.05);`);
        } else {
          inputContainer.setAttribute('style', '');
        }
      }
      
      // Ensure mobile controls wrapper stays properly positioned
      const controlsWrapper = document.querySelector('.mobile-controls-wrapper');
      if (controlsWrapper) {
        if (isKeyboardVisible) {
          controlsWrapper.setAttribute('style', 
            `position: sticky; bottom: 0; left: 0; right: 0; z-index: 40; 
             padding-top: ${isKeyboardVisible && visibleHeight < 500 ? '0.25rem' : '0.5rem'};`);
        } else {
          controlsWrapper.setAttribute('style', '');
        }
      }
      
      // Adjust the audio button size on smaller screens
      const audioButton = document.querySelector('.audio-recorder-button-inline');
      if (audioButton && isKeyboardVisible && visibleHeight < 400) {
        audioButton.setAttribute('style', 'transform: scale(0.9);');
      } else if (audioButton) {
        audioButton.setAttribute('style', '');
      }
      
      // Scroll to bottom with a slight delay to ensure layout is complete
      setTimeout(scrollToBottom, 150);
    };

    // Initial call
    updateViewportHeight();

    // Add event listeners
    window.addEventListener('resize', updateViewportHeight);
    window.addEventListener('orientationchange', updateViewportHeight);
    window.visualViewport?.addEventListener('resize', handleVisualViewportResize);

    // Add classes to prevent unwanted scrolling
    document.body.classList.add('in-chat-view');
    
    const appLayout = document.querySelector('.app-layout');
    if (appLayout) {
      appLayout.classList.add('chat-active');
    }

    // Clean up function
    return () => {
      document.body.classList.remove('in-chat-view');
      
      if (appLayout) {
        appLayout.classList.remove('chat-active');
      }
      
      window.removeEventListener('resize', updateViewportHeight);
      window.removeEventListener('orientationchange', updateViewportHeight);
      window.visualViewport?.removeEventListener('resize', handleVisualViewportResize);
    };
  }, [scrollToBottom]);

  // Additional effect to ensure proper focus management for screen readers
  useEffect(() => {
    // When new messages arrive, ensure the latest message is announced for screen readers
    if (messages.length > 0 && latestMessageRef.current) {
      // Optional: add aria-live attributes dynamically for screen reader announcements
      latestMessageRef.current.setAttribute('aria-live', 'polite');
    }
  }, [messages]);

  // Clear messages when companion changes
  useEffect(() => {
    if (resolvedCompanion?.id) {
      console.log(`Companion changed to ${resolvedCompanion.id}, clearing messages`);
      setMessages([]);
      setShowIntroMessage(true);
    }
  }, [resolvedCompanion?.id, setMessages]);

  // Update vibe entity when messages change and we have a selected loobricate
  useEffect(() => {
    // Temporarily disabled vibe entity updates
    // if (!activeLoobricate || messages.length === 0) return;
    // 
    // const lastMessage = messages[messages.length - 1];
    // if (lastMessage.role === 'assistant') {
    //   updateVibeEntity(activeLoobricate.id, lastMessage.content);
    // }
  }, [messages, activeLoobricate]);

  const updateVibeEntity = async (loobricate_id: string, message: string) => {
    // Temporarily disabled
    return;
    // try {
    //   const response = await fetch('/api/vibe_entities', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({
    //       id: loobricate_id,
    //       state: {
    //         message,
    //         timestamp: new Date().toISOString(),
    //         mood: 'neutral',
    //         sentiment: 0,
    //         intensity: 0.5
    //       }
    //     }),
    //   });
    //   
    //   if (!response.ok) throw new Error('Failed to update vibe entity');
    // } catch (error) {
    //   console.error('Error updating vibe entity:', error);
    // }
  };

  // Use a memoized version of handleScroll
  const handleScroll = useCallback(() => {
    if (messagesContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;
      // Show scroll button only when user has scrolled up significantly
      const isScrolledUp = scrollHeight - scrollTop - clientHeight > 150;
      setShowScrollButton(isScrolledUp);
    }
  }, []);

  // Add a more efficient debounced scroll handler
  const debouncedScroll = useCallback(
    (() => {
      let timeout: NodeJS.Timeout;
      return () => {
        clearTimeout(timeout);
        timeout = setTimeout(handleScroll, 100);
      };
    })(),
    [handleScroll]
  );

  useEffect(() => {
    if (messages.length > 0) {
      setTimeout(scrollToBottom, 100);
    }
  }, [messages, scrollToBottom]);

  useEffect(() => {
    if (isProcessing) {
      setTimeout(scrollToBottom, 100);
    }
  }, [isProcessing, scrollToBottom]);

  useEffect(() => {
    if (isProcessing) {
      document.body.style.cursor = 'wait';
    } else {
      document.body.style.cursor = 'default';
    }
    return () => {
      document.body.style.cursor = 'default';
    };
  }, [isProcessing]);

  useEffect(() => {
    const container = messagesContainerRef.current;
    if (container) {
      // Use the debounced scroll handler for better performance
      container.addEventListener('scroll', debouncedScroll);
      return () => container.removeEventListener('scroll', debouncedScroll);
    }
  }, [debouncedScroll]);

  // Add a function to prevent scroll propagation
  const preventScrollPropagation = useCallback((e: React.TouchEvent) => {
    // Check if we're at the top or bottom boundary
    const container = messagesContainerRef.current;
    if (!container) return;

    const { scrollTop, scrollHeight, clientHeight } = container;
    const isAtTop = scrollTop <= 0;
    const isAtBottom = scrollTop + clientHeight >= scrollHeight;

    // If we're at a boundary and trying to scroll further in that direction, prevent it
    if ((isAtTop && e.touches[0].clientY > 0) || 
        (isAtBottom && e.touches[0].clientY < 0)) {
      e.stopPropagation();
    }
  }, []);

  // Monitor chat messages for resource upload trigger
  useEffect(() => {
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      const isBaseLoob = !resolvedCompanion || resolvedCompanion.isBaseCompanion;
      if (
        isBaseLoob &&
        lastMessage.role === 'user' &&
        (lastMessage.content.toLowerCase().includes('upload resource') ||
         lastMessage.content.toLowerCase().includes('add resource') ||
         lastMessage.content.toLowerCase().includes('share resource'))
      ) {
        setShowResourceTooltip(true);
      }
    }
  }, [messages, resolvedCompanion]);

  const inputDisabled = isProcessing || isRecording;

  const handleSend = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!input.trim()) return;
    
    handleSubmit(e);
    handleInputChange({ target: { value: "" } } as React.ChangeEvent<HTMLInputElement>);
    setShowIntroMessage(false);
    setTimeout(scrollToBottom, 100);
  };

  // Enhanced function to get contextual memories for the AI
  const getContextualMemories = async (query: string): Promise<string> => {
    try {
      if (!isKgReady || !user?.userId) {
        // Fallback to existing memory retrieval logic
        return '';
      }
      
      // Query the knowledge graph for relevant memories
      const relevantMemories = await queryMemories(query, 5);
      
      if (!relevantMemories || relevantMemories.length === 0) {
        return '';
      }
      
      // Format memories for the AI context
      const formattedMemories = relevantMemories
        .map((memory: any) => {
          const date = new Date(memory.timestamp).toLocaleString();
          return `[Memory from ${date}]: ${memory.content}`;
        })
        .join('\n\n');
      
      return `
Here are some relevant memories that might help with your response:

${formattedMemories}
`;
    } catch (error) {
      console.error('Error retrieving contextual memories:', error);
      return '';
    }
  };
  
  // Enhanced function to generate insights from the knowledge graph
  const generateMemoryInsights = async (context: string): Promise<string> => {
    try {
      if (!isKgReady || !user?.userId) {
        return '';
      }
      
      const insights = await getInsights(context);
      
      if (!insights || insights.length === 0) {
        return '';
      }
      
      return `
Based on the user's memory graph, here are some insights that might be relevant:

${insights.join('\n')}
`;
    } catch (error) {
      console.error('Error generating memory insights:', error);
      return '';
    }
  };
  
  // Update the handlePrompt function to include knowledge graph context
  const handlePrompt = async (text: string) => {
    setShowIntroMessage(false);
      
    // Copy current messages including the new user message
    const updatedMessages = [...messages, { role: 'user', content: text, id: Date.now().toString() }];

    // Add the user's message
    append({
      role: 'user',
      content: text,
    });

    // Add the typing indicator
    setIsProcessing(true);
    setTimeout(scrollToBottom, 100);

    try {
      // Get contextual memories from knowledge graph
      let memoryContext = '';
      let insightsContext = '';
      
      if (isKgReady) {
        memoryContext = await getContextualMemories(text);
        insightsContext = await generateMemoryInsights(text);
      }
      
      // Add contextual information to the prompt if available
      let messagesWithContext = [...updatedMessages];
      
      if (memoryContext) {
        // Add memory context to the system message instead of the user prompt
        // This keeps the original user message clean
        const systemMessageWithContext = {
          role: 'system' as const,
          content: `${memoryContext}\n\n${insightsContext}\n\nPlease use this contextual information when relevant to your response.`,
          id: `system-context-${Date.now()}`
        };
        
        // Add the system message with context before the user message
        messagesWithContext.splice(-1, 0, systemMessageWithContext);
      }
      
      // Continue with the original API call logic using messagesWithContext
      // You may need to modify your existing API call to use messagesWithContext
    } catch (error) {
      console.error("Error in chat:", error);
      if (error instanceof Error) {
        // Handle error appropriately based on your existing error handling
        console.error("Chat error:", error.message);
      }
      setIsProcessing(false);
    }
  };

  const handleResourceUploadFlow = async (message: string) => {
    // Only allow resource upload flow for base Loob and logged-in users
    const isBaseLoob = !resolvedCompanion || resolvedCompanion.isBaseCompanion;
    if (!isBaseLoob || isAnonymous) {
      return false;
    }

    if (!resourceUpload.isInProgress) {
      // Only start flow if we're already in a resource context
      if (message.toLowerCase().includes('venue') || 
          message.toLowerCase().includes('gear') || 
          message.toLowerCase().includes('talent')) {
        setResourceUpload({
          isInProgress: true,
          currentStep: 'type',
          collectedData: {}
        });
        append({
          role: 'assistant',
          content: "I'll help you add a resource to LOOBRARY. What type of resource is it?\n\n" +
                  "**A venue or space**\n" +
                  "**Some gear or equipment**\n" +
                  "**A talent or service**"
        });
        return true;
      }
      return false;
    }

    // Handle each step of the flow
    switch (resourceUpload.currentStep) {
      case 'type': {
        let offeringType: 'venue' | 'gear' | 'talent' | undefined;
        const lowerMessage = message.toLowerCase();
        
        if (lowerMessage.includes('venue') || lowerMessage.includes('space')) {
          offeringType = 'venue';
        } else if (lowerMessage.includes('gear') || lowerMessage.includes('equipment')) {
          offeringType = 'gear';
        } else if (lowerMessage.includes('talent') || lowerMessage.includes('service')) {
          offeringType = 'talent';
        }

        if (offeringType) {
          setResourceUpload(prev => ({
            ...prev,
            currentStep: 'title',
            collectedData: { ...prev.collectedData, offeringType }
          }));
          append({
            role: 'assistant',
            content: `Great! What would you like to title this ${offeringType}?`
          });
        } else {
          append({
            role: 'assistant',
            content: "Please choose one of the following:\n\n" +
                    "**A venue or space**\n" +
                    "**Some gear or equipment**\n" +
                    "**A talent or service**"
          });
        }
        return true;
      }

      case 'title': {
        setResourceUpload(prev => ({
          ...prev,
          currentStep: 'description',
          collectedData: { ...prev.collectedData, title: message }
        }));
        append({
          role: 'assistant',
          content: "Great title! Now, please provide a description of your resource. Include any relevant details like capacity, specifications, or special features."
        });
        return true;
      }

      case 'description': {
        // Once we have title and description, show the modal
        const updatedData = {
          ...resourceUpload.collectedData,
          description: message,
          loobricateId: activeLoobricate?.id
        };
        
        // Set the resource data and show modal
        setResourceData({
          title: updatedData.title || '',
          description: updatedData.description || '',
          offeringType: updatedData.offeringType || 'venue',
          tags: [],
          loobricateId: updatedData.loobricateId
        });
        
        setShowResourceModal(true);
        setResourceUpload({
          isInProgress: false,
          currentStep: 'type',
          collectedData: {}
        });
        
        append({
          role: 'assistant',
          content: "Perfect! I've opened the resource form with the information you provided. You can review and edit the details there, add tags, and submit when ready!"
        });
        return true;
      }
    }
    return false;
  };

  const handleResourceSubmit = async (data: ResourceUploadState['collectedData']) => {
    if (!data.loobricateId || !data.title || !data.description || !data.offeringType) {
      throw new Error('Missing required fields');
    }

    const submissionData = {
      dataType: 'userEntry',
      loobricateId: data.loobricateId,
      loobricates: [data.loobricateId],
      title: data.title,
      description: data.description,
      offeringType: data.offeringType,
      tags: data.tags || [],
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      status: 'active',
      photos: [],
      photoUrls: [],
      createdBy: userId,
      creatorPseudonym: pseudonym,
      pseudonym,
      email,
      phone,
      ...(activeLoobricate?.location && {
        location: activeLoobricate.location,
        latitude: activeLoobricate.location.lat,
        longitude: activeLoobricate.location.lng,
      })
    };

    const response = await fetch('/api/loobrary-signup', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(submissionData),
    });

    if (!response.ok) {
      throw new Error('Failed to submit resource');
    }

    setShowResourceModal(false);
    setResourceData(null);
    setResourceUpload({
      isInProgress: false,
      currentStep: 'type',
      collectedData: {}
    });

    // Add success message
    append({
      role: 'assistant',
      content: "✅ Resource successfully added to LOOBRARY! You can view and manage your resources in the **Map** view."
    });
  };

  // Add the MAX_RETRIES constant at the top of the file
  const MAX_RETRIES = 2;
  // Add MAX_CHUNK_SIZE constant
  const MAX_CHUNK_SIZE = 20 * 1024 * 1024; // 20MB chunks (leaving buffer for Whisper API's 25MB limit)

  // Handle audio upload with retry logic
  const handleAudioUpload = async (audioBlob: Blob, retryCount = 0) => {
    setIsProcessing(true);
    
    try {
      const sessionId = `session-${Date.now()}`; // Unique ID for this recording session
      
      // Check if we need to split the audio (for large recordings)
      if (audioBlob.size > MAX_CHUNK_SIZE) {
        console.log(`Large audio detected (${audioBlob.size} bytes), processing in chunks`);
        return await handleLargeAudioUpload(audioBlob, sessionId, retryCount);
      }
      
      // For smaller recordings, process as a single file
      // Create a filename with date and device info
      const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      
      // Get appropriate extension based on mime type
      const extension = audioBlob.type === 'audio/webm' ? 'webm' :
                       audioBlob.type === 'audio/mp4' || audioBlob.type === 'video/mp4' ? 'm4a' :
                       audioBlob.type === 'audio/wav' ? 'wav' :
                       audioBlob.type === 'audio/ogg' ? 'ogg' :
                       isIOS ? 'm4a' : 'webm';
      
      const filename = `audio_${timestamp}.${extension}`;
      
      // Log upload details
      console.log('Uploading audio:', {
        size: audioBlob.size,
        type: audioBlob.type || (isIOS ? 'audio/mp4' : 'audio/webm'),
        filename,
        sessionId
      });
      
      // We won't add a temporary message that needs to be removed to avoid typing issues
      // Just set the processing state and let the UI show progress
      
      const formData = new FormData();
      
      // Ensure the blob has the correct type - create a new blob to enforce this
      const processedBlob = new Blob([audioBlob], { 
        type: audioBlob.type || (isIOS ? 'audio/mp4' : 'audio/webm') 
      });
      
      // Add file and metadata to the form
      formData.append("audio", processedBlob, filename);
      formData.append("sessionId", sessionId);
      formData.append("chunkIndex", "0");
      formData.append("totalChunks", "1");
      formData.append("deviceInfo", isIOS ? 'iOS' : 'desktop');
      formData.append("mimeType", processedBlob.type);
      formData.append("fileSize", processedBlob.size.toString());
      
      // Store the original blob in case we need to retry
      const originalBlob = audioBlob;
      
      // Setup timeout for the fetch operation - client-side timeout
      const fetchTimeoutMs = 45000; // 45 seconds client-side timeout
      
      // Create a controller to abort the fetch if needed
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), fetchTimeoutMs);
      
      try {
        // Make the API request with abort controller
        const response = await fetch("/api/transcribe", {
          method: "POST",
          body: formData,
          signal: controller.signal
        });
      
        // Clear the timeout since we got a response
        clearTimeout(timeoutId);

        // Handle API errors
        if (!response.ok) {
          const errorData = await response.json().catch(() => ({}));
                  
          // Retry logic for server errors
          if ((response.status === 404 || response.status === 500 || response.status === 408) && retryCount < MAX_RETRIES) {
            console.log(`Transcription failed with status ${response.status}, retrying (${retryCount + 1}/${MAX_RETRIES})...`);
            
            // Wait before retrying (longer for each retry)
            await new Promise(resolve => setTimeout(resolve, 1000 * (retryCount + 1)));
            
            // Clear processing state before retry
            setIsProcessing(false);
            
            // Retry with incremented retry count
            return handleAudioUpload(originalBlob, retryCount + 1);
          }
          
          throw new Error(
            errorData.error || 
            errorData.details || 
            `Server responded with ${response.status}`
          );
        }

        // Parse the response
        let data;
        try {
          data = await response.json();
        } catch (e) {
          console.error('Failed to parse response:', e);
          throw new Error('Failed to parse server response');
        }
        
        // Validate the response
        if (!data.transcription) {
          throw new Error('No transcription received from server');
        }
        
        console.log('Transcription successful:', {
          model: data.meta?.model || 'unknown',
          transcriptionLength: data.transcription.length,
          processingTime: data.meta?.processingTimeMs || 'unknown'
        });
        
        // First, add the transcribed text as a user message
        append({
          role: "user",
          content: data.transcription
        });
        
        // Then, let the AI respond to it (this will trigger the chat processing)
        handlePrompt(data.transcription);
      } catch (fetchError: unknown) {
        // Handle AbortController timeout specifically
        if (fetchError && typeof fetchError === 'object' && 'name' in fetchError && fetchError.name === 'AbortError') {
          console.error('Fetch request timed out after', fetchTimeoutMs/1000, 'seconds');
          throw new Error('Transcription request timed out. The server is taking too long to respond.');
        }
        throw fetchError; // Re-throw other fetch errors to be handled by the outer catch
      }
    } catch (error: any) {
      console.error("Error uploading audio:", error);
      
      // Explicitly check for timeout errors
      const isTimeoutError = error.message.includes('timed out') || 
                           error.name === 'AbortError' ||
                           error.message.includes('408');
      
      // Get a user-friendly error message
      const errorMessage = isTimeoutError ? 
        "The transcription is taking longer than expected. Please try a shorter recording or try again later." :
        getTranscriptionErrorMessage(error.message, retryCount);
      
      // Show error to user - use append to add as an assistant message
      append({
        role: "assistant",
        content: errorMessage
      });
      
      // Offer a manual retry after max retries
      if (retryCount >= MAX_RETRIES && audioBlob) {
        // Add a small delay so the error message appears first
        setTimeout(() => {
          const retryMessageId = `retry-${Date.now()}`;
          
          setRetryTranscription({
            messageId: retryMessageId,
            retry: () => handleAudioUpload(audioBlob, 0)
          });
          
          append({
            role: "assistant",
            content: `Would you like to try transcribing your message again? If so, click the retry button below.\n\n<retry-button id="${retryMessageId}">Retry Transcription</retry-button>`
          });
        }, 500);
      }
    } finally {
      setIsProcessing(false);
    }
  };
  
  // Add a new function to handle resource audio uploads
  const handleResourceAudioUpload = async (audioBlob: Blob, retryCount = 0) => {
    setIsProcessing(true);
    
    try {
      const sessionId = `resource-${Date.now()}`;
      
      // Create a filename with date for resource audio
      const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      
      // Determine the appropriate extension
      const extension = audioBlob.type === 'audio/webm' ? 'webm' :
                        audioBlob.type === 'audio/mp4' || audioBlob.type === 'video/mp4' ? 'm4a' :
                        audioBlob.type === 'audio/wav' ? 'wav' :
                        audioBlob.type === 'audio/ogg' ? 'ogg' :
                        isIOS ? 'm4a' : 'webm';
                        
      const filename = `resource_audio_${timestamp}.${extension}`;
      
      // We won't add a temporary message that needs to be removed to avoid typing issues
      // Just set the processing state and let the UI show progress
      
      // Create FormData for the upload
      const formData = new FormData();
      const processedBlob = new Blob([audioBlob], { 
        type: audioBlob.type || (isIOS ? 'audio/mp4' : 'audio/webm') 
      });
      
      // Log upload details
      console.log('Uploading resource audio:', {
        size: processedBlob.size,
        type: processedBlob.type,
        filename,
        sessionId
      });
      
      formData.append("audio", processedBlob, filename);
      formData.append("sessionId", sessionId);
      formData.append("isResource", "true"); // Flag that this is for resource description
      formData.append("deviceInfo", isIOS ? 'iOS' : 'desktop');
      formData.append("mimeType", processedBlob.type);
      formData.append("fileSize", processedBlob.size.toString());
      formData.append("chunkIndex", "0");
      formData.append("totalChunks", "1");
      
      // Store the original blob in case we need to retry
      const originalBlob = audioBlob;
      
      // Setup timeout for the fetch operation
      const fetchTimeoutMs = 45000; // 45 seconds client-side timeout
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), fetchTimeoutMs);
      
      try {
        // Upload the audio for transcription with abort signal
        const response = await fetch("/api/transcribe", {
          method: "POST",
          body: formData,
          signal: controller.signal
        });
        
        // Clear timeout since we got a response
        clearTimeout(timeoutId);
        
        if (!response.ok) {
          const errorData = await response.json().catch(() => ({}));
          
          // Handle retries for resource audio
          if ((response.status === 404 || response.status === 500 || response.status === 408) && retryCount < MAX_RETRIES) {
            console.log(`Resource transcription failed with status ${response.status}, retrying (${retryCount + 1}/${MAX_RETRIES})...`);
            
            // Wait before retry
            await new Promise(resolve => setTimeout(resolve, 1000 * (retryCount + 1)));
            setIsProcessing(false);
            
            // Retry with incremented count
            return handleResourceAudioUpload(originalBlob, retryCount + 1);
          }
          
          throw new Error(errorData.error || errorData.details || `Server responded with ${response.status}`);
        }
        
        // Parse the transcription
        const data = await response.json();
        
        if (!data.transcription) {
          throw new Error('No transcription received from server for resource description');
        }
        
        console.log('Resource transcription successful:', {
          model: data.meta?.model || 'unknown',
          transcriptionLength: data.transcription.length,
          processingTime: data.meta?.processingTimeMs || 'unknown'
        });
        
        // Start the resource upload flow with the transcribed content
        await handleResourceUploadFlow(data.transcription);
      } catch (fetchError: unknown) {
        // Handle AbortController timeout specifically
        if (fetchError && typeof fetchError === 'object' && 'name' in fetchError && fetchError.name === 'AbortError') {
          console.error('Fetch request timed out after', fetchTimeoutMs/1000, 'seconds');
          throw new Error('Resource transcription request timed out. Please try a shorter recording.');
        }
        throw fetchError; // Re-throw other fetch errors
      }
    } catch (error: any) {
      console.error("Error processing resource audio:", error);
      
      // Check for timeout errors
      const isTimeoutError = error.message.includes('timed out') || 
                           error.name === 'AbortError' ||
                           error.message.includes('408');
      
      // Get a user-friendly error message
      const errorMessage = isTimeoutError ? 
        "The resource transcription is taking longer than expected. Please try a shorter recording or try again later." :
        getTranscriptionErrorMessage(error.message, retryCount);
      
      // Show error to user
      append({
        role: "assistant",
        content: errorMessage
      });
      
      // Offer a manual retry after max retries
      if (retryCount >= MAX_RETRIES && audioBlob) {
        setTimeout(() => {
          const retryMessageId = `retry-resource-${Date.now()}`;
          
          setRetryTranscription({
            messageId: retryMessageId,
            retry: () => handleResourceAudioUpload(audioBlob, 0)
          });
          
          append({
            role: "assistant",
            content: `Would you like to try transcribing your resource description again? If so, click the retry button below.\n\n<retry-button id="${retryMessageId}">Retry Resource Transcription</retry-button>`
          });
        }, 500);
      }
    } finally {
      setIsProcessing(false);
    }
  };
  
  // Helper function to handle large audio uploads by splitting into chunks
  const handleLargeAudioUpload = async (audioBlob: Blob, sessionId: string, retryCount = 0) => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    
    try {
      // Calculate how many chunks we need
      const totalChunks = Math.ceil(audioBlob.size / MAX_CHUNK_SIZE);
      console.log(`Splitting large audio (${audioBlob.size} bytes) into ${totalChunks} chunks`);
      
      // Process each chunk sequentially
      let fullTranscription = '';
      
      for (let i = 0; i < totalChunks; i++) {
        // Extract the chunk from the original blob
        const start = i * MAX_CHUNK_SIZE;
        const end = Math.min(start + MAX_CHUNK_SIZE, audioBlob.size);
        const chunkBlob = audioBlob.slice(start, end, audioBlob.type || (isIOS ? 'audio/mp4' : 'audio/webm'));
        
        console.log(`Processing chunk ${i+1}/${totalChunks} (${chunkBlob.size} bytes)`);
        
        // Create form data for this chunk
        const formData = new FormData();
        const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
        const extension = audioBlob.type === 'audio/webm' ? 'webm' :
                         audioBlob.type === 'audio/mp4' || audioBlob.type === 'video/mp4' ? 'm4a' :
                         audioBlob.type === 'audio/wav' ? 'wav' :
                         audioBlob.type === 'audio/ogg' ? 'ogg' :
                         isIOS ? 'm4a' : 'webm';
        
        const filename = `audio_${timestamp}_chunk${i+1}.${extension}`;
        
        formData.append("audio", chunkBlob, filename);
        formData.append("sessionId", sessionId);
        formData.append("chunkIndex", i.toString());
        formData.append("totalChunks", totalChunks.toString());
        
        // Send this chunk for transcription
        const response = await fetch("/api/transcribe", {
          method: "POST",
          body: formData,
        });
        
        if (!response.ok) {
          const errorData = await response.json().catch(() => ({}));
          throw new Error(
            `Chunk ${i+1}/${totalChunks} failed: ${errorData.error || response.status}`
          );
        }
        
        const data = await response.json();
        
        if (!data.transcription) {
          throw new Error(`No transcription received for chunk ${i+1}/${totalChunks}`);
        }
        
        // Append this chunk's transcription to the full result
        // Add a space between chunks to prevent words from running together
        fullTranscription += (fullTranscription ? ' ' : '') + data.transcription;
        
        console.log(`Chunk ${i+1}/${totalChunks} processed successfully`);
        
        // For the first chunk, show a progress indicator to the user
        if (i === 0 && totalChunks > 1) {
          append({
            role: "assistant",
            content: `Processing your audio (${i+1}/${totalChunks})...`
          });
        }
      }
      
      console.log('Full transcription complete', {
        totalChunks,
        transcriptionLength: fullTranscription.length
      });
      
      // Process the complete transcription
      handlePrompt(fullTranscription);
      
    } catch (error: any) {
      console.error("Error processing large audio:", error);
      
      // Show error and retry option
      const errorMessage = getTranscriptionErrorMessage(
        `Error with large audio: ${error.message}`, 
        retryCount
      );
      
      append({
        role: "assistant",
        content: errorMessage
      });
      
      // Offer manual retry
      if (retryCount < 2) {
        setTimeout(() => {
          const retryMessageId = `retry-${Date.now()}`;
          
          setRetryTranscription({
            messageId: retryMessageId,
            retry: () => handleAudioUpload(audioBlob, retryCount + 1)
          });
          
          append({
            role: "assistant",
            content: `Would you like to try transcribing your message again? This is a large audio file (${Math.round(audioBlob.size/1024/1024)}MB) which may take longer to process.\n\n<retry-button id="${retryMessageId}">Retry Transcription</retry-button>`
          });
        }, 500);
      }
    }
  };

  // Helper to get a user-friendly error message
  const getTranscriptionErrorMessage = (errorMessage: string, retryCount: number): string => {
    // Check for specific error messages and provide user-friendly responses
    
    // Check for quota errors first
    if (errorMessage.includes('quota') || 
        errorMessage.includes('usage limit') || 
        errorMessage.includes('429')) {
      return "The voice transcription service is currently unavailable due to usage limits. Please type your message instead.";
    }
    
    // Audio file issues
    if (errorMessage.includes('No audio file received') || errorMessage.includes('empty file')) {
      return "I couldn't receive your audio recording. Please try again.";
    } 
    
    // Format issues
    else if (errorMessage.includes('format') || errorMessage.includes('codec') || errorMessage.includes('unsupported')) {
      return "There was an issue with the audio format. Please try again with a different recording setting.";
    } 
    
    // Size issues
    else if (errorMessage.includes('too large') || errorMessage.includes('25MB')) {
      return "Your recording is too large for me to process. Please try a shorter message (under 5 minutes).";
    } 
    
    // API errors
    else if (errorMessage.includes('exceeded your quota') || errorMessage.includes('API quota')) {
      return "I've reached my transcription limit for now. Please try typing your message instead.";
    } 
    
    // Auth errors
    else if (errorMessage.includes('API key') || errorMessage.includes('403') || errorMessage.includes('401')) {
      return "I don't have permission to access the transcription service right now. Please try typing your message.";
    } 
    
    // Processing errors
    else if (errorMessage.includes('failed to parse') || errorMessage.includes('processing')) {
      return "There was a problem processing your recording. Please try again with a clearer recording.";
    } 
    
    // Server errors
    else if (errorMessage.includes('500') || errorMessage.includes('502') || errorMessage.includes('503')) {
      return "The transcription service is having issues right now. Please try again in a few moments.";
    }
    
    // iOS-specific errors
    else if (errorMessage.includes('iOS') || errorMessage.includes('Safari')) {
      return "There might be an issue with recording on your iOS device. Please try again using the microphone button.";
    }
    
    // Multiple retry failures
    else if (retryCount >= 2) {
      return "I've tried multiple times but am still having trouble understanding your recording. Would you mind typing your message instead?";
    } 
    
    // Generic fallback
    else {
      return "I'm having trouble processing your voice message. You can try again with a clearer recording or type your message instead.";
    }
  };

  // Function to handle the retry action when clicked
  const handleTranscriptionRetry = (messageId: string) => {
    if (retryTranscription && retryTranscription.messageId === messageId) {
      retryTranscription.retry();
      setRetryTranscription(null);
    }
  };

  // Add this function after handleAudioUpload
  const handleMemoryCreated = async (memory: MemoryData) => {
    try {
      // Store the memory in the regular memory bank
      append({
        role: 'user',
        content: `${memory.type === 'want-to-know' ? 'I want you to know: ' : 
                 memory.type === 'looking-for' ? 'I\'m looking for: ' : 
                 'Something else: '}${memory.content}`
      });
      
      // Also store the memory in the knowledge graph if available
      if (isKgReady) {
        await addMemory(
          memory.content,
          memory.type,
          {
            id: memory.id,
            keywords: memory.keywords,
            createdAt: memory.createdAt,
          }
        );
        console.log('Memory added to knowledge graph:', memory.id);
      }
      
      // Close the recorder
      setShowEnhancedRecorder(false);
      
      // Determine what type of message to add based on memory type
      let assistantMessage = '';
      
      switch (memory.type) {
        case 'want-to-know':
          assistantMessage = `Thanks for letting me know. I've saved this to your memory archive: "${memory.content}"`;
          // Add a GenUI card for the memory later in the renderMessageContent function
          break;
        case 'looking-for':
          assistantMessage = `I'll help you find: "${memory.content}"`;
          // The search functionality would happen asynchronously and appear in the next message
          break;
        case 'something-else':
          assistantMessage = `I've noted: "${memory.content}"`;
          break;
        default:
          assistantMessage = `I've saved your memory: "${memory.content}"`;
      }
      
      // Add the assistant's response
      append({
        role: 'assistant',
        content: assistantMessage,
        // Add custom UI data for this message
        ui: {
          type: 'memory-created',
          memoryData: memory
        }
      });
    } catch (error) {
      console.error('Error handling memory creation:', error);
    }
  };
  
  // Add this to open the enhanced recorder
  const handleOpenEnhancedRecorder = () => {
    setShowEnhancedRecorder(true);
  };
  
  // Modify the existing renderMessageContent function to handle memory cards
  const renderMessageContent = (message: any) => {
    // Existing rendering logic
    if (message.role === 'assistant') {
      try {
        // Check if this message is a memory creation confirmation
        if (message.ui?.type === 'memory-created' && message.ui.memoryData) {
          const memory = message.ui.memoryData;
          
          // Create actions based on memory type
          const actions: CardAction[] = [];
          
          // For memory type "want-to-know" - usually just an acknowledgment
          if (memory.type === 'want-to-know') {
            actions.push({
              label: 'Got it',
              action: () => {},
              type: 'secondary'
            });
          }
          
          // For memory type "looking-for" - option to search or remind later
          if (memory.type === 'looking-for') {
            actions.push({
              label: 'Search Now',
              action: () => {
                // Trigger a search with the content
                handlePrompt(`Find: ${memory.content}`);
              },
              type: 'primary'
            });
            
            actions.push({
              label: 'Remind Later',
              action: () => {
                // This would set up a reminder in a real implementation
                append({
                  role: 'assistant',
                  content: `I'll remind you about "${memory.content}" later.`
                });
              },
              type: 'secondary'
            });
          }
          
          // Return text content with the card below it
          return (
            <>
              <div className="message-text">{message.content}</div>
              <GenUICard
                title={memory.type === 'want-to-know' ? 'Memory Saved' : 
                       memory.type === 'looking-for' ? 'Search Request' : 
                       'Note Saved'}
                type="memory"
                data={{
                  content: memory.content,
                  keywords: memory.keywords,
                  createdAt: memory.createdAt
                }}
                actions={actions}
              />
            </>
          );
        }
      } catch (error) {
        console.error('Error rendering enhanced message content:', error);
      }
    }
    
    // Apply markdown formatting to all message content
    return message.content;
  };

  // Render actual message JSX with markdown formatting
  const renderMessageMarkdown = (content: string) => {
    const formattedContent = LLMResponseFormatter.enhanceMarkdown(content);
    return <div className="message-text" dangerouslySetInnerHTML={{ __html: formattedContent }} />;
  }

  // Add this effect to ensure ChatModal visibility right after login
  useEffect(() => {
    // Force a reflow to ensure proper component rendering
    const forceReflow = () => {
      const container = document.querySelector('.chat-modal-container');
      if (container) {
        // Add initialized class to trigger reflow
        container.classList.add('initialized');
        // Set a timeout to trigger a second reflow for problematic devices
        setTimeout(() => {
          container.classList.add('fully-initialized');
        }, 300);
      }
    };
    
    // Initial call
    forceReflow();
    
    // Setup a mutation observer to detect when the component is actually mounted
    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          forceReflow();
        }
      }
    });
    
    // Start observing the document body for any added nodes
    observer.observe(document.body, { childList: true, subtree: true });
    
    return () => {
      observer.disconnect();
    };
  }, []);

  // Update the message rendering in the chat
  const renderMessage = (message: ExtendedChatMessage) => {
    const isUser = message.role === 'user';
    const styles = isUser ? userMessageStyles : assistantMessageStyles;
    
    return (
      <div key={message.id} style={styles}>
        {message.content}
      </div>
    );
  };

  // Update the typing indicator
  const renderTypingIndicator = () => {
    return (
      <div style={typingIndicatorStyles}>
        <div style={{ ...dotStyles, animationDelay: '0s' }} />
        <div style={{ ...dotStyles, animationDelay: '0.2s' }} />
        <div style={{ ...dotStyles, animationDelay: '0.4s' }} />
      </div>
    );
  };

  // Add an effect to ensure intro message is shown when auth is ready
  useEffect(() => {
    // Check if we have a valid companion (either resolved from ID or default Loob)
    if (resolvedCompanion) {
      console.log("Chat initialized with companion:", {
        companionId: resolvedCompanion.id,
        companionName: resolvedCompanion.name,
        isBaseCompanion: resolvedCompanion.isBaseCompanion,
        userId,
        isAnonymous: !!isAnonymous,
        pseudonym: pseudonym || '(none)'
      });
      
      // Always show intro for a companion
      setShowIntroMessage(true);
      
      // If this is the first time we have messages, log intro message details
      if (messages.length === 0) {
        console.log("Initializing chat with intro message");
        // Always provide default values for falsy values
        const normalizedPseudonym = pseudonym || '';
        const normalizedIsAnonymous = !!isAnonymous; // Convert to boolean
        
        const introContent = getIntroMessage(resolvedCompanion, normalizedIsAnonymous, normalizedPseudonym);
        console.log("Intro content:", introContent);
        
        // Mark user as returning for future visits if there's intro content
        if (introContent && introContent.trim() !== '') {
          try {
            localStorage.setItem('loob_companion_selected', 'true');
          } catch (error) {
            console.error("Error setting localStorage for companion selection:", error);
          }
        }
      }
    } else {
      console.warn("No companion resolved for chat initialization");
    }
  }, [userId, resolvedCompanion, isAnonymous, pseudonym, messages.length]);

  return (
    <div className="chat-modal-container w-full h-full flex flex-col">
      <div className="chatbot-section">
        {/* Chat Header with Companion Button - Updated for better mobile responsiveness */}
        <div className="flex items-center justify-between px-3 py-2 relative z-10 bg-[#1e1e1e]/80 backdrop-blur-md">
          <button
            onClick={() => setShowCompanionManager(true)}
            className="flex items-center rounded-lg bg-[#2a2b36] hover:bg-[#32333e] transition-all duration-300 group relative p-2 sm:p-3"
          >
            <div className="flex items-center gap-2">
              {(!resolvedCompanion || resolvedCompanion.isBaseCompanion || isAnonymous) ? (
                <div className="w-8 h-8 relative flex items-center justify-center overflow-hidden rounded-full">
                  <img 
                    src="/LoobLogo.png" 
                    alt="Loob" 
                    className="w-7 h-7 object-contain transform group-hover:scale-110 transition-transform duration-300"
                    style={{objectFit: 'contain'}}
                  />
                </div>
              ) : (
                <span className="text-2xl transform group-hover:scale-110 transition-transform duration-300">
                  {resolvedCompanion.icon || '🤖'}
                </span>
              )}
              <div className="flex flex-col items-start">
                <span className="text-xs text-gray-400">
                  Active Companion
                </span>
                <span className="text-sm text-gray-200 font-medium truncate max-w-[120px] sm:max-w-[150px]">
                  {(!resolvedCompanion || isAnonymous) ? 'Loob' : resolvedCompanion.name}
                </span>
              </div>
            </div>
            <span className="ml-2 text-pink-300/70 text-xs group-hover:text-pink-300 transition-colors duration-300">
              {!isAnonymous && <span className="hidden sm:inline">Change</span>} →
            </span>
          </button>
        </div>

        {/* Chat Messages Section - Adjusted margin to create more unified appearance */}
        <div 
          ref={messagesContainerRef}
          className="native-scroll-container flex-1"
          onScroll={debouncedScroll}
          onTouchMove={preventScrollPropagation}
          tabIndex={-1} 
          data-testid="chat-messages-container"
        >
          <div className="w-full flex flex-col px-1 pt-1"> {/* Added slight padding for better spacing */}
            {showIntroMessage && resolvedCompanion && (
              <div ref={latestMessageRef}>
                <Bubble
                  key="intro-message"
                  content={{
                    role: "system",
                    content: getIntroMessage(resolvedCompanion, !!isAnonymous, pseudonym || ''),
                  }}
                  icon={(!resolvedCompanion || resolvedCompanion.isBaseCompanion || isAnonymous) ? '/LoobLogo.png' : resolvedCompanion.icon || '🤖'}
                />
              </div>
            )}
            {messages.map((message) => renderMessage(message))}
            {isProcessing && (
              <div ref={latestMessageRef}>
                <Bubble
                  content={{
                    role: "user",
                    content: "Processing your voice message...",
                  }}
                />
              </div>
            )}
            {isLoading && renderTypingIndicator()}
          </div>
        </div>

        {/* Bottom Controls Section - Removed visual separation */}
        <div className="mobile-controls-wrapper">
          {/* Prompt Suggestions - Positioned above the input */}
          <div className="prompt-suggestions-container">
            <PromptSuggestionsRow 
              onPromptClick={handlePrompt} 
              hasMessages={messages.length > 0}
            />
          </div>

          {/* Input and Send Button - Unified native app-like control bar */}
          <div className="chatbot-input-container sticky bottom-0 left-0 right-0 z-20 bg-[#1e1e1e]/95 backdrop-blur-md px-3 py-2 md:relative md:bg-transparent">
            <form className="flex w-full items-center gap-2 max-w-screen-lg mx-auto" onSubmit={handleSend}>
              {/* Audio Recorder Button - Integrated into the input bar */}
              <div ref={audioButtonRef} className="flex-shrink-0">
                <button 
                  className="audio-recorder-button-inline relative group"
                  onClick={() => {
                    setShowIntroMessage(false);
                    
                    // Check if resource tooltip is visible
                    if (showResourceTooltip) {
                      // Use the regular ImmersiveAudioRecorder for resources
                      setShowImmersiveRecorder(true);
                    } else {
                      // Use our enhanced recorder for normal recording
                      setShowEnhancedRecorder(true);
                    }
                  }}
                  aria-label="Record audio message"
                  type="button"
                >
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 24 24"
                    fill="currentColor" 
                    className="w-6 h-6"
                  >
                    <path d="M12 15c1.93 0 3.5-1.57 3.5-3.5V5c0-1.93-1.57-3.5-3.5-3.5S8.5 3.07 8.5 5v6.5c0 1.93 1.57 3.5 3.5 3.5z"/>
                    <path d="M12 16.5c-2.76 0-5-2.24-5-5H5c0 3.53 2.61 6.43 6 6.92V21h2v-2.58c3.39-.49 6-3.39 6-6.92h-2c0 2.76-2.24 5-5 5z"/>
                  </svg>
                </button>
              </div>
              
              {/* Input Field */}
              <input
                onChange={handleInputChange}
                value={input}
                disabled={inputDisabled}
                className={`chatbot-input flex-1 text-sm md:text-base outline-none rounded-2xl p-3 
                  ${inputDisabled ? 'opacity-50 cursor-not-allowed' : ''} 
                  bg-[#2a2b36] text-white placeholder-gray-400
                  border border-gray-700 focus:border-pink-300/50 transition-colors
                  no-zoom-fix`}
                placeholder={isProcessing ? 'Processing...' : isRecording ? 'Recording...' : 'Send a message...'}
                autoComplete="off"
                autoCorrect="off"
                spellCheck="false"
              />
              
              {/* Send Button */}
              <button 
                type="submit" 
                disabled={!input.trim() || inputDisabled}
                className="send-button px-4 py-2 rounded-2xl font-medium transition-all duration-200 disabled:opacity-70 min-w-[80px] flex-shrink-0"
              >
                {isLoading ? 'Sending...' : 'Send'}
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Resource Upload Components */}
      <ResourceUploadTooltip
        isVisible={showResourceTooltip}
        onClose={() => setShowResourceTooltip(false)}
        audioButtonRef={audioButtonRef as React.RefObject<HTMLDivElement>}
      />

      <ResourceUploadModal
        isOpen={showResourceModal}
        onClose={() => setShowResourceModal(false)}
        initialData={resourceData || {
          title: '',
          description: '',
          offeringType: 'venue',
          tags: []
        }}
        onSubmit={handleResourceSubmit}
      />

      {/* Keep the original ImmersiveAudioRecorder for resource recording */}
      <ImmersiveAudioRecorder
        isOpen={showImmersiveRecorder}
        onClose={() => {
          setShowImmersiveRecorder(false);
          setIsRecording(false);
          setIsProcessing(false);
          setShowResourceTooltip(false);
        }}
        onRecordingComplete={(audioBlob) => {
          setShowImmersiveRecorder(false);
          setIsProcessing(true);
          if (showResourceTooltip) {
            handleResourceAudioUpload(audioBlob);
          } else {
            handleAudioUpload(audioBlob);
          }
        }}
      />

      {/* CompanionManager Modal */}
      <CompanionManager
        isOpen={showCompanionManager}
        onClose={() => setShowCompanionManager(false)}
        onSelect={async (companion) => {
          try {
            console.log('ChatModal: Setting active companion:', companion.name);
            
            // Persist the companion data with proper error handling
            await setActiveCompanion(companion);
            
            // Update local state too
            setResolvedCompanion(companion);
            
            // Clear messages to start fresh with new companion
            setMessages([]);
            
            // Hide the companion manager
            setShowCompanionManager(false);
            
            console.log('ChatModal: Successfully set active companion:', companion.name);
          } catch (error) {
            console.error('ChatModal: Error setting active companion:', error);
          }
        }}
      />

      {/* Render the enhanced recorder when needed */}
      {showEnhancedRecorder && (
        <EnhancedImmersiveRecorder
          isOpen={showEnhancedRecorder}
          onClose={() => setShowEnhancedRecorder(false)}
          onMemoryCreated={handleMemoryCreated}
        />
      )}

      {/* Scroll to bottom button - improved position for mobile */}
      {showScrollButton && (
        <button
          onClick={scrollToBottom}
          className="fixed bottom-20 right-4 md:absolute md:bottom-4 md:right-4 gradient-button shadow-lg rounded-full p-2.5 
            hover:opacity-90 transition-all duration-200 
            flex items-center justify-center z-10"
          aria-label="Scroll to bottom"
        >
          <svg 
            className="w-5 h-5 text-gray-800" 
            fill="none" 
            strokeWidth="2" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path d="M19 14l-7 7m0 0l-7-7m7 7V3" />
          </svg>
        </button>
      )}
    </div>
  );
}