import axios from 'axios';

// Try to import optional dependencies, but provide fallbacks if they're not available
let createTransport: any;
let Client: any;

try {
  // Import from Smithery SDK for MCP client
  const transportModule = require("@smithery/sdk/transport.js");
  createTransport = transportModule.createTransport;
  
  // Import from MCP SDK for client
  const clientModule = require("@modelcontextprotocol/sdk/client/index.js");
  Client = clientModule.Client;
} catch (error) {
  console.warn("Could not import Smithery/MCP SDK modules:", error);
  // Create dummy functions as fallbacks
  createTransport = () => null;
  Client = class DummyClient {
    constructor() {}
    async connect() { return null; }
    async listTools() { return []; }
    async callTool() { return null; }
  };
}

// Smithery.ai API endpoints - use knowledge-specific API domain
const SMITHERY_REGISTRY_API = 'https://registry.smithery.ai';
const SMITHERY_SERVER_API = 'https://api.knowledge.smithery.ai';

/**
 * Client for interacting with Smithery.ai Knowledge Graph API
 */
export class SmitheryKnowledgeGraph {
  private apiKey: string;
  private userId: string;
  private mcpClient: Client | null = null;
  private useServerProxy: boolean = true;
  
  constructor(apiKey: string, userId: string) {
    // When apiKey is empty, we'll use server-side proxies exclusively
    this.apiKey = apiKey;
    this.userId = userId;
    this.useServerProxy = !apiKey;
    
    // Only try to initialize MCP client if we have an API key
    if (apiKey) {
      // Initialize MCP client asynchronously but don't wait for it
      this.initializeMcpClient().catch(err => {
        console.error("Failed to initialize MCP client in constructor:", err);
        // Set mcpClient to null to indicate initialization failure
        this.mcpClient = null;
      });
    } else {
      console.log("Using server-side proxies for knowledge graph API calls");
    }
  }

  private async initializeMcpClient() {
    try {
      // Skip initialization if dependencies are not available
      if (!createTransport || !Client) {
        console.warn("Skipping MCP client initialization - dependencies not available");
        this.mcpClient = null;
        return;
      }
      
      // Skip initialization if no API key
      if (!this.apiKey) {
        console.warn("Skipping MCP client initialization - no API key provided");
        this.mcpClient = null;
        return;
      }
      
      // Create transport with error handling
      const transport = createTransport(
        "https://api.knowledge.smithery.ai", 
        { "memoryFilePath": `memory_${this.userId}.json` }, 
        this.apiKey
      );
      
      if (!transport) {
        console.warn("Failed to create MCP transport");
        this.mcpClient = null;
        return;
      }
      
      // Create and connect client
      const client = new Client({
        name: "Loob Client",
        version: "1.0.0"
      });
      
      await client.connect(transport);
      this.mcpClient = client;
      
      console.log("MCP client initialized successfully");
      
      // List available tools for debugging
      try {
        const tools = await client.listTools();
        if (tools && Array.isArray(tools)) {
          console.log(`Available tools: ${tools.map(t => t.name).join(", ")}`);
        } else {
          console.log("Tools available but not in expected array format:", tools);
        }
      } catch (toolsError) {
        console.warn("Could not list tools:", toolsError);
      }
    } catch (error) {
      console.error("Failed to initialize MCP client:", error);
      this.mcpClient = null;
    }
  }
  
  /**
   * Add a new memory to the knowledge graph
   */
  async addMemoryNode(memory: {
    content: string;
    source: string;
    metadata?: Record<string, any>;
    timestamp?: string;
  }) {
    try {
      // Try using MCP client first if available
      if (this.mcpClient) {
        try {
          // Check if callTool method exists before calling it
          if (typeof this.mcpClient.callTool === 'function') {
            const result = await this.mcpClient.callTool("add_memory", {
              userId: this.userId,
              content: memory.content,
              source: memory.source,
              metadata: memory.metadata || {},
              timestamp: memory.timestamp || new Date().toISOString()
            });
            
            if (result) return result;
          } else {
            console.warn("MCP client exists but callTool is not a function");
          }
        } catch (mcpError) {
          console.warn("Error using MCP client, falling back to API:", mcpError);
        }
      }
      
      // Prepare memory data
      const memoryData = {
        userId: this.userId,
        content: memory.content,
        source: memory.source,
        metadata: memory.metadata || {},
        timestamp: memory.timestamp || new Date().toISOString()
      };
      
      // Always use server-side proxy when useServerProxy is true
      if (this.useServerProxy) {
        try {
          // Use server-side proxy to avoid CORS issues
          const response = await axios.post(
            `/api/smithery/memories`,
            memoryData,
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );
          
          return response.data;
        } catch (proxyError) {
          console.error("Error with proxy API call for memory creation:", proxyError);
          return { success: false, error: 'Failed to add memory through proxy' };
        }
      } else {
        // Direct API call when we have an API key
        try {
          const response = await axios.post(
            `${SMITHERY_SERVER_API}/memories`, 
            memoryData,
            {
              headers: {
                'Authorization': `Bearer ${this.apiKey}`,
                'Content-Type': 'application/json'
              }
            }
          );
          
          return response.data;
        } catch (apiError) {
          console.error("Error with direct API call for memory creation:", apiError);
          return { success: false, error: 'Failed to add memory through direct API' };
        }
      }
    } catch (error) {
      console.error('Error adding memory to knowledge graph:', error);
      // Return a safe fallback value
      return { success: false, error: 'Failed to add memory' };
    }
  }
  
  /**
   * Query the knowledge graph for related memories
   */
  async queryKnowledgeGraph(query: string, limit: number = 5) {
    try {
      // Try using MCP client first if available
      if (this.mcpClient) {
        try {
          if (typeof this.mcpClient.callTool === 'function') {
            const result = await this.mcpClient.callTool("query_memories", {
              userId: this.userId,
              query,
              limit
            });
            
            if (result) return result;
          } else {
            console.warn("MCP client exists but callTool is not a function");
          }
        } catch (mcpError) {
          console.warn("Error querying via MCP client, falling back to API:", mcpError);
        }
      }
      
      // Always use server-side proxy when useServerProxy is true
      if (this.useServerProxy) {
        try {
          // First try using a server-side proxy to avoid CORS issues
          const response = await axios.post(
            `/api/smithery/query`,
            {
              userId: this.userId,
              query,
              limit
            },
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );
          
          return response.data;
        } catch (proxyError) {
          console.error("Error with proxy API call for querying:", proxyError);
          return [];
        }
      } else {
        // Direct API call when we have an API key
        try {
          const response = await axios.post(
            `${SMITHERY_SERVER_API}/query`,
            {
              userId: this.userId,
              query,
              limit
            },
            {
              headers: {
                'Authorization': `Bearer ${this.apiKey}`,
                'Content-Type': 'application/json'
              }
            }
          );
          
          return response.data;
        } catch (apiError) {
          console.error("Error with direct API call for querying:", apiError);
          return [];
        }
      }
    } catch (error) {
      console.error('Error querying knowledge graph:', error);
      // Return empty array as safe fallback
      return [];
    }
  }
  
  /**
   * Get connections between memories
   */
  async getMemoryConnections(memoryId: string) {
    try {
      // Try using MCP client first if available
      if (this.mcpClient) {
        try {
          if (typeof this.mcpClient.callTool === 'function') {
            const result = await this.mcpClient.callTool("get_connections", {
              userId: this.userId,
              memoryId
            });
            
            if (result) return result;
          } else {
            console.warn("MCP client exists but callTool is not a function");
          }
        } catch (mcpError) {
          console.warn("Error getting connections via MCP client, falling back to API:", mcpError);
        }
      }
      
      // Always use server-side proxy when useServerProxy is true
      if (this.useServerProxy) {
        try {
          // Use server-side proxy to avoid CORS issues
          const response = await axios.get(
            `/api/smithery/connections?memoryId=${encodeURIComponent(memoryId)}&userId=${encodeURIComponent(this.userId)}`,
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );
          
          return response.data;
        } catch (proxyError) {
          console.error("Error with proxy API call for connections:", proxyError);
          return { nodes: [], links: [] };
        }
      } else {
        // Direct API call when we have an API key
        try {
          const response = await axios.get(
            `${SMITHERY_SERVER_API}/connections/${memoryId}`,
            {
              headers: {
                'Authorization': `Bearer ${this.apiKey}`
              },
              params: { userId: this.userId }
            }
          );
          
          return response.data;
        } catch (apiError) {
          console.error("Error with direct API call for connections:", apiError);
          return { nodes: [], links: [] };
        }
      }
    } catch (error) {
      console.error('Error getting memory connections:', error);
      // Return empty graph structure as safe fallback
      return { nodes: [], links: [] };
    }
  }
  
  /**
   * Generate insights from the knowledge graph
   */
  async generateInsights(context: string) {
    try {
      // Try using MCP client first if available
      if (this.mcpClient) {
        try {
          if (typeof this.mcpClient.callTool === 'function') {
            const result = await this.mcpClient.callTool("generate_insights", {
              userId: this.userId,
              context
            });
            
            if (result) return result;
          } else {
            console.warn("MCP client exists but callTool is not a function");
          }
        } catch (mcpError) {
          console.warn("Error generating insights via MCP client, falling back to API:", mcpError);
        }
      }
      
      // Prepare insights request data
      const insightsData = {
        userId: this.userId,
        context
      };
      
      // Always use server-side proxy when useServerProxy is true
      if (this.useServerProxy) {
        try {
          // Use server-side proxy to avoid CORS issues
          const response = await axios.post(
            `/api/smithery/insights`,
            insightsData,
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );
          
          return response.data;
        } catch (proxyError) {
          console.error("Error with proxy API call for insights:", proxyError);
          return { insights: '' };
        }
      } else {
        // Direct API call when we have an API key
        try {
          const response = await axios.post(
            `${SMITHERY_SERVER_API}/insights`,
            insightsData,
            {
              headers: {
                'Authorization': `Bearer ${this.apiKey}`,
                'Content-Type': 'application/json'
              }
            }
          );
          
          return response.data;
        } catch (apiError) {
          console.error("Error with direct API call for insights:", apiError);
          return { insights: '' };
        }
      }
    } catch (error) {
      console.error('Error generating insights:', error);
      // Return empty insights as safe fallback
      return { insights: '' };
    }
  }
}

// Helper function to create a new SmitheryKnowledgeGraph instance
export const createKnowledgeGraphClient = (apiKey: string, userId: string) => {
  return new SmitheryKnowledgeGraph(apiKey, userId);
}; 