'use client';

import React, { useState, useEffect } from 'react';
import { FaList, FaThLarge, FaPlus } from 'react-icons/fa';
import { ListingCard, Listing } from './ListingCard';
import './ListingsCards.css';

export interface ListingsCardsProps {
  /**
   * The listings to display. If not provided, the component will fetch them using the fetchUrl
   */
  listings?: Listing[];
  
  /**
   * The URL to fetch listings from. Required if listings are not provided directly
   */
  fetchUrl?: string;
  
  /**
   * Optional title to display above the listings
   */
  title?: string;
  
  /**
   * Optional count label to display next to the title
   */
  countLabel?: string;
  
  /**
   * Whether to show the search input
   */
  showSearch?: boolean;
  
  /**
   * Whether to show the type filter
   */
  showTypeFilter?: boolean;
  
  /**
   * Whether to show the sort order dropdown
   */
  showSortOrder?: boolean;
  
  /**
   * Whether to show the items per page dropdown
   */
  showItemsPerPage?: boolean;
  
  /**
   * Whether to show the view mode toggle (grid/list)
   */
  showViewModeToggle?: boolean;
  
  /**
   * Whether to show the add new listing button when there are no listings
   */
  showAddNew?: boolean;
  
  /**
   * The default number of items to show per page
   */
  defaultItemsPerPage?: number;
  
  /**
   * The default view mode (grid or list)
   */
  defaultViewMode?: 'grid' | 'list';
  
  /**
   * The default sort order
   */
  defaultSortOrder?: 'newest' | 'oldest';
  
  /**
   * Callback when a listing is clicked
   */
  onListingClick?: (listing: Listing) => void;
  
  /**
   * Callback when the add new button is clicked
   */
  onAddNew?: () => void;
  
  /**
   * Custom empty state message
   */
  emptyStateMessage?: string;
  
  /**
   * Custom loading message
   */
  loadingMessage?: string;
  
  /**
   * Custom CSS class to apply to the container
   */
  className?: string;
  
  /**
   * Whether to show pagination controls
   */
  showPagination?: boolean;
  
  /**
   * Whether to show the header section with title and filters
   */
  showHeader?: boolean;
  
  /**
   * Maximum number of listings to display (overrides pagination)
   */
  maxListings?: number;
  
  /**
   * Card display mode
   */
  cardMode?: 'grid' | 'list' | 'compact' | 'chat';
  
  /**
   * Whether to show the view details button on cards
   */
  showViewButton?: boolean;
  
  /**
   * Whether to show author information on cards
   */
  showAuthor?: boolean;
  
  /**
   * Whether to show date information on cards
   */
  showDate?: boolean;
}

export const ListingsCards: React.FC<ListingsCardsProps> = ({
  listings: propListings,
  fetchUrl,
  title = 'Listings',
  countLabel,
  showSearch = true,
  showTypeFilter = true,
  showSortOrder = true,
  showItemsPerPage = true,
  showViewModeToggle = true,
  showAddNew = true,
  defaultItemsPerPage = 6,
  defaultViewMode = 'grid',
  defaultSortOrder = 'newest',
  onListingClick,
  onAddNew = () => alert('Add listing feature coming soon!'),
  emptyStateMessage,
  loadingMessage = 'Loading listings...',
  className = '',
  showPagination = true,
  showHeader = true,
  maxListings,
  cardMode,
  showViewButton = true,
  showAuthor = true,
  showDate = true,
}) => {
  // State for listings data
  const [listings, setListings] = useState<Listing[]>(propListings || []);
  const [isLoading, setIsLoading] = useState(!propListings);
  const [error, setError] = useState<string | null>(null);
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);
  const [totalPages, setTotalPages] = useState(1);
  
  // Filtering state
  const [filterType, setFilterType] = useState<string>('all');
  const [sortOrder, setSortOrder] = useState<'newest' | 'oldest'>(defaultSortOrder);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredListings, setFilteredListings] = useState<Listing[]>([]);
  const [viewMode, setViewMode] = useState<'grid' | 'list'>(defaultViewMode);

  // Get unique listing types for filter dropdown
  const listingTypes = React.useMemo(() => {
    const types = new Set<string>();
    listings.forEach(listing => {
      if (listing.type) types.add(listing.type);
    });
    return ['all', ...Array.from(types)];
  }, [listings]);

  // Apply filters and sorting
  useEffect(() => {
    let result = [...listings];
    
    // Apply search term filter
    if (searchTerm.trim()) {
      const term = searchTerm.toLowerCase();
      result = result.filter(listing => 
        listing.title.toLowerCase().includes(term) || 
        listing.description.toLowerCase().includes(term) ||
        (listing.tags && listing.tags.some(tag => tag.toLowerCase().includes(term)))
      );
    }
    
    // Apply type filter
    if (filterType !== 'all') {
      result = result.filter(listing => 
        listing.type && listing.type.toLowerCase() === filterType.toLowerCase()
      );
    }
    
    // Apply sort
    result.sort((a, b) => {
      const dateA = new Date(a.metadata.createdAt).getTime();
      const dateB = new Date(b.metadata.createdAt).getTime();
      return sortOrder === 'newest' ? dateB - dateA : dateA - dateB;
    });
    
    // Apply maxListings limit if specified
    if (maxListings && maxListings > 0) {
      result = result.slice(0, maxListings);
    }
    
    setFilteredListings(result);
    setTotalPages(Math.max(1, Math.ceil(result.length / itemsPerPage)));
    
    // Reset to first page when filters change
    setCurrentPage(1);
  }, [listings, filterType, sortOrder, searchTerm, itemsPerPage, maxListings]);

  // Fetch listings if not provided as props
  useEffect(() => {
    if (propListings) {
      setListings(propListings);
      setIsLoading(false);
      return;
    }
    
    if (!fetchUrl) {
      setError('No listings provided and no fetch URL specified');
      setIsLoading(false);
      return;
    }
    
    const fetchListings = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(fetchUrl);
        if (!response.ok) throw new Error('Failed to fetch listings');
        const data = await response.json();
        setListings(data.listings || []);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to load listings');
      } finally {
        setIsLoading(false);
      }
    };

    fetchListings();
  }, [propListings, fetchUrl]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    // Scroll to the top of the listings section
    document.querySelector('.listings-cards-section')?.scrollIntoView({ 
      behavior: 'smooth',
      block: 'start'
    });
  };

  const getCurrentPageItems = () => {
    if (maxListings && maxListings > 0) {
      return filteredListings;
    }
    
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredListings.slice(startIndex, endIndex);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const toggleViewMode = () => {
    setViewMode(prev => prev === 'grid' ? 'list' : 'grid');
  };

  if (isLoading) {
    return (
      <div className={`listings-cards-loading ${className}`}>
        <div className="loading-spinner"></div>
        <p>{loadingMessage}</p>
      </div>
    );
  }

  if (error) {
    return <div className={`listings-cards-error ${className}`}>Error: {error}</div>;
  }

  // Determine the card mode based on props and view mode
  const effectiveCardMode = cardMode || viewMode;

  return (
    <div className={`listings-cards-section ${className}`}>
      {showHeader && (
        <div className="listings-cards-header">
          <h3 className="listings-cards-title">
            {title}
            {countLabel || (filteredListings.length > 0 && 
              <span className="listings-cards-count">{filteredListings.length}</span>
            )}
          </h3>
          
          {(showSearch || showTypeFilter || showSortOrder || showItemsPerPage || showViewModeToggle) && (
            <div className="listings-cards-filters">
              {showSearch && (
                <div className="search-container">
                  <input
                    type="text"
                    placeholder="Search listings..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="search-input"
                    aria-label="Search listings"
                  />
                </div>
              )}
              
              {showTypeFilter && listingTypes.length > 1 && (
                <select
                  value={filterType}
                  onChange={(e) => setFilterType(e.target.value)}
                  className="filter-select"
                  aria-label="Filter by type"
                >
                  {listingTypes.map(type => (
                    <option key={type} value={type}>
                      {type === 'all' ? 'All Types' : type}
                    </option>
                  ))}
                </select>
              )}
              
              {showSortOrder && (
                <select
                  value={sortOrder}
                  onChange={(e) => setSortOrder(e.target.value as 'newest' | 'oldest')}
                  className="filter-select"
                  aria-label="Sort order"
                >
                  <option value="newest">Newest First</option>
                  <option value="oldest">Oldest First</option>
                </select>
              )}
              
              {showItemsPerPage && !maxListings && (
                <select
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(Number(e.target.value))}
                  className="filter-select"
                  aria-label="Items per page"
                >
                  <option value="6">6 per page</option>
                  <option value="12">12 per page</option>
                  <option value="24">24 per page</option>
                </select>
              )}
              
              {showViewModeToggle && !cardMode && (
                <button 
                  onClick={toggleViewMode}
                  className="view-toggle-btn"
                  aria-label={`Switch to ${viewMode === 'grid' ? 'list' : 'grid'} view`}
                >
                  {viewMode === 'grid' ? <FaList /> : <FaThLarge />}
                </button>
              )}
            </div>
          )}
        </div>
      )}
      
      {filteredListings.length > 0 ? (
        <>
          <div className={`listings-cards-${viewMode}`}>
            {getCurrentPageItems().map((listing) => (
              <ListingCard
                key={listing.id}
                listing={listing}
                onClick={onListingClick}
                mode={effectiveCardMode}
                showViewButton={showViewButton}
                showAuthor={showAuthor}
                showDate={showDate}
              />
            ))}
          </div>
          
          {showPagination && totalPages > 1 && !maxListings && (
            <div className="pagination-container">
              {currentPage > 1 && (
                <button 
                  className="pagination-button"
                  onClick={() => handlePageChange(currentPage - 1)}
                  aria-label="Previous page"
                >
                  &lt;
                </button>
              )}
              
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
                <button
                  key={page}
                  className={`pagination-button ${page === currentPage ? 'active' : ''}`}
                  onClick={() => handlePageChange(page)}
                  aria-label={`Page ${page}`}
                  aria-current={page === currentPage ? 'page' : undefined}
                >
                  {page}
                </button>
              ))}
              
              {currentPage < totalPages && (
                <button 
                  className="pagination-button"
                  onClick={() => handlePageChange(currentPage + 1)}
                  aria-label="Next page"
                >
                  &gt;
                </button>
              )}
            </div>
          )}
        </>
      ) : (
        <div className="listings-cards-empty">
          <h3>No Active Listings</h3>
          <p>{emptyStateMessage || `There are no ${filterType !== 'all' ? filterType : ''} listings at the moment.`}</p>
          {showAddNew && (
            <button className="btn-gradient add-listing-btn" onClick={onAddNew}>
              <FaPlus style={{ marginRight: '0.5rem' }} />
              Add New Listing
            </button>
          )}
        </div>
      )}
    </div>
  );
}; 