'use client';

import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaLock, FaMapMarkedAlt, FaExternalLinkAlt, FaClock, FaCoins } from 'react-icons/fa';

interface Survey {
  id: string;
  title: string;
  description: string;
  type: 'public' | 'private';
  duration: string;
  reward: string;
  url: string;
}

interface SurveyModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (survey: Survey) => void;
  surveys: Survey[];
}

export const SurveyModal: React.FC<SurveyModalProps> = ({
  isOpen,
  onClose,
  onSelect,
  surveys
}) => {
  const [selectedSurvey, setSelectedSurvey] = useState<Survey | null>(null);

  const modalVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.3,
        ease: "easeOut"
      }
    },
    exit: {
      opacity: 0,
      y: 20,
      transition: {
        duration: 0.2,
        ease: "easeIn"
      }
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50">
          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={modalVariants}
            className="w-full max-w-2xl bg-background-primary rounded-lg shadow-xl overflow-hidden"
          >
            <div className="p-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-semibold text-text-primary">Available Surveys</h2>
                <button
                  onClick={onClose}
                  className="text-text-secondary hover:text-text-primary transition-colors"
                >
                  ×
                </button>
              </div>

              <div className="space-y-4">
                {surveys.map((survey) => (
                  <motion.div
                    key={survey.id}
                    whileHover={{ scale: 1.02 }}
                    className={`p-4 rounded-lg border border-border-primary hover:border-primary-color transition-colors cursor-pointer ${
                      selectedSurvey?.id === survey.id ? 'border-primary-color bg-primary-color bg-opacity-10' : ''
                    }`}
                    onClick={() => setSelectedSurvey(survey)}
                  >
                    <div className="flex justify-between items-start">
                      <div>
                        <h3 className="text-lg font-medium text-text-primary mb-2">{survey.title}</h3>
                        <p className="text-text-secondary text-sm mb-4">{survey.description}</p>
                        <div className="flex items-center gap-4 text-sm text-text-secondary">
                          <span className="flex items-center gap-2">
                            <FaLock className={survey.type === 'private' ? 'text-error-color' : 'text-success-color'} />
                            {survey.type}
                          </span>
                          <span className="flex items-center gap-2">
                            <FaClock />
                            {survey.duration}
                          </span>
                          <span className="flex items-center gap-2">
                            <FaCoins className="text-accent-color" />
                            {survey.reward}
                          </span>
                        </div>
                      </div>
                      <FaExternalLinkAlt className="text-text-secondary" />
                    </div>
                  </motion.div>
                ))}
              </div>

              <div className="mt-6 flex justify-end gap-4">
                <button
                  onClick={onClose}
                  className="px-4 py-2 rounded-md text-text-secondary hover:text-text-primary transition-colors"
                >
                  Cancel
                </button>
                <button
                  onClick={() => selectedSurvey && onSelect(selectedSurvey)}
                  disabled={!selectedSurvey}
                  className="px-4 py-2 rounded-md bg-primary-color text-white disabled:opacity-50 disabled:cursor-not-allowed hover:bg-primary-color-dark transition-colors"
                >
                  Start Survey
                </button>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default SurveyModal; 