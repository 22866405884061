// Intent Types
export type IntentType = 'want-to-know' | 'looking-for' | 'something-else';

// Interface for parsed intent result
export interface ParsedIntent {
  intent: IntentType;
  processedText: string;
  keywords: string[];
}

/**
 * Parses transcription text to extract intent and format content
 * @param transcription The raw transcription text to parse
 * @param defaultIntent Optional default intent type if not detected in text
 * @returns Parsed intent information
 */
export const parseTranscription = async (
  transcription: string,
  defaultIntent: IntentType = 'want-to-know'
): Promise<ParsedIntent> => {
  if (!transcription || typeof transcription !== 'string') {
    console.error('Invalid transcription input:', transcription);
    return {
      intent: defaultIntent,
      processedText: 'Invalid transcription',
      keywords: [],
    };
  }

  try {
    // Clean up transcription
    const cleanedTranscription = transcription.trim();
    
    // Extract keywords for better recall
    const keywords = extractKeywords(cleanedTranscription);
    
    // Determine intent from content if not provided
    const detectedIntent = detectIntent(cleanedTranscription, defaultIntent);

    return {
      intent: detectedIntent,
      processedText: cleanedTranscription,
      keywords,
    };
  } catch (error) {
    console.error('Error in parseTranscription:', error);
    return {
      intent: defaultIntent,
      processedText: transcription,
      keywords: [],
    };
  }
};

/**
 * Alias for parseTranscription to maintain backward compatibility
 * This fixes the function name discrepancy in EnhancedImmersiveRecorder
 */
export const parseTranscriptionIntent = parseTranscription;

/**
 * Extracts keywords from text for better memory indexing
 */
export const extractKeywords = (text: string): string[] => {
  if (!text) return [];
  
  // Split text into words and normalize
  const words = text.toLowerCase()
    .replace(/[^\w\s]/g, '')
    .split(/\s+/)
    .filter(word => word.length > 3) // Filter out very short words
    .filter(word => !commonStopWords.includes(word)); // Remove stop words
  
  // Count word frequency and select top keywords
  const wordCounts = words.reduce((acc, word) => {
    acc[word] = (acc[word] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);
  
  // Sort words by frequency and take top 5
  return Object.entries(wordCounts)
    .sort((a, b) => b[1] - a[1])
    .slice(0, 5)
    .map(([word]) => word);
};

/**
 * Determines the most likely intent from text content
 */
export const detectIntent = (text: string, defaultIntent: IntentType): IntentType => {
  const lowerText = text.toLowerCase();
  
  // Check for want-to-know patterns
  if (
    lowerText.includes('i want you to know') ||
    lowerText.includes('just so you know') ||
    lowerText.includes('remember that') ||
    lowerText.includes('keep in mind')
  ) {
    return 'want-to-know';
  }
  
  // Check for looking-for patterns
  if (
    lowerText.includes('i\'m looking for') ||
    lowerText.includes('i am looking for') ||
    lowerText.includes('can you find') ||
    lowerText.includes('where can i find') ||
    lowerText.includes('search for')
  ) {
    return 'looking-for';
  }
  
  // Default to provided intent if no pattern matched
  return defaultIntent;
};

/**
 * Determines if transcription should be saved as a memory
 */
export const shouldSaveAsMemory = (intent: IntentType, text: string): boolean => {
  // Skip very short texts
  if (text.length < 10) return false;
  
  // Always save want-to-know intent
  if (intent === 'want-to-know') return true;
  
  // For other intents, only save if they seem substantive
  return text.length > 20;
};

/**
 * Creates a memory entry object
 */
export const createMemoryEntry = (
  userId: string,
  intent: IntentType,
  content: string,
  keywords: string[] = []
) => {
  return {
    userId,
    type: intent,
    content,
    keywords,
    createdAt: new Date().toISOString(),
  };
};

// Common English stop words to filter out from keywords
const commonStopWords = [
  'the', 'and', 'that', 'have', 'this', 'with', 'from', 'they', 'will',
  'would', 'there', 'their', 'what', 'about', 'which', 'when', 'your',
  'said', 'could', 'some', 'them', 'into', 'other', 'than', 'then', 'only'
]; 