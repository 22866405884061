'use client';

import React, { useState, useEffect } from "react";
import { VibeEntity } from "./VibeEntity";
import { supabase } from '../lib/supabase/client';
import { CircularProgress } from '@mui/material';

// Define the structure of history entries
interface HistoryEntry {
  action: string;
  date: string;
  location: string;
}

// Define the props for the GearProfile component
interface GearProfileProps {
  gear: {
    id: string;
    name: string;
    description: string;
    status: string;
    history: HistoryEntry[];
    loobricateId: string;
    photos?: string[]; // Storage paths
    photoUrls?: string[]; // Public URLs
  };
  onClose: () => void;
  onAddToMap?: () => void;
}

export const GearProfile: React.FC<GearProfileProps> = ({ gear, onClose }) => {
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState<number>(0);
  const [showVibeEntity, setShowVibeEntity] = useState<boolean>(false);
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [isLoadingImages, setIsLoadingImages] = useState(true);

  useEffect(() => {
    const loadImages = async () => {
      // If no photos, exit early
      if ((!gear.photos || gear.photos.length === 0) && (!gear.photoUrls || gear.photoUrls.length === 0)) {
        setIsLoadingImages(false);
        return;
      }

      try {
        // If we have photoUrls, use them directly
        if (gear.photoUrls && gear.photoUrls.length > 0) {
          setImageUrls(gear.photoUrls);
          setIsLoadingImages(false);
          return;
        }

        // Otherwise, get signed URLs for the storage paths
        const urls = await Promise.all(
          (gear.photos || []).map(async (photoPath) => {
            try {
              // If it's already a full URL, use it directly
              if (photoPath.startsWith('http')) {
                return photoPath;
              }

              // Otherwise, get a signed URL from Supabase
              const { data, error } = await supabase
                .storage
                .from('entry-photos')
                .createSignedUrl(photoPath, 3600); // 1 hour expiry

              if (error || !data?.signedUrl) {
                console.error('Error getting signed URL:', error);
                return null;
              }

              return data.signedUrl;
            } catch (error) {
              console.error('Error processing photo URL:', error);
              return null;
            }
          })
        );

        // Filter out any null values and set the URLs
        setImageUrls(urls.filter((url): url is string => url !== null));
      } catch (error) {
        console.error('Error loading images:', error);
      } finally {
        setIsLoadingImages(false);
      }
    };

    loadImages();
  }, [gear.photos, gear.photoUrls]);

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 backdrop-blur-sm z-50 px-4 sm:px-6 md:px-8"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      <div className="bg-[#1a1a24] text-white rounded-xl w-full max-w-2xl p-6 relative overflow-y-auto max-h-[90vh] shadow-2xl">
        {/* Close button - Updated styling */}
        <button
          className="absolute top-4 right-4 w-8 h-8 flex items-center justify-center rounded-full bg-black/20 text-white hover:bg-black/30 transition-colors focus:outline-none"
          onClick={onClose}
          aria-label="Close"
        >
          <span className="text-lg leading-none">&times;</span>
        </button>

        {/* Header Section */}
        <div className="mb-6 text-center">
          <h2 className="text-3xl font-bold mb-2">{gear.name}</h2>
          <p className="text-gray-400 text-lg">{gear.description}</p>
        </div>

        {/* Main Image Gallery */}
        {isLoadingImages ? (
          <div className="w-full h-[400px] flex items-center justify-center bg-[#22222e] rounded-lg mb-8">
            <CircularProgress />
          </div>
        ) : imageUrls.length > 0 ? (
          <div className="mb-8">
            <div className="relative w-full h-[400px] mb-4 rounded-lg overflow-hidden">
              <img
                src={imageUrls[selectedPhotoIndex]}
                alt={`${gear.name} - Photo ${selectedPhotoIndex + 1}`}
                className="w-full h-full object-contain bg-gray-100 dark:bg-gray-800"
              />
              {imageUrls.length > 1 && (
                <div className="absolute inset-x-0 bottom-0 flex justify-center space-x-2 p-4 bg-gradient-to-t from-black/50 to-transparent">
                  {imageUrls.map((_, index) => (
                    <button
                      key={index}
                      className={`w-2 h-2 rounded-full transition-all ${
                        selectedPhotoIndex === index 
                          ? 'bg-white w-4' 
                          : 'bg-white/50 hover:bg-white/75'
                      }`}
                      onClick={() => setSelectedPhotoIndex(index)}
                    />
                  ))}
                </div>
              )}
              {imageUrls.length > 1 && (
                <>
                  <button
                    className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-black/50 hover:bg-black/75 text-white rounded-full p-3 transition-all"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedPhotoIndex((prev) => 
                        prev === 0 ? imageUrls.length - 1 : prev - 1
                      );
                    }}
                  >
                    ←
                  </button>
                  <button
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-black/50 hover:bg-black/75 text-white rounded-full p-3 transition-all"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedPhotoIndex((prev) => 
                        prev === imageUrls.length - 1 ? 0 : prev + 1
                      );
                    }}
                  >
                    →
                  </button>
                </>
              )}
            </div>
            {imageUrls.length > 1 && (
              <div className="flex gap-2 overflow-x-auto pb-2 px-1">
                {imageUrls.map((url, index) => (
                  <img
                    key={index}
                    src={url}
                    alt={`${gear.name} - Thumbnail ${index + 1}`}
                    className={`w-20 h-20 object-cover rounded-lg cursor-pointer transition-all
                      ${selectedPhotoIndex === index 
                        ? 'ring-2 ring-blue-500 ring-offset-2' 
                        : 'opacity-70 hover:opacity-100'
                      }`}
                    onClick={() => setSelectedPhotoIndex(index)}
                  />
                ))}
              </div>
            )}
          </div>
        ) : null}

        {/* Status and Actions Section */}
        <div className="flex items-center justify-between mb-6 p-4 bg-[#22222e] rounded-lg">
          <div className="flex items-center space-x-2">
            <span className={`w-3 h-3 rounded-full ${
              gear.status === 'available' ? 'bg-green-500' : 'bg-yellow-500'
            }`} />
            <span className="font-medium">{gear.status}</span>
          </div>
          <button
            onClick={() => alert("Feature coming soon!")}
            className="inline-flex items-center justify-center px-4 py-2 rounded-lg font-medium transition-all focus:outline-none bg-primary text-white hover:bg-primary/90"
          >
            Add to Map
          </button>
        </div>

        {/* History Section */}
        <div className="mb-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-bold">History</h3>
            <button
              onClick={() => setShowVibeEntity(!showVibeEntity)}
              className="text-primary text-sm px-3 py-1 rounded-full border border-primary/30 hover:bg-primary/10 transition-colors"
            >
              {showVibeEntity ? 'Hide Vibe' : 'Show Vibe'}
            </button>
          </div>
          
          {showVibeEntity && (
            <div className="relative h-40 mb-4 rounded-lg overflow-hidden bg-[#22222e]">
              <VibeEntity 
                entityId={gear.loobricateId}
                className="gear-vibe-entity"
                onStateUpdate={async (state) => {
                  try {
                    await fetch('/api/vibe_entities', {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify({ id: gear.loobricateId, state })
                    });
                  } catch (error) {
                    console.error('Failed to update vibe state:', error);
                  }
                }}
              />
            </div>
          )}

          <div className="space-y-2">
            {gear.history.map((entry, index) => (
              <div 
                key={index} 
                className="p-3 bg-[#22222e] rounded-lg flex items-center justify-between"
              >
                <div>
                  <span className="font-medium">{entry.action}</span>
                  <span className="text-gray-400"> at {entry.location}</span>
                </div>
                <span className="text-sm text-gray-400">{entry.date}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
