import { createBrowserClient } from '@supabase/ssr';

// Check for required environment variables
if (!process.env.NEXT_PUBLIC_SUPABASE_URL) {
  throw new Error('Missing NEXT_PUBLIC_SUPABASE_URL environment variable');
}
if (!process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY) {
  throw new Error('Missing NEXT_PUBLIC_SUPABASE_ANON_KEY environment variable');
}

// Create Supabase client
export const supabase = createBrowserClient(
  process.env.NEXT_PUBLIC_SUPABASE_URL,
  process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY,
  {
    auth: {
      persistSession: true,
      autoRefreshToken: true,
      detectSessionInUrl: true
    }
  }
);

// Create Supabase admin client with service role key
export const supabaseAdmin = process.env.SUPABASE_SERVICE_ROLE_KEY
  ? createBrowserClient(
      process.env.NEXT_PUBLIC_SUPABASE_URL,
      process.env.SUPABASE_SERVICE_ROLE_KEY,
      {
        auth: {
          persistSession: false
        }
      }
    )
  : null;

// Maximum file size in bytes (5MB)
const MAX_FILE_SIZE = 5 * 1024 * 1024;

// Supported image types
const SUPPORTED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/webp'];

// Helper function to validate image file
function validateImageFile(file: File) {
  if (!SUPPORTED_IMAGE_TYPES.includes(file.type)) {
    throw new Error('Unsupported file type. Please upload JPEG, PNG, or WebP images.');
  }
  
  if (file.size > MAX_FILE_SIZE) {
    throw new Error('File size exceeds 5MB limit.');
  }
}

// Helper function to compress image if needed
async function compressImageIfNeeded(file: File): Promise<File> {
  if (file.size <= MAX_FILE_SIZE) return file;
  
  // Implement image compression logic here if needed
  // For now, just return the original file
  return file;
}

// Retry function with exponential backoff
async function retry<T>(
  fn: () => Promise<T>,
  retries = 3,
  delay = 1000,
): Promise<T> {
  try {
    return await fn();
  } catch (error) {
    if (retries === 0) throw error;
    await new Promise(resolve => setTimeout(resolve, delay));
    return retry(fn, retries - 1, delay * 2);
  }
}

// Helper function for image uploads
export async function uploadImage(file: File, bucket: string, path: string, userId: string | null) {
  try {
    // Check if user is authenticated using passed userId
    if (!userId || userId.startsWith('anon-')) {
      throw new Error('Please sign in to upload images');
    }

    // Validate file
    validateImageFile(file);
    
    // Compress if needed
    const processedFile = await compressImageIfNeeded(file);
    
    // Generate unique filename with user ID prefix for better organization
    const fileExt = file.name.split('.').pop();
    const fileName = `${userId}/${Date.now()}-${Math.random().toString(36).substring(7)}.${fileExt}`;
    const fullPath = `${path}/${fileName}`;

    // Upload with retry logic
    const { data, error } = await retry(async () => {
      const uploadResult = await supabase.storage
        .from(bucket)
        .upload(fullPath, processedFile, {
          contentType: file.type,
          upsert: false,
          cacheControl: '3600',
        });
      
      if (uploadResult.error) {
        // Check for specific error types
        if (uploadResult.error.message.includes('Permission denied')) {
          throw new Error('You do not have permission to upload files. Please sign in.');
        }
        if (uploadResult.error.message.includes('Payload too large')) {
          throw new Error('File size exceeds the maximum limit of 5MB.');
        }
        throw uploadResult.error;
      }
      return uploadResult;
    });

    if (error) throw error;

    // Get public URL with retry logic
    const { data: urlData } = await retry(async () => {
      return supabase.storage
        .from(bucket)
        .getPublicUrl(fullPath);
    });

    // Verify the URL is accessible
    try {
      const response = await fetch(urlData.publicUrl, { method: 'HEAD' });
      if (!response.ok) {
        throw new Error('Failed to verify uploaded image URL');
      }
    } catch (error) {
      console.error('URL verification failed:', error);
      // Don't throw here, just log the error
    }

    return { 
      url: urlData.publicUrl, 
      path: fullPath,
      size: processedFile.size,
      type: processedFile.type,
      userId: userId
    };
  } catch (error) {
    console.error('Error uploading image:', error);
    
    // Handle specific error types
    if (error instanceof Error) {
      throw error; // Preserve the original error object
    } else if (typeof error === 'object' && error !== null) {
      // Handle case where error is an object but not an Error instance
      const errorMessage = (error as any).message || JSON.stringify(error);
      throw new Error(errorMessage);
    } else {
      // Handle primitive error values or null/undefined
      throw new Error('Failed to upload image. Please try again.');
    }
  }
}

// POAP functions removed as part of the POAP functionality cleanup 