"use client";

import React, { useEffect, useState, useRef } from 'react';
import { useGlobalState } from './GlobalStateContext';
import { format, parseISO } from 'date-fns';
import './MemoryBank.css';
import { MemoryGraph } from './MemoryGraph';
import { useKnowledgeGraph } from '../hooks/useKnowledgeGraph';

interface MemoryBankProps {
  onClose: () => void;
  setView?: (view: string) => void;
}

interface MemoryEntry {
  id: string;
  content: string;
  createdAt: string;
  pseudonym: string;
  analysis?: {
    mood: string | null;
    topics: string[];
    actionItems: string[];
    sentiment: string | null;
    title?: string;
    emoji?: string | null;
    overview?: string;
  };
  metadata?: {
    source: string;
    version: string;
    hasEmbedding: boolean;
    formatVersion?: string;
    hasSpeakers?: boolean;
    originalData?: {
      structured?: {
        title?: string;
        overview?: string;
        emoji?: string;
        category?: string;
        action_items?: Array<{
          description: string;
          completed: boolean;
        }>;
      };
    };
  };
}

export const MemoryBank: React.FC<MemoryBankProps> = ({ onClose, setView }) => {
  const { user } = useGlobalState();
  const { isReady: isKgReady } = useKnowledgeGraph();
  const [memories, setMemories] = useState<MemoryEntry[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [memoryTypeView, setMemoryTypeView] = useState<'all' | 'omi' | 'other'>('all');
  const [showOmiInstructions, setShowOmiInstructions] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [lastFetchTime, setLastFetchTime] = useState<number | null>(null);
  const [viewMode, setViewMode] = useState<'list' | 'graph'>('list');
  const [selectedMemoryId, setSelectedMemoryId] = useState<string | null>(null);

  // Add CSS styles
  const styles = {
    memoryCard: {
      borderRadius: '0.75rem',
      backgroundColor: 'rgba(30, 41, 59, 0.5)',
      marginBottom: '0.75rem',
      overflow: 'hidden',
      border: '1px solid rgba(71, 85, 105, 0.3)',
      transition: 'transform 0.2s, box-shadow 0.2s',
    },
    memoryCardHover: {
      transform: 'translateY(-2px)',
      boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.2)',
    },
    memoryContent: {
      padding: '0.75rem',
      color: 'rgb(203, 213, 225)',
      fontSize: '0.95rem',
      lineHeight: '1.6',
    },
    memoryDate: {
      padding: '0.5rem 0.75rem',
      backgroundColor: 'rgba(15, 23, 42, 0.7)',
      fontSize: '0.85rem',
      color: 'rgb(148, 163, 184)',
      borderBottom: '1px solid rgba(71, 85, 105, 0.3)',
    },
    memorySource: {
      fontSize: '0.75rem',
      backgroundColor: 'rgba(59, 130, 246, 0.2)',
      color: 'rgb(186, 230, 253)',
      borderRadius: '9999px',
      padding: '0.2rem 0.6rem',
      marginLeft: '0.5rem',
      display: 'inline-flex',
      alignItems: 'center',
    },
    omiSource: {
      backgroundColor: 'rgba(124, 58, 237, 0.2)',
      color: 'rgb(216, 180, 254)',
    },
    loobSource: {
      backgroundColor: 'rgba(16, 185, 129, 0.2)',
      color: 'rgb(167, 243, 208)',
    },
    conversationLine: {
      marginBottom: '0.5rem',
      paddingLeft: '0.5rem',
      borderLeft: '2px solid rgba(59, 130, 246, 0.5)',
    },
    userLine: {
      borderLeft: '2px solid rgba(16, 185, 129, 0.7)',
    },
    speakerLabel: {
      fontWeight: '600',
      color: 'rgb(147, 197, 253)',
    },
    userSpeakerLabel: {
      color: 'rgb(134, 239, 172)',
    }
  };

  const fetchMemories = async (forceRefresh = false) => {
    if (!user || !user.userId) {
      console.error('MemoryBank: No user ID found');
      setError('No user ID found. Please log in.');
      setLoading(false);
      return;
    }
    
    // Skip if already fetching
    if (isRefreshing && !forceRefresh) {
      return;
    }
    
    // If we have recent data (< 5 minutes) and not forcing refresh, use cached data
    if (!forceRefresh && lastFetchTime && (Date.now() - lastFetchTime < 5 * 60 * 1000)) {
      console.log('MemoryBank: Using cached memories from recent fetch');
      return;
    }
    
    console.log('MemoryBank: Fetching memories for user', user.userId);
    
    try {
      setLoading(true);
      setError(null);
      setIsRefreshing(true);
      
      // Fetch with cache busting parameter to avoid browser caching
      const cacheBuster = Date.now();
      // AstraDB has a limit of 20 results when using sort
      // We'll fetch in batches if needed
      const batchSize = 20; // AstraDB max limit when sorting
      let allMemories: MemoryEntry[] = [];
      let hasMore = true;
      let batchNumber = 0;
      
      console.log('MemoryBank: Starting batch fetching');
      
      // Fetch first batch
      const firstBatchUrl = `/api/memories?userId=${user.userId}&limit=${batchSize}&_=${cacheBuster}`;
      console.log('MemoryBank: Fetching first batch:', firstBatchUrl);
      
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 15000); // 15 second timeout
      
      try {
        const response = await fetch(firstBatchUrl, {
          signal: controller.signal,
          headers: {
            'Cache-Control': forceRefresh ? 'no-cache' : 'max-age=300',
          }
        });
        
        clearTimeout(timeoutId);
        
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`API error: ${response.status} - ${errorText}`);
        }
        
        const data = await response.json();
        console.log('MemoryBank: First batch received, count:', Array.isArray(data) ? data.length : 'not an array');
        
        if (Array.isArray(data)) {
          allMemories = [...data];
          // If we got less than the batch size, there's no more data
          hasMore = data.length >= batchSize;
        } else {
          console.warn('MemoryBank: Unexpected response format:', data);
          hasMore = false;
        }
      } catch (error) {
        console.error('MemoryBank: Error fetching first batch:', error);
        throw error; // Re-throw to be caught by outer catch
      }
      
      // Ensure we have valid memories
      const validMemories = allMemories.filter(memory => 
        memory && typeof memory === 'object' && memory.content && memory.createdAt
      );
      
      if (validMemories.length < allMemories.length) {
        console.warn(`MemoryBank: Filtered out ${allMemories.length - validMemories.length} invalid memories`);
      }
      
      if (validMemories.length === 0) {
        console.log('MemoryBank: No memories found, adding demo placeholder memories');
        
        // Create demo placeholder memories
        const placeholderMemories: MemoryEntry[] = [
          {
            id: 'placeholder-1',
            content: "Just had a fantastic conversation with the Omi team! They showed me the latest memory features and how they're integrating with various apps. Excited to explore more of what Omi can do.",
            createdAt: new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString(), // Yesterday
            pseudonym: "Demo User",
            analysis: {
              mood: "excited",
              topics: ["Omi", "technology", "integration"],
              actionItems: ["Explore Omi features", "Try memory integration"],
              sentiment: "positive"
            },
            metadata: {
              source: "omi",
              version: "1.0",
              hasEmbedding: true,
              formatVersion: "v2",
              hasSpeakers: false
            }
          },
          {
            id: 'placeholder-2',
            content: "Today's thoughts: Need to finish the Loob memory integration project by Friday. Also remember to buy groceries and call mom for her birthday.",
            createdAt: new Date(Date.now() - 2 * 24 * 60 * 60 * 1000).toISOString(), // 2 days ago
            pseudonym: "Demo User",
            analysis: {
              mood: "neutral",
              topics: ["work", "personal", "tasks"],
              actionItems: ["Finish memory integration", "Buy groceries", "Call mom"],
              sentiment: "neutral"
            },
            metadata: {
              source: "loob",
              version: "1.0",
              hasEmbedding: true
            }
          },
          {
            id: 'placeholder-3',
            content: "You: Hey Omi, what's the weather like today?\n\nOmi: It's currently 72°F and sunny in your location. Perfect day for a walk!\n\nYou: Thanks, that sounds great. Any rain in the forecast?\n\nOmi: There's a 20% chance of light rain tomorrow evening, but today should remain clear.",
            createdAt: new Date(Date.now() - 3 * 24 * 60 * 60 * 1000).toISOString(), // 3 days ago
            pseudonym: "Conversation",
            analysis: {
              mood: "curious",
              topics: ["weather", "forecast", "outdoor activities"],
              actionItems: [],
              sentiment: "positive"
            },
            metadata: {
              source: "omi",
              version: "1.0",
              hasEmbedding: true,
              hasSpeakers: true,
              formatVersion: "v2"
            }
          },
          {
            id: 'placeholder-4',
            content: "Memory: Had a productive day. Met with the design team to discuss UI improvements for the dashboard. Outlined next steps for the memory archive feature. Need to follow up with Bradley about the new visualizations.",
            createdAt: new Date(Date.now() - 4 * 24 * 60 * 60 * 1000).toISOString(), // 4 days ago
            pseudonym: "Demo User",
            analysis: {
              mood: "productive",
              topics: ["work", "design", "meetings", "planning"],
              actionItems: ["Follow up with design team", "Outline memory archive feature", "Contact Bradley"],
              sentiment: "positive"
            },
            metadata: {
              source: "loob",
              version: "1.0",
              hasEmbedding: true
            }
          },
          {
            id: 'placeholder-5',
            content: "Brain snapshot: Read an interesting article about memory techniques today. The method of loci seems particularly useful for remembering structured information. Should try applying it to learning the new API endpoints. Also, don't forget to water plants tomorrow morning!",
            createdAt: new Date(Date.now() - 5 * 24 * 60 * 60 * 1000).toISOString(), // 5 days ago
            pseudonym: "Demo User",
            analysis: {
              mood: "thoughtful",
              topics: ["learning", "memory techniques", "personal"],
              actionItems: ["Try method of loci", "Learn API endpoints", "Water plants"],
              sentiment: "positive"
            },
            metadata: {
              source: "loob",
              version: "1.0",
              hasEmbedding: true
            }
          }
        ];
        
        allMemories = placeholderMemories;
        
        // Use these placeholders instead of the empty data
        setMemories(placeholderMemories);
        setLastFetchTime(Date.now());
        console.log('MemoryBank: Added placeholder memories for demo');
        
        // Early return as we've set the memories
        setLoading(false);
        setIsRefreshing(false);
        return;
      }
      
      // Sort memories by date, newest first
      validMemories.sort((a: MemoryEntry, b: MemoryEntry) => 
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      
      setMemories(validMemories);
      setLastFetchTime(Date.now());
      console.log('MemoryBank: Successfully fetched and processed memories:', validMemories.length);
    } catch (error) {
      console.error('Error fetching memories:', error);
      // Don't overwrite existing memories if we have them
      if (memories.length === 0) {
      setError(error instanceof Error ? error.message : 'Failed to load your memories. Please try again.');
      } else {
        // Just show a temporary error toast
        setError('Error refreshing memories. Using cached data.');
        setTimeout(() => setError(null), 3000);
      }
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };

  // Initial fetch and refresh interval
  useEffect(() => {
    fetchMemories(true);
    
    // Set up periodic refresh every 3 minutes if tab is visible
    const refreshInterval = setInterval(() => {
      if (document.visibilityState === 'visible') {
        fetchMemories(false);
      }
    }, 3 * 60 * 1000);
    
    return () => clearInterval(refreshInterval);
  }, [user.userId]);
  
  // Handle manual refresh
  const handleRefresh = () => {
    fetchMemories(true);
  };

  const groupMemoriesByDate = (memories: MemoryEntry[]) => {
    return memories.reduce((groups: Record<string, MemoryEntry[]>, memory) => {
      const date = format(parseISO(memory.createdAt), 'MMMM d, yyyy');
      if (!groups[date]) groups[date] = [];
      groups[date].push(memory);
      return groups;
    }, {});
  };

  const filteredMemories = memories.filter(memory => {
    // Filter by search term
    const matchesSearch = memory.content.toLowerCase().includes(searchTerm.toLowerCase()) ||
      memory.analysis?.topics?.some(topic => 
        topic.toLowerCase().includes(searchTerm.toLowerCase())
      );
    
    // Filter by memory type (omi vs loob)
    const matchesType = memoryTypeView === 'all' 
      ? true 
      : memoryTypeView === 'omi'
        ? memory.metadata?.source === 'omi'
        : memory.metadata?.source !== 'omi'; // anything that's not omi is considered loob
    
    return matchesSearch && matchesType;
  });

  const groupedMemories = groupMemoriesByDate(filteredMemories);
  const dates = Object.keys(groupedMemories).sort((a, b) => 
    new Date(b).getTime() - new Date(a).getTime()
  );

  const extractKeywords = (content: string): string[] => {
    const words = content.toLowerCase().split(/\W+/);
    const commonWords = new Set(['the', 'and', 'or', 'but', 'in', 'on', 'at', 'to', 'for', 'was', 'is', 'are']);
    return [...new Set(words)]
      .filter(word => word.length > 3 && !commonWords.has(word))
      .slice(0, 5);
  };

  const getMemoryContent = (memory: MemoryEntry) => {
    return (
      <div style={styles.memoryContent}>
        {formatContent(memory)}
        
        {/* Display analysis data if available */}
        {memory.analysis && (
          <div className="memory-analysis-section mt-3 pt-3 border-t border-gray-700">
            {memory.analysis.topics && memory.analysis.topics.length > 0 && (
              <div className="memory-topics mb-2">
                <div className="text-xs text-gray-400 mb-1">Topics:</div>
                <div className="flex flex-wrap gap-1">
                  {memory.analysis.topics.map((topic, i) => (
                    <span 
                      key={i} 
                      className="text-xs bg-blue-900/30 text-blue-200 px-2 py-1 rounded-full"
                    >
                      {topic}
                    </span>
                  ))}
                </div>
              </div>
            )}
            
            {memory.analysis.actionItems && memory.analysis.actionItems.length > 0 && (
              <div className="memory-action-items">
                <div className="text-xs text-gray-400 mb-1">Action Items:</div>
                <ul className="text-sm list-disc list-inside">
                  {memory.analysis.actionItems.map((item, i) => (
                    <li key={i} className="text-gray-300">{item}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  // Helper function to format transcript content with speakers
  const formatContent = (memory: MemoryEntry): JSX.Element => {
    // Check if this is an Omi conversation with speakers
    if (memory.metadata?.hasSpeakers) {
      const lines = memory.content.split('\n\n');
      return (
        <div className="conversation-transcript">
          {lines.map((line, i) => {
            // Check if line has a speaker prefix (e.g., "Speaker 1: ")
            const speakerMatch = line.match(/^(You|Omi|Speaker \d+): (.*)/);
            if (speakerMatch) {
              const [, speaker, text] = speakerMatch;
              const isUser = speaker === 'You';
              return (
                <div 
                  key={i} 
                  style={{
                    ...styles.conversationLine,
                    ...(isUser ? styles.userLine : {})
                  }}
                >
                  <span 
                    style={{
                      ...styles.speakerLabel,
                      ...(isUser ? styles.userSpeakerLabel : {})
                    }}
                  >
                    {speaker}:
                  </span>
                  <span className="speaker-text ml-2">{text}</span>
                </div>
              );
            }
            // Regular line without speaker
            return <p key={i} className="mb-2">{line}</p>;
          })}
        </div>
      );
    }

    // For structured data from Omi that doesn't have conversation format
    if (memory.metadata?.formatVersion === 'v2' && 
        (memory.metadata?.originalData?.structured || memory.analysis?.overview)) {
      const structured = memory.metadata.originalData?.structured || {};
      const title = memory.analysis?.title || structured.title;
      const overview = memory.analysis?.overview || structured.overview;
      
      if (title || overview) {
        return (
          <div className="structured-memory">
            {title && (
              <h4 className="text-lg font-medium mb-2 text-blue-300">{title}</h4>
            )}
            {overview && (
              <p className="text-gray-300 mb-2">{overview}</p>
            )}
            <div className="whitespace-pre-wrap">{memory.content}</div>
          </div>
        );
      }
    }
    
    // Daily dump or other content types
    // Split paragraphs for better readability
    const paragraphs = memory.content.split('\n\n');
    if (paragraphs.length > 1) {
      return (
        <div className="whitespace-pre-line">
          {paragraphs.map((paragraph, i) => (
            <p key={i} className="mb-2">{paragraph}</p>
          ))}
        </div>
      );
    }

    // Default rendering for regular content
    return <div className="whitespace-pre-wrap">{memory.content}</div>;
  };

  const toggleOmiInstructions = () => {
    setShowOmiInstructions(!showOmiInstructions);
  };

  const renderMemoryItem = (memory: MemoryEntry) => {
    const source = memory.metadata?.source || 'unknown';
    const sourceLabel = source === 'omi' 
      ? 'Omi' 
      : 'Loob';
    
    // Determine source style
    const sourceStyle = {
      ...styles.memorySource,
      ...(source === 'omi' ? styles.omiSource : styles.loobSource)
    };

    // Get formatted date
    const memoryDate = format(parseISO(memory.createdAt), 'MMMM d, yyyy');
    const memoryTime = format(parseISO(memory.createdAt), 'h:mm a');

  return (
      <div 
        style={styles.memoryCard} 
        className="memory-card hover:shadow-lg"
        key={memory.id}
      >
        <div style={styles.memoryDate} className="memory-date flex justify-between">
          <div className="flex items-center">
            <span>{memoryDate}</span>
            <span style={sourceStyle}>{sourceLabel}</span>
          </div>
          <div className="text-gray-400 text-xs">{memoryTime}</div>
        </div>
        {getMemoryContent(memory)}
      </div>
    );
  };

  const handleMemorySelect = (memoryId: string) => {
    setSelectedMemoryId(memoryId);
    // Find the memory in our list
    const memory = memories.find(m => m.id === memoryId);
    if (memory) {
      // Set the date to ensure we show this memory in the list view
      const date = format(parseISO(memory.createdAt), 'MMMM d, yyyy');
      setSelectedDate(date);
    }
  };

  return (
    <div className="memory-bank-overlay">
      <div className="memory-bank-container w-full h-full flex flex-col bg-gray-900 text-white rounded-lg shadow-xl overflow-hidden">
        <div className="memory-bank-header p-4 flex flex-col sm:flex-row justify-between items-start sm:items-center border-b border-gray-700">
          <div className="flex items-center justify-between w-full sm:w-auto">
            <h2 className="text-xl font-semibold">Memory Archive</h2>
            
            {/* Close button for mobile - top right */}
            <button
              onClick={onClose}
              className="sm:hidden text-gray-400 hover:text-white transition-colors"
              aria-label="Close memory bank"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
        </div>

          <div className="flex mt-3 sm:mt-0 justify-between w-full sm:w-auto sm:justify-end">
            {/* Search bar */}
            <div className="relative mr-2 flex-grow sm:flex-grow-0 sm:w-64">
              <input
                type="text"
                placeholder="Search memories..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full bg-gray-800 text-white border border-gray-700 rounded-lg py-1 px-3 pr-8 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
              />
              {searchTerm && (
                <button 
                  onClick={() => setSearchTerm('')}
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-white"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              )}
            </div>
            
            {/* Controls for desktop */}
            <div className="hidden sm:flex items-center space-x-2">
              <button
                onClick={handleRefresh}
                className="p-1 text-gray-400 hover:text-white transition-colors"
                aria-label="Refresh memories"
                disabled={isRefreshing}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 ${isRefreshing ? 'animate-spin' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                </svg>
              </button>
              <button
                onClick={onClose}
                className="p-1 text-gray-400 hover:text-white transition-colors"
                aria-label="Close memory bank"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
          </div>
            
            {/* Mobile refresh button */}
            <button
              onClick={handleRefresh}
              className="sm:hidden ml-2 p-1 text-gray-400 hover:text-white transition-colors"
              aria-label="Refresh memories"
              disabled={isRefreshing}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 ${isRefreshing ? 'animate-spin' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
              </svg>
            </button>
          </div>
        </div>
        
        {/* View mode selector */}
        <div className="bg-gray-800 px-4 py-2 border-b border-gray-700">
          <div className="flex space-x-4 overflow-x-auto scrollbar-hide">
            <button
              onClick={() => setViewMode('list')}
              className={`py-2 px-3 text-sm font-medium transition-all ${
                viewMode === 'list'
                  ? 'border-b-2 border-purple-500 text-white'
                  : 'text-gray-400 hover:text-gray-300'
              }`}
              role="tab"
              aria-selected={viewMode === 'list'}
            >
              List
            </button>
            <button
              onClick={() => setViewMode('graph')}
              className={`py-2 px-3 text-sm font-medium transition-all ${
                viewMode === 'graph'
                  ? 'border-b-2 border-purple-500 text-white'
                  : 'text-gray-400 hover:text-gray-300'
              }`}
              role="tab"
              aria-selected={viewMode === 'graph'}
            >
              Graph
            </button>
          </div>
        </div>

        {/* Memory type filter - conditionally render for list view only */}
        {viewMode === 'list' && (
          <div className="bg-gray-800 px-4 border-b border-gray-700">
            <div className="flex space-x-2 overflow-x-auto scrollbar-hide">
              <button
                onClick={() => setMemoryTypeView('all')}
                className={`py-2 px-3 text-sm font-medium transition-all ${
                  memoryTypeView === 'all'
                    ? 'border-b-2 border-purple-500 text-white'
                    : 'text-gray-400 hover:text-gray-300'
                }`}
                role="tab"
                aria-selected={memoryTypeView === 'all'}
              >
                All
              </button>
              <button
                onClick={() => setMemoryTypeView('omi')}
                className={`py-2 px-3 text-sm font-medium transition-all ${
                  memoryTypeView === 'omi'
                    ? 'border-b-2 border-purple-500 text-white'
                    : 'text-gray-400 hover:text-gray-300'
                }`}
                role="tab"
                aria-selected={memoryTypeView === 'omi'}
              >
                Omi
              </button>
              <button
                onClick={() => setMemoryTypeView('other')}
                className={`py-2 px-3 text-sm font-medium transition-all ${
                  memoryTypeView === 'other'
                    ? 'border-b-2 border-purple-500 text-white'
                    : 'text-gray-400 hover:text-gray-300'
                }`}
                role="tab"
                aria-selected={memoryTypeView === 'other'}
              >
                Loob
              </button>
            </div>
          </div>
        )}

        {/* Add helpful message when no memories */}
        {!loading && memories.length === 0 && (
          <div className="flex items-center justify-center h-full p-6">
            <div className="text-center">
              <div className="mb-4 text-6xl opacity-25">🧠</div>
              <h3 className="text-xl mb-3">No memories found</h3>
              <p className="text-gray-400 mb-4 max-w-sm">
                Create your first memory using the Create a Memory feature or connect your Omi device.
              </p>
              <div className="flex justify-center gap-4">
                <button
                  onClick={() => {
                    onClose();
                    if (setView) setView('Profile');
                  }}
                  className="px-4 py-2 bg-blue-600 hover:bg-blue-500 rounded-lg text-white"
                >
                  Create a Memory
                </button>
                <button
                  onClick={toggleOmiInstructions}
                  className="px-4 py-2 bg-gray-700 hover:bg-gray-600 rounded-lg text-white"
                >
                  Connect Omi
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="memory-bank-content flex-1 p-6 overflow-y-auto">
          {/* Loading state */}
          {loading && memories.length === 0 && (
            <div className="flex justify-center items-center h-40">
              <div className="animate-pulse text-blue-300">
                <svg className="w-10 h-10 animate-spin" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            </div>
          )}
          
          {/* Error state */}
          {error && (
            <div className="bg-red-900/30 border border-red-800 text-red-300 px-4 py-3 rounded-lg mb-6">
              {error}
            </div>
          )}
          
          {/* Memory list - only visible in list view */}
          {viewMode === 'list' && !loading && dates.length > 0 && (
            <div className="space-y-4">
              {dates.map(date => (
                <div key={date} className="mb-4">
                  <h3 className="text-lg font-medium text-gray-200 sticky top-0 bg-gray-900/95 backdrop-blur-sm py-2 z-10">
                    {date}
                  </h3>
                  <div className="space-y-3 mt-2">
                    {groupedMemories[date].map(memory => renderMemoryItem(memory))}
                  </div>
                </div>
              ))}
            </div>
          )}
          
          {/* Knowledge Graph view */}
          {viewMode === 'graph' && !loading && memories.length > 0 && (
            <div className="flex flex-col h-full">
              <div className="mb-2 text-sm text-gray-400">
                Visualizing connections between your memories. Click nodes to explore relationships.
              </div>
              <div className="flex-1 bg-gray-800 rounded-lg overflow-hidden">
                <MemoryGraph 
                  selectedMemoryId={selectedMemoryId || undefined}
                  onMemorySelect={handleMemorySelect}
                  width={window.innerWidth - 100}
                  height={window.innerHeight - 300}
                />
              </div>
              
              {/* Selected memory details */}
              {selectedMemoryId && (
                <div className="mt-4 p-4 bg-gray-800/60 rounded-lg">
                  <div className="text-sm text-gray-400 mb-2">Selected Memory:</div>
                  {memories.find(m => m.id === selectedMemoryId) ? (
                    <div>
                      {getMemoryContent(memories.find(m => m.id === selectedMemoryId)!)}
                    </div>
                  ) : (
                    <div className="text-gray-400">Memory not found in current view. Try adjusting your filters.</div>
                  )}
                </div>
              )}
            </div>
          )}
          
          {/* No results state */}
          {!loading && memories.length > 0 && dates.length === 0 && viewMode === 'list' && (
            <div className="text-center py-6">
              <p className="text-gray-400">No memories match your search.</p>
              <button
                onClick={() => {
                  setSearchTerm('');
                  setMemoryTypeView('all');
                }}
                className="mt-2 text-blue-400 hover:text-blue-300"
              >
                Clear filters
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};