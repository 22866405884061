"use client";

import React, { useState, useEffect } from "react";
import {
  UserIcon,
  MapIcon,
  Cog6ToothIcon,
  QrCodeIcon,
  PlusCircleIcon,
  ArrowLeftIcon,
} from "@heroicons/react/24/outline";
import "./Header.css";
import Image from 'next/image';
import { Settings } from './Settings';
import { ViewType } from './HomePage';

interface HeaderProps {
  toggleView: (view: ViewType) => void;
  activeView: ViewType;
  onConfigureClick: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  toggleView,
  activeView,
}) => {
  // Determine if we're in a special view that needs a back button
  const showBackButton = activeView === "LoobricateProfile";
  
  // Add state for settings modal
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [selectedLlm, setSelectedLlm] = useState<string>('gpt-3.5-turbo');
  
  // Add state to track if we're on mobile
  const [isMobile, setIsMobile] = useState(false);
  
  // Add state to track the last main navigation selection (Chat or Map)
  const [lastMainView, setLastMainView] = useState<"Chat" | "Map">("Chat");
  
  // Update the selected LLM when settings are opened
  useEffect(() => {
    if (isSettingsOpen && typeof window !== 'undefined') {
      setSelectedLlm(localStorage.getItem('llm') || 'gpt-3.5-turbo');
    }
  }, [isSettingsOpen]);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 640);
    };
    
    // Set the correct mobile state
    if (typeof window !== 'undefined') {
      checkIfMobile();
      window.addEventListener('resize', checkIfMobile);
    }
    
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', checkIfMobile);
      }
    };
  }, []);
  
  // Update lastMainView only when Chat or Map is explicitly selected
  useEffect(() => {
    if (activeView === "Chat" || activeView === "Map") {
      setLastMainView(activeView);
    }
  }, [activeView]);
  
  // Custom handler for main navigation views to update the lastMainView
  const handleMainViewToggle = (view: "Chat" | "Map") => {
    setLastMainView(view);
    toggleView(view);
  };
  
  return (
    <>
      <header className="header-container" style={{ zIndex: 50 }}>
        {/* Left Section */}
        <div className={`left-icons ${isMobile ? 'mobile-left-icons' : ''}`}>
          {showBackButton ? (
            // Back button for LoobricateProfile view
            <button
              className="icon-button"
              onClick={() => toggleView("Map")}
              aria-label="Back to Map"
            >
              <ArrowLeftIcon className="h-6 w-6" />
            </button>
          ) : (
            <>
              {/* Add Entry Icon */}
              <button
                className={`icon-button ${
                  activeView === "AddEntry" ? "active" : ""
                }`}
                onClick={() => toggleView("AddEntry")}
                aria-label="Add Entry"
              >
                <PlusCircleIcon className="h-6 w-6" />
              </button>

              {/* NFC Reader Icon */}
              <button
                className={`icon-button ${
                  activeView === "NFCReader" ? "active" : ""
                }`}
                onClick={() => toggleView("NFCReader")}
                aria-label="NFC Reader"
              >
                <QrCodeIcon className="h-6 w-6" />
              </button>
            </>
          )}
        </div>

        <div className={`slider-container ${isMobile ? 'mobile-slider-container' : ''}`}>
          <div
            className={`slider-pill ${
              lastMainView === "Chat" ? "active-chat" : "active-discover"
            }`}
          ></div>
          <button
            className={`slider-button ${
              activeView === "Chat" ? "active" : ""
            }`}
            onClick={() => handleMainViewToggle("Chat")}
          >
            <Image 
              src="/favicon.ico"
              alt="Loob"
              width={40}
              height={40}
              className="mr-1"
            />
            {!isMobile && <span className="ml-1">Chat</span>}
          </button>

          <button
            className={`slider-button ${
              activeView === "Map" || activeView === "LoobricateProfile" ? "active" : ""
            }`}
            onClick={() => handleMainViewToggle("Map")}
          >
            {!isMobile && <span className="mr-1">Explore</span>}
            <MapIcon className="h-6 w-6 ml-1" />
          </button>
        </div>

        {/* Right Section */}
        <div className={`right-icons ${isMobile ? 'mobile-right-icons' : ''}`}>
          {/* Profile Icon */}
          <button
            className={`icon-button ${
              activeView === "Profile" ? "active" : ""
            }`}
            onClick={() => toggleView("Profile")}
            aria-label="Profile"
          >
            <UserIcon className="h-6 w-6" />
          </button>

          {/* Settings Icon */}
          <button
            className="icon-button"
            onClick={() => setIsSettingsOpen(true)}
            aria-label="Settings"
          >
            <Cog6ToothIcon className="h-6 w-6" />
          </button>
        </div>
      </header>
      
      {/* Settings Modal */}
      <Settings
        isOpen={isSettingsOpen}
        onClose={() => setIsSettingsOpen(false)}
        useRag={true} // Default to true since you mentioned RAG is always on
        llm={selectedLlm}
        similarityMetric="cosine" // Default similarity metric
        setConfiguration={() => {}} // Empty function since you don't need this anymore
      />
    </>
  );
};

