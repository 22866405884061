"use client";

import React, { useEffect, useRef, useState } from 'react';
import { useKnowledgeGraph } from '../hooks/useKnowledgeGraph';
import { useGlobalState } from './GlobalStateContext';
import dynamic from 'next/dynamic';

// Dynamically import the graph components to avoid SSR issues
const ForceGraph2D = dynamic(() => 
  import('react-force-graph-2d').catch(err => {
    console.error('Failed to load ForceGraph2D component:', err);
    // Return a minimal component as fallback
    return ({ nodes, links, ...props }) => (
      <div className="graph-error-container">
        <p>Could not load graph visualization component</p>
      </div>
    );
  }), 
  { ssr: false, loading: () => <div className="graph-loading">Loading graph...</div> }
);

const ForceGraph3D = dynamic(() => 
  import('react-force-graph-3d').catch(err => {
    console.error('Failed to load ForceGraph3D component:', err);
    // Return a minimal component as fallback
    return ({ nodes, links, ...props }) => (
      <div className="graph-error-container">
        <p>Could not load 3D graph visualization component</p>
      </div>
    );
  }), 
  { ssr: false, loading: () => <div className="graph-loading">Loading 3D graph...</div> }
);

// Graph data interface
interface GraphData {
  nodes: Array<{
    id: string;
    name: string;
    val?: number;
    color?: string;
    type?: string;
  }>;
  links: Array<{
    source: string;
    target: string;
    value?: number;
    label?: string;
  }>;
}

// Memory node colors by type
const NODE_COLORS = {
  omi: '#7C3AED', // purple
  loob: '#10B981', // green
  user: '#3B82F6', // blue
  default: '#6B7280', // gray
};

interface MemoryGraphProps {
  selectedMemoryId?: string;
  onMemorySelect?: (memoryId: string) => void;
  is3D?: boolean;
  width?: number;
  height?: number;
}

export const MemoryGraph: React.FC<MemoryGraphProps> = ({
  selectedMemoryId,
  onMemorySelect,
  is3D = false,
  width = 600,
  height = 400,
}) => {
  const { user } = useGlobalState();
  const { isReady, error, queryMemories, getConnections } = useKnowledgeGraph();
  const [graphData, setGraphData] = useState<GraphData>({ nodes: [], links: [] });
  const [loading, setLoading] = useState(true);
  const [graphError, setGraphError] = useState<string | null>(null);
  const graphRef = useRef<any>();

  // Generate demo data when API is not available
  const generateDemoData = (): GraphData => {
    // Create some interesting demo nodes
    const nodes = [
      { id: "mem1", name: "Today I went for a run", val: 1, color: NODE_COLORS.loob, type: "loob" },
      { id: "mem2", name: "Planning trip to Japan", val: 1, color: NODE_COLORS.loob, type: "loob" },
      { id: "mem3", name: "Meeting with creative team", val: 1, color: NODE_COLORS.omi, type: "omi" },
      { id: "mem4", name: "New recipe for pasta", val: 1, color: NODE_COLORS.loob, type: "loob" },
      { id: "mem5", name: "Call with mom about birthday", val: 1, color: NODE_COLORS.omi, type: "omi" },
      { id: "mem6", name: "Ideas for new project", val: 1, color: NODE_COLORS.loob, type: "loob" },
      { id: "mem7", name: "Workout routine for next week", val: 1, color: NODE_COLORS.omi, type: "omi" },
      { id: "mem8", name: "Books to read this summer", val: 1, color: NODE_COLORS.loob, type: "loob" },
      { id: "mem9", name: "Research on machine learning", val: 1, color: NODE_COLORS.omi, type: "omi" },
      { id: "mem10", name: "Planning birthday party", val: 1, color: NODE_COLORS.loob, type: "loob" },
    ];

    // Create some connections between nodes
    const links = [
      { source: "mem1", target: "mem7", value: 1, label: "exercise" },
      { source: "mem2", target: "mem8", value: 1, label: "planning" },
      { source: "mem3", target: "mem6", value: 1, label: "work" },
      { source: "mem4", target: "mem10", value: 1, label: "food" },
      { source: "mem5", target: "mem10", value: 1, label: "family" },
      { source: "mem6", target: "mem9", value: 1, label: "ideas" },
      { source: "mem7", target: "mem8", value: 1, label: "personal" },
      { source: "mem2", target: "mem4", value: 1, label: "planning" },
      { source: "mem1", target: "mem2", value: 1, label: "personal" },
    ];

    return { nodes, links };
  };

  // Load graph data
  useEffect(() => {
    async function loadGraphData() {
      try {
        setLoading(true);
        setGraphError(null);

        // If we're in demo mode or API isn't ready, use demo data
        if (!isReady || !user?.userId) {
          console.log('Using demo data for graph visualization');
          const demoData = generateDemoData();
          setGraphData(demoData);
          setLoading(false);
          return;
        }

        // Start with recent memories as initial nodes
        let recentMemories = [];
        try {
          recentMemories = await queryMemories('', 20);
        } catch (err) {
          console.error('Error querying memories, using demo data instead:', err);
          const demoData = generateDemoData();
          setGraphData(demoData);
          setLoading(false);
          return;
        }
        
        const nodes: GraphData['nodes'] = [];
        const links: GraphData['links'] = [];
        const nodeMap = new Set<string>();

        // Process nodes
        recentMemories.forEach((memory: any) => {
          if (!nodeMap.has(memory.id)) {
            nodeMap.add(memory.id);
            nodes.push({
              id: memory.id,
              name: memory.content.substring(0, 30) + (memory.content.length > 30 ? '...' : ''),
              val: 1,
              color: NODE_COLORS[memory.source] || NODE_COLORS.default,
              type: memory.source,
            });
          }
        });

        // If we have a selected memory, load its connections
        if (selectedMemoryId) {
          try {
            const connections = await getConnections(selectedMemoryId);
            
            // Add connected nodes
            connections.nodes.forEach((node: any) => {
              if (!nodeMap.has(node.id)) {
                nodeMap.add(node.id);
                nodes.push({
                  id: node.id,
                  name: node.content.substring(0, 30) + (node.content.length > 30 ? '...' : ''),
                  val: 1,
                  color: NODE_COLORS[node.source] || NODE_COLORS.default,
                  type: node.source,
                });
              }
            });
            
            // Add links
            connections.links.forEach((link: any) => {
              links.push({
                source: link.source,
                target: link.target,
                value: link.strength || 1,
                label: link.type || 'related',
              });
            });
          } catch (err) {
            console.error('Error getting connections, continuing with nodes only:', err);
          }
        }

        // If we don't have enough real connections, add some demo ones
        if (links.length < 3 && nodes.length > 2) {
          // Create some random connections between nodes
          const nodeIds = nodes.map(n => n.id);
          for (let i = 0; i < Math.min(5, nodeIds.length); i++) {
            const source = nodeIds[Math.floor(Math.random() * nodeIds.length)];
            let target;
            do {
              target = nodeIds[Math.floor(Math.random() * nodeIds.length)];
            } while (source === target);
            
            links.push({
              source,
              target,
              value: 1,
              label: 'related',
            });
          }
        }

        setGraphData({ nodes, links });
      } catch (err) {
        console.error('Error loading graph data:', err);
        setGraphError('Failed to load knowledge graph data');
        // Use demo data as fallback
        const demoData = generateDemoData();
        setGraphData(demoData);
      } finally {
        setLoading(false);
      }
    }

    loadGraphData();
  }, [isReady, user?.userId, selectedMemoryId, queryMemories, getConnections]);

  // Center graph on selected node
  useEffect(() => {
    if (graphRef.current && selectedMemoryId) {
      const node = graphData.nodes.find(n => n.id === selectedMemoryId);
      if (node) {
        graphRef.current.centerAt(node.x, node.y, 1000);
        if (is3D) {
          graphRef.current.zoomToFit(400);
        }
      }
    }
  }, [selectedMemoryId, graphData.nodes, is3D]);

  const handleNodeClick = (node: any) => {
    if (onMemorySelect) {
      onMemorySelect(node.id);
    }
  };

  if (error) {
    // Display a more informative error for API key issues
    if (error.includes('API key')) {
      return (
        <div className="bg-red-900/30 border border-red-500 rounded-lg p-4 m-2 text-red-300">
          <h3 className="font-semibold text-red-200 mb-2">Configuration Error</h3>
          <p className="mb-2">{error}</p>
          <p className="text-sm text-red-400">
            Please ensure the <code className="bg-red-950/50 px-1 rounded">SMITHERY_API_KEY</code> 
            is set in the <code className="bg-red-950/50 px-1 rounded">.env.local</code> file on the server.
          </p>
        </div>
      );
    }
    
    return <div className="text-red-400 p-4">Error: {error}</div>;
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="animate-pulse text-blue-300">
          <svg className="w-10 h-10 animate-spin" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      </div>
    );
  }

  if (graphError) {
    return <div className="text-red-400 p-4">Error loading graph: {graphError}</div>;
  }

  if (!graphData.nodes.length) {
    return (
      <div className="flex justify-center items-center h-full p-4 text-center">
        <div>
          <p className="text-gray-400 mb-2">No memory nodes available yet.</p>
          <p className="text-sm text-gray-500">Create more memories to start visualizing connections between them.</p>
        </div>
      </div>
    );
  }

  const commonProps = {
    graphData,
    nodeLabel: (node: any) => node.name,
    linkLabel: (link: any) => link.label || 'related',
    nodeColor: (node: any) => node.color,
    onNodeClick: handleNodeClick,
    width,
    height,
    ref: graphRef,
    cooldownTicks: 100,
    linkWidth: (link: any) => (link.source.id === selectedMemoryId || link.target.id === selectedMemoryId) ? 3 : 1,
    nodeCanvasObject: (node: any, ctx: CanvasRenderingContext2D, globalScale: number) => {
      // Node circle
      const label = node.name;
      const fontSize = 12/globalScale;
      const isSelected = node.id === selectedMemoryId;
      
      // Draw node
      ctx.beginPath();
      ctx.arc(node.x, node.y, isSelected ? 8 : 5, 0, 2 * Math.PI);
      ctx.fillStyle = node.color;
      ctx.fill();
      
      if (isSelected) {
        ctx.strokeStyle = '#ffffff';
        ctx.lineWidth = 2/globalScale;
        ctx.stroke();
      }
      
      // Draw label if close enough
      if (globalScale >= 0.8) {
        ctx.font = `${fontSize}px Sans-Serif`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = 'white';
        
        // Background for text
        const textWidth = ctx.measureText(label).width;
        ctx.fillStyle = 'rgba(0, 0, 0, 0.6)';
        ctx.fillRect(
          node.x - textWidth/2 - 2,
          node.y + 8,
          textWidth + 4,
          fontSize + 2
        );
        
        // Text
        ctx.fillStyle = 'white';
        ctx.fillText(label, node.x, node.y + 8 + fontSize/2);
      }
    }
  };

  return (
    <div className="memory-graph-container relative bg-gray-900 rounded-xl overflow-hidden">
      {/* Conditionally render 2D or 3D graph */}
      {is3D ? (
        typeof window !== 'undefined' && (
          <ForceGraph3D
            {...commonProps}
            nodeAutoColorBy="type"
            linkDirectionalArrowLength={3.5}
            linkDirectionalArrowRelPos={1}
            linkCurvature={0.25}
            backgroundColor="#111827"
          />
        )
      ) : (
        typeof window !== 'undefined' && (
          <ForceGraph2D
            {...commonProps}
            linkDirectionalArrowLength={4}
            linkDirectionalArrowRelPos={1}
            linkCurvature={0.25}
            backgroundColor="#111827"
          />
        )
      )}
      
      <div className="absolute bottom-2 left-2 text-xs text-gray-400">
        {graphData.nodes.length} memories, {graphData.links.length} connections
      </div>
    </div>
  );
}; 