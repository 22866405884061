import { PromptSuggestionButton } from "./PromptSuggestionButton";
import { useGlobalState } from "../GlobalStateContext";
import { motion, AnimatePresence } from "framer-motion";
import { Companion } from "../GlobalStateContext";
import React from 'react';

// Helper function to resolve companion ID to companion object
const resolveCompanion = (companion: string | Companion | null): Companion | null => {
  if (!companion) return null;
  if (typeof companion === 'string') {
    // This is just a placeholder, in a real implementation this would fetch the companion
    return {
      id: companion,
      name: companion,
      icon: '🤖',
      isBaseCompanion: false
    };
  }
  return companion;
};

const companionPrompts = {
  'logis': [
    "Help me plan an event layout",
    "Track equipment inventory",
    "Create a budget breakdown"
  ],
  'harmoni': [
    "Guide me through a vibe check",
    "Suggest music for the mood",
    "Safety guidelines for the space"
  ],
  'nexus': [
    "Find collaboration opportunities",
    "Connect with similar projects",
    "Map community connections"
  ],
  'base-loob': [
    "How do I use LOOB?",
    "Tell me about LOOBRARY",
    "Add to LOOBRARY"
  ],
  'ash': [
    "Help me find camps at Burning Man",
    "Playa survival guide tips",
    "Explain the 10 principles"
  ]
};

// Default prompts for anonymous users (same as base-loob but without resource management)
const defaultPrompts = [
  "How do I use LOOB?",
  "Tell me about LOOBRARY",
  "What can LOOB do?"
];

interface PromptSuggestionsRowProps {
  onPromptClick: (prompt: string) => void;
  hasMessages?: boolean;
}

const PromptSuggestionsRow: React.FC<PromptSuggestionsRowProps> = ({ onPromptClick, hasMessages = false }) => {
  const { activeCompanion, isAnonymous } = useGlobalState();
  const resolvedCompanion = resolveCompanion(activeCompanion);
  
  const prompts = (!isAnonymous && resolvedCompanion?.id) 
    ? companionPrompts[resolvedCompanion.id] || defaultPrompts
    : defaultPrompts;

  return (
    <div className="flex flex-col gap-4">
      {/* Regular prompt suggestions - only show when no messages */}
      <AnimatePresence>
        {!hasMessages && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="flex flex-row flex-wrap items-center justify-center gap-3 py-6 px-2 opacity-90"
          >
            {prompts.map((prompt, index) => (
              <PromptSuggestionButton
                key={`suggestion-${index}`}
                text={prompt}
                onClick={() => onPromptClick(prompt)}
              />
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export { PromptSuggestionsRow };
