'use client';

import React, { useState, useEffect, useRef } from 'react';
import { FaTimes, FaUserPlus, FaUserMinus, FaCamera, FaImage } from 'react-icons/fa';
import './LoobricateAdminPanel.css';
import type { LoobricateData } from '../types/loobricate';
import debounce from 'lodash/debounce';
import { uploadImage } from '../lib/supabase/client';
import { useGlobalState } from './GlobalStateContext';

interface LoobricateAdminPanelProps {
  loobricateId: string;
  onClose: () => void;
  onUpdate: (updatedLoobricate: LoobricateData) => void;
  onViewProfile?: () => void;
}

// Add interface for user suggestions
interface UserSuggestion {
  id: string;
  pseudonym: string;
}

export const LoobricateAdminPanel: React.FC<LoobricateAdminPanelProps> = ({
  loobricateId,
  onClose,
  onUpdate,
  onViewProfile
}) => {
  const { userId, isAnonymous } = useGlobalState();
  const [loobricate, setLoobricate] = useState<LoobricateData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>('');
  // Initialize all state upfront, before any conditionals
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    addressLine1: '',
    city: '',
    adminUsername: '',
    adminPassword: '',
    tags: [] as string[],
    type: 'community'
  });
  const [users, setUsers] = useState({
    admins: [] as string[],
    members: [] as string[]
  });
  const [newUser, setNewUser] = useState('');
  const [userError, setUserError] = useState('');
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [userSuggestions, setUserSuggestions] = useState<UserSuggestion[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const suggestionsRef = useRef<HTMLDivElement>(null);
  const [profilePhoto, setProfilePhoto] = useState<string | null>(null);
  const [coverPhoto, setCoverPhoto] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const coverInputRef = useRef<HTMLInputElement>(null);

  // Add authentication check at component level
  useEffect(() => {
    if (isAnonymous || !userId || userId.startsWith('anon-')) {
      // Redirect to login
      onClose();
      window.location.reload(); // Force reload to show login screen
    }
  }, [userId, isAnonymous, onClose]);

  // Create debounced search function
  const debouncedSearch = debounce(async (query: string) => {
    if (!query.trim()) {
      setUserSuggestions([]);
      return;
    }

    try {
      const response = await fetch(`/api/users/search?q=${encodeURIComponent(query)}`);
      const data = await response.json();
      
      if (response.ok) {
        // Filter out users that are already members or admins
        const existingUsers = new Set([...users.members, ...users.admins]);
        setUserSuggestions(
          data.users.filter((user: UserSuggestion) => !existingUsers.has(user.pseudonym))
        );
      } else {
        console.error('Failed to fetch user suggestions:', data.error);
      }
    } catch (error) {
      console.error('Error fetching user suggestions:', error);
    }
  }, 300);

  // Handle input changes for user search
  const handleUserSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setNewUser(value);
    debouncedSearch(value);
    setShowSuggestions(true);
  };

  // Handle suggestion selection
  const handleSelectUser = async (user: UserSuggestion) => {
    setNewUser(user.pseudonym);
    setShowSuggestions(false);

    try {
      // First, update the loobricate with the new member
      const updatedLoobricate = {
        ...loobricate,
        members: [...users.members, user.pseudonym]
      };

      const loobricateResponse = await fetch(`/api/loobricates/${loobricateId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedLoobricate)
      });

      if (!loobricateResponse.ok) {
        throw new Error('Failed to update loobricate');
      }

      // Then, update the user's connectedLoobricates
      const userResponse = await fetch(`/api/users/${user.id}/loobricates`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          loobricateId,
          action: 'add'
        })
      });

      if (!userResponse.ok) {
        throw new Error('Failed to update user connections');
      }

      // Update local state
      setUsers(prev => ({
        ...prev,
        members: [...prev.members, user.pseudonym]
      }));
      
      setNewUser('');
      setHasChanges(true);
    } catch (error) {
      console.error('Error adding user:', error);
      setUserError('Failed to add user to loobricate');
    }
  };

  // Close suggestions when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target as Node)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Update formData and users when loobricate data is loaded
  useEffect(() => {
    if (loobricate) {
      setFormData({
        name: loobricate.name || '',
        description: loobricate.description || '',
        addressLine1: loobricate.addressLine1 || '',
        city: loobricate.city || '',
        adminUsername: loobricate.adminUsername || '',
        adminPassword: '',
        tags: loobricate.tags || [],
        type: loobricate.type || 'community'
      });
      setUsers({
        admins: loobricate.admins || [],
        members: loobricate.members || []
      });
      setProfilePhoto(loobricate.profilePhoto || null);
      setCoverPhoto(loobricate.coverPhoto || null);
    }
  }, [loobricate]);

  useEffect(() => {
    const fetchLoobricateData = async () => {
      try {
        setIsLoading(true);
        setError('');
        const response = await fetch(`/api/loobricates/${loobricateId}`);
        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error || 'Failed to fetch loobricate data');
        }

        setLoobricate(data);
      } catch (error) {
        console.error('Error fetching loobricate data:', error);
        setError('Failed to load loobricate data. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchLoobricateData();
  }, [loobricateId]);

  // Handle loading and error states
  if (isLoading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;
  if (!loobricate) return <div className="error">No loobricate data found</div>;

  // Handle input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setHasChanges(true);
  };

  // Handle tag changes
  const handleTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const tags = e.target.value.split(',').map(tag => tag.trim());
    setFormData(prev => ({ ...prev, tags }));
    setHasChanges(true);
  };

  // Verify user exists before adding
  const verifyUser = async (username: string) => {
    try {
      const response = await fetch('/api/auth/verify-user', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username })
      });
      
      if (!response.ok) {
        throw new Error('User not found');
      }
      
      return true;
    } catch (error) {
      return false;
    }
  };

  // Add user handler
  const handleAddUser = async (e: React.FormEvent) => {
    e.preventDefault();
    setUserError('');
    setIsAddingUser(true);

    try {
      const userExists = await verifyUser(newUser);
      
      if (!userExists) {
        setUserError('User not found');
        return;
      }

      setUsers(prev => ({
        ...prev,
        members: [...prev.members, newUser]
      }));
      
      setNewUser('');
      setHasChanges(true);
    } catch (error) {
      setUserError('Failed to add user');
    } finally {
      setIsAddingUser(false);
    }
  };

  // Enhanced user management
  const handleRemoveUser = async (username: string, type: 'member' | 'admin') => {
    try {
      // First update the loobricate
      const updatedUsers = {
        ...users,
        [type + 's']: users[type + 's'].filter(u => u !== username)
      };

      const updatedData = {
        ...loobricate,
        members: updatedUsers.members,
        admins: updatedUsers.admins
      };

      const loobricateResponse = await fetch(`/api/loobricates/${loobricateId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedData)
      });

      if (!loobricateResponse.ok) {
        throw new Error('Failed to update loobricate');
      }

      // Then update the user's connections
      const userResponse = await fetch(`/api/users/by-username/${username}/loobricates`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          loobricateId,
          action: 'remove'
        })
      });

      if (!userResponse.ok) {
        throw new Error('Failed to update user connections');
      }

      setUsers(updatedUsers);
      setHasChanges(true);
    } catch (error) {
      console.error('Error removing user:', error);
      setUserError('Failed to remove user. Please try again.');
    }
  };

  const handlePhotoUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    type: 'profile' | 'cover'
  ) => {
    const file = e.target.files?.[0];
    if (!file) return;

    try {
      if (!file.type.startsWith('image/')) {
        setError('Please upload an image file');
        return;
      }

      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        setError('Image size should be less than 5MB');
        return;
      }

      // Check authentication before proceeding
      if (isAnonymous || !userId || userId.startsWith('anon-')) {
        setError('Please sign in to upload images');
        onClose();
        window.location.reload(); // Force reload to show login screen
        return;
      }

      setIsUploading(true);
      setError('');

      // Use the Supabase uploadImage function with authenticated userId
      const { url, path } = await uploadImage(
        file,
        'entry-photos',
        type === 'profile' ? 'profiles' : 'covers',
        userId
      );

      // Prepare the update data
      const updateData = type === 'profile' 
        ? {
            profilePhoto: url,
            profilePhotoPath: path
          }
        : {
            coverPhoto: url,
            coverPhotoPath: path
          };

      // Update the loobricate with the new photo URL
      const response = await fetch(`/api/loobricates/${loobricateId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updateData),
      });

      if (!response.ok) {
        throw new Error(`Failed to update ${type} photo`);
      }

      const updatedLoobricate = await response.json();

      // Update local state
      if (type === 'profile') {
        setProfilePhoto(url);
      } else {
        setCoverPhoto(url);
      }

      // Update the loobricate state
      setLoobricate(updatedLoobricate);
      setHasChanges(true);

      // Notify parent component
      if (onUpdate) {
        onUpdate(updatedLoobricate);
      }
    } catch (error) {
      console.error('Error uploading photo:', error);
      setError(error instanceof Error ? error.message : `Failed to upload ${type} photo`);
    } finally {
      setIsUploading(false);
    }
  };

  const handleProfilePhotoClick = () => {
    if (!isUploading) {
      fileInputRef.current?.click();
    }
  };

  const handleCoverPhotoClick = () => {
    if (!isUploading) {
      coverInputRef.current?.click();
    }
  };

  // Enhanced save handler with proper state updates
  const handleSave = async () => {
    setIsSaving(true);
    try {
      const updatedData = {
        ...loobricate,
        ...formData,
        members: users.members,
        admins: users.admins,
        profilePhoto,
        coverPhoto
      };

      const response = await fetch(`/api/loobricates/${loobricateId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedData)
      });

      if (!response.ok) {
        throw new Error('Failed to update loobricate');
      }

      const savedData = await response.json();
      setLoobricate(savedData);
      setHasChanges(false);
      
      // Call onUpdate with verified data
      if (onUpdate) {
        onUpdate(savedData);
      }
    } catch (error) {
      console.error('Error saving loobricate:', error);
      setError('Failed to save changes. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  // Prevent closing if there are unsaved changes
  const handleClose = () => {
    if (hasChanges && !window.confirm('You have unsaved changes. Are you sure you want to close?')) {
      return;
    }
    onClose();
  };

  return (
    <div className="loobricate-admin-container">
      {isLoading ? (
        <div className="fullscreen-loading">
          <div className="loading-spinner"></div>
        </div>
      ) : (
        <div className="admin-panel-content">
          <button className="close-button" onClick={onClose}>
            <FaTimes />
          </button>
          <div className="admin-panel">
            <div className="panel-header">
              <h2>Admin Panel - {formData.name}</h2>
              <div className="header-buttons">
                {onViewProfile && (
                  <button 
                    className="view-profile-button"
                    onClick={onViewProfile}
                    disabled={isSaving}
                  >
                    View Profile
                  </button>
                )}
                <button 
                  className="close-button"
                  onClick={handleClose}
                  disabled={isSaving}
                >
                  <FaTimes />
                </button>
              </div>
            </div>

            <div className="admin-content">
              <section className="info-section">
                <h3>Loobricate Information</h3>
                
                <div className="cover-photo-section">
                  <div 
                    className="cover-photo-container"
                    onClick={handleCoverPhotoClick}
                  >
                    {coverPhoto ? (
                      <img src={coverPhoto} alt="Community cover" className="cover-photo" />
                    ) : (
                      <div className="cover-photo-placeholder">
                        <FaImage />
                      </div>
                    )}
                    <div className="cover-photo-overlay">
                      <button className="upload-button" disabled={isUploading}>
                        <FaCamera style={{ marginRight: '0.5rem' }} />
                        {isUploading ? 'Uploading...' : 'Upload Cover'}
                      </button>
                    </div>
                  </div>
                  <input
                    ref={coverInputRef}
                    type="file"
                    accept="image/*"
                    onChange={(e) => handlePhotoUpload(e, 'cover')}
                    className="photo-input"
                  />
                </div>

                <div className="profile-photo-section">
                  <div 
                    className="profile-photo-container"
                    onClick={handleProfilePhotoClick}
                  >
                    {profilePhoto ? (
                      <img src={profilePhoto} alt="Community profile" />
                    ) : (
                      <div className="profile-photo-placeholder">
                        <FaImage />
                      </div>
                    )}
                    <div className="profile-photo-overlay">
                      <button className="upload-button" disabled={isUploading}>
                        <FaCamera style={{ marginRight: '0.5rem' }} />
                        {isUploading ? 'Uploading...' : 'Upload Photo'}
                      </button>
                    </div>
                  </div>
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    onChange={(e) => handlePhotoUpload(e, 'profile')}
                    className="photo-input"
                  />
                </div>

                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="form-input"
                  />
                </div>

                <div className="form-group">
                  <label>Description</label>
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    className="form-input"
                    rows={4}
                  />
                </div>

                <div className="form-group">
                  <label>Address</label>
                  <input
                    type="text"
                    name="addressLine1"
                    value={formData.addressLine1}
                    onChange={handleInputChange}
                    className="form-input"
                  />
                </div>

                <div className="form-group">
                  <label>City</label>
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    className="form-input"
                  />
                </div>

                <div className="form-group">
                  <label>Tags (comma-separated)</label>
                  <input
                    type="text"
                    name="tags"
                    value={formData.tags.join(', ')}
                    onChange={handleTagChange}
                    className="form-input"
                  />
                </div>

                <div className="form-group">
                  <label>Admin Username</label>
                  <input
                    type="text"
                    name="adminUsername"
                    value={formData.adminUsername}
                    onChange={handleInputChange}
                    className="form-input"
                  />
                </div>

                <div className="form-group">
                  <label>Admin Password (leave blank to keep current)</label>
                  <input
                    type="password"
                    name="adminPassword"
                    value={formData.adminPassword}
                    onChange={handleInputChange}
                    className="form-input"
                    placeholder="••••••••"
                  />
                </div>
              </section>

              <section className="users-section">
                <h3>User Management</h3>
                
                <div className="user-lists">
                  <div className="admins-list">
                    <h4>Administrators</h4>
                    <ul>
                      {users.admins.map(admin => (
                        <li key={admin}>
                          {admin}
                          <button 
                            onClick={() => handleRemoveUser(admin, 'admin')}
                            className="remove-user"
                          >
                            <FaUserMinus />
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="members-list">
                    <h4>Members</h4>
                    <ul>
                      {users.members.map(member => (
                        <li key={member}>
                          {member}
                          <button 
                            onClick={() => handleRemoveUser(member, 'member')}
                            className="remove-user"
                          >
                            <FaUserMinus />
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="add-user-container" ref={suggestionsRef}>
                  <input
                    type="text"
                    value={newUser}
                    onChange={handleUserSearch}
                    placeholder="Search for users..."
                    className="form-input"
                  />
                  
                  {showSuggestions && userSuggestions.length > 0 && (
                    <div className="user-suggestions">
                      {userSuggestions.map(user => (
                        <div
                          key={user.id}
                          className="suggestion-item"
                          onClick={() => handleSelectUser(user)}
                        >
                          {user.pseudonym}
                        </div>
                      ))}
                    </div>
                  )}
                  
                  {userError && <p className="error-message">{userError}</p>}
                </div>
              </section>
            </div>

            <div className="panel-footer">
              <button
                className="save-button"
                onClick={handleSave}
                disabled={!hasChanges || isSaving}
              >
                {isSaving ? 'Saving...' : 'Save Changes'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}; 