'use client';

import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './GenUICard.css';

interface CampData {
  name: string;
  description: string;
  location?: string;
  year?: string;
  offerings?: string[] | string;
  events?: string[] | string;
  id?: string;
}

interface CampCardProps {
  campData: CampData;
  className?: string;
  onClose?: () => void;
}

export const CampCard: React.FC<CampCardProps> = ({
  campData,
  className = '',
  onClose
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  // Animation variants for the card
  const cardVariants = {
    collapsed: { height: 'auto', opacity: 1 },
    expanded: { height: 'auto', opacity: 1 }
  };
  
  // Animation variants for the content
  const contentVariants = {
    collapsed: { opacity: 0, height: 0 },
    expanded: { opacity: 1, height: 'auto' }
  };

  // Ensure offerings is always an array
  const offerings = React.useMemo(() => {
    if (Array.isArray(campData.offerings)) {
      return campData.offerings;
    } else if (typeof campData.offerings === 'string') {
      return campData.offerings.split(',').map(o => o.trim());
    }
    return [];
  }, [campData.offerings]);
  
  // Ensure events is always an array
  const events = React.useMemo(() => {
    if (Array.isArray(campData.events)) {
      return campData.events;
    } else if (typeof campData.events === 'string') {
      return campData.events.split(',').map(e => e.trim());
    }
    return [];
  }, [campData.events]);

  return (
    <motion.div 
      className={`genui-card camp-card ${isExpanded ? 'expanded' : ''} ${className}`}
      initial="collapsed"
      animate={isExpanded ? "expanded" : "collapsed"}
      variants={cardVariants}
      transition={{ duration: 0.3 }}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <div className="card-header">
        <div className="card-icon">🔥</div>
        <h3 className="card-title">{campData.name}</h3>
        <button 
          className="card-expand-button" 
          aria-label={isExpanded ? 'Collapse card' : 'Expand card'}
          onClick={(e) => {
            e.stopPropagation();
            setIsExpanded(!isExpanded);
          }}
        >
          {isExpanded ? '−' : '+'}
        </button>
      </div>
      
      {/* Always visible summary info */}
      <div className="card-summary">
        {campData.location && (
          <div className="card-location">
            <span className="location-icon">📍</span>
            <span className="location-text">{campData.location}</span>
          </div>
        )}
        
        {offerings.length > 0 && (
          <div className="card-summary-offerings">
            <span className="offerings-icon">✨</span>
            <span className="offerings-preview">
              {offerings.slice(0, 3).join(', ')}
              {offerings.length > 3 && '...'}
            </span>
          </div>
        )}
      </div>
      
      <motion.div 
        className="card-body"
        variants={contentVariants}
        initial="collapsed"
        animate={isExpanded ? "expanded" : "collapsed"}
        transition={{ duration: 0.3 }}
      >
        {campData.description && (
          <div className="card-description">
            <p>{campData.description}</p>
          </div>
        )}
        
        <div className="card-details">
          {campData.year && (
            <div className="card-detail-item">
              <span className="card-detail-icon">📅</span>
              <span className="card-detail-text">{campData.year}</span>
            </div>
          )}
          
          {offerings.length > 0 && (
            <div className="card-detail-offerings">
              <span className="card-detail-icon">✨</span>
              <div className="offerings-list">
                {offerings.map((offering, idx) => (
                  <span key={idx} className="offering-tag">{offering}</span>
                ))}
              </div>
            </div>
          )}
          
          {events.length > 0 && (
            <div className="card-events">
              <h4><span className="card-detail-icon">🗓️</span> Events:</h4>
              <ul className="events-list">
                {events.map((event, index) => (
                  <li key={index}>{event}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </motion.div>
      
      <div className="card-actions">
        <button
          className="card-action-button primary"
          onClick={(e) => {
            e.stopPropagation();
            window.open(`https://burningman.org/event/camps/`, '_blank');
          }}
        >
          More Info
        </button>
        
        <button
          className="card-action-button secondary"
          onClick={(e) => {
            e.stopPropagation();
            // Copy camp details to clipboard
            const details = `${campData.name}\nLocation: ${campData.location || 'Unknown'}\n${campData.description}`;
            navigator.clipboard.writeText(details);
            alert('Camp details copied to clipboard!');
          }}
        >
          Copy Details
        </button>
      </div>
      
      {onClose && (
        <button
          className="card-close"
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
          aria-label="Close card"
        >
          ×
        </button>
      )}
    </motion.div>
  );
}; 