'use client';

import React, { useState, useRef, useEffect } from "react";
import * as THREE from 'three';
import { useGlobalState } from './GlobalStateContext';
import { checkPermission, requestMicrophonePermission, getPermissionInstructions } from '../utils/permissions';
import "./ImmersiveAudioRecorder.css";
import { Companion } from './GlobalStateContext';

// Helper function to resolve companion ID to companion object
const resolveCompanion = (companion: string | Companion | null): Companion | null => {
  if (!companion) return null;
  if (typeof companion === 'string') {
    // This is just a placeholder, in a real implementation this would fetch the companion
    // For now, we create a basic companion object with the ID
    return {
      id: companion,
      name: companion,
      icon: '🤖',
      isBaseCompanion: false
    };
  }
  return companion;
};

interface ImmersiveAudioRecorderProps {
  isOpen: boolean;
  onClose: () => void;
  onRecordingComplete: (audioBlob: Blob) => void;
}

// Mobile-optimized wisp settings
const optimizedWispSettings = {
  // Reduced particle count for better mobile performance
  particleCount: 3500, // Increased for better mobile distribution
  // Color settings based on companion - will be overridden
  volumeColor: '#E8B4B8',  // Primary gradient color from globals.css
  surfaceColor: '#FFE4E1',  // Soft pink from globals.css
  backgroundColor: '#000000',  // Darker background for better contrast
  // Optimized for performance
  particleSize: 3.0,  // Larger to be more visible on mobile
  flowFieldStrength: 1.2,
  flowFieldFrequency: 0.3,
  rotationSpeed: 0.08, // Slower for more fluid motion
};

export const ImmersiveAudioRecorder: React.FC<ImmersiveAudioRecorderProps> = ({
  isOpen,
  onClose,
  onRecordingComplete,
}) => {
  // IMPORTANT: Remove the early return and DO NOT conditionally call hooks
  // if (!isOpen) return null;
  
  const { activeCompanion, setIsRecording } = useGlobalState();
  const resolvedActiveCompanion = resolveCompanion(activeCompanion);
  const [error, setError] = useState<string | null>(null);
  const [recordingDuration, setRecordingDuration] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  
  // Audio recording references
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const chunksRef = useRef<Blob[]>([]);
  const recordingStartTimeRef = useRef<number | null>(null);
  const timerIntervalRef = useRef<NodeJS.Timeout | null>(null);

  // THREE.js references
  const sceneRef = useRef<THREE.Scene | null>(null);
  const cameraRef = useRef<THREE.PerspectiveCamera | null>(null);
  const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
  const wispRef = useRef<THREE.Points | null>(null);
  const animationFrameRef = useRef<number | null>(null);
  
  // Audio analysis references
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const dataArrayRef = useRef<Uint8Array | null>(null);
  const audioLevelRef = useRef(0);

  // Format duration helper
  const formatDuration = (ms: number) => {
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  // Get companion colors
  const getCompanionColors = () => {
    // Pastel pinks and oranges only - from globals.css gradients
    let volumeColor = '#E8B4B8'; // pink from gradient-primary
    let surfaceColor = '#FFE4E1'; // pink from gradient-user
    
    // Base Loob companion colors - using globals.css gradients - pink variants
    if (!resolvedActiveCompanion || resolvedActiveCompanion.isBaseCompanion) {
      volumeColor = '#D4A5A5'; // from gradient-title
      surfaceColor = '#F5E6E8'; // from gradient-primary
    } 
    // For all companions, use variations of pastel pinks and oranges
    else if (resolvedActiveCompanion.id === 'logis') {
      volumeColor = '#FFB3BA'; // soft pastel pink
      surfaceColor = '#FFDAB9'; // peach/orange pastel
    } 
    else if (resolvedActiveCompanion.id === 'harmoni') {
      volumeColor = '#D8B4BE'; // from gradient-user
      surfaceColor = '#FFE4E1'; // from gradient-user
    } 
    else if (resolvedActiveCompanion.id === 'nexus') {
      volumeColor = '#FFCCB6'; // soft pastel orange
      surfaceColor = '#FEC8D8'; // light pastel pink
    }
    
    return { volumeColor, surfaceColor };
  };

  // Helper to get supported audio format
  const getSupportedMimeType = (): string => {
    // Check if MediaRecorder is available at all
    if (typeof MediaRecorder === 'undefined') {
      console.warn('MediaRecorder API not available in this browser');
      return 'audio/webm'; // Return a default, though it won't work
    }
    
    // Detect device types
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const isMobile = /Mobile|Android|webOS/.test(navigator.userAgent);
    
    console.log(`Detecting audio format for device: ${isIOS ? 'iOS' : isMobile ? 'Mobile' : 'Desktop'}`);
    
    // Special handling for iOS
    if (isIOS) {
      console.log('iOS device detected, checking compatible formats');
      
      // iOS known working formats in priority order
      const iosFormats = [
        'audio/mp4',
        'audio/x-m4a',
        'audio/aac',
        'video/mp4', // Sometimes iOS uses this for audio
        'audio/webm'
      ];
      
      // Check which formats are supported
      const supportedFormats = iosFormats.filter(format => {
        const isSupported = MediaRecorder.isTypeSupported(format);
        console.log(`iOS format ${format}: ${isSupported ? 'SUPPORTED' : 'not supported'}`);
        return isSupported;
      });
      
      // Return first supported format or fallback
      if (supportedFormats.length > 0) {
        console.log(`Using iOS format: ${supportedFormats[0]}`);
        return supportedFormats[0];
      }
      
      // Fallback for iOS
      console.log('No explicitly supported iOS formats found, using audio/mp4 as fallback');
      return 'audio/mp4'; // Most likely to work on iOS even if not reported as supported
    }
    
    // For mobile (non-iOS)
    if (isMobile) {
      console.log('Mobile device detected, checking optimized formats');
      
      // Mobile-optimized formats in priority order
      const mobileFormats = [
        'audio/webm;codecs=opus', // Good quality, well supported on Android
        'audio/webm',
        'audio/mp4',
        'audio/ogg;codecs=opus',
        'audio/wav'
      ];
      
      // Find first supported mobile format
      for (const format of mobileFormats) {
        if (MediaRecorder.isTypeSupported(format)) {
          console.log(`Using mobile format: ${format}`);
          return format;
        }
      }
    }
    
    // For desktop browsers - prioritize quality
    console.log('Checking desktop audio formats');
    const desktopFormats = [
      'audio/webm;codecs=opus', // Best quality on Chrome/Edge
      'audio/webm',
      'audio/ogg;codecs=opus', // Good for Firefox
      'audio/mp4',
      'audio/wav',
      'audio/ogg'
    ];
    
    // Find first supported desktop format
    for (const format of desktopFormats) {
      if (MediaRecorder.isTypeSupported(format)) {
        console.log(`Using desktop format: ${format}`);
        return format;
      }
    }
    
    // Last resort fallback - this may not work, but it's our best guess
    console.warn('No explicitly supported formats found, using audio/webm as default');
    return 'audio/webm';
  };

  // Stop recording and process audio
  const stopRecording = async () => {
    if (!mediaRecorderRef.current) {
      console.log('No active recorder to stop');
      return;
    }
    
    // Prevent multiple calls
    if (isProcessing) {
      console.log('Already processing, ignoring duplicate stop call');
      return;
    }
    
    console.log('Stopping recording...');
    setIsProcessing(true);
    
    try {
      // Create a single promise that will resolve once the recording is finalized
      const audioPromise = new Promise<Blob>((resolve, reject) => {
        // Only set up handlers if we're still recording
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
          // Set handlers before stopping
          
          // This is called when the recorder stops
          mediaRecorderRef.current.onstop = () => {
            console.log('MediaRecorder stopped, finalizing audio...');
            
            try {
              if (!chunksRef.current || chunksRef.current.length === 0) {
                reject(new Error('No audio data captured'));
                return;
              }
              
              // Log the chunks we have
              console.log(`Finalizing ${chunksRef.current.length} audio chunks`);
              const totalSize = chunksRef.current.reduce((size, chunk) => size + chunk.size, 0);
              
              // Create blob with the appropriate MIME type
              const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
              const mimeType = mediaRecorderRef.current?.mimeType || (isIOS ? 'audio/mp4' : 'audio/webm');
              
              // Create a single blob from all chunks
              const audioBlob = new Blob(chunksRef.current, { type: mimeType });
              
              console.log('Audio blob created:', {
                type: audioBlob.type,
                size: audioBlob.size,
                chunks: chunksRef.current.length,
                totalChunkSize: totalSize,
                duration: recordingDuration
              });
              
              resolve(audioBlob);
            } catch (err) {
              console.error('Error in onstop handler:', err);
              reject(err);
            }
          };
          
          // Error handler
          mediaRecorderRef.current.onerror = (event) => {
            console.error('MediaRecorder error:', event);
            reject(new Error('Recording failed'));
          };
          
          // Stop the recorder - this will trigger the onstop event
          console.log('Calling stop() on MediaRecorder');
          mediaRecorderRef.current.stop();
        } else {
          // If recorder isn't recording, use existing chunks if available
          console.log('MediaRecorder not in recording state, using existing chunks');
          
          if (!chunksRef.current || chunksRef.current.length === 0) {
            reject(new Error('No audio data captured'));
            return;
          }
          
          const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
          const mimeType = isIOS ? 'audio/mp4' : 'audio/webm';
          const audioBlob = new Blob(chunksRef.current, { type: mimeType });
          
          resolve(audioBlob);
        }
      });
      
      // Wait for the audio blob
      const audioBlob = await audioPromise;
      
      // Ensure we have a valid blob
      if (!audioBlob || audioBlob.size === 0) {
        throw new Error('Generated audio blob is empty');
      }
      
      console.log(`Sending audio blob to parent (${audioBlob.size} bytes)`);
      
      // Send to parent component - this should happen exactly once
      onRecordingComplete(audioBlob);
      
      // Clean up after successful handoff
      cleanupRecording();
      onClose();
      
    } catch (error) {
      console.error('Error finalizing recording:', error);
      setError('Failed to process recording. Please try again.');
      cleanupRecording();
    }
  };
  
  // Cancel recording and clean up
  const cancelRecording = () => {
    cleanupRecording();
    onClose();
  };
  
  // Comprehensive cleanup of all resources
  const cleanupRecording = () => {
    console.log('Starting cleanup of recording resources...');
    
    // Use refs to check if we've already cleaned up to prevent double-cleanup issues
    const alreadyCleaned = !mediaRecorderRef.current && !streamRef.current && !audioContextRef.current;
    
    if (alreadyCleaned) {
      console.log('Resources already cleaned up, skipping');
      return;
    }
    
    // Clear timer first - this is safe to call even if the timer doesn't exist
    if (timerIntervalRef.current) {
      clearInterval(timerIntervalRef.current);
      timerIntervalRef.current = null;
      console.log('Timer cleared');
    }
    
    // Local variable to track what we've cleaned
    let cleanupStatus = { mediaRecorder: false, tracks: false, audio: false, animation: false };
    
    // 1. Stop media recorder - if it exists and is recording
    if (mediaRecorderRef.current) {
      try {
        // Check state
        const state = mediaRecorderRef.current.state;
        console.log(`MediaRecorder state during cleanup: ${state}`);
        
        if (state === 'recording') {
          console.log('Stopping MediaRecorder');
          
          // Remove any existing handlers to prevent side effects during cleanup
          const recorder = mediaRecorderRef.current;
          recorder.ondataavailable = null;
          recorder.onstop = null;
          recorder.onerror = null;
          
          // Stop recording - this triggers ondataavailable one last time, but we've nulled the handler
          recorder.stop();
        }
        
        cleanupStatus.mediaRecorder = true;
      } catch (e) {
        console.warn('Error stopping MediaRecorder:', e);
      } finally {
        mediaRecorderRef.current = null;
      }
    }
    
    // 2. Stop media tracks
    if (streamRef.current) {
      try {
        const tracks = streamRef.current.getTracks();
        console.log(`Stopping ${tracks.length} media tracks`);
        
        tracks.forEach(track => {
          try {
            track.stop();
          } catch (e) {
            console.warn(`Error stopping track ${track.kind}:`, e);
          }
        });
        
        cleanupStatus.tracks = true;
      } catch (e) {
        console.warn('Error accessing media tracks:', e);
      } finally {
        streamRef.current = null;
      }
    }
    
    // 3. Handle audio resources
    // Disconnect analyzer first
    if (analyserRef.current) {
      try {
        analyserRef.current.disconnect();
        console.log('Audio analyzer disconnected');
      } catch (e) {
        console.warn('Error disconnecting analyzer:', e);
      } finally {
        analyserRef.current = null;
      }
    }
    
    // Close AudioContext with careful state checking
    if (audioContextRef.current) {
      try {
        // Get current state
        const state = audioContextRef.current.state;
        console.log(`AudioContext state before cleanup: ${state}`);
        
        // Only try to close if not already closed
        if (state !== 'closed') {
          console.log(`Closing AudioContext (current state: ${state})`);
          
          // Use promise-based approach for better error handling
          audioContextRef.current.close()
            .then(() => {
              console.log('AudioContext closed successfully');
              cleanupStatus.audio = true;
            })
            .catch(e => {
              console.warn('Error closing AudioContext:', e);
            });
        } else {
          console.log('AudioContext already closed');
          cleanupStatus.audio = true;
        }
      } catch (e) {
        console.warn('Error handling AudioContext:', e);
      } finally {
        audioContextRef.current = null;
      }
    }
    
    // 4. Clean up visual elements
    // Cancel animation frame
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
      animationFrameRef.current = null;
      console.log('Animation frame canceled');
    }
    
    // Dispose of WebGL renderer and resources
    if (rendererRef.current) {
      try {
        rendererRef.current.dispose();
        console.log('WebGL renderer disposed');
      } catch (e) {
        console.warn('Error disposing renderer:', e);
      } finally {
        rendererRef.current = null;
      }
    }
    
    // Clean up THREE.js resources
    if (sceneRef.current && wispRef.current) {
      try {
        // Dispose geometry if exists
        if (wispRef.current.geometry) {
          wispRef.current.geometry.dispose();
        }
        
        // Dispose materials
        if (wispRef.current.material) {
          if (Array.isArray(wispRef.current.material)) {
            wispRef.current.material.forEach(material => material.dispose());
          } else {
            (wispRef.current.material as THREE.Material).dispose();
          }
        }
        
        // Remove from scene
        sceneRef.current.remove(wispRef.current);
        cleanupStatus.animation = true;
        console.log('THREE.js resources cleaned up');
      } catch (e) {
        console.warn('Error cleaning up THREE.js resources:', e);
      } finally {
        wispRef.current = null;
      }
    }
    
    // 5. Reset state variables
    setIsProcessing(false);
    setIsRecording(false);
    setRecordingDuration(0);
    setError(null);
    
    // 6. Clear data references
    chunksRef.current = [];
    recordingStartTimeRef.current = null;
    dataArrayRef.current = null;
    
    console.log('Recording cleanup complete', cleanupStatus);
  };

  // Initialize visualization
  const initVisualization = () => {
    if (!canvasRef.current) return;

    // Set up THREE.js scene
    const scene = new THREE.Scene();
    scene.background = new THREE.Color('#000000'); // Darker background for better contrast
    sceneRef.current = scene;
    
    // Set up camera
    const camera = new THREE.PerspectiveCamera(
      75, 
      canvasRef.current.clientWidth / canvasRef.current.clientHeight, 
      0.1, 
      1000
    );
    camera.position.z = 20; // Further back for better mobile view
    camera.position.y = 0; // Centered vertically for mobile
    camera.position.x = 0; // Centered horizontally for mobile
    cameraRef.current = camera;
    
    // Set up renderer
    const renderer = new THREE.WebGLRenderer({
      canvas: canvasRef.current,
      antialias: false,
      alpha: true,
      powerPreference: 'default',
      precision: 'mediump'
    });
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1.5));
    renderer.setSize(canvasRef.current.clientWidth, canvasRef.current.clientHeight);
    rendererRef.current = renderer;
    
    // Create particles
    createParticles();
    
    // Start animation loop
    animate();
    
    // Set up resize handler
    const handleResize = () => {
      if (!canvasRef.current || !cameraRef.current || !rendererRef.current) return;
      
      const width = canvasRef.current.clientWidth;
      const height = canvasRef.current.clientHeight;
      
      cameraRef.current.aspect = width / height;
      cameraRef.current.updateProjectionMatrix();
      
      rendererRef.current.setSize(width, height);
    };
    
    window.addEventListener('resize', handleResize);
    
    // Return cleanup function for resize handler
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  };

  // Create particles
  const createParticles = () => {
    if (!sceneRef.current) return;
    
    const { volumeColor, surfaceColor } = getCompanionColors();
    
    const geometry = new THREE.BufferGeometry();
    const particleCount = optimizedWispSettings.particleCount;
    const positions = new Float32Array(particleCount * 3);
    const sizes = new Float32Array(particleCount);
    const pulseFactor = new Float32Array(particleCount); // For pulse variation
    const flowFactor = new Float32Array(particleCount); // For liquid flow variation
    const opacityAttributes = new Float32Array(particleCount); // For varied opacity control
    
    // Create fluid, liquid-like distribution for better mobile spread
    const phi = Math.PI * (3 - Math.sqrt(5)); // Golden angle for base distribution
    
    // Adjust camera position for better mobile view
    if (cameraRef.current) {
      cameraRef.current.position.z = 20; // Further back for wider view on mobile
      cameraRef.current.position.y = 0;
      cameraRef.current.position.x = 0;
    }
    
    // Create a more distributed, mobile-friendly fluid shape
    for (let i = 0; i < particleCount; i++) {
      const i3 = i * 3;
      
      // Use a parameter between 0 and 1 for particle placement
      const t = i / particleCount;
      
      // Even lower default opacity for less brightness and more ethereal look
      let opacity = 0.4 + Math.random() * 0.15; // Much lower opacity
      
      // Create a more distributed fluid shape for mobile
      // Use spherical harmonics for a fluid-like blob
      const theta = phi * i;
      const radius = 6 + Math.sin(theta * 3) * 1.2; // Larger radius for better mobile spread
      
      let x, y, z;
      let flowZone;
      
      // Central core (30% of particles - reduced for less central brightness)
      if (i < particleCount * 0.3) {
        // Central fluid core - more distributed
        const r = radius * 0.8 * Math.pow(Math.random(), 0.6); // More spread out core
        const phi = Math.random() * Math.PI * 2;
        const theta = Math.random() * Math.PI;
        
        x = r * Math.sin(theta) * Math.cos(phi);
        y = r * Math.sin(theta) * Math.sin(phi);
        z = r * Math.cos(theta) * 0.8; // Flatter in z-axis for better mobile view
        
        // Add distortion for more alive liquid effect
        x += Math.sin(phi * 4) * 0.6;
        y += Math.sin(theta * 5) * 0.6;
        z += Math.cos(phi * 3) * 0.3;
        
        flowZone = 1; // Core zone
        opacity = 0.35 + Math.random() * 0.15; // Even dimmer core for less central brightness
      }
      // Flowing tendrils and ripples (40% of particles - increased for better distribution)
      else if (i < particleCount * 0.7) {
        // Create more pronounced flowing tendrils that spread wider
        const tendrilFactor = Math.pow(t, 0.7) * 2.0; // Increased spread
        const tendrilAngle = theta * (2 + Math.sin(i * 0.01) * 0.7);
        const tendrilLength = 4 + Math.sin(i * 0.02) * 3; // Longer tendrils for better mobile spread
        
        x = Math.cos(tendrilAngle) * tendrilLength * tendrilFactor;
        y = Math.sin(tendrilAngle) * tendrilLength * tendrilFactor;
        z = (Math.sin(tendrilAngle * 2) + Math.cos(tendrilAngle * 3)) * tendrilFactor * 0.6;
        
        flowZone = 2; // Flowing tendrils zone
        opacity = 0.3 + Math.random() * 0.2; // More varied opacity for more alive look
      }
      // Outer misty particles that drift farther (30% of particles)
      else {
        // Outer misty particles - wider spread
        const mistRadius = radius * (1.2 + Math.random() * 1.2); // Larger radius for better screen coverage
        const mistPhi = Math.random() * Math.PI * 2;
        const mistTheta = Math.random() * Math.PI;
        
        x = mistRadius * Math.sin(mistTheta) * Math.cos(mistPhi);
        y = mistRadius * Math.sin(mistTheta) * Math.sin(mistPhi);
        z = mistRadius * Math.cos(mistTheta) * 0.4; // Flatter for better mobile view
        
        flowZone = 3; // Misty outer zone
        opacity = 0.2 + Math.random() * 0.1; // Dimmer for ethereal look
      }
      
      // Add more fluid-like variation
      const jitter = 0.25; // More variation for more alive look
      x += (Math.random() - 0.5) * jitter * 1.5;
      y += (Math.random() - 0.5) * jitter * 1.5;
      z += (Math.random() - 0.5) * jitter * 0.5; // Less z-jitter for a flatter, more mobile-friendly look
      
      // Save position
      positions[i3] = x;
      positions[i3 + 1] = y;
      positions[i3 + 2] = z;
      
      // Size variation - better distribution for mobile
      const distanceFromCenter = Math.sqrt(x*x + y*y + z*z);
      
      // Flow zone specific size adjustments for mobile
      let sizeFactor = 1.0;
      if (flowZone === 1) sizeFactor = 0.9; // Core particles larger on mobile
      else if (flowZone === 2) sizeFactor = 1.1; // Tendril particles emphasized
      else sizeFactor = 0.85; // Misty particles visible but subtle
      
      // Mobile-optimized particle sizes - more variation for alive feeling
      sizes[i] = Math.max(0.3, 1.0 - distanceFromCenter * 0.04) * sizeFactor * (0.8 + Math.random() * 0.4);
      
      // Create varied pulse and flow timing - more variation for alive feel
      pulseFactor[i] = Math.random();
      flowFactor[i] = Math.random() * 2 * Math.PI; // For fluid flow phase variation
      
      // Store attributes
      opacityAttributes[i] = opacity;
    }
    
    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    geometry.setAttribute('size', new THREE.BufferAttribute(sizes, 1));
    geometry.setAttribute('pulseFactor', new THREE.BufferAttribute(pulseFactor, 1));
    geometry.setAttribute('flowFactor', new THREE.BufferAttribute(flowFactor, 1));
    geometry.setAttribute('baseOpacity', new THREE.BufferAttribute(opacityAttributes, 1));
    
    // Create material with improved shader for more liquid movement
    const material = new THREE.ShaderMaterial({
      vertexShader: `
        uniform float time;
        uniform float audioLevel;
        uniform float particleSize;
        attribute float size;
        attribute float pulseFactor;
        attribute float flowFactor;
        attribute float baseOpacity;
        varying float vOpacity;
        
        // Simplex noise functions for fluid movement
        vec3 mod289(vec3 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
        vec2 mod289(vec2 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
        vec3 permute(vec3 x) { return mod289(((x*34.0)+1.0)*x); }
        
        float snoise(vec2 v) {
          const vec4 C = vec4(0.211324865405187, 0.366025403784439,
                             -0.577350269189626, 0.024390243902439);
          vec2 i  = floor(v + dot(v, C.yy));
          vec2 x0 = v - i + dot(i, C.xx);
          vec2 i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
          vec4 x12 = x0.xyxy + C.xxzz;
          x12.xy -= i1;
          i = mod289(i);
          vec3 p = permute(permute(i.y + vec3(0.0, i1.y, 1.0))
                           + i.x + vec3(0.0, i1.x, 1.0));
          vec3 m = max(0.5 - vec3(dot(x0, x0), dot(x12.xy, x12.xy),
                                  dot(x12.zw, x12.zw)), 0.0);
          m = m*m;
          m = m*m;
          vec3 x = 2.0 * fract(p * C.www) - 1.0;
          vec3 h = abs(x) - 0.5;
          vec3 ox = floor(x + 0.5);
          vec3 a0 = x - ox;
          m *= 1.79284291400159 - 0.85373472095314 * (a0*a0 + h*h);
          vec3 g;
          g.x = a0.x * x0.x + h.x * x0.y;
          g.yz = a0.yz * x12.xz + h.yz * x12.yw;
          return 130.0 * dot(m, g);
        }
        
        // Simplified 3D noise for fluid flow - more compatible with WebGL
        float snoise3D(vec3 p) {
          // Simple fractal noise to simulate 3D effect without complex permutations
          const float scale = 0.05;
          float xy = snoise(vec2(p.x * scale, p.y * scale));
          float yz = snoise(vec2(p.y * scale, p.z * scale));
          float xz = snoise(vec2(p.x * scale, p.z * scale));
          
          // Blend the three 2D noise samples to create a 3D-like effect
          return (xy + yz + xz) * 0.333;
        }
        
        void main() {
          vec3 pos = position;
          
          // Calculate parameters for fluid motion
          float radius = length(pos);
          float theta = atan(pos.z, pos.x);
          float phi = acos(pos.y / (radius + 0.0001)); // Avoid division by zero
          
          // Calculate slower time factors for more fluid motion
          float timeFactor = time * 0.2; // Slightly faster for more alive feel
          float flowTime = time * 0.1; // Slightly faster for more alive flow
          
          // Create fluid flow noise at different scales
          float noise1 = snoise(vec2(theta + flowTime, phi + flowTime * 0.8)) * 0.25; // More displacement
          float noise2 = snoise(vec2(theta * 2.0 - flowTime * 0.6, phi * 2.0 + flowTime * 0.3)) * 0.15;
          
          // Add simplified 3D noise for more complex fluid behavior
          float noise3D = snoise3D(vec3(
              pos.x * 0.2 + flowTime, 
              pos.y * 0.2 - flowTime * 0.8, 
              pos.z * 0.2 + flowTime * 0.4
          )) * 0.4; // More pronounced noise for more alive effect
          
          // Enhanced audio reactivity - slightly more responsive
          float smoothAudio = smoothstep(0.0, 0.9, audioLevel);
          
          // Audio factor for fluid reactivity
          float audioFactor = smoothAudio * 1.5; // Increased for more liveliness
          
          // Individual flow movement - more variation for alive feel
          float flowRate = 0.2 + pulseFactor * 0.3; // More varied flow rates
          float flowPhase = flowFactor; // Use separate flow phase attribute
          
          // Create more pronounced undulating movement
          float waveX = sin(time * flowRate + flowPhase) * 0.5;
          float waveY = cos(time * flowRate * 0.7 + flowPhase * 1.3) * 0.5;
          float waveZ = sin(time * flowRate * 0.5 + flowPhase * 0.9) * 0.3;
          
          // Base breathing motion - slightly faster for more alive feel
          float breathingEffect = sin(time * 0.15) * 0.2;
          
          // Apply fluid displacement with audio reactivity
          vec3 displacedPos = pos;
          
          // Apply fluid motion based on position and audio - more pronounced for mobile
          // Core particles move like a central blob with more life
          if (radius < 3.5) {
            // Core fluid blob motion - more alive
            float blobPulse = breathingEffect + sin(time * 0.3 + radius) * 0.2;
            float audioBlob = smoothAudio * 0.8; // More audio expansion
            
            // Radial expansion/contraction with audio - more pronounced
            displacedPos += normalize(pos) * (blobPulse + audioBlob) * (1.2 + noise3D * 0.7);
            
            // Add gentle swirl - more pronounced
            float angle = time * 0.15 * (1.0 - radius / 4.0);
            float cosA = cos(angle);
            float sinA = sin(angle);
            displacedPos.xz = vec2(
              displacedPos.x * cosA - displacedPos.z * sinA,
              displacedPos.x * sinA + displacedPos.z * cosA
            );
          } 
          // Middle particles flow more dramatically
          else if (radius < 7.0) {
            // Flow particles - more dynamic movement for better mobile effect
            float flowStrength = (radius - 3.5) / 3.5; // Stronger at the middle radius
            
            // Create more dynamic flowing tendrils
            float tendrilMotion = noise3D * (1.2 + audioFactor * 0.7) * flowStrength;
            
            // More pronounced wave motion along tendrils
            displacedPos.x += waveX * flowStrength * (1.2 + audioFactor * 0.5) * 1.2;
            displacedPos.y += waveY * flowStrength * (1.2 + audioFactor * 0.5) * 1.2;
            displacedPos.z += waveZ * flowStrength * (1.2 + audioFactor * 0.4) * 0.7;
            
            // Radial component for expansion/contraction
            displacedPos += normalize(pos) * tendrilMotion * 0.7;
          }
          // Outer misty particles drift more freely and dramatically
          else {
            // Misty particles - more dramatic drift for mobile visibility
            float mistFactor = min(1.0, (radius - 7.0) / 3.0);
            
            // More pronounced soft drift influenced by noise
            displacedPos.x += (noise1 + waveX * 0.7) * mistFactor * (1.2 + audioFactor * 0.3) * 1.3;
            displacedPos.y += (noise2 + waveY * 0.7) * mistFactor * (1.2 + audioFactor * 0.3) * 1.3;
            displacedPos.z += (noise3D * 0.7 + waveZ * 0.4) * mistFactor * (1.2 + audioFactor * 0.25) * 0.7;
            
            // More pronounced slow inward/outward drift
            float mistPulse = sin(time * 0.1 + radius + flowPhase) * 0.3;
            displacedPos += normalize(pos) * mistPulse * mistFactor * 1.3;
          }
          
          // Add more pronounced smooth, small-scale noise displacement for liquid surface detail
          displacedPos += vec3(
            snoise(vec2(pos.x * 2.0 + flowTime, pos.y * 2.0 - flowTime)) * 0.12,
            snoise(vec2(pos.y * 2.0 + flowTime * 0.7, pos.z * 2.0 + flowTime * 0.5)) * 0.12,
            snoise(vec2(pos.z * 2.0 - flowTime * 0.6, pos.x * 2.0 - flowTime * 0.4)) * 0.07
          );
          
          // Very gentle global rotation - slightly more for alive feel
          float rotSpeed = 0.05 * (0.6 + smoothAudio * 0.15);
          float rotAngle = time * 0.03; // Slightly faster rotation for more alive feel
          
          mat3 rotation = mat3(
            cos(rotAngle), 0, sin(rotAngle),
            0, 1, 0,
            -sin(rotAngle), 0, cos(rotAngle)
          );
          displacedPos = rotation * displacedPos;
          
          vec4 mvPosition = modelViewMatrix * vec4(displacedPos, 1.0);
          gl_Position = projectionMatrix * mvPosition;
          
          // Size variation with more pronounced audio reactivity for mobile
          float audioSizeEffect = 1.0 + (audioFactor * 0.6); // More audio effect on size
          float pulseSize = 1.0 + sin(time * 0.25 + pulseFactor * 6.28) * 0.25; // More pronounced size pulsing
          
          // Size adjustment based on position for depth cues
          float radiusSize = 1.0;
          if (radius < 3.5) radiusSize = 1.1; // Core particles larger on mobile
          else if (radius < 7.0) radiusSize = 1.0; // Middle flow normal size
          else radiusSize = 0.9; // Outer mist slightly smaller
          
          float depth = (30.0 - abs(mvPosition.z)) / 30.0;
          float sizeWithDepth = size * particleSize * pulseSize * audioSizeEffect * radiusSize;
          gl_PointSize = sizeWithDepth * (300.0 / length(mvPosition.xyz)); // Increased scale factor for mobile
          
          // Pass opacity to fragment shader - more audio reactive for alive feel
          float audioOpacityEffect = 0.7 + smoothAudio * 0.25; // More audio effect on opacity for alive feel
          vOpacity = baseOpacity * audioOpacityEffect; // Balanced opacity for mobile
        }
      `,
      fragmentShader: `
        uniform vec3 volumeColor;
        uniform vec3 surfaceColor;
        uniform float audioLevel;
        uniform float time;
        
        varying float vOpacity;
        
        void main() {
          // Create very soft, fluid-like particles
          vec2 uv = gl_PointCoord - 0.5;
          float dist = length(uv);
          
          // Create even softer, more fluid-like falloff for mobile
          float edgeSoftness = 0.35; // Very soft edges for fluid look on mobile
          float circle = smoothstep(0.5, 0.5 - edgeSoftness, dist);
          
          // More transparency for fluid look
          if (circle < 0.03) discard;  // More transparent centers for fluid depth
          
          // More pronounced audio-reactive color pulsing for alive feel
          float audioSmooth = smoothstep(0.0, 0.7, audioLevel);
          
          // More pronounced color pulse for alive feel
          float pulseEffect = sin(time * 0.4) * 0.15 + 0.85; // More pronounced
          float audioPulse = pulseEffect * (1.0 + audioSmooth * 0.7); // More audio effect
          
          // More varied color mix - optimized for pastel pinks/oranges
          float mixFactor = mix(0.25, 0.65, audioSmooth * audioPulse); // Wider range for more alive variation
          vec3 baseColor = mix(volumeColor, surfaceColor, mixFactor);
          
          // Add more pronounced soft inner glow for mobile visibility
          float glow = 1.0 - dist * 2.0;
          glow = pow(glow, 1.3) * (0.7 + sin(time * 0.6) * 0.15 + audioSmooth * 0.25); // Stronger glow
          
          // Enhance pastel quality with more variation for alive feel
          float pastelAmount = 0.12 + audioSmooth * 0.12 + sin(time * 0.5) * 0.04; // More pronounced
          vec3 pastelColor = mix(baseColor, vec3(1.0), pastelAmount);
          
          // More audio reactivity in brightness for alive feel
          vec3 color = pastelColor * (1.0 + audioLevel * 0.25); // More audio effect on brightness
          
          // Balanced opacity for mobile visibility while maintaining fluid look
          float alpha = glow * vOpacity * 0.75; // Slightly higher base opacity for mobile
          alpha = smoothstep(0.0, 0.45, alpha) * 0.85; // Adjusted curve for better mobile visibility
          
          gl_FragColor = vec4(color, alpha * circle);
        }
      `,
      uniforms: {
        time: { value: 0 },
        audioLevel: { value: 0 },
        particleSize: { value: optimizedWispSettings.particleSize },
        volumeColor: { value: new THREE.Color(volumeColor) },
        surfaceColor: { value: new THREE.Color(surfaceColor) }
      },
      transparent: true,
      blending: THREE.AdditiveBlending,
      depthWrite: false
    });
    
    const particles = new THREE.Points(geometry, material);
    wispRef.current = particles;
    sceneRef.current.add(particles);
  };

  // Animation loop
  const animate = () => {
    if (!wispRef.current || !rendererRef.current || !sceneRef.current || !cameraRef.current) return;
    
    animationFrameRef.current = requestAnimationFrame(animate);
    
    const time = performance.now() * 0.001;
    
    // Update audio-reactive uniforms with smoother animation
    const material = wispRef.current.material as THREE.ShaderMaterial;
    if (material.uniforms) {
      material.uniforms.time.value = time;
      material.uniforms.audioLevel.value = audioLevelRef.current;
    }
    
    // Slightly more rotation for more alive feel
    const rotationSpeed = 0.0001 + (audioLevelRef.current * 0.0002);
    wispRef.current.rotation.y += rotationSpeed;
    
    // More pronounced camera movement for fluid dynamics and alive feel
    if (cameraRef.current) {
      // Gentle orbital movement for more alive appearance
      const breatheRate = 0.07; // Slightly faster for more alive appearance
      const orbitRadius = 0.02; // Slightly larger movement
      cameraRef.current.position.x = Math.sin(time * breatheRate) * orbitRadius;
      cameraRef.current.position.y = Math.cos(time * breatheRate * 0.7) * orbitRadius * 0.5;
      cameraRef.current.lookAt(0, 0, 0);
    }
    
    // Render
    rendererRef.current.render(sceneRef.current, cameraRef.current);
  };

  // Instead of early return, initialize the component normally
  // and then conditionally render the content based on isOpen state
  useEffect(() => {
    // Only initialize recording when the component is open
    if (isOpen) {
      let cleanupFn: (() => void) | undefined;
      
      async function initialize() {
        try {
          // Check microphone permission
          const permState = await checkPermission('microphone');
          if (permState === 'denied') {
            setError(getPermissionInstructions('microphone'));
            return;
          }
          
          // Get audio stream with improved settings for iOS compatibility
          const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
          const audioConstraints: MediaTrackConstraints = {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true
          };
          
          // iOS-specific constraints if needed
          if (isIOS) {
            // Some iOS devices perform better with these specific settings
            audioConstraints.sampleRate = 44100;
            audioConstraints.channelCount = 1;
          }
          
          console.log('Getting user media with constraints:', audioConstraints);
          
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: audioConstraints
          });
          streamRef.current = stream;
          
          // Log stream tracks for debugging
          console.log('Audio stream obtained:', {
            tracks: stream.getAudioTracks().map(track => ({
              id: track.id,
              kind: track.kind,
              label: track.label,
              enabled: track.enabled,
              muted: track.muted,
              settings: track.getSettings()
            }))
          });
          
          // Set up audio context and analyzer
          const audioContext = new (window.AudioContext || window.webkitAudioContext)();
          audioContextRef.current = audioContext;
          
          const analyser = audioContext.createAnalyser();
          analyserRef.current = analyser;
          // Increased FFT size for better frequency resolution
          analyser.fftSize = 256;
          analyser.minDecibels = -90;
          analyser.maxDecibels = -10;
          // Improved smoothing for less jagged visualization
          analyser.smoothingTimeConstant = 0.8;
          
          const microphone = audioContext.createMediaStreamSource(stream);
          microphone.connect(analyser);
          
          const bufferLength = analyser.frequencyBinCount;
          dataArrayRef.current = new Uint8Array(bufferLength);
          
          // Get optimal supported MIME type
          const mimeType = getSupportedMimeType();
          console.log(`Using MIME type: ${mimeType} for recording`);
          
          // Create recorder with explicit options
          let recorderOptions: MediaRecorderOptions = { 
            mimeType: mimeType,
            audioBitsPerSecond: 128000 // 128kbps for good quality but reasonable file size
          };
          
          // Special handling for iOS
          if (isIOS) {
            console.log('Setting up iOS-specific recorder options');
            // For iOS, sometimes simpler options work better
            recorderOptions = { mimeType }; 
          }
          
          try {
            const recorder = new MediaRecorder(stream, recorderOptions);
            mediaRecorderRef.current = recorder;
            console.log('MediaRecorder created successfully with options:', recorderOptions);
          } catch (e) {
            console.warn('Failed to create MediaRecorder with options:', recorderOptions, e);
            // Fallback to default options
            console.log('Trying MediaRecorder with default options');
            const recorder = new MediaRecorder(stream);
            mediaRecorderRef.current = recorder;
          }
          
          if (!mediaRecorderRef.current) {
            throw new Error('Failed to initialize MediaRecorder');
          }
          
          // Set up data handling
          chunksRef.current = [];
          mediaRecorderRef.current.ondataavailable = (event) => {
            console.log('Data available event:', {
              size: event.data?.size,
              type: event.data?.type,
              timeStamp: event.timeStamp
            });
            
            if (event.data && event.data.size > 0) {
              chunksRef.current.push(event.data);
            }
          };
          
          // Start recording - WITHOUT timeslice parameter to avoid continuous chunking
          // This will only trigger ondataavailable when stop() is called
          console.log('Starting MediaRecorder - will collect data only when stopped');
          mediaRecorderRef.current.start();
          recordingStartTimeRef.current = Date.now();
          setIsRecording(true);
          
          // Set up timer
          timerIntervalRef.current = setInterval(() => {
            if (recordingStartTimeRef.current) {
              const duration = Date.now() - recordingStartTimeRef.current;
              setRecordingDuration(duration);
              
              // Auto-stop after 3 minutes (180 seconds)
              if (duration >= 180000) {
                console.log('Maximum recording duration reached (3 minutes), auto-stopping');
                stopRecording();
              }
            }
          }, 100); // Keep the timer precision at 100ms for responsive UI
          
          // Initialize visualization
          cleanupFn = initVisualization();
          
          // Set up audio analysis
          function updateAudioLevel() {
            if (!analyserRef.current || !dataArrayRef.current) return;
            
            try {
              // Get frequency data
              analyserRef.current.getByteFrequencyData(dataArrayRef.current);
              
              // Focus on voice frequencies (300Hz-3400Hz)
              const minBin = Math.floor(300 * analyserRef.current.fftSize / (44100));
              const maxBin = Math.min(Math.floor(3400 * analyserRef.current.fftSize / (44100)), dataArrayRef.current.length - 1);
              
              // Calculate weighted average with smoother response curve
              let sum = 0;
              let weightSum = 0;
              
              for (let i = minBin; i <= maxBin; i++) {
                // Bell curve weighting centered on human voice frequencies
                const binFreq = i * 44100 / analyserRef.current.fftSize;
                // Broader curve for better responsiveness
                const weight = Math.exp(-0.5 * Math.pow((binFreq - 1200) / 1200, 2)); // Wider curve
                
                sum += dataArrayRef.current[i] * weight;
                weightSum += weight;
              }
              
              const voiceAverage = weightSum > 0 ? sum / weightSum : 0;
              
              // More responsive non-linear scaling for clearer audio reaction
              const normalizedLevel = Math.pow(voiceAverage / 255, 0.7) * 0.9; // Higher scale factor, less aggressive curve
              
              // Slightly faster transitions for more noticeable audio reactivity
              const prevLevel = audioLevelRef.current;
              
              // Faster increase, still smooth decrease
              const increaseSmoothingFactor = 0.85; // Faster to increase (was 0.96)
              const decreaseSmoothingFactor = 0.96; // Still smooth decrease (was 0.98)
              
              const smoothingFactor = normalizedLevel > prevLevel ? 
                increaseSmoothingFactor : decreaseSmoothingFactor;
              
              audioLevelRef.current = prevLevel * smoothingFactor + normalizedLevel * (1 - smoothingFactor);
              
              // Schedule next update
              requestAnimationFrame(updateAudioLevel);
            } catch (error) {
              console.error('Error analyzing audio:', error);
            }
          }
          
          // Start audio analysis
          updateAudioLevel();
          
        } catch (error) {
          console.error('Error starting recording:', error);
          setError('Could not start recording. Please check your microphone permission.');
        }
      }
      
      initialize();
      
      return () => {
        if (cleanupFn) cleanupFn();
        cleanupRecording();
      };
    }
  }, [isOpen]); // Only reinitialize when isOpen changes

  // Return null conditionally, but AFTER all hooks have been defined
  if (!isOpen) {
    return null;
  }

  return (
    <div className="immersive-audio-overlay">
      <div className="immersive-audio-container">
        {/* Companion Header */}
        <div className="companion-header">
          <div className="companion-info">
            <div className="companion-avatar">
              {resolvedActiveCompanion?.isBaseCompanion ? (
                <img src="/favicon.ico" alt="Loob" />
              ) : (
                <span>{resolvedActiveCompanion?.icon || '🤖'}</span>
              )}
            </div>
            <div>
              <h3>{resolvedActiveCompanion?.name || 'Companion'}</h3>
              <p className="status">{isProcessing ? 'Processing...' : 'Listening...'}</p>
            </div>
          </div>
          <div className="recording-duration">
            {formatDuration(recordingDuration)}
          </div>
        </div>
        
        {/* Visualization Area */}
        <div className="visualization-area">
          <canvas ref={canvasRef} />
          {error && (
            <div className="error-message">
              {error}
            </div>
          )}
          
          {/* Processing animation overlay */}
          {isProcessing && (
            <div className="processing-overlay">
              <div className="processing-animation">
                <div className="dot dot1"></div>
                <div className="dot dot2"></div>
                <div className="dot dot3"></div>
              </div>
              <p>Transcribing your message...</p>
            </div>
          )}
        </div>
        
        {/* Controls */}
        <div className="controls">
          <button 
            className="cancel-button" 
            onClick={cancelRecording}
            aria-label="Cancel recording"
          >
            Cancel
          </button>
          <button 
            className={`done-button ${isProcessing ? 'processing' : ''}`}
            onClick={stopRecording}
            disabled={isProcessing}
            aria-label="Done recording"
          >
            {isProcessing ? 'Processing...' : 'Done'}
          </button>
        </div>
      </div>
    </div>
  );
}; 