"use client";

import { useState, useEffect } from 'react';
import { ModalProvider } from './ModalContext';
import { Header } from './Header';
import { Footer } from './Footer';
import { ChatModal } from './ChatModal';
import Map from './Map';
import { Profile } from './Profile';
import { ConfigureModal } from './ConfigureModal';
import { LoobricateProfile } from './LoobricateProfile';
import { NFCReader } from './NFCReader';
import { AddEntry } from './AddEntry';
import { MemoryBank } from './MemoryBank';

export type ViewType = "Chat" | "Map" | "Profile" | "NFCReader" | "AddEntry" | "LoobricateProfile" | "EditEntry" | "MemoryBank";

export const HomePage = () => {
  const [view, setView] = useState<ViewType>("Chat");
  const [configureOpen, setConfigureOpen] = useState(false);
  const [activeLoobricate, setActiveLoobricate] = useState<any>(null);
  const [isMobile, setIsMobile] = useState(false);

  // Check if we're on mobile when component mounts and when window resizes
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    // Initial check
    checkIfMobile();
    
    // Add event listener for window resize
    window.addEventListener('resize', checkIfMobile);
    
    // Cleanup
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);
  
  // Apply mobile view class to body when in mobile mode
  useEffect(() => {
    if (isMobile) {
      document.body.classList.add('mobile-view');
    } else {
      document.body.classList.remove('mobile-view');
    }
    
    return () => {
      document.body.classList.remove('mobile-view');
    };
  }, [isMobile]);

  const handleToggleView = (newView: ViewType) => {
    setView(newView);
  };

  // Add a function to navigate to the LoobricateProfile view with a specific loobricate
  const navigateToLoobricate = (loobricate: any) => {
    setActiveLoobricate(loobricate);
    setView("LoobricateProfile");
  };

  const handleConfigureClick = () => setConfigureOpen(true);
  const handleCloseConfigure = () => setConfigureOpen(false);

  // Set the viewport height function for mobile devices
  useEffect(() => {
    function setViewportHeight() {
      requestAnimationFrame(() => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        
        // Don't disable scrolling or interactions for any view
        document.documentElement.style.overflow = '';
        document.body.style.overflow = '';
        document.body.style.position = '';
        document.body.style.width = '';
        document.body.style.height = '';
      });
    }
    
    setViewportHeight();
    window.addEventListener('resize', setViewportHeight);
    window.addEventListener('orientationchange', setViewportHeight);
    
    return () => {
      window.removeEventListener('resize', setViewportHeight);
      window.removeEventListener('orientationchange', setViewportHeight);
    };
  }, [view]);

  // Set data attribute for the current view
  useEffect(() => {
    document.body.setAttribute('data-current-view', view);
  }, [view]);

  return (
    <ModalProvider>
      <div className="app-container flex flex-col min-h-screen h-screen-dynamic overflow-hidden">
        <Header 
          toggleView={handleToggleView} 
          activeView={view} 
          onConfigureClick={handleConfigureClick}
        />
        <main className="app-content flex-grow flex flex-col items-center overflow-x-hidden">
          {view === "Profile" && (
            <Profile 
              toggleView={handleToggleView}
              setActiveLoobricate={setActiveLoobricate}
            />
          )}
          {view === "Map" && <Map />}
          {view === "Chat" && <ChatModal />}
          {view === "NFCReader" && <NFCReader />}
          {view === "AddEntry" && <AddEntry />}
          {view === "LoobricateProfile" && (
            <LoobricateProfile 
              loobricate={activeLoobricate}
              onClose={() => setView("Profile")}
            />
          )}
          {view === "EditEntry" && <AddEntry />}
          {view === "MemoryBank" && (
            <MemoryBank 
              onClose={() => setView("Profile")}
            />
          )}
          {configureOpen && (
            <ConfigureModal 
              isOpen={configureOpen}
              onClose={handleCloseConfigure} 
            />
          )}
        </main>
        <Footer />
      </div>
    </ModalProvider>
  );
}; 