"use client"

import { useState, useEffect } from 'react';

export type SimilarityMetric = "cosine" | "euclidean" | "dot_product";

const useConfiguration = () => {
  // Safely get values from localStorage
  const getLocalStorageValue = (key: string, defaultValue: any) => {
    if (typeof window !== 'undefined') {
      const storedValue = localStorage.getItem(key);
      if (storedValue !== null) {
        if (key === 'showThinking') {
          console.log(`getLocalStorageValue - ${key}: stored value = "${storedValue}"`, `parsed as:`, storedValue === 'true');
          // Check if the stored value is JSON
          try {
            const parsedValue = JSON.parse(storedValue);
            console.log(`getLocalStorageValue - ${key}: parsed JSON value:`, parsedValue, 'type:', typeof parsedValue);
            if (typeof parsedValue === 'boolean') {
              return parsedValue ? 'true' : 'false';
            }
          } catch (e) {
            // Not JSON, treat as string
            console.log(`getLocalStorageValue - ${key}: not JSON, treating as string`);
          }
        }
        return storedValue;
      }
    }
    if (key === 'showThinking') {
      console.log(`getLocalStorageValue - ${key}: using default = "${defaultValue}"`);
    }
    return defaultValue;
  };

  const [useRag, setUseRag] = useState<boolean>(() => getLocalStorageValue('useRag', 'true') === 'true');
  const [llm, setLlm] = useState<string>(() => getLocalStorageValue('llm', 'deepseek-ai/DeepSeek-R1-Distill-Llama-70B-free'));
  const [similarityMetric, setSimilarityMetric] = useState<SimilarityMetric>(
    () => getLocalStorageValue('similarityMetric', 'cosine') as SimilarityMetric
  );
  const [showThinking, setShowThinking] = useState<boolean>(() => {
    // Get the raw value from localStorage first
    const rawValue = typeof window !== 'undefined' 
      ? localStorage.getItem('showThinking') 
      : null;
    
    console.log('useConfiguration init - raw showThinking from localStorage:', rawValue);
    
    // Explicitly check for "true" string to convert to boolean
    const value = rawValue === 'true';
    
    console.log('useConfiguration init - parsed showThinking as boolean:', value);
    
    // If there was no value in localStorage, set a default
    if (rawValue === null && typeof window !== 'undefined') {
      console.log('useConfiguration init - no existing value, setting default (false)');
      localStorage.setItem('showThinking', 'false');
    }
    
    return value;
  });

  const setConfiguration = (
    rag: boolean, 
    llm: string, 
    similarityMetric: SimilarityMetric,
    showThinking: boolean = false
  ) => {
    console.log('useConfiguration.setConfiguration called with:', { rag, llm, similarityMetric, showThinking });
    setUseRag(rag);
    setLlm(llm);
    setSimilarityMetric(similarityMetric);
    setShowThinking(showThinking);
    
    // Immediately update localStorage
    if (typeof window !== 'undefined') {
      localStorage.setItem('useRag', String(rag));
      localStorage.setItem('llm', llm);
      localStorage.setItem('similarityMetric', similarityMetric);
      
      // For showThinking, use String instead of JSON.stringify to be consistent with Settings.tsx
      localStorage.setItem('showThinking', String(showThinking));
      console.log('useConfiguration.setConfiguration - saved showThinking to localStorage:', String(showThinking), 
        'JSON would be:', JSON.stringify(showThinking));
      
      // Dispatch storage event to ensure all components are updated
      window.dispatchEvent(new Event('storage'));
    }
  }

  // Listen for storage events to update state when localStorage is changed from another component
  useEffect(() => {
    const handleStorageChange = (e: StorageEvent | Event) => {
      // If it's a regular event (manually dispatched), load all values
      if (!(e instanceof StorageEvent)) {
        if (typeof window !== 'undefined') {
          const storedLlm = localStorage.getItem('llm');
          const storedUseRag = localStorage.getItem('useRag') === 'true';
          const storedMetric = localStorage.getItem('similarityMetric') as SimilarityMetric || 'cosine';
          
          // For showThinking, check if it's a JSON string or regular string 
          const showThinkingValue = localStorage.getItem('showThinking');
          let storedThinking = false;
          
          if (showThinkingValue) {
            try {
              // Try parsing as JSON first
              const parsed = JSON.parse(showThinkingValue);
              console.log('useConfiguration: showThinking parsed from JSON:', parsed);
              storedThinking = Boolean(parsed);
            } catch (e) {
              // If not JSON, use string comparison
              console.log('useConfiguration: showThinking not JSON, comparing as string:', showThinkingValue);
              storedThinking = showThinkingValue === 'true';
            }
          }
          
          console.log('useConfiguration: Storage event triggered, new values:', {
            llm: storedLlm,
            useRag: storedUseRag,
            similarityMetric: storedMetric,
            showThinking: storedThinking
          });
          
          if (storedLlm) setLlm(storedLlm);
          setUseRag(storedUseRag);
          setSimilarityMetric(storedMetric);
          setShowThinking(storedThinking);
        }
        return;
      }
      
      // Handle storage event
      if (e.key === 'llm' && e.newValue !== null) {
        console.log('useConfiguration: LLM changed to', e.newValue);
        setLlm(e.newValue);
      } else if (e.key === 'useRag' && e.newValue !== null) {
        setUseRag(e.newValue === 'true');
      } else if (e.key === 'similarityMetric' && e.newValue !== null) {
        setSimilarityMetric(e.newValue as SimilarityMetric);
      } else if (e.key === 'showThinking' && e.newValue !== null) {
        console.log('useConfiguration: showThinking changed to', e.newValue, 'parsed as:', e.newValue === 'true');
        
        // Try parsing as JSON first
        try {
          const parsed = JSON.parse(e.newValue);
          console.log('useConfiguration: showThinking parsed from JSON:', parsed);
          setShowThinking(Boolean(parsed));
        } catch (e) {
          // If not JSON, use string comparison
          console.log('useConfiguration: showThinking not JSON, comparing as string');
          setShowThinking(e.newValue === 'true');
        }
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('storage', handleStorageChange);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('storage', handleStorageChange);
      }
    };
  }, []);

  // Persist to localStorage
  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('useRag', String(useRag));
      localStorage.setItem('llm', llm);
      localStorage.setItem('similarityMetric', similarityMetric);
      localStorage.setItem('showThinking', String(showThinking));
      console.log('useConfiguration: useEffect persistence - showThinking saved as:', 
        String(showThinking), 'Boolean value:', showThinking);
    }
  }, [useRag, llm, similarityMetric, showThinking]);

  return {
    useRag,
    llm,
    similarityMetric,
    showThinking,
    setConfiguration,
  };
}

export default useConfiguration;
